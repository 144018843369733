import classNames from "classnames";
import Paragraph from "components/shared/Paragraph";
import SharedWalletModal from "components/shared/SharedWalletModal";
import Link from "next/link";
import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import Modal from "react-modal";
import { getUser } from "src/services/user.service";
import { WalletBucketItem } from "../../src/context/Wallet.types";
import { useWalletStateContext } from "../../src/context/WalletStateContext";
import { availableWallets } from "../../src/walletApi/api";
import { WalletApp } from "../../src/walletApi/types";
import { walletItems } from "../../src/walletApi/walletItems";
import { buildWalletBucket } from "../../src/walletApi/walletService";
import ConnectCard from "../ConnectCard/ConnectCard";
import { Close } from "../Icons";
import styles from "./WalletModal.module.scss";

//Tabs
const walletTabs = [{ name: "wallet" }];

const customStyles = {
  content: {
    display: "flex",
    top: "calc(50% + 30px)",
    left: "50%",
    bottom: 0,
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "2px",
    justifyContent: "space-between",
    borderColor: "transparent",
    border: "none",
    width: "93%",
    maxWidth: "522px",
    height: "fit-content",
    maxHeight: "95vh",
    padding: 40,
    zIndex: 98,
  },
  overlay: {
    overflow: "auto",
    background: "rgba(36, 33, 33, 0.3)",
    zIndex: 99,
  },
};

Modal.setAppElement("#__next");

type WalletModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

const WalletModal: React.FC<WalletModalProps> = ({ onClose, isOpen }) => {
  const [wallets, setWallets] = useState<WalletApp[]>([] as WalletApp[]);
  const [activeElem, setActiveElem] = useState(walletTabs[0].name);
  const [userName, setUsername] = useState("");
  const [cookies] = useCookies();

  const [walletBucket, setWalletBucket] = useState<WalletBucketItem[]>([]);

  useEffect(() => {
    if (window) {
      buildWalletBucket(walletItems, cookies).then((result) => {
        setWalletBucket(result);
      });
    }
  }, [cookies, setWalletBucket]);

  useEffect(() => {
    const wallets = [...availableWallets()];
    setWallets(wallets);
  }, []);
  const { address } = useWalletStateContext().wallet.activeWallet;
  const wallet = useWalletStateContext().wallet;
  useEffect(() => {
    if (wallet.connected) {
      getUser().then((data) => setUsername(data?.data.name));
    }
  }, [wallet.connected, wallet.activeWallet]);
  const displayName = (name: string, address: string) => {
    if (!address) return name;
    if (name) return `${name} #${address?.slice(6, 10)}`;
    return `Equine User #${address.slice(6, 10)}`;
  };

  const WalletItemsElement = (
    <div className={classNames(styles.connectCards)}>
      {walletBucket.map((item, idx) => {
        const isAvailable = wallets.includes(item.name as WalletApp);
        const itemProps = {
          ...item,
          isAvailable,
        };
        return isAvailable && <ConnectCard key={idx} {...itemProps} />;
      })}
    </div>
  );

  const PrivacyElement = (
    <div className={classNames(styles.terms)}>
      By connecting my crypto wallet, I agree to <Link href="terms-of-service">Equine’s Terms of Service</Link>
    </div>
  );

  return (
    <Modal isOpen={isOpen} onRequestClose={onClose} style={customStyles}>
      <button onClick={onClose} className={classNames(styles.close)}>
        <Close />
      </button>
      <SharedWalletModal
        activeElem={activeElem}
        WalletItems={WalletItemsElement}
        address={
          address ? (
            <div className={styles.wallet}>
              <Paragraph title={displayName(userName || "", address)} />
              <Paragraph color={"grey"} size={1} fontFamily={"urbanist"} title={address} />
            </div>
          ) : (
            <Paragraph className={"text-center w-100"} title={"Connect Wallet"} />
          )
        }
        Privacy={PrivacyElement}
        connectText={"Connect wallet"}
      />
    </Modal>
  );
};

export default WalletModal;
