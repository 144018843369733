import cn from "classnames";
import React from "react";
import ContentLoader from "react-content-loader";
import styles from "./index.module.scss";

const DEFAULT_SIZES = {
  width: 30,
  height: 30,
};

interface IPlaceholderProps {
  type: "rect" | "circle";
  width?: number;
  height?: number;
  repeat?: number;
  axis?: "vertical" | "horizontal";
  darkMode: boolean;
}

const PlaceholderLoader: React.FC<IPlaceholderProps> = ({
  type,
  width = DEFAULT_SIZES.width,
  height = DEFAULT_SIZES.height,
  repeat = 1,
  axis = "vertical",
  darkMode,
}) => {
  const buildShape = (_type: string, idx: number) => {
    switch (_type) {
      case "rect":
        return <rect key={idx} className={cn(styles.elem)} x="0" y="0" rx="5" ry="5" width={width} height={height} />;
      case "circle":
        return <circle key={idx} className={cn(styles.elem)} rx="5" ry="5" width={width} height={height} />;
    }
  };

  const placeholderShapes = () => {
    const arr = [];
    for (let i = 0; i < repeat; i++) {
      arr.push(buildShape(type, i));
    }
    return [...arr];
  };
  placeholderShapes();
  return (
    <ContentLoader
      backgroundColor={darkMode ? "#1C1F2A" : "#C7C7C7"}
      foregroundColor={darkMode ? "#161821" : "white"}
      speed={1}
      viewBox="0 0 380 70"
      className={cn(styles.wrapper)}
    >
      {placeholderShapes()}
    </ContentLoader>
  );
};

export default PlaceholderLoader;
