import { WalletBucketItem } from "src/context/Wallet.types";
import { CachedWalletCookieType, getWalletBucketState } from "../context/WalletStateContext";
import { availableWallets } from "./api";
import { WalletApp } from "./types";
import { WalletItem } from "./walletItems";

const WALLET_COOKIE_KEYS = {
  TYPE: "type",
  WALLET: "wallet",
};

export const getActiveWalletCookie = (
  stakeId: string,
  cookies: Record<string, any>
): CachedWalletCookieType | undefined => {
  if (cookies.hasOwnProperty(stakeId) && cookies[stakeId].type === "wallet") {
    return cookies[stakeId];
  }
};

export const installedWallets = async (retry: number = 0): Promise<WalletApp[]> => {
  await delay(250);
  const wallets = availableWallets();
  if (retry < 4 && wallets.length === 0) {
    return await installedWallets(retry + 1);
  } else {
    return wallets;
  }
};

export const getCachedWalletCookies = (cookies: Record<string, any>): CachedWalletCookieType[] => {
  return Object.values(cookies).reduce((walletCookies, cookie) => {
    if (
      cookie.hasOwnProperty(WALLET_COOKIE_KEYS.TYPE) &&
      cookie[WALLET_COOKIE_KEYS.TYPE] === WALLET_COOKIE_KEYS.WALLET
    ) {
      walletCookies.push(cookie);
    }
    return walletCookies;
  }, []);
};

export async function delay(time: number): Promise<void> {
  return new Promise((resolve) => setTimeout(() => resolve(), time));
}

export const buildWalletBucket = async (
  walletItems: WalletItem[],
  cookies: Record<string, any>
): Promise<Awaited<WalletBucketItem>[]> => {
  const walletCookies = getCachedWalletCookies(cookies);
  const wallets = await installedWallets();
  return Promise.all(walletItems.map((item) => getWalletBucketState(item, walletCookies, wallets)));
};
