import { useMediaQuery } from "react-responsive";
import breakpoints from "../../assets/styles/variables/customMedia.module.scss";

const useResponsive = (size: BreakpointsT) => {
  return useMediaQuery({
    query: `(max-width: ${breakpoints[size]}px)`,
  });
};

export default useResponsive;

type BreakpointsT = "xs" | "sm" | "md" | "lg" | "xl" | "xxl";
