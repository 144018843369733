import cn from "classnames";
import React, { FC } from "react";
import Linkify from "react-linkify";
import styles from "./Paragraph.module.scss";

interface ParagraphProps {
  title: string | number | JSX.Element;
  className?: string;
  size?: 1 | 2 | 3 | 4 | 5;
  linkify?: boolean;
  onClick?: () => void;
  fontFamily?: "marope" | "urbanist" | "nunito-sans";
  color?: "gold" | "black" | "grey" | "white" | "green" | "red";
  onChange?: () => void;
}

const Paragraph: FC<ParagraphProps> = ({
  title,
  className,
  size = 2,
  linkify,
  onClick,
  fontFamily,
  color = "black",
  onChange,
}) => {
  return (
    <p
      onClick={onClick}
      onChange={onChange}
      className={cn(
        className,
        styles.wrapper,
        styles[`hasSize-${size}`],
        styles[`hasColor-${color}`],
        styles[`hasFontFamily-${fontFamily}`]
      )}
    >
      {linkify ? <Linkify>{title}</Linkify> : title}
    </p>
  );
};

export default Paragraph;
