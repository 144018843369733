export default () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.80859 13.364C1.5653 12.993 1.44366 12.8075 1.36215 12.4714C1.30222 12.2244 1.30222 11.7756 1.36215 11.5286C1.44366 11.1925 1.5653 11.007 1.80859 10.636C3.30641 8.35163 6.83727 4 12 4C17.1627 4 20.6936 8.35163 22.1914 10.636C22.4347 11.007 22.5563 11.1925 22.6379 11.5286C22.6978 11.7756 22.6978 12.2244 22.6379 12.4714C22.5563 12.8075 22.4347 12.993 22.1914 13.364C20.6936 15.6484 17.1627 20 12 20C6.83727 20 3.30641 15.6484 1.80859 13.364Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
