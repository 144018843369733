export const languageQuery = `{
    languages {
      homeUrl
      locale
      name
      slug
      code
      id
    }
  }`;
