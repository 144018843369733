export default () => {
  return (
    <svg width="23" height="30" viewBox="0 0 23 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.5039 13.3359C22.6913 14.1275 22.6913 15.8725 21.5038 16.6641L3.1094 28.9271C1.78029 29.8131 0 28.8604 0 27.263V2.73703C0 1.13964 1.78029 0.186861 3.1094 1.07293L21.5039 13.3359Z"
        fill="white"
      />
    </svg>
  );
};
