import { setCookie } from "cookies-next";
import { getBaseUrlFromEnv } from "../system/baseUrl";

export function persistDarkMode(isDarkMode: boolean) {
  const domain = getBaseUrlFromEnv() ? getBaseUrlFromEnv() : "localhost";
  setCookie("darkMode", `${isDarkMode}`, {
    path: "/",
    domain: domain,
  });
  document.getElementsByTagName("html")[0].setAttribute("dark-mode", `${isDarkMode}`);
}
