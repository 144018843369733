export const headerQuery = `query menuItems($lang : MenuLocationEnum){
  menuItems(where: {parentId: "0", location : $lang}) {
    nodes {
      parentId
      id
      uri
      label,
      menuItemFields {
        disabled
      }
      childItems{
        nodes{
          id
          label
          uri
          menuItemFields {
            disabled
          }
        }
      }
    }
  }
}`;

export const subHeader = `query menuItems($lang : MenuLocationEnum){
  menuItems(where: {parentId: "0" , location : $lang}) {
    nodes {
      id
      childItems {
        edges {
          node {
            id
            uri
            label
          }
        }
      }
      uri
      label
    }
  }
}`;
