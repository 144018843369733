import cn from "classnames";
import { FC, ReactNode } from "react";
import { ReactComponent as AdaBigIcon } from "../../../assets/icons/icon-ada-big.svg";
import { ReactComponent as WalletIcon } from "../../../assets/icons/wallet.svg";
import Container from "../Container";
import HamburgerMenu from "../HamburgerMenu";
import IconBox from "../IconBox";
import PlaceholderLoader from "../PlaceholderLoaders";
import styles from "./Header.module.scss";

interface IHeaderProps {
  isResponsive: boolean;
  blockScreen: boolean;
  loggedIn: boolean;
  walletModal: boolean;
  balance: string;
  hamburgerMenu: boolean;
  isApp: boolean;
  setHamburgerMenu: (state: boolean) => void;
  modalToggler: () => void;
  SubHeaderComponent: ReactNode;
  NavBarBox?: ReactNode;
  HeaderMobile: ReactNode;
  ConnectButton: ReactNode;
  LogoComponent: ReactNode;
  DarkModeComponent: ReactNode;
  ProfileIcons: ReactNode[];
  walletInitializing: boolean;
  darkMode: boolean;
}

const SharedHeader: FC<IHeaderProps> = ({
  isResponsive,
  blockScreen,
  loggedIn,
  NavBarBox,
  walletModal,
  modalToggler,
  DarkModeComponent,
  balance,
  SubHeaderComponent,
  HeaderMobile,
  ConnectButton,
  LogoComponent,
  ProfileIcons,
  hamburgerMenu,
  setHamburgerMenu,
  isApp,
  walletInitializing,
  darkMode,
}) => {
  return (
    <>
      <div className={"position-relative"}>
        <div className={cn(styles.element, "d-flex justify-content-center")}>
          <Container>
            <div className={"d-flex justify-content-between w-100 align-items-center"}>
              <div className={styles.headerWrapper + " d-flex justify-content-between"}>
                <div className="d-flex">
                  {/* Logo Component with link coming from source  */}
                  <div className={styles.logo}>{LogoComponent}</div>
                  {!isResponsive && (
                    <div
                      className={cn(
                        styles.navbarWrapper,
                        "d-flex flex-column justify-content-center align-items-center flex-1",
                        !isApp && styles.websiteNavbar
                      )}
                    >
                      {/* Navbar components coming from source */}
                      {NavBarBox}
                    </div>
                  )}
                </div>
                {!blockScreen && walletInitializing ? (
                  <PlaceholderLoader type={"rect"} darkMode={darkMode} width={400} height={60} />
                ) : loggedIn ? (
                  <div className={cn("d-flex align-items-center")}>
                    {isApp && (
                      <IconBox
                        onClick={modalToggler}
                        className={cn(styles.icon, walletModal && styles["isActive"])}
                        type={2}
                      >
                        {
                          <>
                            <span className={cn(styles.icons, styles.isWalletIcon)} key="walletIcon">
                              <WalletIcon />
                            </span>
                            <span className={cn(styles.icons, styles.isAdaBigIcon)} key="adaIcon">
                              <AdaBigIcon />
                            </span>
                          </>
                        }
                        <span className={styles.number}>{balance || ""}</span>
                      </IconBox>
                    )}
                    <div className={cn(styles.profileBox, "d-flex align-items-center")}>
                      {ProfileIcons}
                      {DarkModeComponent}
                      {isResponsive && (
                        <HamburgerMenu isActive={hamburgerMenu} onClick={() => setHamburgerMenu(!hamburgerMenu)} />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={"d-flex align-items-center "}>
                    {!blockScreen && (
                      <div className={cn(styles.actionsWrapper, "d-flex align-items-center")}>
                        {DarkModeComponent}
                        {ConnectButton}
                      </div>
                    )}

                    {isResponsive && (
                      <HamburgerMenu
                        isActive={hamburgerMenu}
                        onClick={() => {
                          setHamburgerMenu(!hamburgerMenu);
                        }}
                      />
                    )}
                  </div>
                )}
              </div>
            </div>
          </Container>
        </div>
        {!isResponsive && SubHeaderComponent}
        {isResponsive && (
          <nav className={cn(hamburgerMenu && styles.mobileDeactive, styles.mobileMenu)}>{HeaderMobile}</nav>
        )}
      </div>
    </>
  );
};

export default SharedHeader;
