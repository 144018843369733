import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useCookies } from "react-cookie";
import { persistDarkMode } from "../../utils/darkMode";

type DarkModeContextStateType = {
  darkMode: boolean;
  setDarkMode: Dispatch<SetStateAction<boolean>>;
};
export const DarkModeContext = createContext<DarkModeContextStateType>({} as DarkModeContextStateType);

export const DARK_MODE_KEY = "darkMode";

const DarkModeContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [cookies] = useCookies([DARK_MODE_KEY]);

  const getInitialDarkMode = useCallback(() => {
    return cookies[DARK_MODE_KEY] === "true" ? true : false;
  }, [cookies]);

  const [darkMode, setDarkMode] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);

  //On mount check storage
  useEffect(() => {
    document.querySelector("html")?.classList.add(".html");
    setDarkMode(getInitialDarkMode());
    setMounted(true);
  }, [setDarkMode, setMounted, getInitialDarkMode]);

  //React to toggle
  useEffect(() => {
    if (mounted) {
      if (darkMode) {
        persistDarkMode(true);
        setDarkMode(true);
      } else {
        persistDarkMode(false);
        setDarkMode(false);
      }
    }
  }, [darkMode, mounted, setDarkMode]);

  return <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>{children}</DarkModeContext.Provider>;
};

export const useDarkModeContext = () => useContext(DarkModeContext);
export default DarkModeContextProvider;
