import axios from "axios";
import log from "utils/log";
import TokenService from "./token.service";

const host = process.env.NEXT_PUBLIC_BACKEND_URL;
if (host === undefined) {
  throw new Error("Please define NEXT_PUBLIC_BACKEND_URL");
}
const client = axios.create({
  baseURL: `${host}/`,
  headers: {
    "Content-type": "application/json",
  },
});

export const updateHeaderAuthorizationToken = (token: string) =>
  (client.defaults.headers.common["Authorization"] = `Bearer ${token}`);

const accessToken = TokenService.getWalletAccessToken() as string;
updateHeaderAuthorizationToken(accessToken || "");

client.interceptors.response.use(
  (config) => config,
  (error) => {
    const originalConfig = error.config;
    if (originalConfig.url === "/auth/login" || !error.response) {
      // Flow-through
    } else if (error.response.status === 401 && !originalConfig._retry) {
      // Access Token is expired

      const refreshToken = TokenService.getWalletRefreshToken();
      originalConfig._retry = true;
      return client
        .post(`${host}/auth/refresh`, { token: refreshToken }, originalConfig)
        .then(async (rs) => {
          const { accessToken, refreshToken } = rs.data;
          TokenService.setWalletTokens(accessToken, refreshToken);
          return accessToken;
        })
        .then((token) => {
          updateHeaderAuthorizationToken(token);
          originalConfig.headers.Authorization = `Bearer ${token}`;
          return client(originalConfig);
        })
        .catch((error) => {
          log.error(error);
          TokenService.flush();
        });
    }

    return Promise.reject(error);
  }
);

export default client;
