import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import DarkLightMode from "components/DarkLightMode";
import SharedHeader from "components/shared/SharedHeader";
import Link from "next/link";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { EquineLogo } from "../../assets/icons/EquineLogo";
import { headerAndFooterQuery } from "../../lib/queries/headerAndFooter";
import { useDarkModeContext } from "../../src/context/DarkModeContext";
import { useLanguageContext } from "../../src/context/LanguageContext";
import { useUiStateContext } from "../../src/context/UiStateContext";
import { useWalletStateContext } from "../../src/context/WalletStateContext";
import useResponsive from "../../system/hooks/useResponsive";
import { BlockScreenWrapperContext } from "../../system/providers/AppBlockScreenProvider";
import Button from "../Button/Button";
import styles from "./Header.module.scss";
import HeaderIconsBar from "./HeaderIconsBar/HeaderIconsBar";
import MobileMenu from "./MobileMenu";
import NavList from "./NavList";
import SubNavList from "./SubNavList";

const Header = () => {
  const router = useRouter();
  const { lang } = useLanguageContext();
  const { darkMode, setDarkMode } = useDarkModeContext();
  const isResponsive = useResponsive("xl");
  const blockScreen = useContext(BlockScreenWrapperContext);
  const {
    mobileMenu: { isMenuOpen, openMenu, closeMenu },
    walletModal: { isModalOpen, openModal, closeModal },
  } = useUiStateContext();
  const { wallet, walletInitializing } = useWalletStateContext();
  const info = wallet.info;
  const isConnected = wallet.activeWallet.isConnected;

  const headerAndFooter = useQuery(
    gql`
      ${headerAndFooterQuery}
    `,
    { variables: { language: lang.code } }
  );

  const headerAndFooterData = headerAndFooter?.data?.pages?.nodes[3].headerAndFooter;
  const { headerLogo, headerCreateUserButton } = headerAndFooterData || {};

  const [mobile, setMobile] = useState<boolean>(true);
  const [mounted, setMounted] = useState<boolean>(false);
  const [modalIsOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => setMounted(true), []);

  const [cookies, setCookies] = useState<string>();
  const [hamburgerMenu, setHamburgerMenu] = useState(isMenuOpen);

  useEffect(() => {
    toggleMenu(isMenuOpen);
  }, [isMenuOpen]);
  const toggleMenu = (state: boolean) => {
    if (state) {
      openMenu();
      setHamburgerMenu(true);
    } else {
      closeMenu();
      setHamburgerMenu(false);
    }
  };
  useEffect(() => {
    if (window) {
      setCookies(document.cookie);
    }
  }, []);

  if (!mounted) return null;

  if (!mobile || modalIsOpen) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "initial";
  }

  const ProfileIcons = (
    <HeaderIconsBar
      key="1"
      setMobile={setMobile}
      mobile={mobile}
      headerAndFooterData={headerAndFooterData}
      openModal={true}
      walletData={info}
      connected={isConnected}
    />
  );

  const ConnectButton = (
    <Button
      text={headerCreateUserButton}
      smallHeight={true}
      width="medium"
      className={classNames(styles.createUserButton)}
      onClick={openModal}
    />
  );

  const LogoComponent = (
    <Link href="/">
      <a className={classNames(styles.logo)}>
        <EquineLogo />
      </a>
    </Link>
  );
  return (
    <>
      <SharedHeader
        darkMode={darkMode}
        loggedIn={isConnected}
        isResponsive={isResponsive}
        blockScreen={blockScreen}
        isApp={false}
        walletModal={modalIsOpen}
        modalToggler={openModal}
        balance="0"
        hamburgerMenu={hamburgerMenu}
        setHamburgerMenu={toggleMenu}
        SubHeaderComponent={router.pathname !== "/news/article/[slug]" && <SubNavList />}
        DarkModeComponent={<DarkLightMode />}
        ProfileIcons={[ProfileIcons]}
        HeaderMobile={<MobileMenu setMobile={setMobile} />}
        NavBarBox={<NavList />}
        ConnectButton={ConnectButton}
        LogoComponent={LogoComponent}
        walletInitializing={walletInitializing}
      />
    </>
  );
};

export default Header;
