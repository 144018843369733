import type { AppProps } from "next/app";
import { useEffect } from "react";
import { AppVersion } from "src/version";
import "../assets/styles/base.scss";
import Layout from "../components/Layout";
import NotificationBox from "../components/NotificationBox/NotificationBox";
import AppContextProvider from "../src/context/AppContextProvider";
import "../styles/globals.css";

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    console.log("Website version: ", AppVersion);
  }, []);
  return (
    <AppContextProvider>
      <Layout>
        <Component {...pageProps} />
        <NotificationBox />
      </Layout>
    </AppContextProvider>
  );
}

export default MyApp;
