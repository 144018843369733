export default () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="burger-icon"
    >
      <rect width="20" height="1.2" rx="0.6" fill="currentColor" />
      <rect y="6" width="20" height="1.2" rx="0.6" fill="currentColor" />
      <rect y="12" width="20" height="1.2" rx="0.6" fill="currentColor" />
    </svg>
  );
};
