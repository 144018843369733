import classNames from "classnames";
import Link from "next/link";
import styles from "./SubFooter.module.scss";
// import { gql, useQuery } from "@apollo/client";
// import { headerAndFooterQuery } from "../../lib/queries/headerAndFooter";
import { DiscordSVG, TwitterSVG } from "../../icons";

const SubFooter = () => {
  // const { lang } = useLanguageContext();

  // const headerAndFooter = useQuery(
  //   gql`
  //     ${headerAndFooterQuery}
  //   `,
  //   { variables: { language: lang.code } }
  // );

  // const headerAndFooterData =
  //   headerAndFooter?.data?.pages?.nodes[0].headerAndFooter;
  return (
    <div className={classNames(styles.element, "container")}>
      <div className={classNames(styles.subfooterContainer)}>
        <ul>
          {/* Adding Urls here 
            TODO: add to both wp instances
          */}
          {/* {headerAndFooterData && headerAndFooterData?.subFooterMenuList?.length &&
            headerAndFooterData.subFooterMenuList.length > 0 &&
            headerAndFooterData.subFooterMenuList.map((el: any, i: any) => (
              <li key={i}>
                <Link href={el.subFooterMenuListItem.url || ""}>
                  <a>{el.subFooterMenuListItem.title}</a>
                </Link>
              </li>
            ))} */}
          <li>
            <a>© 2022 Equine</a>
          </li>
          <li>
            <Link href="/privacy-policy">
              <a>Privacy Policy</a>
            </Link>
          </li>
          <li>
            <Link href="/terms-of-service">
              <a>Terms of Service</a>
            </Link>
          </li>
        </ul>
        <div className={classNames(styles.socialIcons)}>
          {/* Adding social icons
            TODO: add to both wp instances
          */}
          {/* {headerAndFooterData &&
            headerAndFooterData?.subFooterIcons?.length &&
            headerAndFooterData.subFooterIcons.length > 0 &&
            headerAndFooterData.subFooterIcons.map((el: any, i: any) => (
              <div className={classNames(styles.socialIcon)} key={i}>
                <Link href={el.subFooterIconLink.url || ""}>
                  <a>
                    <img src={el.subFooterIcon.sourceUrl} />
                  </a>
                </Link>
              </div>
            ))} */}
          <div className={classNames(styles.socialIcon)}>
            <Link href="https://twitter.com/equinenft">
              <a>
                <TwitterSVG className="social-icon-svg" />
              </a>
            </Link>
          </div>
          <div className={classNames(styles.socialIcon)}>
            <Link href="https://discord.com/invite/equine">
              <a>
                <DiscordSVG className="social-icon-svg" />
              </a>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubFooter;
