import cn from "classnames";
import React, { FC, ReactNode } from "react";
import styles from "./Container.module.scss";

interface ContainerProps {
  children: ReactNode;
  className?: string;
}

const Container: FC<ContainerProps> = ({ children, className }) => {
  return <div className={cn(styles.element, className)}>{children}</div>;
};

export default Container;
