import EternlWallet from "../../components/Icons/walletIcons/EternlWallet.svg";
import Exodus from "../../components/Icons/walletIcons/ExodusWallet.svg";
import FlintWallet from "../../components/Icons/walletIcons/FlintWallet.svg";
import GeroWallet from "../../components/Icons/walletIcons/GeroWallet.svg";
import Lace from "../../components/Icons/walletIcons/LaceWallet.svg";
import NamiWallet from "../../components/Icons/walletIcons/NamiWallet.svg";
import Typhon from "../../components/Icons/walletIcons/TyphonWallet.svg";
import Yoroi from "../../components/Icons/walletIcons/YoroiWallet.svg";
import { WalletApp } from "./types";

export type WalletItem = {
  name: WalletApp;
  icon: { src: string };
};

export const walletItems: WalletItem[] = [
  { name: "Nami", icon: NamiWallet },
  { name: "Eternl", icon: EternlWallet },
  { name: "Flint", icon: FlintWallet },
  { name: "Gero", icon: GeroWallet },
  { name: "Yoroi", icon: Yoroi },
  { name: "Typhon", icon: Typhon },
  { name: "Lace", icon: Lace },
  { name: "Exodus", icon: Exodus },
];
