import { WalletApp, WalletInfo } from "../../src/walletApi/types";
import { WalletItem } from "../../src/walletApi/walletItems";

export const ACTIVE_WALLET_COOKIE_KEY = "_activeWallet";

export interface CookieSetOptions {
  path?: string;
  expires?: Date;
  maxAge?: number;
  domain?: string;
  secure?: boolean;
  httpOnly?: boolean;
  sameSite?: boolean | "none" | "lax" | "strict";
  encode?: (value: string) => string;
}

export type RemoveCookiesFnType = (name: string, options?: CookieSetOptions | undefined) => void;

export type ActiveWalletCookieType = {
  stakeId: string | null;
  walletName: WalletApp;
};

export type WalletBucketItem = WalletItem & {
  isAvailable: boolean;
  isEnabled: boolean;
  stakeId: string | null;
  refreshToken: string | null;
  accessToken: string | null;
};

export interface IWalletState {
  activeWallet: ActiveWalletType;
  walletsBucket: WalletBucketItem[];
}

export type WalletAuthParamType = {
  walletName: WalletApp;
  stakeId: string;
};

export type ActiveWalletType = {
  name: WalletApp;
  balance: string;
  address: string;
  isConnected: boolean;
  walletInfo: WalletInfo | null;
  access: string;
  refresh: string;
};
