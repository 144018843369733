import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import Link from "next/link";
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import { getChats, getNofications } from "src/services/notification.service";
import log from "utils/log";
import { BalanceIcon } from "../../../assets/icons/BalanceIcon";
import { languageQuery } from "../../../lib/queries/language";
import tokenService from "../../../src/api/token.service";
import { useLanguageContext } from "../../../src/context/LanguageContext";
import { useUiStateContext } from "../../../src/context/UiStateContext";
import { useWalletStateContext } from "../../../src/context/WalletStateContext";
import { WalletInfo } from "../../../src/walletApi/types";
import { clearWalletCookies } from "../../../system/utils/walletUtils";
import { DownArrow, Language, Logout, Mail, Notification, User, Wallet } from "../../Icons";
import styles from "./NavlistMobile.module.scss";

export type HeaderIconsBarProps = {
  setMobile: Dispatch<SetStateAction<boolean>>;
  mobile: Boolean;
  headerAndFooterData: any;
  openModal: any;
  walletData?: WalletInfo;
  connected: boolean;
};

const HeaderIconsBar: React.FC<HeaderIconsBarProps> = ({ setMobile, mobile, walletData, connected }) => {
  const { lang, setLang } = useLanguageContext();
  const {
    walletModal: { openModal },
  } = useUiStateContext();
  const { clearWalletData, wallet } = useWalletStateContext();
  const [cookies, setCookies, removeCookies] = useCookies();
  const [languageActive, setLanguageActive] = useState(false);
  const isConnected = wallet.activeWallet.isConnected;
  const [notificationCount, setNotificationCount] = useState(0);
  const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
  const componentRef = useRef();

  const languageData = useQuery(
    gql`
      ${languageQuery}
    `
  );

  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e: any) {
      if (componentRef && componentRef.current) {
        const ref: any = componentRef.current;
        if (!ref.contains(e.target)) {
          setLanguageActive(false);
        }
      }
    }
  }, []);
  useEffect(() => {
    if (isConnected) {
      getNofications().then((data) => {
        const unreadCount = data?.data.data.filter((notification: any) => !notification.isRead).length;
        setNotificationCount(unreadCount);
      });
      getChats().then((data) => {
        const unreadCount = data?.data.reduce((unread: boolean, chat: any) => unread + chat.state.unread || 0, 0);
        log.debug("Unread chat count", unreadCount);
        setUnreadMessagesCount(unreadCount);
      });
    }
  }, [isConnected, wallet.activeWallet]);
  const handleSignOut = () => {
    tokenService.flush();
    clearWalletCookies();
    clearWalletData();
  };

  return (
    <nav className={classNames(styles.element)}>
      {!languageData.loading && !languageData.error && languageData.data && (
        <div className={classNames(styles.menuListItem)}>
          <div
            className={classNames(styles.language, languageActive && styles.languageActive)}
            onClick={() => setLanguageActive(!languageActive)}
            ref={componentRef as any}
          >
            <Language />
            <p>{lang.code}</p>
            <DownArrow />
            <div className={classNames(styles.options, languageActive && styles.optionsActive)}>
              {/* Dont change unless client wants multilingual functionality*/}

              {/* Turning off multiple languages */}
              {/* {languageData.data.languages.map((el: any) => (
                <span
                  key={el.id}
                  onClick={() => setLang({ code: el.code, name: el.name })}
                  className={classNames(
                    lang.code === el.code && styles.languageActive
                  )}
                >
                  {el.name === "HornjoserbÅ¡Ä‡ina" ? "Deutsch" : el.name}
                </span>
              ))} */}
              {/* Enabling only English Option */}
              <span
                key={languageData.data.languages[0].id}
                onClick={() =>
                  setLang({
                    code: languageData.data.languages[0].code,
                    name: languageData.data.languages[0].name,
                  })
                }
                className={classNames(lang.code === languageData.data.languages[0].code && styles.languageActive)}
              >
                {"English"}
              </span>
            </div>
          </div>
        </div>
      )}

      {connected && (
        <>
          <div className={classNames(styles.wallet)} onClick={openModal}>
            <Wallet />
            <BalanceIcon className={""} />
            <p className={styles.balance}>{wallet.activeWallet.balance}</p>
          </div>
          <Link href={`${process.env.NEXT_PUBLIC_APP_URL}/my_account/notifications`}>
            <span className={classNames(styles.multiColorSvg)}>
              {notificationCount !== 0 && <div className={styles.count}>{notificationCount}</div>}
              <Notification />
            </span>
          </Link>
          <Link href={`${process.env.NEXT_PUBLIC_APP_URL}/my_account/about`}>
            <span className={classNames(styles.multiColorSvg)}>
              <User />
            </span>
          </Link>
          <Link href={`${process.env.NEXT_PUBLIC_APP_URL}/chat-list`}>
            <span className={classNames(styles.multiColorSvg)}>
              {unreadMessagesCount !== 0 && <div className={styles.count}>{unreadMessagesCount}</div>}
              <Mail />
            </span>
          </Link>
          <span onClick={handleSignOut}>
            <Logout />
          </span>
        </>
      )}
    </nav>
  );
};

export default HeaderIconsBar;
