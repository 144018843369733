import { deleteCookie, getCookies, setCookie } from "cookies-next";
import { ACTIVE_WALLET_COOKIE_KEY } from "../../src/context/Wallet.types";
import { WalletApp } from "../../src/walletApi/types";
import { getBaseUrlFromEnv } from "../baseUrl";

const WALLET_COOKIE_KEYS = {
  TYPE: "type",
  WALLET: "wallet",
};

const CookieValidity = {
  valid: 604800,
  invalid: 0,
};

export const setCachedWalletCookie = (stakeId: string, accessToken: string, refreshToken: string) => {
  const domain = getBaseUrlFromEnv() ? getBaseUrlFromEnv() : "localhost";
  setCookie(
    stakeId,
    {
      stakeId,
      accessToken,
      refreshToken,
      type: WALLET_COOKIE_KEYS.WALLET,
    },
    {
      path: "/",
      domain,
      maxAge: CookieValidity.valid,
    }
  );
};

export const setWalletCookies = (
  walletName: WalletApp,
  stakeId: string,
  accessToken: string,
  refreshToken: string
): void => {
  const domain = getBaseUrlFromEnv() ? getBaseUrlFromEnv() : "localhost";
  setCachedWalletCookie(stakeId, accessToken, refreshToken);
  setCookie(ACTIVE_WALLET_COOKIE_KEY, walletName, {
    path: "/",
    domain,
    maxAge: CookieValidity.valid,
  });
};

export const clearWalletCookies = () => {
  const domain = getBaseUrlFromEnv() ? getBaseUrlFromEnv() : "localhost";
  const cookies = getCookies();
  document.cookie = `${ACTIVE_WALLET_COOKIE_KEY}=null;path=/;domain=${domain};max-age=${CookieValidity.invalid};`;
  Object.keys(cookies).forEach((key) => {
    deleteCookie(key, { path: "/", domain });
    document.cookie = `${key}=null;path=/;domain=${domain};max-age=${CookieValidity.invalid};`;
  });
};
