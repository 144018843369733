import React from "react";
import { ToastContainer, ToastContainerProps } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const toastContainerConfig: ToastContainerProps = {
  autoClose: 2000,
};

const NotificationBox: React.FC = () => <ToastContainer {...toastContainerConfig} />;

export default NotificationBox;
