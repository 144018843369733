import { Mock } from "./mock";
import * as Purs from "./purs-equine-api.js";
import type {
  Addr,
  AssetClass,
  AuthentifyInfo,
  Horse,
  HorseResult,
  Jockey,
  Pedigree,
  RaceFilters,
  RaceId,
  RaceInfo,
  RaceInfoStatic,
  ShelleyAddress,
  Skin,
  StakingAddress,
  Training,
  WalletApp,
  WalletId,
  WalletInfo,
} from "./types";

export const debug = () => Purs.main(); // exported for testing

////////////////////////////////////////////////////////////////
// Wallet
////////////////////////////////////////////////////////////////

export const isEnabled = async (walletApp: WalletApp): Promise<boolean> => Purs.isEnabled(walletApp)();

export const availableWallets = (): WalletApp[] => Purs.availableWallets();

export const selectWallet = async (walletApp: WalletApp): Promise<WalletInfo> =>
  Purs.getWalletInfo(walletApp as WalletId)();

// WalletId
// When querying the linked wallet, send the WalletApp as the id
// When querying other people's wallet, the bech 32 staking address is used
export const getWalletInfo = async (walletId: WalletId): Promise<WalletInfo> => Purs.getWalletInfo(walletId)();

export const authentifyWallet = async (walletApp: WalletApp): Promise<AuthentifyInfo> =>
  Purs.authentifyWallet(walletApp)();

export const ownStakeAddr = async (walletApp: WalletApp): Promise<StakingAddress> => Purs.ownStakeAddr(walletApp)();

export const resolveStakingAddr = async (address: Addr): Promise<ShelleyAddress> => Purs.resolveAddr(address)();

////////////////////////////////////////////////////////////////
// Horses
////////////////////////////////////////////////////////////////

export const getHorses = async (walletId: WalletId): Promise<Horse[]> => Purs.getHorsesByWalletId(walletId)();

export const getHorse = async (asset: AssetClass): Promise<Horse | null> => Purs.getHorse(asset)();

////////////////////////////////////////////////////////////////
// Jockeys
////////////////////////////////////////////////////////////////

export const getJockeys = async (walletId: WalletId): Promise<Jockey[]> => Purs.getJockeysByWalletId(walletId)();

export const getJockey = async (asset: AssetClass): Promise<Jockey | null> => Purs.getJockey(asset)();

////////////////////////////////////////////////////////////////
// Skins
////////////////////////////////////////////////////////////////

export const getSkins = async (walletId: WalletId): Promise<Skin[]> => Purs.getSkinsByWalletId(walletId)();

export const getSkin = async (asset: AssetClass): Promise<Skin | null> => Purs.getSkin(asset)();

////////////////////////////////////////////////////////////////
// Races
////////////////////////////////////////////////////////////////

export const getRaces = async (filter?: RaceFilters): Promise<RaceInfo[]> => Mock.getRaces(filter);

export const getRaceInfo = async (id: RaceId): Promise<RaceInfo | null> => Mock.getRace(id);

export const activateHorse = async (asset: AssetClass, name: string): Promise<boolean> => Mock.delayTrue(asset, name);

export const registerToRace = async (asset: AssetClass, id: RaceId): Promise<boolean> => Mock.delayTrue(asset, id);

export const deregisterfromRace = async (asset: AssetClass, id: RaceId): Promise<boolean> => Mock.delayTrue(asset, id);

export const scratchFromRace = async (asset: AssetClass, id: RaceId): Promise<boolean> => Mock.delayTrue(asset, id);

export const completeRace = async (asset: AssetClass, id: RaceId): Promise<HorseResult> => Mock.completeRace(asset, id);

export const getTraining = async (asset: AssetClass): Promise<Training> => Mock.getTraining(asset);

export const setTraining = async (asset: AssetClass, training: Training) => Mock.delayTrue(asset, training);

////////////////////////////////////////////////////////////////
// Races - Admin
////////////////////////////////////////////////////////////////

export const adminCreateRace = async (info: RaceInfoStatic): Promise<RaceId> => Mock.adminCreateRace(info);

export const adminUpdateRace = async (info: RaceInfoStatic): Promise<RaceId> => Mock.adminCreateRace(info);

export const adminDeleteRace = async (id: RaceId): Promise<boolean> => Mock.delayTrue(id);

////////////////////////////////////////////////////////////////
// Breeding
////////////////////////////////////////////////////////////////

export const switchToBreeding = (horse: AssetClass): Promise<boolean> => Mock.delayTrue(horse);

export const breed = (stallion: AssetClass, mare: AssetClass): Promise<boolean> => Mock.delayTrue(stallion, mare);

export const getStuds = async (walletId: WalletId): Promise<Horse[]> => {
  const horses = await getHorses(walletId);
  return horses.filter((horse) => horse.volatile.isBreeding && horse.static.gender === "Male");
};

export const getBreedingFee = (): Promise<number> => Mock.delayNumber(85);

export const emitBreedingVouchers = (quantity: number): Promise<boolean> => Mock.delayTrue(quantity);

export const getPedigree = (horse: AssetClass): Promise<Pedigree> => Mock.delay<Pedigree>({}, horse);

export const getProginy = (horse: AssetClass): Promise<Horse[]> => Mock.delay<Horse[]>([], horse);

////////////////////////////////////////////////////////////////
// Breeding - Admin
////////////////////////////////////////////////////////////////
