import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import { useEffect, useRef, useState } from "react";
import { headerQuery } from "../../lib/queries/header";
import { languageQuery } from "../../lib/queries/language";
import { whitepaperQuery } from "../../lib/queries/whitepaper";
import { useLanguageContext } from "../../src/context/LanguageContext";
import { DownArrow, Language } from "../Icons";
import Navlink from "../Navlink";
import styles from "./Header.module.scss";
import { IMenuItems } from "./type";

const NavList = () => {
  const { lang, setLang } = useLanguageContext();
  const [languageActive, setLanguageActive] = useState(false);
  const componentRef = useRef();
  const router = useRouter();
  const { pathname, asPath, query, locale } = router;
  const { data, error, loading } = useQuery(
    gql`
      ${headerQuery}
    `,
    {
      variables: {
        lang: lang.code === "EN" ? "MAIN_MENU" : `MAIN_MENU___${lang.code}`,
      },
    }
  );
  const languageData = useQuery(
    gql`
      ${languageQuery}
    `
  );
  const whitepaperData = useQuery(
    gql`
      ${whitepaperQuery}
    `
  );
  useEffect(() => {
    document.addEventListener("click", handleClick);
    return () => document.removeEventListener("click", handleClick);
    function handleClick(e: any) {
      if (componentRef && componentRef.current) {
        const ref: any = componentRef.current;
        if (!ref.contains(e.target)) {
          setLanguageActive(false);
        }
      }
    }
  }, []);
  return (
    <nav>
      <ul className={classNames(styles.menuList)}>
        {!loading &&
          !error &&
          data &&
          data.menuItems.nodes.slice(0).map((el: IMenuItems) => {
            return (
              !el.parentId && (
                <li key={el.id} className={classNames(styles.menuListItem, el.label === "marketplace" && styles.hide)}>
                  {el.menuItemFields.disabled === null ? (
                    <Navlink
                      activeClassName={classNames(styles.active)}
                      activeHref={el.uri === "/guide-2/" ? "/faq" : el.uri}
                      href={
                        el.uri === "/guide-2/"
                          ? "/faq/getting-started"
                          : el.uri === "/news/"
                          ? "/news/all-news"
                          : el.uri.includes("stables/race")
                          ? `${process.env.NEXT_PUBLIC_APP_URL}/stables/race`
                          : el.uri.includes("race")
                          ? `${process.env.NEXT_PUBLIC_APP_URL}/race`
                          : el.uri.includes("social")
                          ? `${process.env.NEXT_PUBLIC_APP_URL}/social/friends`
                          : el.uri.includes("friends")
                          ? `${process.env.NEXT_PUBLIC_APP_URL}/social/friends`
                          : el.uri.includes("my-guild")
                          ? `${process.env.NEXT_PUBLIC_APP_URL}/social/my-guild`
                          : el.uri.includes("all-guilds")
                          ? `${process.env.NEXT_PUBLIC_APP_URL}/social/all-guilds`
                          : el.uri === "/guide/"
                          ? "/faq/getting-started"
                          : el.uri === "/?page_id=552"
                          ? "/faq/getting-started"
                          : el.uri
                      }
                    >
                      <a>{el.label === "guide" ? "FAQ" : el.label}</a>
                    </Navlink>
                  ) : (
                    <span className={classNames(styles.disabled)}>{el.label}</span>
                  )}
                </li>
              )
            );
          })}
        {whitepaperData.data?.pages && (
          <Link href={"/whitepaper"}>
            <li className={classNames(styles.menuListItem, styles.menuItemBorder)}>
              <a>{"WHITEPAPER"}</a>
            </li>
          </Link>
        )}
        {!languageData.loading && !languageData.error && languageData.data && (
          <li className={classNames(styles.menuListItem)}>
            <div
              className={classNames(styles.language, languageActive && styles.languageActive)}
              onClick={() => setLanguageActive(!languageActive)}
              ref={componentRef as any}
            >
              <Language />
              <p>{lang.code === "HSB" ? "DEU" : lang.code}</p>
              <DownArrow />
              <div className={classNames(styles.options, languageActive && styles.optionsActive)}>
                {/* Removing List of languages per request and showing only english */}
                {/* {languageData.data.languages.map((el: any) => (
                  <span
                    key={el.id}
                    onClick={() => setLang({ code: el.code, name: el.name })}
                    className={classNames(
                      lang.code === el.code && styles.languageActive
                    )}
                  >
                    {el.name  === "HornjoserbÅ¡Ä‡ina" ? "Deutsch"  : el.name }
                  </span>
                ))} */}
                {/* Showing only English */}
                {
                  <span
                    key={languageData.data.languages[0].id}
                    onClick={() =>
                      setLang({
                        code: languageData.data.languages[0].code,
                        name: languageData.data.languages[0].name,
                      })
                    }
                    className={classNames(lang.code === languageData.data.languages[0].code && styles.languageActive)}
                  >
                    {/* {languageData.data.languages[0].name} */}
                    {"English"}
                  </span>
                }
              </div>
            </div>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default NavList;
