import React, { ReactNode } from "react";
import styles from "./WalletModal.module.scss";

interface IWalletModal {
  activeElem: string;
  address: ReactNode;
  connectText: string;
  WalletItems: JSX.Element;
  Privacy: JSX.Element;
}

const SharedWalletModal: React.FC<IWalletModal> = ({ activeElem, address, WalletItems, Privacy, connectText }) => {
  return (
    <>
      {/*<div className={'d-flex align-items-center'}>*/}
      {/*{walletTabs.map(({ name }) => (*/}
      {/*    <WalletTab*/}
      {/*        onClick={() => setActiveElem(name)}*/}
      {/*        key={name}*/}
      {/*        text={name}*/}
      {/*        activeTab={activeElem === name}*/}
      {/*        className={activeElem === name && styles['isActive']}*/}
      {/*    />*/}
      {/*))}*/}
      {/*</div>*/}
      {activeElem === "wallet" && (
        <div className={"d-flex justify-content-between flex-column"}>
          <div>
            <div className={styles.walletAddressContainer + " d-flex align-items-center"}>{address}</div>
          </div>
          <div>{WalletItems}</div>
          <div>{Privacy}</div>
        </div>
      )}
      {/*{activeElem === 'balance' && (*/}
      {/*    <>*/}
      {/*        <Balance balance={balance} />*/}
      {/*    </>*/}
      {/*)}*/}
    </>
  );
};

export default SharedWalletModal;
