import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import { useState } from "react";
import { headerQuery } from "../../lib/queries/header";
import { whitepaperQuery } from "../../lib/queries/whitepaper";
import { useLanguageContext } from "../../src/context/LanguageContext";
import { useUiStateContext } from "../../src/context/UiStateContext";
import Navlink from "../Navlink";
import SubNavlink from "../SubNavLink";
import styles from "./Header.module.scss";
import { MobileMenuProps } from "./type";

const EXTERNAL_URIS = ["race", "stable", "social"];
const MobileMenu = ({ setMobile }: MobileMenuProps) => {
  const { lang } = useLanguageContext();
  const {
    mobileMenu: { isMenuOpen, openMenu, closeMenu },
  } = useUiStateContext();

  // const { ctxWalletData, setCTXWalletData } = useContext(WalletContext);
  const [modalIsOpen, setIsOpen] = useState(false);
  // async function enableWallet(wallet: WalletApp) {
  //   try {
  //     localStorage.setItem(
  //       "wallet",
  //       JSON.stringify(await Api.selectWallet(wallet))
  //     );
  //     setCTXWalletData(await Api.selectWallet(wallet));
  //     return await Api.selectWallet(wallet);
  //   } catch {
  //     localStorage.removeItem("wallet");
  //     setCTXWalletData(null);
  //     alert(`You must install ${wallet} browser extension first`);
  //   } finally {
  //     setIsOpen(false);
  //   }
  // }
  const { data, error, loading } = useQuery(
    gql`
      ${headerQuery}
    `,
    {
      variables: {
        lang: lang.code === "EN" ? "MAIN_MENU" : `MAIN_MENU___${lang.code}`,
      },
    }
  );
  const whitepaperData = useQuery(
    gql`
      ${whitepaperQuery}
    `
  );
  return (
    <div className={classNames(styles.mobileMenuList)}>
      {!loading &&
        !error &&
        data &&
        data.menuItems?.nodes?.map((el: any) => (
          <div key={el.id} className={classNames(el.label === "marketplace" && styles.hide)}>
            <ul>
              {el.menuItemFields.disabled === null ? (
                <div
                  onClick={() => {
                    setMobile(true);
                    closeMenu();
                  }}
                  className={classNames(styles.mobileMenuListMainItem)}
                >
                  <Navlink
                    activeClassName={classNames(styles.active)}
                    activeHref={el.uri}
                    href={
                      el.uri === "/guide-2/"
                        ? "/faq/getting-started"
                        : el.uri === "/news/"
                        ? "/news/all-news"
                        : el.uri === "/stables/race"
                        ? `${process.env.NEXT_PUBLIC_APP_URL}/stables/race`
                        : el.uri === "/race/"
                        ? `${process.env.NEXT_PUBLIC_APP_URL}/race`
                        : el.uri === "/guide/"
                        ? "/faq/getting-started"
                        : el.uri === "/?page_id=552"
                        ? "/faq/getting-started"
                        : el.uri
                    }
                  >
                    <a>{el.label}</a>
                  </Navlink>
                </div>
              ) : (
                <h4 className={classNames(styles.disabledMobileMenu)}>{el.label}</h4>
              )}

              {el.childItems &&
                el.childItems?.nodes.length > 0 &&
                el.childItems?.nodes.map((t: any) => (
                  <li
                    key={t.id}
                    onClick={() => {
                      setMobile(true);
                      closeMenu();
                    }}
                  >
                    {!t.menuItemFields.disabled ? (
                      <SubNavlink
                        activeClassName={classNames(styles.active)}
                        href={
                          EXTERNAL_URIS.find((el) => t.uri.includes(el))
                            ? `${process.env.NEXT_PUBLIC_APP_URL}${t.uri}`
                            : t.uri
                        }
                        activeHref={t.uri}
                      >
                        <a>{t.label}</a>
                      </SubNavlink>
                    ) : (
                      <a className={classNames(styles.disabledMobileMenu)}>{t.label}</a>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        ))}
      {whitepaperData.data?.pages && (
        <h2
          className={classNames(styles.whitepaper)}
          dangerouslySetInnerHTML={{
            __html: whitepaperData.data?.pages?.nodes[0].content,
          }}
        ></h2>
      )}

      {/*{!ctxWalletData &&*/}
      {/*  headerAndFooterData &&*/}
      {/*  headerAndFooterData.headerCreateUserButton && (*/}
      {/*    <Button*/}
      {/*      text={headerAndFooterData.headerCreateUserButton}*/}
      {/*      smallHeight={true}*/}
      {/*      width="small"*/}
      {/*      className={classNames(styles.connectWalletButton)}*/}
      {/*      onClick={openModal}*/}
      {/*    />*/}
      {/*  )}*/}
    </div>
  );
};

export default MobileMenu;
