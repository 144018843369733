import classNames from "classnames";
import { FC, useCallback } from "react";
import { useCookies } from "react-cookie";
import { useDarkModeContext } from "../../src/context/DarkModeContext";
import { useWalletStateContext } from "../../src/context/WalletStateContext";
import { selectWallet } from "../../src/walletApi/api";
import styles from "./ConnectCard.module.scss";

import { NotificationsService } from "../../src/services/notification.service";
import { WalletItem } from "../../src/walletApi/walletItems";

type ConnectCardProps = WalletItem & {
  isAvailable: boolean;
  accessToken: string | null;
  refreshToken: string | null;
  stakeId: string | null;
};

const ConnectCard: FC<ConnectCardProps> = ({ icon, name, isAvailable, accessToken, refreshToken, stakeId }) => {
  const [cookies] = useCookies();
  const { darkMode } = useDarkModeContext();

  const { onWalletAuth, wallet, clearWalletData, setWalletsBucket, connectWallet } = useWalletStateContext();

  const {
    activeWallet: { address, name: stateActiveWalletName, isConnected, access, refresh },
  } = wallet;

  const isSameStakeIdWallet = stakeId === address;

  const connectedWallet: boolean = name === stateActiveWalletName && isConnected;
  const handleAuthenticate = useCallback(() => {
    NotificationsService.onWalletSelect(selectWallet(name));
    onWalletAuth(name);
  }, [name]);

  const handleSameStakeIdConnection = useCallback(() => {
    if (access && refresh) {
      connectWallet(name, access, refresh);
    }
  }, [refresh, access, name]);

  const handleDiffStakeIdConnection = useCallback(() => {
    if (refreshToken && accessToken) {
      if (refreshToken && accessToken) {
        connectWallet(name, accessToken, refreshToken);
      }
    }
  }, [accessToken, refreshToken, name]);

  const onClickFn =
    !accessToken && !refreshToken
      ? handleAuthenticate
      : isSameStakeIdWallet
      ? handleSameStakeIdConnection
      : handleDiffStakeIdConnection;

  return (
    <div
      className={classNames(styles.wrapper, !isAvailable && styles.disabled, darkMode && styles.hasDarkMode)}
      onClick={onClickFn}
    >
      <div className={classNames(styles.image)}>
        <img src={icon.src} alt={name} />
      </div>
      <h4>{name}</h4>
      {connectedWallet && (
        <div className={styles.active}>
          <div className={"d-flex flex-column"}>
            <p className={styles.connected}>Wallet is connected</p>
            <button className={styles.reconnectButton}>Reconnect</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ConnectCard;
