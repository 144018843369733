import classnames from "classnames";
import Moon from "../../assets/images/icons/moon.png";
import Sun from "../../assets/images/icons/sun.png";
import { useDarkModeContext } from "../../src/context/DarkModeContext";
import styles from "./index.module.scss";

const DarkLightMode = () => {
  const { darkMode, setDarkMode } = useDarkModeContext();
  const handleClick = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div
      data-testid="darkModeButton"
      className={classnames(styles.button)}
      onClick={handleClick}
      style={darkMode ? { transform: "rotate(360deg)" } : { transform: "rotate(0deg)" }}
    >
      <img src={!darkMode ? Moon.src : Sun.src} alt="icon" />
    </div>
  );
};

export default DarkLightMode;
