import {
  AssetClass,
  HorseInfo,
  HorseResult,
  Instanced,
  RaceFilters,
  RaceId,
  RaceInfo,
  RaceInfoStatic,
  Track,
  Training,
  Value,
  Weather,
  YearTraining,
} from "./types";

const Ada = (n: number): Value => ({ ada: n });
const igen = (n: number) => [...new Array(n)].map((_, i) => i);

const gates = [GetHorsesInfo(14), GetHorsesInfo(13, 14), GetHorsesInfo(13, 27), GetHorsesInfo(13, 40)];

function GetHorsesInfo(n: number, x = 0) {
  return igen(n).map(
    (i) =>
      ({
        name: `EquinePioneerHorse${(1 + i + x).toString().padStart(5, "0")}`,
        stable: `Stable ${1 + i + x}`,
        jockey: "Jockey name",
        guild: "Guild name",
      } as HorseInfo)
  );
}

function GetPrize(fee: number, prizePool: Value): Value {
  return {
    ...prizePool,
    ada: (prizePool.ada ?? 0) + fee * (prizePool.percent ?? 0),
  };
}

function GetRaceResults(entryFee: Value, prizePool: Value[], gates: Instanced<HorseInfo>) {
  return gates.map((instanceGate) => {
    const n = instanceGate.length;
    const places = igen(n).sort(() => 0.5 - Math.random());
    let t = 285 + 25 * (0.5 - Math.random());
    return places.map((gate, place) => {
      t += (1 + 0.2 * place) * Math.random();
      return {
        ...instanceGate[gate],
        time: t,
        gate,
        place,
        prize: GetPrize(n * (entryFee?.ada ?? 0), prizePool[place] ?? {}),
        racingPoints: Math.round(((15 - place) * (15 - place)) / 15),
      } as HorseResult;
    });
  }) as Instanced<HorseResult>;
}

const track1: Track = {
  name: "Track 1",
  surface: "dirt",
  rating: "soft",
  distance: 4000,
  fieldSize: 10,
  location: "City 1, Country 1",
  curvature: "Slow",
  turns: "Left",
};

const track2: Track = {
  name: "Track 2",
  surface: "synthetic",
  rating: "mediums",
  distance: 1200,
  fieldSize: 12,
  location: "City 2, Country 2",
  curvature: "Slow",
  turns: "Left",
};

const weather: Weather = {
  rain: 0,
  wind: 0,
  windDirection: "",
  description: "",
  temperature: 0,
  altitude: 0,
  humidity: 0,
};

const weather2: Weather = {
  rain: 0,
  wind: 15,
  windDirection: "North West",
  description: "Clear Sky",
  temperature: 22,
  altitude: 10,
  humidity: 3,
};

const prizePool: Value[] = [
  { percent: 40 },
  { percent: 20 },
  { percent: 15 },
  { percent: 10 },
  { percent: 8 },
  { percent: 7 },
];

const race1: RaceInfo = {
  id: "01",
  name: "Race 1",
  date: new Date("2022-12-16T08:41:0000-4"),
  currentEntries: 53,
  eventInfo: "Recurring Friday Race",
  entryFee: Ada(20),
  trackDetails: track1,
  weather,
  season: 1,
  location: "City 1, Country 1",
  distance: 4000,
  prizePool,
  scratchFee: { percent: 2 },
  canScratch: true,
  gates,
  results: GetRaceResults(Ada(20), prizePool, gates),
  status: "completed",
};

const race2: RaceInfo = {
  id: "02",
  name: "Race 2",
  date: new Date("2023-04-16T18:00:0000-4"),
  trackDetails: track2,
  season: 1,
  location: "City 2, Country 2",
  distance: 2000,
  requirements: { class: 2 },
  currentEntries: 10,
  eventInfo: "Single Free Race",
  entryFee: Ada(0),
  prizePool: [
    { percent: 40, ada: 25, SnowmanJockeySkin: 1 },
    { percent: 20, ada: 10 },
    { percent: 15, ada: 5 },
    { percent: 10 },
    { percent: 8 },
    { percent: 7 },
  ],
  maxEntries: 16,
  scratchFee: Ada(1),
  canScratch: true,
  weather,
  status: "announced",
};

const raceList: RaceInfo[] = [race1, race2];

const raceResult: HorseResult = {
  name: "Quicksilver",
  stable: "Name of Stable",
  jockey: "Default",
  guild: "Test Guild",
  place: 2,
  prize: { ada: 37.5 },
  time: 120.3,
};

const delay = <T>(val: T): Promise<T> => {
  const errorRate = 0.02;
  const delayMs = 200 + 500 * (Math.random() + Math.random() + Math.random());
  return new Promise((resolve, reject) => {
    if (Math.random() < errorRate) {
      setTimeout(() => reject("Random API Error"), delayMs);
    } else {
      setTimeout(() => resolve(val), delayMs);
    }
  });
};

const races: { [key: string]: RaceInfo } = {
  race1,
  race2,
};

const training: Training = igen(18).map(
  () =>
    ({
      acceleration: false,
      agility: false,
      endurance: false,
      speed: true,
      stamina: false,
    } as YearTraining)
) as Training;

export const Mock = {
  getRaces: (options?: RaceFilters) => delay(raceList),
  getRace: (id: RaceId) => delay(races[id]),
  completeRace: (asset: AssetClass, id: RaceId) => delay(raceResult),
  adminCreateRace: (info: RaceInfoStatic) => delay("raceIdString"),
  adminUpdateRace: (info: RaceInfoStatic) => delay("raceIdString"),
  getTraining: (asset: AssetClass) => delay(training),
  delayTrue: (...params: unknown[]) => delay(true),
  delay: <T>(val: T, ...params: unknown[]) => delay(val),
  delayNumber: (x: number, ...params: unknown[]) => delay(x),
};
