import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import { headerQuery } from "lib/queries/header";
import Link from "next/link";
import { useLanguageContext } from "src/context/LanguageContext";
import styles from "./FooterList.module.scss";

const FooterList = () => {
  const { lang, setLang } = useLanguageContext();

  const { data, error, loading } = useQuery(
    gql`
      ${headerQuery}
    `,
    {
      variables: {
        lang: "MAIN_MENU",
      },
    }
  );
  return (
    <nav className={classNames(styles.footerList)}>
      {!loading &&
        !error &&
        data &&
        data.menuItems.nodes.map((el: any, index: number) => {
          const isAvailable = el.menuItemFields.disabled;
          const localRoutes = ["faq", "news", "updates", "News", "Updates", "FAQ"];
          return (
            <div key={index}>
              {!isAvailable ? (
                <Link
                  href={`${!localRoutes.includes(el.label) ? process.env.NEXT_PUBLIC_APP_URL : ""}${
                    el.uri === "/guide-2/" ? "/faq/getting-started" : el.uri === "/news/" ? "/news/all-news" : el.uri
                  }`}
                >
                  <a>
                    <h4>{el.label}</h4>
                  </a>
                </Link>
              ) : (
                <span>
                  <h4>{el.label}</h4>
                </span>
              )}

              <ul>
                {el.childItems.nodes.map((el: any, index: number) => (
                  <li key={index}>
                    {!el.menuItemFields.disabled ? (
                      <Link href={`${!localRoutes.includes(el.label) ? process.env.NEXT_PUBLIC_APP_URL : ""}${el.uri}`}>
                        <a>{el.label}</a>
                      </Link>
                    ) : (
                      <span>{el.label}</span>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      {/* Disabling Marketplace on request by client */}
      {/* <div>
        <span>
          <h4>Marketplace</h4>
        </span>
        <ul>
          <li>
            <span>Buy</span>
          </li>
          <li>
            <span>Sell</span>
          </li>
          <li>
            <span>Activity</span>
          </li>
        </ul>
      </div> */}
      <div className={classNames(styles.policyterm)}>
        <Link href="/privacy-policy">
          <a>
            <h4>Privacy Policy</h4>
          </a>
        </Link>
        <Link href="/terms-of-service">
          <a>
            <h4>Terms Of Service</h4>
          </a>
        </Link>
      </div>
    </nav>
  );
};

export default FooterList;
