export default () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.50083 13.9397V13.7179C3.53295 13.0616 3.7406 12.4257 4.10236 11.8758C4.7045 11.2152 5.1167 10.4057 5.29571 9.53224C5.29571 8.85714 5.29571 8.17239 5.35393 7.49728C5.65469 4.24714 8.82728 2 11.9611 2H12.0387C15.1725 2 18.345 4.24714 18.6555 7.49728C18.7137 8.17239 18.6555 8.85714 18.704 9.53224C18.8854 10.4078 19.2972 11.2198 19.8974 11.8855C20.2618 12.4305 20.4698 13.0641 20.4989 13.7179V13.9301C20.5206 14.8118 20.2208 15.6715 19.6548 16.3508C18.907 17.145 17.8921 17.6391 16.8024 17.7396C13.607 18.0868 10.383 18.0868 7.18762 17.7396C6.09914 17.6348 5.08576 17.1414 4.33521 16.3508C3.778 15.671 3.48224 14.8164 3.50083 13.9397Z"
        stroke="#242121"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.55469 20.8516C10.054 21.4782 10.7871 21.8838 11.592 21.9785C12.3968 22.0732 13.2069 21.8493 13.843 21.3562C14.0387 21.2103 14.2147 21.0408 14.3669 20.8516"
        stroke="#242121"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
