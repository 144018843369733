import dynamic from "next/dynamic";
import Head from "next/head";
import { useEffect, useState } from "react";
import { useUiStateContext } from "../src/context/UiStateContext";
import CookiesPopUp from "./CookiesPopUp";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import SubFooter from "./SubFooter/SubFooter";
import WalletModal from "./WalletModal/WalletModal";
import { Props } from "./type";

const DynamicHeader = dynamic(() => import("./Header/Header"), {
  ssr: false,
});

const Layout = (props: Props) => {
  const { children } = props;
  const [isAccepted, setIsAccepted] = useState(true);

  const {
    walletModal: { isModalOpen, openModal, closeModal },
  } = useUiStateContext();

  useEffect(() => {
    const cookiesAccepted = localStorage.getItem("siteCookies");
    setIsAccepted(!!cookiesAccepted);
  }, []);

  const onClick = () => {
    localStorage.setItem("siteCookies", JSON.stringify({ enabled: true }));
    setIsAccepted(true);
  };

  return (
    <>
      <Head>
        <title>Equine</title>
        <meta name="description" content="HORSE RACING, BREEDING AND TRADING GAME" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Header />
      <main>
        {children}
        {!isAccepted && <CookiesPopUp onClick={onClick} />}
      </main>
      <WalletModal isOpen={isModalOpen} onClose={closeModal} />
      <Footer />
      <SubFooter />
    </>
  );
};

export default Layout;
