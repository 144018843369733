import Link from "next/link";
import { useRouter } from "next/router";
import React, { Children } from "react";
import { ActiveLinkProps } from "./type";

const SubNavlink: React.FC<ActiveLinkProps> = ({ children, ...props }) => {
  const router = useRouter();
  const child = Children.only(children) as React.ReactElement;
  let className = child.props.className ? child.props.className : "";
  if (`${router.asPath}` === props.href && props.activeClassName) {
    className = `${props.activeClassName}`;
  }
  delete props.activeClassName;

  return <Link href={props.href ?? "/"}>{React.cloneElement(child, { className })}</Link>;
};

export default SubNavlink;
