import { deleteCookie, getCookie, setCookie } from "cookies-next";
import { getBaseUrlFromEnv } from "../../system/baseUrl";

const WALLET_ACCESS_TOKEN_KEY = "walletAccess";
const WALLET_REFRESH_TOKEN_KEY = "walletRefresh";

const domain = getBaseUrlFromEnv() ? getBaseUrlFromEnv() : "localhost";

class TokenService {
  getLocalAccessToken() {
    return getCookie(WALLET_ACCESS_TOKEN_KEY);
  }

  getLocalRefreshToken() {
    return getCookie(WALLET_REFRESH_TOKEN_KEY);
  }

  updateLocalAccessToken(token: string) {
    setCookie(WALLET_ACCESS_TOKEN_KEY, token, {
      path: "/",
      domain,
    });
  }

  updateLocalRefreshToken(token: string) {
    setCookie(WALLET_REFRESH_TOKEN_KEY, token, {
      path: "/",
      domain,
    });
  }

  getWalletRefreshToken() {
    return getCookie(WALLET_REFRESH_TOKEN_KEY);
  }

  getWalletAccessToken() {
    return getCookie(WALLET_ACCESS_TOKEN_KEY);
  }

  setWalletTokens(access: string, refresh: string) {
    setCookie(WALLET_ACCESS_TOKEN_KEY, access, {
      path: "/",
      domain,
    });
    setCookie(WALLET_REFRESH_TOKEN_KEY, refresh, {
      path: "/",
      domain,
    });
  }

  setTokens(access: string, refresh: string) {
    this.setWalletTokens(access, refresh);
  }

  flush() {
    deleteCookie(WALLET_ACCESS_TOKEN_KEY);
    deleteCookie(WALLET_REFRESH_TOKEN_KEY);
  }
}

export default new TokenService();
