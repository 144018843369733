import log from "utils/log";
import { authentifyWallet } from "../../src/walletApi/api";
import { AuthentifyInfo, WalletApp } from "../../src/walletApi/types";
import { NotificationsService } from "../services/notification.service";

import { AuthorizationResponseType, SignupRequestAttributes } from "./auth";
import api, { updateHeaderAuthorizationToken } from "./axios";
import TokenService from "./token.service";

class AuthService {
  refresh(token: string) {
    return api.post("/auth/refresh", { token });
  }

  login(data: SignupRequestAttributes) {
    return api.post("/auth/login", data);
  }

  async authenticateWallet(walletName: WalletApp): Promise<AuthorizationResponseType | undefined> {
    // This part happens frontend in the connected wallet
    try {
      const authInfo: AuthentifyInfo = await authentifyWallet(walletName);

      // This part happens backend and validates the transaction in a safe
      // place where the user cannot spoof validity and returns a JWT token
      // which cannot be faked

      const verifyWallet = api.post("/auth/verify-sig", authInfo);
      NotificationsService.onWalletAuth(verifyWallet);
      return verifyWallet.then(({ data: tokenData }) => {
        this.setAuthTokens(tokenData);
        return tokenData;
      });
    } catch (e) {
      log.error("authenticateWallet error", e);
    }
  }

  setAuthTokens({ accessToken, refreshToken }: AuthorizationResponseType) {
    TokenService.updateLocalAccessToken(accessToken);
    TokenService.updateLocalRefreshToken(refreshToken);
    TokenService.setWalletTokens(accessToken, refreshToken);

    updateHeaderAuthorizationToken(accessToken);
  }

  async verifyWallet(walletName: WalletApp): Promise<AuthorizationResponseType> {
    // This part happens frontend in the connected wallet
    const authInfo: AuthentifyInfo = await authentifyWallet(walletName);

    // This part happens backend and validates the transaction in a safe
    // place where the user cannot spoof validity and returns a JWT token
    // which cannot be faked
    const { data: tokenData } = await api.post("/auth/verify-sig", authInfo);

    const { accessToken, refreshToken }: AuthorizationResponseType = tokenData;

    TokenService.setWalletTokens(accessToken, refreshToken);
    updateHeaderAuthorizationToken(accessToken);

    return tokenData;
  }
}

export default new AuthService();
