import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import { useRouter } from "next/router";
import { subHeader } from "../../lib/queries/header";
import { useLanguageContext } from "../../src/context/LanguageContext";
import SubNavlink from "../SubNavLink";
import styles from "./Header.module.scss";

const SubNavList = () => {
  const { lang, setLang } = useLanguageContext();
  const { data, error, loading } = useQuery(
    gql`
      ${subHeader}
    `,
    {
      variables: {
        lang: lang.code === "EN" ? "MAIN_MENU" : `MAIN_MENU___${lang.code}`,
      },
    }
  );
  const router = useRouter();
  const base = router.asPath.split("/");
  const currentItem = data?.menuItems?.nodes.find((el: any) => el.uri === `/${base[1]}/`);
  return (
    <>
      {!loading && !error && currentItem?.childItems && currentItem?.childItems?.edges?.length > 0 && (
        <div className={classNames(styles.subNavList)}>
          <ul className={classNames(styles.menuList)}>
            {currentItem &&
              currentItem.childItems.edges.map((el: any) => (
                <li key={el.node.id} className={classNames(styles.menuListItem, styles.subMenu)}>
                  <SubNavlink
                    activeClassName={classNames(styles.subActive)}
                    href={el.node.uri}
                    activeHref={currentItem.uri}
                  >
                    <a>{el.node.label}</a>
                  </SubNavlink>
                </li>
              ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default SubNavList;
