import cn from "classnames";
import React, { FC, ReactNode } from "react";
import styles from "./IconBox.module.scss";

export interface IconBoxProps {
  children: ReactNode;
  type?: 1 | 2;
  className?: string;
  onClick?: () => void;
  link?: string;
}

const IconBox: FC<IconBoxProps> = ({ children, type = 1, className, link, onClick }) => {
  return (
    <a
      href={link}
      onClick={onClick}
      className={cn(
        className,
        styles.element,
        "d-flex align-items-center justify-content-center",
        styles[`hasType-${type}`]
      )}
    >
      {children}
    </a>
  );
};

export default IconBox;
