export default () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.5 9.5625L7.62638 6.43583C7.66741 6.39486 7.69047 6.33924 7.69047 6.28125C7.69047 6.22326 7.66741 6.16764 7.62638 6.12667L4.5 3"
        stroke="#8C8987"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
