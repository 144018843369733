import React, { createContext, Dispatch, PropsWithChildren, SetStateAction, useContext, useState } from "react";

type LanguageType = {
  code: string;
  name: string;
};

type LanguageContextType = {
  lang: LanguageType;
  setLang: Dispatch<SetStateAction<LanguageType>>;
};

export const LanguageContext = createContext<LanguageContextType>({} as LanguageContextType);

const LanguageContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [lang, setLang] = useState({ code: "EN", name: "English" });
  return <LanguageContext.Provider value={{ lang, setLang }}>{children}</LanguageContext.Provider>;
};

export const useLanguageContext = () => useContext(LanguageContext);
export default LanguageContextProvider;
