export const EquineLogo = () => {
  return (
    <svg
      width="88"
      height="38"
      viewBox="0 0 88 38"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="equine_logo"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.46 25.1032L9.50888 24.818C9.62296 24.2232 9.54962 23.7425 9.28888 23.3758C9.03629 23.0091 8.63703 22.8299 8.11555 22.8299H6.46963C5.91555 22.8299 5.42666 23.0254 5.01111 23.4084C4.59555 23.7914 4.32666 24.2966 4.21259 24.924L4.18 25.1032H9.46ZM4.89703 31.3366C4.07407 31.3366 3.37333 31.1817 2.80296 30.8721C2.23259 30.5625 1.82518 30.1225 1.58888 29.5521C1.35259 28.9817 1.29555 28.3299 1.43407 27.5884L1.93925 24.8669C2.08592 24.0847 2.3874 23.3921 2.8437 22.7891C3.3 22.1862 3.87037 21.7217 4.56296 21.3877C5.25555 21.0536 6.01333 20.8906 6.83629 20.8906H8.45777C9.68814 20.8906 10.6089 21.2491 11.22 21.9662C11.8311 22.6832 12.0267 23.6366 11.8067 24.8262L11.4318 26.8551L3.90296 26.8632L3.76444 27.5966C3.66666 28.1343 3.74814 28.5743 4.01703 28.8921C4.28592 29.218 4.70148 29.381 5.25555 29.381H6.81999C7.35777 29.381 7.82222 29.2506 8.20518 28.9817C8.58814 28.721 8.81629 28.3706 8.89777 27.9388H11.1874C10.9918 28.9654 10.4622 29.7884 9.59037 30.4158C8.71851 31.0351 7.6674 31.3528 6.43703 31.3528L4.89703 31.3366Z"
        fill="white"
        className="logo-text"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.4451 29.324C18.0155 29.324 18.537 29.0958 19.0096 28.6314C19.4903 28.1751 19.8244 27.5721 20.0281 26.8469L20.3948 24.8751C20.4355 24.2803 20.3133 23.7995 20.0199 23.4328C19.7266 23.0662 19.3273 22.8869 18.814 22.8869H17.3962C16.8422 22.8869 16.3533 23.0906 15.9296 23.5062C15.5059 23.9217 15.237 24.4595 15.1066 25.1358L14.7155 27.2299C14.6014 27.8491 14.6585 28.3543 14.9029 28.7373C15.1392 29.1203 15.5222 29.3158 16.0355 29.3158L17.4451 29.324ZM19.4088 30.1877C19.0747 30.5299 18.6836 30.7988 18.2355 31.0106C17.7873 31.2225 17.3718 31.3284 16.9888 31.3284H15.5303C14.3651 31.3284 13.4933 30.9699 12.9148 30.2447C12.3281 29.5195 12.1488 28.5662 12.3688 27.3766L12.8088 25.0054C13.037 23.7588 13.591 22.7647 14.471 22.0151C15.351 21.2654 16.394 20.8906 17.6081 20.8906H19.0096C19.4333 20.8906 19.8244 21.0047 20.2073 21.2247C20.5822 21.4447 20.8673 21.738 21.0547 22.1047L21.2503 21.0454H23.4177L21.0547 33.781H18.7407L19.4088 30.1877Z"
        fill="white"
        className="logo-text"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.3673 31.3224C25.3488 31.3224 24.5992 30.972 24.094 30.2794C23.597 29.5787 23.4503 28.6742 23.654 27.5498L24.8599 21.0394H27.1903L25.9762 27.5905C25.8785 28.1038 25.9273 28.5194 26.1229 28.829C26.3185 29.1387 26.6118 29.2935 27.0192 29.2935H28.2333C28.7466 29.2935 29.2518 29.0898 29.7407 28.6742C30.2296 28.2587 30.6125 27.7209 30.8814 27.0609L31.9977 21.0312H34.3281L32.4459 31.1594H30.254L30.5066 29.7824C30.1236 30.2387 29.6673 30.6135 29.1296 30.8987C28.5999 31.1838 28.111 31.3224 27.6792 31.3305L26.3673 31.3224Z"
        fill="white"
        className="logo-text"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.7296 21.0312H38.06L36.1859 31.1594H33.8474L35.7296 21.0312Z"
        fill="white"
        className="logo-text"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M39.5511 21.0302H41.7185L41.474 22.3501C41.857 21.902 42.3133 21.5435 42.8511 21.2746C43.3889 21.0057 43.9022 20.8672 44.3911 20.8672H45.6459C46.6889 20.8672 47.4629 21.2013 47.9681 21.8613C48.4733 22.5213 48.6281 23.3931 48.4244 24.4768L47.1859 31.1501H44.8555L46.0859 24.5339C46.1755 24.045 46.1266 23.6539 45.9311 23.3442C45.7355 23.0427 45.434 22.8879 45.0348 22.8879H43.78C43.2503 22.8879 42.7452 23.0916 42.2481 23.4909C41.7511 23.8901 41.3926 24.4035 41.1563 25.0227L40.0074 31.1501H37.677L39.5511 21.0302Z"
        fill="white"
        className="logo-text"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.037 25.0801L57.0859 24.7949C57.2 24.2001 57.1267 23.7194 56.8659 23.3527C56.6052 22.986 56.2141 22.8068 55.6926 22.8068H54.0467C53.4926 22.8068 53.0037 23.0023 52.5881 23.3853C52.1726 23.7683 51.9037 24.2734 51.7896 24.9009L51.757 25.0801H57.037ZM52.4741 31.3053C51.6511 31.3053 50.9504 31.1505 50.38 30.8409C49.8096 30.5312 49.4022 30.0912 49.1659 29.5209C48.9296 28.9505 48.8726 28.2986 49.0111 27.5572L49.5163 24.8357C49.6629 24.0534 49.9644 23.3609 50.4207 22.7579C50.877 22.1549 51.4474 21.6905 52.14 21.3564C52.8326 21.0223 53.5904 20.8594 54.4133 20.8594H56.0348C57.2652 20.8594 58.1859 21.2179 58.797 21.9349C59.4081 22.652 59.6037 23.6053 59.3837 24.7949L59.0089 26.8238L51.48 26.832L51.3415 27.5653C51.2437 28.1031 51.3252 28.5431 51.6022 28.8609C51.8711 29.1868 52.2867 29.3497 52.8407 29.3497H54.4052C54.9429 29.3497 55.4074 29.2194 55.7904 28.9505C56.1733 28.6897 56.4015 28.3394 56.4829 27.9075H58.7726C58.5852 28.9342 58.0474 29.7572 57.1755 30.3846C56.3037 31.0038 55.2526 31.3216 54.0222 31.3216L52.4741 31.3053Z"
        fill="white"
        className="logo-text"
      ></path>
      <path
        d="M34.1733 18.6749C33.6437 18.6749 33.2281 18.2512 33.2281 17.7378C33.2281 17.2163 33.6519 16.8008 34.1652 16.8008H60.1333C60.663 16.8008 61.0785 17.2245 61.0785 17.7378C61.0785 18.2593 60.663 18.6749 60.1415 18.6749H34.1733Z"
        fill="#DFA04A"
      ></path>
      <path
        d="M27.8748 15.0733C27.3452 15.0733 26.9215 14.6496 26.9215 14.1363C26.9215 13.6148 27.3452 13.1992 27.8585 13.1992H66.1711C66.7008 13.1992 67.1245 13.6229 67.1245 14.1363C67.1245 14.6577 66.7008 15.0733 66.1874 15.0733H27.8748Z"
        fill="#DFA04A"
      ></path>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.8326 22.4724C64.2889 24.045 65.2992 25.6013 67.8252 25.6013H73.2111L77.5052 29.8954H82.4755L83.9666 26.7339C83.9666 26.7339 61.2252 5.32871 61.1844 5.28797L62.04 11.4643H42.0444C41.5148 11.4643 41.0992 11.0406 41.0992 10.5272C41.0992 10.0057 41.5229 9.59019 42.0363 9.59019H59.8318L59.3104 5.35315C58.96 3.15315 61.0133 2.55019 62.2111 3.67464L86.2318 26.3591L83.6733 31.7776H76.7311L72.437 27.4835H67.8252C64.1341 27.4835 62.6022 25.0146 62.0237 22.9939C61.8852 22.4969 62.1704 21.9754 62.6674 21.8287C63.1726 21.682 63.6941 21.9754 63.8326 22.4724Z"
        fill="#DFA04A"
      ></path>
    </svg>
  );
};
