import { ApolloProvider } from "@apollo/client";
import React, { PropsWithChildren } from "react";
import { client } from "../../lib/apollo";
import DarkModeContextProvider from "./DarkModeContext";
import LanguageContextProvider from "./LanguageContext";
import UiStateContextProvider from "./UiStateContext";
import WalletStateContextProvider from "./WalletStateContext";

const AppContextProvider: React.FC<PropsWithChildren> = ({ children }) => (
  <DarkModeContextProvider>
    <LanguageContextProvider>
      <WalletStateContextProvider>
        <UiStateContextProvider>
          <ApolloProvider client={client}>{children}</ApolloProvider>
        </UiStateContextProvider>
      </WalletStateContextProvider>
    </LanguageContextProvider>
  </DarkModeContextProvider>
);

export default AppContextProvider;
