import classNames from "classnames";
import Link from "next/link";
import cookieImage from "../../assets/images/icons/cookie3.png";
import Button from "../Button/Button";
import styles from "./CookiesPopUp.module.scss";
type Props = {
  onClick: () => void;
};

const CookiesPopUp = ({ onClick }: Props) => {
  return (
    <div className={classNames(styles.container)}>
      <div className={classNames(styles.cookieImgWrapper)}>
        <img src={cookieImage.src} alt="Website Cookies Image" />
      </div>
      <div className={classNames(styles.cookieText)}>
        We use cookies
        <br />
        <p>
          We use cookies to understand how you use our site, to personalize content, and improve your experience. By
          continuing to use our site, you accept our use of cookies, Terms of Service and Privacy Policy
        </p>
      </div>
      <div className={classNames(styles.actionsWrapper)}>
        <Button text="Accept" width="medium" smallHeight onClick={onClick} />
        <div>
          <Link href={"/privacy-policy"}>
            <a>Privacy Policy</a>
          </Link>
        </div>
        <div>
          <Link href={"/terms-of-service"}>
            <a>Terms of Service</a>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CookiesPopUp;
