export const headerAndFooterQuery = `query HeaderAndFooter($language : LanguageCodeFilterEnum!){
    pages(where :{language :$language}){
      nodes{
        headerAndFooter{
          headerLogo{
            sourceUrl
          }
          footerLogo{
            sourceUrl
          }
          headerCreateUserButton
          subFooterMenuList{
            subFooterMenuListItem{
              url
              title
            }
          }
          footerDescription
          subFooterIcons{
            subFooterIcon{
              sourceUrl
            }
            subFooterIconLink{
              url
            }
          }
        }
      }
    }
  }`;
