import React, { PropsWithChildren, useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import log from "utils/log";
import { getWalletInfo, isEnabled as walletIsEnabled } from "../../src/walletApi/api";
import { WalletApp, WalletInfo } from "../../src/walletApi/types";
import { setWalletCookies } from "../../system/utils/walletUtils";
import { default as AuthService, default as authService } from "../api/auth.service";
import { ownStakeAddr } from "../walletApi/api";
import { WalletItem } from "../walletApi/walletItems";
import { getActiveWalletCookie, installedWallets } from "../walletApi/walletService";
import { ACTIVE_WALLET_COOKIE_KEY, ActiveWalletType, WalletBucketItem } from "./Wallet.types";

type WalletStateContextType = {
  wallet: WalletState;
  onWalletAuth: (name: WalletApp) => void;
  refreshToken: (refreshToken: string) => Promise<any>;
  setWalletsBucket: (buckets: WalletBucketItem[]) => void;
  clearWalletData: () => void;
  connectWallet: (name: WalletApp, accessToken: string, refreshToken: string) => any;
  walletInitializing: boolean;
};

type WalletState = {
  info?: WalletInfo;
  error?: string;
  loading: boolean;
  connected: boolean;
  activeWallet: ActiveWalletType;
  walletBuckets: WalletBucketItem[];
};

const initState: WalletState = {
  connected: false,
  loading: false,
  activeWallet: {
    isConnected: false,
  } as ActiveWalletType,
  walletBuckets: [],
};

export type CachedWalletCookieType = AuthorizationResponseType & {
  type: "wallet";
  stakeId: string;
};

export type AuthorizationResponseType = {
  accessToken: string;
  refreshToken: string;
};

const WalletStateContext = React.createContext<WalletStateContextType>({} as WalletStateContextType);

export async function getWalletBucketState(
  { name, icon }: WalletItem,
  walletCookies: CachedWalletCookieType[],
  wallets: WalletApp[],
  reset: boolean = false
): Promise<WalletBucketItem> {
  const walletBucket: WalletBucketItem = {
    name,
    icon,
    isAvailable: false,
    isEnabled: false,
    stakeId: null,
    refreshToken: null,
    accessToken: null,
  };

  if (!wallets.includes(name) || wallets.length === 0) {
    return walletBucket;
  }
  walletBucket.isAvailable = true;

  let isEnabled: boolean = false;
  try {
    isEnabled = await walletIsEnabled(name);
  } catch (exception) {
    log.error(`Error getting the enabled state of ${name} wallet`, exception);
  }
  if (!isEnabled) {
    return walletBucket;
  }
  walletBucket.isEnabled = true;

  let stakeAddr: string | null = null;

  try {
    stakeAddr = await ownStakeAddr(name);
  } catch (exception) {
    log.error(`Error getting stakeId of ${name} wallet`);
  }
  walletBucket.stakeId = stakeAddr;

  if (!reset) {
    const walletTokens = walletCookies.find(({ stakeId }) => stakeId === stakeAddr);
    if (walletTokens !== undefined) {
      walletBucket.accessToken = walletTokens.accessToken;
      walletBucket.refreshToken = walletTokens.refreshToken;
    }
  }

  return walletBucket;
}

const WalletStateContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [walletInfo, setWalletInfo] = useState<WalletState>(initState);
  const [cookies] = useCookies();
  const [walletInitializing, setWalletInitializing] = useState<boolean>(true);

  //Entry point to start Authentication
  const onWalletAuth = async (name: WalletApp) => {
    return authenticateWallet(name);
  };

  //Authenticates and calls connection
  const authenticateWallet = async (name: WalletApp) => {
    try {
      const valid = await AuthService.authenticateWallet(name);

      if (!valid) return;
      const { accessToken, refreshToken } = valid;
      connectWallet(name, accessToken, refreshToken);
    } catch (err) {
      // todo implement error handling
      log.error(err);
    } finally {
    }
  };

  const isWalletAvailable = async (name: WalletApp) => {
    const wallets = await installedWallets();
    return wallets.includes(name);
  };

  const getActiveWalletStakeId = async (walletName: WalletApp): Promise<string | null> => {
    const isAvailable = await isWalletAvailable(walletName);

    if (isAvailable) {
      return await ownStakeAddr(walletName);
    } else {
      return null;
    }
  };

  useEffect(() => {
    if (window) {
      const activeWalletName: WalletApp | undefined = cookies[ACTIVE_WALLET_COOKIE_KEY];
      if (activeWalletName) {
        getActiveWalletStakeId(activeWalletName).then((result) => {
          if (result) {
            const walletInfo = getActiveWalletCookie(result, cookies);
            if (walletInfo) {
              const { stakeId, accessToken, refreshToken } = walletInfo;
              connectWallet(activeWalletName, accessToken, refreshToken);
            }
          }
        });
      } else {
        setWalletInitializing(false);
      }
    }
  }, [cookies]);

  //Connects and sets cookies
  const connectWallet = async (name: WalletApp, accessToken: string, refreshToken: string) => {
    const walletData = await getWalletInfo(name);
    const data = { ...walletData, name };

    const activeWallet: ActiveWalletType = {
      name: data.name,
      isConnected: true,
      address: data.stakeId,
      balance: (Number(data.coins) / 1_000_000).toFixed(2).toString(),
      walletInfo: data,
      access: accessToken,
      refresh: refreshToken,
    };
    const newState = { ...walletInfo, activeWallet, connected: true };
    setWalletInfo(newState);
    setWalletCookies(name, walletData.stakeId, accessToken, refreshToken);
    setWalletInitializing(false);
    return data;
  };

  //Refresh Auth Token
  const refreshToken = async (refreshToken: string) => {
    const { data } = await authService.refresh(refreshToken);
    if (!data) return;
    return data;
  };

  const clearWalletData = () => {
    const emptyState = { ...walletInfo, activeWallet: {} as ActiveWalletType };
    setWalletInfo(emptyState);
  };

  const setWalletsBucket = (buckets: WalletBucketItem[]) => {
    const newState = { ...walletInfo, walletBuckets: buckets };
    setWalletInfo(newState);
  };

  const contextValue: WalletStateContextType = {
    wallet: walletInfo,
    onWalletAuth,
    refreshToken,
    clearWalletData,
    setWalletsBucket,
    connectWallet,
    walletInitializing,
  };

  return <WalletStateContext.Provider value={contextValue}>{children}</WalletStateContext.Provider>;
};

export const useWalletStateContext = () => useContext(WalletStateContext);
export default WalletStateContextProvider;
