export default () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="2"
        y="4"
        width="20"
        height="16"
        rx="3"
        stroke="#242121"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9033 9.85156L13.46 13.4646C12.6205 14.1306 11.4394 14.1306 10.5999 13.4646L6.11914 9.85156"
        stroke="#242121"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
