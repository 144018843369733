import * as CSL from "@emurgo/cardano-serialization-lib-browser";
import bigInt from "big-integer";
// output/Data.Array/foreign.js
var range = function (start2) {
  return function (end) {
    var step3 = start2 > end ? -1 : 1;
    var result = new Array(step3 * (end - start2) + 1);
    var i3 = start2,
      n = 0;
    while (i3 !== end) {
      result[n++] = i3;
      i3 += step3;
    }
    result[n] = i3;
    return result;
  };
};
var fromFoldableImpl = (function () {
  function Cons3(head4, tail) {
    this.head = head4;
    this.tail = tail;
  }
  var emptyList = {};
  function curryCons(head4) {
    return function (tail) {
      return new Cons3(head4, tail);
    };
  }
  function listToArray(list2) {
    var result = [];
    var count = 0;
    var xs = list2;
    while (xs !== emptyList) {
      result[count++] = xs.head;
      xs = xs.tail;
    }
    return result;
  }
  return function (foldr4) {
    return function (xs) {
      return listToArray(foldr4(curryCons)(emptyList)(xs));
    };
  };
})();
var length = function (xs) {
  return xs.length;
};
var indexImpl = function (just) {
  return function (nothing) {
    return function (xs) {
      return function (i3) {
        return i3 < 0 || i3 >= xs.length ? nothing : just(xs[i3]);
      };
    };
  };
};
var filter = function (f) {
  return function (xs) {
    return xs.filter(f);
  };
};
var zipWith = function (f) {
  return function (xs) {
    return function (ys) {
      var l = xs.length < ys.length ? xs.length : ys.length;
      var result = new Array(l);
      for (var i3 = 0; i3 < l; i3++) {
        result[i3] = f(xs[i3])(ys[i3]);
      }
      return result;
    };
  };
};
var any = function (p) {
  return function (xs) {
    var len = xs.length;
    for (var i3 = 0; i3 < len; i3++) {
      if (p(xs[i3])) return true;
    }
    return false;
  };
};
var unsafeIndexImpl = function (xs) {
  return function (n) {
    return xs[n];
  };
};

// output/Data.Functor/foreign.js
var arrayMap = function (f) {
  return function (arr) {
    var l = arr.length;
    var result = new Array(l);
    for (var i3 = 0; i3 < l; i3++) {
      result[i3] = f(arr[i3]);
    }
    return result;
  };
};

// output/Control.Semigroupoid/index.js
var semigroupoidFn = {
  compose: function (f) {
    return function (g) {
      return function (x) {
        return f(g(x));
      };
    };
  },
};
var compose = function (dict) {
  return dict.compose;
};

// output/Control.Category/index.js
var identity = function (dict) {
  return dict.identity;
};
var categoryFn = {
  identity: function (x) {
    return x;
  },
  Semigroupoid0: function () {
    return semigroupoidFn;
  },
};

// output/Data.Boolean/index.js
var otherwise = true;

// output/Data.Function/index.js
var on = function (f) {
  return function (g) {
    return function (x) {
      return function (y) {
        return f(g(x))(g(y));
      };
    };
  };
};
var flip = function (f) {
  return function (b) {
    return function (a) {
      return f(a)(b);
    };
  };
};
var $$const = function (a) {
  return function () {
    return a;
  };
};

// output/Data.Unit/foreign.js
var unit = void 0;

// output/Type.Proxy/index.js
var $$Proxy = /* @__PURE__ */ (function () {
  function $$Proxy2() {}
  $$Proxy2.value = new $$Proxy2();
  return $$Proxy2;
})();

// output/Data.Functor/index.js
var map = function (dict) {
  return dict.map;
};
var mapFlipped = function (dictFunctor) {
  var map115 = map(dictFunctor);
  return function (fa) {
    return function (f) {
      return map115(f)(fa);
    };
  };
};
var $$void = function (dictFunctor) {
  return map(dictFunctor)($$const(unit));
};
var voidLeft = function (dictFunctor) {
  var map115 = map(dictFunctor);
  return function (f) {
    return function (x) {
      return map115($$const(x))(f);
    };
  };
};
var voidRight = function (dictFunctor) {
  var map115 = map(dictFunctor);
  return function (x) {
    return map115($$const(x));
  };
};
var functorFn = {
  map: /* @__PURE__ */ compose(semigroupoidFn),
};
var functorArray = {
  map: arrayMap,
};

// output/Data.Semigroup/foreign.js
var concatString = function (s1) {
  return function (s2) {
    return s1 + s2;
  };
};
var concatArray = function (xs) {
  return function (ys) {
    if (xs.length === 0) return ys;
    if (ys.length === 0) return xs;
    return xs.concat(ys);
  };
};

// output/Data.Symbol/index.js
var reflectSymbol = function (dict) {
  return dict.reflectSymbol;
};

// output/Record.Unsafe/foreign.js
var unsafeHas = function (label4) {
  return function (rec) {
    return {}.hasOwnProperty.call(rec, label4);
  };
};
var unsafeGet = function (label4) {
  return function (rec) {
    return rec[label4];
  };
};

// output/Data.Semigroup/index.js
var semigroupUnit = {
  append: function () {
    return function () {
      return unit;
    };
  },
};
var semigroupString = {
  append: concatString,
};
var semigroupArray = {
  append: concatArray,
};
var append = function (dict) {
  return dict.append;
};

// output/Control.Alt/index.js
var alt = function (dict) {
  return dict.alt;
};

// output/Control.Apply/foreign.js
var arrayApply = function (fs) {
  return function (xs) {
    var l = fs.length;
    var k = xs.length;
    var result = new Array(l * k);
    var n = 0;
    for (var i3 = 0; i3 < l; i3++) {
      var f = fs[i3];
      for (var j = 0; j < k; j++) {
        result[n++] = f(xs[j]);
      }
    }
    return result;
  };
};

// output/Control.Apply/index.js
var identity2 = /* @__PURE__ */ identity(categoryFn);
var applyArray = {
  apply: arrayApply,
  Functor0: function () {
    return functorArray;
  },
};
var apply = function (dict) {
  return dict.apply;
};
var applySecond = function (dictApply) {
  var apply1 = apply(dictApply);
  var map36 = map(dictApply.Functor0());
  return function (a) {
    return function (b) {
      return apply1(map36($$const(identity2))(a))(b);
    };
  };
};
var lift2 = function (dictApply) {
  var apply1 = apply(dictApply);
  var map36 = map(dictApply.Functor0());
  return function (f) {
    return function (a) {
      return function (b) {
        return apply1(map36(f)(a))(b);
      };
    };
  };
};

// output/Control.Applicative/index.js
var pure = function (dict) {
  return dict.pure;
};
var unless = function (dictApplicative) {
  var pure111 = pure(dictApplicative);
  return function (v) {
    return function (v1) {
      if (!v) {
        return v1;
      }
      if (v) {
        return pure111(unit);
      }
      throw new Error(
        "Failed pattern match at Control.Applicative (line 68, column 1 - line 68, column 65): " +
          [v.constructor.name, v1.constructor.name]
      );
    };
  };
};
var when = function (dictApplicative) {
  var pure111 = pure(dictApplicative);
  return function (v) {
    return function (v1) {
      if (v) {
        return v1;
      }
      if (!v) {
        return pure111(unit);
      }
      throw new Error(
        "Failed pattern match at Control.Applicative (line 63, column 1 - line 63, column 63): " +
          [v.constructor.name, v1.constructor.name]
      );
    };
  };
};
var liftA1 = function (dictApplicative) {
  var apply3 = apply(dictApplicative.Apply0());
  var pure111 = pure(dictApplicative);
  return function (f) {
    return function (a) {
      return apply3(pure111(f))(a);
    };
  };
};

// output/Control.Bind/foreign.js
var arrayBind = function (arr) {
  return function (f) {
    var result = [];
    for (var i3 = 0, l = arr.length; i3 < l; i3++) {
      Array.prototype.push.apply(result, f(arr[i3]));
    }
    return result;
  };
};

// output/Control.Bind/index.js
var discard = function (dict) {
  return dict.discard;
};
var bindArray = {
  bind: arrayBind,
  Apply0: function () {
    return applyArray;
  },
};
var bind = function (dict) {
  return dict.bind;
};
var bindFlipped = function (dictBind) {
  return flip(bind(dictBind));
};
var composeKleisliFlipped = function (dictBind) {
  var bindFlipped13 = bindFlipped(dictBind);
  return function (f) {
    return function (g) {
      return function (a) {
        return bindFlipped13(f)(g(a));
      };
    };
  };
};
var discardUnit = {
  discard: function (dictBind) {
    return bind(dictBind);
  },
};

// output/Control.Monad/index.js
var ap = function (dictMonad) {
  var bind9 = bind(dictMonad.Bind1());
  var pure23 = pure(dictMonad.Applicative0());
  return function (f) {
    return function (a) {
      return bind9(f)(function (f$prime) {
        return bind9(a)(function (a$prime) {
          return pure23(f$prime(a$prime));
        });
      });
    };
  };
};

// output/Data.Bounded/foreign.js
var topInt = 2147483647;
var bottomInt = -2147483648;
var topChar = String.fromCharCode(65535);
var bottomChar = String.fromCharCode(0);

// output/Data.Ord/foreign.js
var unsafeCompareImpl = function (lt) {
  return function (eq6) {
    return function (gt) {
      return function (x) {
        return function (y) {
          return x < y ? lt : x === y ? eq6 : gt;
        };
      };
    };
  };
};
var ordIntImpl = unsafeCompareImpl;
var ordNumberImpl = unsafeCompareImpl;
var ordStringImpl = unsafeCompareImpl;
var ordCharImpl = unsafeCompareImpl;

// output/Data.Eq/foreign.js
var refEq = function (r1) {
  return function (r2) {
    return r1 === r2;
  };
};
var eqBooleanImpl = refEq;
var eqIntImpl = refEq;
var eqNumberImpl = refEq;
var eqCharImpl = refEq;
var eqStringImpl = refEq;

// output/Data.Eq/index.js
var eqString = {
  eq: eqStringImpl,
};
var eqRowNil = {
  eqRecord: function () {
    return function () {
      return function () {
        return true;
      };
    };
  },
};
var eqRecord = function (dict) {
  return dict.eqRecord;
};
var eqRec = function () {
  return function (dictEqRecord) {
    return {
      eq: eqRecord(dictEqRecord)($$Proxy.value),
    };
  };
};
var eqNumber = {
  eq: eqNumberImpl,
};
var eqInt = {
  eq: eqIntImpl,
};
var eqChar = {
  eq: eqCharImpl,
};
var eqBoolean = {
  eq: eqBooleanImpl,
};
var eq = function (dict) {
  return dict.eq;
};
var eq2 = /* @__PURE__ */ eq(eqBoolean);
var eqRowCons = function (dictEqRecord) {
  var eqRecord1 = eqRecord(dictEqRecord);
  return function () {
    return function (dictIsSymbol) {
      var reflectSymbol2 = reflectSymbol(dictIsSymbol);
      return function (dictEq) {
        var eq32 = eq(dictEq);
        return {
          eqRecord: function () {
            return function (ra) {
              return function (rb) {
                var tail = eqRecord1($$Proxy.value)(ra)(rb);
                var key = reflectSymbol2($$Proxy.value);
                var get3 = unsafeGet(key);
                return eq32(get3(ra))(get3(rb)) && tail;
              };
            };
          },
        };
      };
    };
  };
};
var notEq = function (dictEq) {
  var eq32 = eq(dictEq);
  return function (x) {
    return function (y) {
      return eq2(eq32(x)(y))(false);
    };
  };
};

// output/Data.Ordering/index.js
var LT = /* @__PURE__ */ (function () {
  function LT2() {}
  LT2.value = new LT2();
  return LT2;
})();
var GT = /* @__PURE__ */ (function () {
  function GT2() {}
  GT2.value = new GT2();
  return GT2;
})();
var EQ = /* @__PURE__ */ (function () {
  function EQ2() {}
  EQ2.value = new EQ2();
  return EQ2;
})();
var eqOrdering = {
  eq: function (v) {
    return function (v1) {
      if (v instanceof LT && v1 instanceof LT) {
        return true;
      }
      if (v instanceof GT && v1 instanceof GT) {
        return true;
      }
      if (v instanceof EQ && v1 instanceof EQ) {
        return true;
      }
      return false;
    };
  },
};

// output/Data.Ring/foreign.js
var intSub = function (x) {
  return function (y) {
    return (x - y) | 0;
  };
};
var numSub = function (n1) {
  return function (n2) {
    return n1 - n2;
  };
};

// output/Data.Semiring/foreign.js
var intAdd = function (x) {
  return function (y) {
    return (x + y) | 0;
  };
};
var intMul = function (x) {
  return function (y) {
    return (x * y) | 0;
  };
};
var numAdd = function (n1) {
  return function (n2) {
    return n1 + n2;
  };
};
var numMul = function (n1) {
  return function (n2) {
    return n1 * n2;
  };
};

// output/Data.Semiring/index.js
var zero = function (dict) {
  return dict.zero;
};
var semiringNumber = {
  add: numAdd,
  zero: 0,
  mul: numMul,
  one: 1,
};
var semiringInt = {
  add: intAdd,
  zero: 0,
  mul: intMul,
  one: 1,
};
var add = function (dict) {
  return dict.add;
};

// output/Data.Ring/index.js
var sub = function (dict) {
  return dict.sub;
};
var ringNumber = {
  sub: numSub,
  Semiring0: function () {
    return semiringNumber;
  },
};
var ringInt = {
  sub: intSub,
  Semiring0: function () {
    return semiringInt;
  },
};
var negate = function (dictRing) {
  var sub1 = sub(dictRing);
  var zero2 = zero(dictRing.Semiring0());
  return function (a) {
    return sub1(zero2)(a);
  };
};

// output/Data.Ord/index.js
var eqRec2 = /* @__PURE__ */ eqRec();
var notEq2 = /* @__PURE__ */ notEq(eqOrdering);
var ordString = /* @__PURE__ */ (function () {
  return {
    compare: ordStringImpl(LT.value)(EQ.value)(GT.value),
    Eq0: function () {
      return eqString;
    },
  };
})();
var ordRecordNil = {
  compareRecord: function () {
    return function () {
      return function () {
        return EQ.value;
      };
    };
  },
  EqRecord0: function () {
    return eqRowNil;
  },
};
var ordNumber = /* @__PURE__ */ (function () {
  return {
    compare: ordNumberImpl(LT.value)(EQ.value)(GT.value),
    Eq0: function () {
      return eqNumber;
    },
  };
})();
var ordInt = /* @__PURE__ */ (function () {
  return {
    compare: ordIntImpl(LT.value)(EQ.value)(GT.value),
    Eq0: function () {
      return eqInt;
    },
  };
})();
var ordChar = /* @__PURE__ */ (function () {
  return {
    compare: ordCharImpl(LT.value)(EQ.value)(GT.value),
    Eq0: function () {
      return eqChar;
    },
  };
})();
var compareRecord = function (dict) {
  return dict.compareRecord;
};
var ordRecord = function () {
  return function (dictOrdRecord) {
    var eqRec1 = eqRec2(dictOrdRecord.EqRecord0());
    return {
      compare: compareRecord(dictOrdRecord)($$Proxy.value),
      Eq0: function () {
        return eqRec1;
      },
    };
  };
};
var compare = function (dict) {
  return dict.compare;
};
var greaterThan = function (dictOrd) {
  var compare3 = compare(dictOrd);
  return function (a1) {
    return function (a2) {
      var v = compare3(a1)(a2);
      if (v instanceof GT) {
        return true;
      }
      return false;
    };
  };
};
var ordRecordCons = function (dictOrdRecord) {
  var compareRecord1 = compareRecord(dictOrdRecord);
  var eqRowCons2 = eqRowCons(dictOrdRecord.EqRecord0())();
  return function () {
    return function (dictIsSymbol) {
      var reflectSymbol2 = reflectSymbol(dictIsSymbol);
      var eqRowCons1 = eqRowCons2(dictIsSymbol);
      return function (dictOrd) {
        var compare3 = compare(dictOrd);
        var eqRowCons22 = eqRowCons1(dictOrd.Eq0());
        return {
          compareRecord: function () {
            return function (ra) {
              return function (rb) {
                var key = reflectSymbol2($$Proxy.value);
                var left = compare3(unsafeGet(key)(ra))(unsafeGet(key)(rb));
                var $95 = notEq2(left)(EQ.value);
                if ($95) {
                  return left;
                }
                return compareRecord1($$Proxy.value)(ra)(rb);
              };
            };
          },
          EqRecord0: function () {
            return eqRowCons22;
          },
        };
      };
    };
  };
};

// output/Data.Bounded/index.js
var top = function (dict) {
  return dict.top;
};
var boundedInt = {
  top: topInt,
  bottom: bottomInt,
  Ord0: function () {
    return ordInt;
  },
};
var boundedChar = {
  top: topChar,
  bottom: bottomChar,
  Ord0: function () {
    return ordChar;
  },
};
var bottom = function (dict) {
  return dict.bottom;
};

// output/Data.Show/foreign.js
var showIntImpl = function (n) {
  return n.toString();
};
var showStringImpl = function (s) {
  var l = s.length;
  return (
    '"' +
    s.replace(/[\0-\x1F\x7F"\\]/g, function (c, i3) {
      switch (c) {
        case '"':
        case "\\":
          return "\\" + c;
        case "\x07":
          return "\\a";
        case "\b":
          return "\\b";
        case "\f":
          return "\\f";
        case "\n":
          return "\\n";
        case "\r":
          return "\\r";
        case "	":
          return "\\t";
        case "\v":
          return "\\v";
      }
      var k = i3 + 1;
      var empty6 = k < l && s[k] >= "0" && s[k] <= "9" ? "\\&" : "";
      return "\\" + c.charCodeAt(0).toString(10) + empty6;
    }) +
    '"'
  );
};

// output/Data.Show/index.js
var showString = {
  show: showStringImpl,
};
var showInt = {
  show: showIntImpl,
};
var show = function (dict) {
  return dict.show;
};

// output/Data.Generic.Rep/index.js
var Inl = /* @__PURE__ */ (function () {
  function Inl2(value0) {
    this.value0 = value0;
  }
  Inl2.create = function (value0) {
    return new Inl2(value0);
  };
  return Inl2;
})();
var Inr = /* @__PURE__ */ (function () {
  function Inr2(value0) {
    this.value0 = value0;
  }
  Inr2.create = function (value0) {
    return new Inr2(value0);
  };
  return Inr2;
})();
var NoArguments = /* @__PURE__ */ (function () {
  function NoArguments2() {}
  NoArguments2.value = new NoArguments2();
  return NoArguments2;
})();
var from = function (dict) {
  return dict.from;
};

// output/Data.Maybe/index.js
var identity3 = /* @__PURE__ */ identity(categoryFn);
var Nothing = /* @__PURE__ */ (function () {
  function Nothing2() {}
  Nothing2.value = new Nothing2();
  return Nothing2;
})();
var Just = /* @__PURE__ */ (function () {
  function Just2(value0) {
    this.value0 = value0;
  }
  Just2.create = function (value0) {
    return new Just2(value0);
  };
  return Just2;
})();
var maybe = function (v) {
  return function (v1) {
    return function (v2) {
      if (v2 instanceof Nothing) {
        return v;
      }
      if (v2 instanceof Just) {
        return v1(v2.value0);
      }
      throw new Error(
        "Failed pattern match at Data.Maybe (line 237, column 1 - line 237, column 51): " +
          [v.constructor.name, v1.constructor.name, v2.constructor.name]
      );
    };
  };
};
var isNothing = /* @__PURE__ */ maybe(true)(/* @__PURE__ */ $$const(false));
var functorMaybe = {
  map: function (v) {
    return function (v1) {
      if (v1 instanceof Just) {
        return new Just(v(v1.value0));
      }
      return Nothing.value;
    };
  },
};
var map2 = /* @__PURE__ */ map(functorMaybe);
var fromMaybe = function (a) {
  return maybe(a)(identity3);
};
var fromJust = function () {
  return function (v) {
    if (v instanceof Just) {
      return v.value0;
    }
    throw new Error(
      "Failed pattern match at Data.Maybe (line 288, column 1 - line 288, column 46): " + [v.constructor.name]
    );
  };
};
var applyMaybe = {
  apply: function (v) {
    return function (v1) {
      if (v instanceof Just) {
        return map2(v.value0)(v1);
      }
      if (v instanceof Nothing) {
        return Nothing.value;
      }
      throw new Error(
        "Failed pattern match at Data.Maybe (line 67, column 1 - line 69, column 30): " +
          [v.constructor.name, v1.constructor.name]
      );
    };
  },
  Functor0: function () {
    return functorMaybe;
  },
};
var bindMaybe = {
  bind: function (v) {
    return function (v1) {
      if (v instanceof Just) {
        return v1(v.value0);
      }
      if (v instanceof Nothing) {
        return Nothing.value;
      }
      throw new Error(
        "Failed pattern match at Data.Maybe (line 125, column 1 - line 127, column 28): " +
          [v.constructor.name, v1.constructor.name]
      );
    };
  },
  Apply0: function () {
    return applyMaybe;
  },
};
var applicativeMaybe = /* @__PURE__ */ (function () {
  return {
    pure: Just.create,
    Apply0: function () {
      return applyMaybe;
    },
  };
})();

// output/Data.Either/index.js
var Left = /* @__PURE__ */ (function () {
  function Left2(value0) {
    this.value0 = value0;
  }
  Left2.create = function (value0) {
    return new Left2(value0);
  };
  return Left2;
})();
var Right = /* @__PURE__ */ (function () {
  function Right2(value0) {
    this.value0 = value0;
  }
  Right2.create = function (value0) {
    return new Right2(value0);
  };
  return Right2;
})();
var note = function (a) {
  return maybe(new Left(a))(Right.create);
};
var functorEither = {
  map: function (f) {
    return function (m) {
      if (m instanceof Left) {
        return new Left(m.value0);
      }
      if (m instanceof Right) {
        return new Right(f(m.value0));
      }
      throw new Error(
        "Failed pattern match at Data.Either (line 0, column 0 - line 0, column 0): " + [m.constructor.name]
      );
    };
  },
};
var map3 = /* @__PURE__ */ map(functorEither);
var fromRight = function (v) {
  return function (v1) {
    if (v1 instanceof Right) {
      return v1.value0;
    }
    return v;
  };
};
var either = function (v) {
  return function (v1) {
    return function (v2) {
      if (v2 instanceof Left) {
        return v(v2.value0);
      }
      if (v2 instanceof Right) {
        return v1(v2.value0);
      }
      throw new Error(
        "Failed pattern match at Data.Either (line 208, column 1 - line 208, column 64): " +
          [v.constructor.name, v1.constructor.name, v2.constructor.name]
      );
    };
  };
};
var hush = /* @__PURE__ */ (function () {
  return either($$const(Nothing.value))(Just.create);
})();
var applyEither = {
  apply: function (v) {
    return function (v1) {
      if (v instanceof Left) {
        return new Left(v.value0);
      }
      if (v instanceof Right) {
        return map3(v.value0)(v1);
      }
      throw new Error(
        "Failed pattern match at Data.Either (line 70, column 1 - line 72, column 30): " +
          [v.constructor.name, v1.constructor.name]
      );
    };
  },
  Functor0: function () {
    return functorEither;
  },
};
var bindEither = {
  bind: /* @__PURE__ */ either(function (e) {
    return function () {
      return new Left(e);
    };
  })(function (a) {
    return function (f) {
      return f(a);
    };
  }),
  Apply0: function () {
    return applyEither;
  },
};
var applicativeEither = /* @__PURE__ */ (function () {
  return {
    pure: Right.create,
    Apply0: function () {
      return applyEither;
    },
  };
})();

// output/Data.Identity/index.js
var Identity = function (x) {
  return x;
};
var functorIdentity = {
  map: function (f) {
    return function (m) {
      return f(m);
    };
  },
};
var applyIdentity = {
  apply: function (v) {
    return function (v1) {
      return v(v1);
    };
  },
  Functor0: function () {
    return functorIdentity;
  },
};
var bindIdentity = {
  bind: function (v) {
    return function (f) {
      return f(v);
    };
  },
  Apply0: function () {
    return applyIdentity;
  },
};
var applicativeIdentity = {
  pure: Identity,
  Apply0: function () {
    return applyIdentity;
  },
};
var monadIdentity = {
  Applicative0: function () {
    return applicativeIdentity;
  },
  Bind1: function () {
    return bindIdentity;
  },
};

// output/Data.EuclideanRing/foreign.js
var intDegree = function (x) {
  return Math.min(Math.abs(x), 2147483647);
};
var intDiv = function (x) {
  return function (y) {
    if (y === 0) return 0;
    return y > 0 ? Math.floor(x / y) : -Math.floor(x / -y);
  };
};
var intMod = function (x) {
  return function (y) {
    if (y === 0) return 0;
    var yy = Math.abs(y);
    return ((x % yy) + yy) % yy;
  };
};

// output/Data.CommutativeRing/index.js
var commutativeRingInt = {
  Ring0: function () {
    return ringInt;
  },
};

// output/Data.EuclideanRing/index.js
var mod = function (dict) {
  return dict.mod;
};
var euclideanRingInt = {
  degree: intDegree,
  div: intDiv,
  mod: intMod,
  CommutativeRing0: function () {
    return commutativeRingInt;
  },
};
var div = function (dict) {
  return dict.div;
};

// output/Data.Monoid/index.js
var monoidUnit = {
  mempty: unit,
  Semigroup0: function () {
    return semigroupUnit;
  },
};
var monoidString = {
  mempty: "",
  Semigroup0: function () {
    return semigroupString;
  },
};
var monoidArray = {
  mempty: [],
  Semigroup0: function () {
    return semigroupArray;
  },
};
var mempty = function (dict) {
  return dict.mempty;
};

// output/Effect/foreign.js
var pureE = function (a) {
  return function () {
    return a;
  };
};
var bindE = function (a) {
  return function (f) {
    return function () {
      return f(a())();
    };
  };
};

// output/Effect/index.js
var $runtime_lazy = function (name16, moduleName, init2) {
  var state3 = 0;
  var val;
  return function (lineNumber) {
    if (state3 === 2) return val;
    if (state3 === 1)
      throw new ReferenceError(
        name16 + " was needed before it finished initializing (module " + moduleName + ", line " + lineNumber + ")",
        moduleName,
        lineNumber
      );
    state3 = 1;
    val = init2();
    state3 = 2;
    return val;
  };
};
var monadEffect = {
  Applicative0: function () {
    return applicativeEffect;
  },
  Bind1: function () {
    return bindEffect;
  },
};
var bindEffect = {
  bind: bindE,
  Apply0: function () {
    return $lazy_applyEffect(0);
  },
};
var applicativeEffect = {
  pure: pureE,
  Apply0: function () {
    return $lazy_applyEffect(0);
  },
};
var $lazy_functorEffect = /* @__PURE__ */ $runtime_lazy("functorEffect", "Effect", function () {
  return {
    map: liftA1(applicativeEffect),
  };
});
var $lazy_applyEffect = /* @__PURE__ */ $runtime_lazy("applyEffect", "Effect", function () {
  return {
    apply: ap(monadEffect),
    Functor0: function () {
      return $lazy_functorEffect(0);
    },
  };
});
var functorEffect = /* @__PURE__ */ $lazy_functorEffect(20);

// output/Control.Monad.ST.Internal/foreign.js
var map_ = function (f) {
  return function (a) {
    return function () {
      return f(a());
    };
  };
};
var foreach = function (as) {
  return function (f) {
    return function () {
      for (var i3 = 0, l = as.length; i3 < l; i3++) {
        f(as[i3])();
      }
    };
  };
};

// output/Control.Monad.ST.Internal/index.js
var functorST = {
  map: map_,
};

// output/Data.Array.ST/foreign.js
var pushAll = function (as) {
  return function (xs) {
    return function () {
      return xs.push.apply(xs, as);
    };
  };
};
var unsafeFreeze = function (xs) {
  return function () {
    return xs;
  };
};
function copyImpl(xs) {
  return function () {
    return xs.slice();
  };
}
var thaw = copyImpl;

// output/Data.Array.ST/index.js
var withArray = function (f) {
  return function (xs) {
    return function __do2() {
      var result = thaw(xs)();
      f(result)();
      return unsafeFreeze(result)();
    };
  };
};
var push = function (a) {
  return pushAll([a]);
};

// output/Data.HeytingAlgebra/foreign.js
var boolConj = function (b1) {
  return function (b2) {
    return b1 && b2;
  };
};
var boolDisj = function (b1) {
  return function (b2) {
    return b1 || b2;
  };
};
var boolNot = function (b) {
  return !b;
};

// output/Data.HeytingAlgebra/index.js
var tt = function (dict) {
  return dict.tt;
};
var not = function (dict) {
  return dict.not;
};
var ff = function (dict) {
  return dict.ff;
};
var disj = function (dict) {
  return dict.disj;
};
var heytingAlgebraBoolean = {
  ff: false,
  tt: true,
  implies: function (a) {
    return function (b) {
      return disj(heytingAlgebraBoolean)(not(heytingAlgebraBoolean)(a))(b);
    };
  },
  conj: boolConj,
  disj: boolDisj,
  not: boolNot,
};
var conj = function (dict) {
  return dict.conj;
};

// output/Data.Foldable/foreign.js
var foldrArray = function (f) {
  return function (init2) {
    return function (xs) {
      var acc = init2;
      var len = xs.length;
      for (var i3 = len - 1; i3 >= 0; i3--) {
        acc = f(xs[i3])(acc);
      }
      return acc;
    };
  };
};
var foldlArray = function (f) {
  return function (init2) {
    return function (xs) {
      var acc = init2;
      var len = xs.length;
      for (var i3 = 0; i3 < len; i3++) {
        acc = f(acc)(xs[i3]);
      }
      return acc;
    };
  };
};

// output/Control.Plus/index.js
var empty = function (dict) {
  return dict.empty;
};

// output/Data.Tuple/index.js
var Tuple = /* @__PURE__ */ (function () {
  function Tuple2(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }
  Tuple2.create = function (value0) {
    return function (value1) {
      return new Tuple2(value0, value1);
    };
  };
  return Tuple2;
})();
var uncurry = function (f) {
  return function (v) {
    return f(v.value0)(v.value1);
  };
};
var snd = function (v) {
  return v.value1;
};
var fst = function (v) {
  return v.value0;
};

// output/Data.Bifunctor/index.js
var identity4 = /* @__PURE__ */ identity(categoryFn);
var bimap = function (dict) {
  return dict.bimap;
};
var lmap = function (dictBifunctor) {
  var bimap1 = bimap(dictBifunctor);
  return function (f) {
    return bimap1(f)(identity4);
  };
};
var rmap = function (dictBifunctor) {
  return bimap(dictBifunctor)(identity4);
};
var bifunctorTuple = {
  bimap: function (f) {
    return function (g) {
      return function (v) {
        return new Tuple(f(v.value0), g(v.value1));
      };
    };
  },
};
var bifunctorEither = {
  bimap: function (v) {
    return function (v1) {
      return function (v2) {
        if (v2 instanceof Left) {
          return new Left(v(v2.value0));
        }
        if (v2 instanceof Right) {
          return new Right(v1(v2.value0));
        }
        throw new Error(
          "Failed pattern match at Data.Bifunctor (line 32, column 1 - line 34, column 36): " +
            [v.constructor.name, v1.constructor.name, v2.constructor.name]
        );
      };
    };
  },
};

// output/Data.Monoid.Conj/index.js
var Conj = function (x) {
  return x;
};
var semigroupConj = function (dictHeytingAlgebra) {
  var conj2 = conj(dictHeytingAlgebra);
  return {
    append: function (v) {
      return function (v1) {
        return conj2(v)(v1);
      };
    },
  };
};
var monoidConj = function (dictHeytingAlgebra) {
  var semigroupConj1 = semigroupConj(dictHeytingAlgebra);
  return {
    mempty: tt(dictHeytingAlgebra),
    Semigroup0: function () {
      return semigroupConj1;
    },
  };
};

// output/Data.Monoid.Disj/index.js
var Disj = function (x) {
  return x;
};
var semigroupDisj = function (dictHeytingAlgebra) {
  var disj2 = disj(dictHeytingAlgebra);
  return {
    append: function (v) {
      return function (v1) {
        return disj2(v)(v1);
      };
    },
  };
};
var monoidDisj = function (dictHeytingAlgebra) {
  var semigroupDisj1 = semigroupDisj(dictHeytingAlgebra);
  return {
    mempty: ff(dictHeytingAlgebra),
    Semigroup0: function () {
      return semigroupDisj1;
    },
  };
};

// output/Unsafe.Coerce/foreign.js
var unsafeCoerce2 = function (x) {
  return x;
};

// output/Safe.Coerce/index.js
var coerce = function () {
  return unsafeCoerce2;
};

// output/Data.Newtype/index.js
var coerce2 = /* @__PURE__ */ coerce();
var wrap = function () {
  return coerce2;
};
var unwrap = function () {
  return coerce2;
};
var unwrap1 = /* @__PURE__ */ unwrap();
var un = function () {
  return function () {
    return unwrap1;
  };
};
var over = function () {
  return function () {
    return function () {
      return coerce2;
    };
  };
};
var alaF = function () {
  return function () {
    return function () {
      return function () {
        return function () {
          return coerce2;
        };
      };
    };
  };
};

// output/Data.Foldable/index.js
var identity5 = /* @__PURE__ */ identity(categoryFn);
var eq12 = /* @__PURE__ */ eq(eqOrdering);
var alaF2 = /* @__PURE__ */ alaF()()()();
var foldr = function (dict) {
  return dict.foldr;
};
var $$null = function (dictFoldable) {
  return foldr(dictFoldable)(function () {
    return function () {
      return false;
    };
  })(true);
};
var traverse_ = function (dictApplicative) {
  var applySecond2 = applySecond(dictApplicative.Apply0());
  var pure23 = pure(dictApplicative);
  return function (dictFoldable) {
    var foldr22 = foldr(dictFoldable);
    return function (f) {
      return foldr22(function ($449) {
        return applySecond2(f($449));
      })(pure23(unit));
    };
  };
};
var for_ = function (dictApplicative) {
  var traverse_1 = traverse_(dictApplicative);
  return function (dictFoldable) {
    return flip(traverse_1(dictFoldable));
  };
};
var foldl = function (dict) {
  return dict.foldl;
};
var minimumBy = function (dictFoldable) {
  var foldl22 = foldl(dictFoldable);
  return function (cmp) {
    var min$prime = function (v) {
      return function (v1) {
        if (v instanceof Nothing) {
          return new Just(v1);
        }
        if (v instanceof Just) {
          return new Just(
            (function () {
              var $302 = eq12(cmp(v.value0)(v1))(LT.value);
              if ($302) {
                return v.value0;
              }
              return v1;
            })()
          );
        }
        throw new Error(
          "Failed pattern match at Data.Foldable (line 454, column 3 - line 454, column 27): " +
            [v.constructor.name, v1.constructor.name]
        );
      };
    };
    return foldl22(min$prime)(Nothing.value);
  };
};
var minimum = function (dictOrd) {
  var compare3 = compare(dictOrd);
  return function (dictFoldable) {
    return minimumBy(dictFoldable)(compare3);
  };
};
var sum = function (dictFoldable) {
  var foldl22 = foldl(dictFoldable);
  return function (dictSemiring) {
    return foldl22(add(dictSemiring))(zero(dictSemiring));
  };
};
var foldableMaybe = {
  foldr: function (v) {
    return function (z) {
      return function (v1) {
        if (v1 instanceof Nothing) {
          return z;
        }
        if (v1 instanceof Just) {
          return v(v1.value0)(z);
        }
        throw new Error(
          "Failed pattern match at Data.Foldable (line 138, column 1 - line 144, column 27): " +
            [v.constructor.name, z.constructor.name, v1.constructor.name]
        );
      };
    };
  },
  foldl: function (v) {
    return function (z) {
      return function (v1) {
        if (v1 instanceof Nothing) {
          return z;
        }
        if (v1 instanceof Just) {
          return v(z)(v1.value0);
        }
        throw new Error(
          "Failed pattern match at Data.Foldable (line 138, column 1 - line 144, column 27): " +
            [v.constructor.name, z.constructor.name, v1.constructor.name]
        );
      };
    };
  },
  foldMap: function (dictMonoid) {
    var mempty5 = mempty(dictMonoid);
    return function (v) {
      return function (v1) {
        if (v1 instanceof Nothing) {
          return mempty5;
        }
        if (v1 instanceof Just) {
          return v(v1.value0);
        }
        throw new Error(
          "Failed pattern match at Data.Foldable (line 138, column 1 - line 144, column 27): " +
            [v.constructor.name, v1.constructor.name]
        );
      };
    };
  },
};
var foldMapDefaultR = function (dictFoldable) {
  var foldr22 = foldr(dictFoldable);
  return function (dictMonoid) {
    var append5 = append(dictMonoid.Semigroup0());
    var mempty5 = mempty(dictMonoid);
    return function (f) {
      return foldr22(function (x) {
        return function (acc) {
          return append5(f(x))(acc);
        };
      })(mempty5);
    };
  };
};
var foldableArray = {
  foldr: foldrArray,
  foldl: foldlArray,
  foldMap: function (dictMonoid) {
    return foldMapDefaultR(foldableArray)(dictMonoid);
  },
};
var foldMap = function (dict) {
  return dict.foldMap;
};
var foldM = function (dictFoldable) {
  var foldl22 = foldl(dictFoldable);
  return function (dictMonad) {
    var bind9 = bind(dictMonad.Bind1());
    var pure23 = pure(dictMonad.Applicative0());
    return function (f) {
      return function (b0) {
        return foldl22(function (b) {
          return function (a) {
            return bind9(b)(flip(f)(a));
          };
        })(pure23(b0));
      };
    };
  };
};
var fold = function (dictFoldable) {
  var foldMap22 = foldMap(dictFoldable);
  return function (dictMonoid) {
    return foldMap22(dictMonoid)(identity5);
  };
};
var any2 = function (dictFoldable) {
  var foldMap22 = foldMap(dictFoldable);
  return function (dictHeytingAlgebra) {
    return alaF2(Disj)(foldMap22(monoidDisj(dictHeytingAlgebra)));
  };
};
var all = function (dictFoldable) {
  var foldMap22 = foldMap(dictFoldable);
  return function (dictHeytingAlgebra) {
    return alaF2(Conj)(foldMap22(monoidConj(dictHeytingAlgebra)));
  };
};

// output/Data.Traversable/foreign.js
var traverseArrayImpl = (function () {
  function array1(a) {
    return [a];
  }
  function array2(a) {
    return function (b) {
      return [a, b];
    };
  }
  function array3(a) {
    return function (b) {
      return function (c) {
        return [a, b, c];
      };
    };
  }
  function concat2(xs) {
    return function (ys) {
      return xs.concat(ys);
    };
  }
  return function (apply3) {
    return function (map36) {
      return function (pure23) {
        return function (f) {
          return function (array4) {
            function go2(bot, top3) {
              switch (top3 - bot) {
                case 0:
                  return pure23([]);
                case 1:
                  return map36(array1)(f(array4[bot]));
                case 2:
                  return apply3(map36(array2)(f(array4[bot])))(f(array4[bot + 1]));
                case 3:
                  return apply3(apply3(map36(array3)(f(array4[bot])))(f(array4[bot + 1])))(f(array4[bot + 2]));
                default:
                  var pivot = bot + Math.floor((top3 - bot) / 4) * 2;
                  return apply3(map36(concat2)(go2(bot, pivot)))(go2(pivot, top3));
              }
            }
            return go2(0, array4.length);
          };
        };
      };
    };
  };
})();

// output/Data.Monoid.Additive/index.js
var Additive = function (x) {
  return x;
};
var semigroupAdditive = function (dictSemiring) {
  var add3 = add(dictSemiring);
  return {
    append: function (v) {
      return function (v1) {
        return add3(v)(v1);
      };
    },
  };
};
var monoidAdditive = function (dictSemiring) {
  var semigroupAdditive1 = semigroupAdditive(dictSemiring);
  return {
    mempty: zero(dictSemiring),
    Semigroup0: function () {
      return semigroupAdditive1;
    },
  };
};

// output/Data.Traversable/index.js
var identity6 = /* @__PURE__ */ identity(categoryFn);
var traverse = function (dict) {
  return dict.traverse;
};
var traversableMaybe = {
  traverse: function (dictApplicative) {
    var pure23 = pure(dictApplicative);
    var map36 = map(dictApplicative.Apply0().Functor0());
    return function (v) {
      return function (v1) {
        if (v1 instanceof Nothing) {
          return pure23(Nothing.value);
        }
        if (v1 instanceof Just) {
          return map36(Just.create)(v(v1.value0));
        }
        throw new Error(
          "Failed pattern match at Data.Traversable (line 115, column 1 - line 119, column 33): " +
            [v.constructor.name, v1.constructor.name]
        );
      };
    };
  },
  sequence: function (dictApplicative) {
    var pure23 = pure(dictApplicative);
    var map36 = map(dictApplicative.Apply0().Functor0());
    return function (v) {
      if (v instanceof Nothing) {
        return pure23(Nothing.value);
      }
      if (v instanceof Just) {
        return map36(Just.create)(v.value0);
      }
      throw new Error(
        "Failed pattern match at Data.Traversable (line 115, column 1 - line 119, column 33): " + [v.constructor.name]
      );
    };
  },
  Functor0: function () {
    return functorMaybe;
  },
  Foldable1: function () {
    return foldableMaybe;
  },
};
var sequenceDefault = function (dictTraversable) {
  var traverse22 = traverse(dictTraversable);
  return function (dictApplicative) {
    return traverse22(dictApplicative)(identity6);
  };
};
var traversableArray = {
  traverse: function (dictApplicative) {
    var Apply0 = dictApplicative.Apply0();
    return traverseArrayImpl(apply(Apply0))(map(Apply0.Functor0()))(pure(dictApplicative));
  },
  sequence: function (dictApplicative) {
    return sequenceDefault(traversableArray)(dictApplicative);
  },
  Functor0: function () {
    return functorArray;
  },
  Foldable1: function () {
    return foldableArray;
  },
};
var sequence = function (dict) {
  return dict.sequence;
};

// output/Data.Unfoldable/foreign.js
var unfoldrArrayImpl = function (isNothing2) {
  return function (fromJust9) {
    return function (fst2) {
      return function (snd2) {
        return function (f) {
          return function (b) {
            var result = [];
            var value14 = b;
            while (true) {
              var maybe3 = f(value14);
              if (isNothing2(maybe3)) return result;
              var tuple2 = fromJust9(maybe3);
              result.push(fst2(tuple2));
              value14 = snd2(tuple2);
            }
          };
        };
      };
    };
  };
};

// output/Data.Unfoldable1/foreign.js
var unfoldr1ArrayImpl = function (isNothing2) {
  return function (fromJust9) {
    return function (fst2) {
      return function (snd2) {
        return function (f) {
          return function (b) {
            var result = [];
            var value14 = b;
            while (true) {
              var tuple2 = f(value14);
              result.push(fst2(tuple2));
              var maybe3 = snd2(tuple2);
              if (isNothing2(maybe3)) return result;
              value14 = fromJust9(maybe3);
            }
          };
        };
      };
    };
  };
};

// output/Data.Unfoldable1/index.js
var fromJust2 = /* @__PURE__ */ fromJust();
var unfoldable1Array = {
  unfoldr1: /* @__PURE__ */ unfoldr1ArrayImpl(isNothing)(fromJust2)(fst)(snd),
};

// output/Data.Unfoldable/index.js
var fromJust3 = /* @__PURE__ */ fromJust();
var unfoldr = function (dict) {
  return dict.unfoldr;
};
var unfoldableArray = {
  unfoldr: /* @__PURE__ */ unfoldrArrayImpl(isNothing)(fromJust3)(fst)(snd),
  Unfoldable10: function () {
    return unfoldable1Array;
  },
};
var replicate2 = function (dictUnfoldable) {
  var unfoldr12 = unfoldr(dictUnfoldable);
  return function (n) {
    return function (v) {
      var step3 = function (i3) {
        var $17 = i3 <= 0;
        if ($17) {
          return Nothing.value;
        }
        return new Just(new Tuple(v, (i3 - 1) | 0));
      };
      return unfoldr12(step3)(n);
    };
  };
};

// output/Data.Array/index.js
var append2 = /* @__PURE__ */ append(semigroupArray);
var traverse2 = /* @__PURE__ */ traverse(traversableArray);
var zip = /* @__PURE__ */ (function () {
  return zipWith(Tuple.create);
})();
var unsafeIndex = function () {
  return unsafeIndexImpl;
};
var unsafeIndex1 = /* @__PURE__ */ unsafeIndex();
var toUnfoldable = function (dictUnfoldable) {
  var unfoldr3 = unfoldr(dictUnfoldable);
  return function (xs) {
    var len = length(xs);
    var f = function (i3) {
      if (i3 < len) {
        return new Just(new Tuple(unsafeIndex1(xs)(i3), (i3 + 1) | 0));
      }
      if (otherwise) {
        return Nothing.value;
      }
      throw new Error(
        "Failed pattern match at Data.Array (line 156, column 3 - line 158, column 26): " + [i3.constructor.name]
      );
    };
    return unfoldr3(f)(0);
  };
};
var snoc = function (xs) {
  return function (x) {
    return withArray(push(x))(xs)();
  };
};
var singleton2 = function (a) {
  return [a];
};
var $$null2 = function (xs) {
  return length(xs) === 0;
};
var mapWithIndex = function (f) {
  return function (xs) {
    return zipWith(f)(range(0)((length(xs) - 1) | 0))(xs);
  };
};
var index = /* @__PURE__ */ (function () {
  return indexImpl(Just.create)(Nothing.value);
})();
var head = function (xs) {
  return index(xs)(0);
};
var fromFoldable = function (dictFoldable) {
  return fromFoldableImpl(foldr(dictFoldable));
};
var cons = function (x) {
  return function (xs) {
    return append2([x])(xs);
  };
};
var concatMap = /* @__PURE__ */ flip(/* @__PURE__ */ bind(bindArray));
var mapMaybe = function (f) {
  return concatMap(
    (function () {
      var $185 = maybe([])(singleton2);
      return function ($186) {
        return $185(f($186));
      };
    })()
  );
};
var filterA = function (dictApplicative) {
  var traverse13 = traverse2(dictApplicative);
  var map36 = map(dictApplicative.Apply0().Functor0());
  return function (p) {
    var $187 = map36(
      mapMaybe(function (v) {
        if (v.value1) {
          return new Just(v.value0);
        }
        return Nothing.value;
      })
    );
    var $188 = traverse13(function (x) {
      return map36(Tuple.create(x))(p(x));
    });
    return function ($189) {
      return $187($188($189));
    };
  };
};
var catMaybes = /* @__PURE__ */ mapMaybe(/* @__PURE__ */ identity(categoryFn));

// output/Data.Typelevel.Undefined/index.js
var $$undefined = unit;

// output/Effect.Aff/foreign.js
var Aff = (function () {
  var EMPTY = {};
  var PURE = "Pure";
  var THROW = "Throw";
  var CATCH = "Catch";
  var SYNC = "Sync";
  var ASYNC = "Async";
  var BIND = "Bind";
  var BRACKET = "Bracket";
  var FORK = "Fork";
  var SEQ = "Sequential";
  var MAP = "Map";
  var APPLY = "Apply";
  var ALT = "Alt";
  var CONS = "Cons";
  var RESUME = "Resume";
  var RELEASE = "Release";
  var FINALIZER = "Finalizer";
  var FINALIZED = "Finalized";
  var FORKED = "Forked";
  function Aff2(tag, _1, _2, _3) {
    this.tag = tag;
    this._1 = _1;
    this._2 = _2;
    this._3 = _3;
  }
  function AffCtr(tag) {
    var fn = function (_1, _2, _3) {
      return new Aff2(tag, _1, _2, _3);
    };
    fn.tag = tag;
    return fn;
  }
  function nonCanceler2(error3) {
    return new Aff2(PURE, void 0);
  }
  function runEff(eff) {
    try {
      eff();
    } catch (error3) {
      setTimeout(function () {
        throw error3;
      }, 0);
    }
  }
  function runSync(left, right, eff) {
    try {
      return right(eff());
    } catch (error3) {
      return left(error3);
    }
  }
  function runAsync(left, eff, k) {
    try {
      return eff(k)();
    } catch (error3) {
      k(left(error3))();
      return nonCanceler2;
    }
  }
  var Scheduler = (function () {
    var limit = 1024;
    var size5 = 0;
    var ix = 0;
    var queue = new Array(limit);
    var draining = false;
    function drain() {
      var thunk;
      draining = true;
      while (size5 !== 0) {
        size5--;
        thunk = queue[ix];
        queue[ix] = void 0;
        ix = (ix + 1) % limit;
        thunk();
      }
      draining = false;
    }
    return {
      isDraining: function () {
        return draining;
      },
      enqueue: function (cb) {
        var tmp;
        if (size5 === limit) {
          tmp = draining;
          drain();
          draining = tmp;
        }
        queue[(ix + size5) % limit] = cb;
        size5++;
        if (!draining) {
          drain();
        }
      },
    };
  })();
  function Supervisor(util) {
    var fibers = {};
    var fiberId = 0;
    var count = 0;
    return {
      register: function (fiber) {
        var fid = fiberId++;
        fiber.onComplete({
          rethrow: true,
          handler: function () {
            return function () {
              count--;
              delete fibers[fid];
            };
          },
        })();
        fibers[fid] = fiber;
        count++;
      },
      isEmpty: function () {
        return count === 0;
      },
      killAll: function (killError, cb) {
        return function () {
          if (count === 0) {
            return cb();
          }
          var killCount = 0;
          var kills = {};
          function kill2(fid) {
            kills[fid] = fibers[fid].kill(killError, function (result) {
              return function () {
                delete kills[fid];
                killCount--;
                if (util.isLeft(result) && util.fromLeft(result)) {
                  setTimeout(function () {
                    throw util.fromLeft(result);
                  }, 0);
                }
                if (killCount === 0) {
                  cb();
                }
              };
            })();
          }
          for (var k in fibers) {
            if (fibers.hasOwnProperty(k)) {
              killCount++;
              kill2(k);
            }
          }
          fibers = {};
          fiberId = 0;
          count = 0;
          return function () {
            return new Aff2(SYNC, function () {
              for (var k2 in kills) {
                if (kills.hasOwnProperty(k2)) {
                  kills[k2]();
                }
              }
            });
          };
        };
      },
    };
  }
  var SUSPENDED = 0;
  var CONTINUE = 1;
  var STEP_BIND = 2;
  var STEP_RESULT = 3;
  var PENDING = 4;
  var RETURN = 5;
  var COMPLETED = 6;
  function Fiber(util, supervisor, aff) {
    var runTick = 0;
    var status2 = SUSPENDED;
    var step3 = aff;
    var fail3 = null;
    var interrupt = null;
    var bhead = null;
    var btail = null;
    var attempts = null;
    var bracketCount = 0;
    var joinId = 0;
    var joins = null;
    var rethrow = true;
    function run3(localRunTick) {
      var tmp, result, attempt;
      while (true) {
        tmp = null;
        result = null;
        attempt = null;
        switch (status2) {
          case STEP_BIND:
            status2 = CONTINUE;
            try {
              step3 = bhead(step3);
              if (btail === null) {
                bhead = null;
              } else {
                bhead = btail._1;
                btail = btail._2;
              }
            } catch (e) {
              status2 = RETURN;
              fail3 = util.left(e);
              step3 = null;
            }
            break;
          case STEP_RESULT:
            if (util.isLeft(step3)) {
              status2 = RETURN;
              fail3 = step3;
              step3 = null;
            } else if (bhead === null) {
              status2 = RETURN;
            } else {
              status2 = STEP_BIND;
              step3 = util.fromRight(step3);
            }
            break;
          case CONTINUE:
            switch (step3.tag) {
              case BIND:
                if (bhead) {
                  btail = new Aff2(CONS, bhead, btail);
                }
                bhead = step3._2;
                status2 = CONTINUE;
                step3 = step3._1;
                break;
              case PURE:
                if (bhead === null) {
                  status2 = RETURN;
                  step3 = util.right(step3._1);
                } else {
                  status2 = STEP_BIND;
                  step3 = step3._1;
                }
                break;
              case SYNC:
                status2 = STEP_RESULT;
                step3 = runSync(util.left, util.right, step3._1);
                break;
              case ASYNC:
                status2 = PENDING;
                step3 = runAsync(util.left, step3._1, function (result2) {
                  return function () {
                    if (runTick !== localRunTick) {
                      return;
                    }
                    runTick++;
                    Scheduler.enqueue(function () {
                      if (runTick !== localRunTick + 1) {
                        return;
                      }
                      status2 = STEP_RESULT;
                      step3 = result2;
                      run3(runTick);
                    });
                  };
                });
                return;
              case THROW:
                status2 = RETURN;
                fail3 = util.left(step3._1);
                step3 = null;
                break;
              case CATCH:
                if (bhead === null) {
                  attempts = new Aff2(CONS, step3, attempts, interrupt);
                } else {
                  attempts = new Aff2(
                    CONS,
                    step3,
                    new Aff2(CONS, new Aff2(RESUME, bhead, btail), attempts, interrupt),
                    interrupt
                  );
                }
                bhead = null;
                btail = null;
                status2 = CONTINUE;
                step3 = step3._1;
                break;
              case BRACKET:
                bracketCount++;
                if (bhead === null) {
                  attempts = new Aff2(CONS, step3, attempts, interrupt);
                } else {
                  attempts = new Aff2(
                    CONS,
                    step3,
                    new Aff2(CONS, new Aff2(RESUME, bhead, btail), attempts, interrupt),
                    interrupt
                  );
                }
                bhead = null;
                btail = null;
                status2 = CONTINUE;
                step3 = step3._1;
                break;
              case FORK:
                status2 = STEP_RESULT;
                tmp = Fiber(util, supervisor, step3._2);
                if (supervisor) {
                  supervisor.register(tmp);
                }
                if (step3._1) {
                  tmp.run();
                }
                step3 = util.right(tmp);
                break;
              case SEQ:
                status2 = CONTINUE;
                step3 = sequential4(util, supervisor, step3._1);
                break;
            }
            break;
          case RETURN:
            bhead = null;
            btail = null;
            if (attempts === null) {
              status2 = COMPLETED;
              step3 = interrupt || fail3 || step3;
            } else {
              tmp = attempts._3;
              attempt = attempts._1;
              attempts = attempts._2;
              switch (attempt.tag) {
                case CATCH:
                  if (interrupt && interrupt !== tmp && bracketCount === 0) {
                    status2 = RETURN;
                  } else if (fail3) {
                    status2 = CONTINUE;
                    step3 = attempt._2(util.fromLeft(fail3));
                    fail3 = null;
                  }
                  break;
                case RESUME:
                  if ((interrupt && interrupt !== tmp && bracketCount === 0) || fail3) {
                    status2 = RETURN;
                  } else {
                    bhead = attempt._1;
                    btail = attempt._2;
                    status2 = STEP_BIND;
                    step3 = util.fromRight(step3);
                  }
                  break;
                case BRACKET:
                  bracketCount--;
                  if (fail3 === null) {
                    result = util.fromRight(step3);
                    attempts = new Aff2(CONS, new Aff2(RELEASE, attempt._2, result), attempts, tmp);
                    if (interrupt === tmp || bracketCount > 0) {
                      status2 = CONTINUE;
                      step3 = attempt._3(result);
                    }
                  }
                  break;
                case RELEASE:
                  attempts = new Aff2(CONS, new Aff2(FINALIZED, step3, fail3), attempts, interrupt);
                  status2 = CONTINUE;
                  if (interrupt && interrupt !== tmp && bracketCount === 0) {
                    step3 = attempt._1.killed(util.fromLeft(interrupt))(attempt._2);
                  } else if (fail3) {
                    step3 = attempt._1.failed(util.fromLeft(fail3))(attempt._2);
                  } else {
                    step3 = attempt._1.completed(util.fromRight(step3))(attempt._2);
                  }
                  fail3 = null;
                  bracketCount++;
                  break;
                case FINALIZER:
                  bracketCount++;
                  attempts = new Aff2(CONS, new Aff2(FINALIZED, step3, fail3), attempts, interrupt);
                  status2 = CONTINUE;
                  step3 = attempt._1;
                  break;
                case FINALIZED:
                  bracketCount--;
                  status2 = RETURN;
                  step3 = attempt._1;
                  fail3 = attempt._2;
                  break;
              }
            }
            break;
          case COMPLETED:
            for (var k in joins) {
              if (joins.hasOwnProperty(k)) {
                rethrow = rethrow && joins[k].rethrow;
                runEff(joins[k].handler(step3));
              }
            }
            joins = null;
            if (interrupt && fail3) {
              setTimeout(function () {
                throw util.fromLeft(fail3);
              }, 0);
            } else if (util.isLeft(step3) && rethrow) {
              setTimeout(function () {
                if (rethrow) {
                  throw util.fromLeft(step3);
                }
              }, 0);
            }
            return;
          case SUSPENDED:
            status2 = CONTINUE;
            break;
          case PENDING:
            return;
        }
      }
    }
    function onComplete(join3) {
      return function () {
        if (status2 === COMPLETED) {
          rethrow = rethrow && join3.rethrow;
          join3.handler(step3)();
          return function () {};
        }
        var jid = joinId++;
        joins = joins || {};
        joins[jid] = join3;
        return function () {
          if (joins !== null) {
            delete joins[jid];
          }
        };
      };
    }
    function kill2(error3, cb) {
      return function () {
        if (status2 === COMPLETED) {
          cb(util.right(void 0))();
          return function () {};
        }
        var canceler = onComplete({
          rethrow: false,
          handler: function () {
            return cb(util.right(void 0));
          },
        })();
        switch (status2) {
          case SUSPENDED:
            interrupt = util.left(error3);
            status2 = COMPLETED;
            step3 = interrupt;
            run3(runTick);
            break;
          case PENDING:
            if (interrupt === null) {
              interrupt = util.left(error3);
            }
            if (bracketCount === 0) {
              if (status2 === PENDING) {
                attempts = new Aff2(CONS, new Aff2(FINALIZER, step3(error3)), attempts, interrupt);
              }
              status2 = RETURN;
              step3 = null;
              fail3 = null;
              run3(++runTick);
            }
            break;
          default:
            if (interrupt === null) {
              interrupt = util.left(error3);
            }
            if (bracketCount === 0) {
              status2 = RETURN;
              step3 = null;
              fail3 = null;
            }
        }
        return canceler;
      };
    }
    function join2(cb) {
      return function () {
        var canceler = onComplete({
          rethrow: false,
          handler: cb,
        })();
        if (status2 === SUSPENDED) {
          run3(runTick);
        }
        return canceler;
      };
    }
    return {
      kill: kill2,
      join: join2,
      onComplete,
      isSuspended: function () {
        return status2 === SUSPENDED;
      },
      run: function () {
        if (status2 === SUSPENDED) {
          if (!Scheduler.isDraining()) {
            Scheduler.enqueue(function () {
              run3(runTick);
            });
          } else {
            run3(runTick);
          }
        }
      },
    };
  }
  function runPar(util, supervisor, par, cb) {
    var fiberId = 0;
    var fibers = {};
    var killId = 0;
    var kills = {};
    var early = new Error("[ParAff] Early exit");
    var interrupt = null;
    var root = EMPTY;
    function kill2(error3, par2, cb2) {
      var step3 = par2;
      var head4 = null;
      var tail = null;
      var count = 0;
      var kills2 = {};
      var tmp, kid;
      loop: while (true) {
        tmp = null;
        switch (step3.tag) {
          case FORKED:
            if (step3._3 === EMPTY) {
              tmp = fibers[step3._1];
              kills2[count++] = tmp.kill(error3, function (result) {
                return function () {
                  count--;
                  if (count === 0) {
                    cb2(result)();
                  }
                };
              });
            }
            if (head4 === null) {
              break loop;
            }
            step3 = head4._2;
            if (tail === null) {
              head4 = null;
            } else {
              head4 = tail._1;
              tail = tail._2;
            }
            break;
          case MAP:
            step3 = step3._2;
            break;
          case APPLY:
          case ALT:
            if (head4) {
              tail = new Aff2(CONS, head4, tail);
            }
            head4 = step3;
            step3 = step3._1;
            break;
        }
      }
      if (count === 0) {
        cb2(util.right(void 0))();
      } else {
        kid = 0;
        tmp = count;
        for (; kid < tmp; kid++) {
          kills2[kid] = kills2[kid]();
        }
      }
      return kills2;
    }
    function join2(result, head4, tail) {
      var fail3, step3, lhs, rhs, tmp, kid;
      if (util.isLeft(result)) {
        fail3 = result;
        step3 = null;
      } else {
        step3 = result;
        fail3 = null;
      }
      loop: while (true) {
        lhs = null;
        rhs = null;
        tmp = null;
        kid = null;
        if (interrupt !== null) {
          return;
        }
        if (head4 === null) {
          cb(fail3 || step3)();
          return;
        }
        if (head4._3 !== EMPTY) {
          return;
        }
        switch (head4.tag) {
          case MAP:
            if (fail3 === null) {
              head4._3 = util.right(head4._1(util.fromRight(step3)));
              step3 = head4._3;
            } else {
              head4._3 = fail3;
            }
            break;
          case APPLY:
            lhs = head4._1._3;
            rhs = head4._2._3;
            if (fail3) {
              head4._3 = fail3;
              tmp = true;
              kid = killId++;
              kills[kid] = kill2(early, fail3 === lhs ? head4._2 : head4._1, function () {
                return function () {
                  delete kills[kid];
                  if (tmp) {
                    tmp = false;
                  } else if (tail === null) {
                    join2(fail3, null, null);
                  } else {
                    join2(fail3, tail._1, tail._2);
                  }
                };
              });
              if (tmp) {
                tmp = false;
                return;
              }
            } else if (lhs === EMPTY || rhs === EMPTY) {
              return;
            } else {
              step3 = util.right(util.fromRight(lhs)(util.fromRight(rhs)));
              head4._3 = step3;
            }
            break;
          case ALT:
            lhs = head4._1._3;
            rhs = head4._2._3;
            if ((lhs === EMPTY && util.isLeft(rhs)) || (rhs === EMPTY && util.isLeft(lhs))) {
              return;
            }
            if (lhs !== EMPTY && util.isLeft(lhs) && rhs !== EMPTY && util.isLeft(rhs)) {
              fail3 = step3 === lhs ? rhs : lhs;
              step3 = null;
              head4._3 = fail3;
            } else {
              head4._3 = step3;
              tmp = true;
              kid = killId++;
              kills[kid] = kill2(early, step3 === lhs ? head4._2 : head4._1, function () {
                return function () {
                  delete kills[kid];
                  if (tmp) {
                    tmp = false;
                  } else if (tail === null) {
                    join2(step3, null, null);
                  } else {
                    join2(step3, tail._1, tail._2);
                  }
                };
              });
              if (tmp) {
                tmp = false;
                return;
              }
            }
            break;
        }
        if (tail === null) {
          head4 = null;
        } else {
          head4 = tail._1;
          tail = tail._2;
        }
      }
    }
    function resolve(fiber) {
      return function (result) {
        return function () {
          delete fibers[fiber._1];
          fiber._3 = result;
          join2(result, fiber._2._1, fiber._2._2);
        };
      };
    }
    function run3() {
      var status2 = CONTINUE;
      var step3 = par;
      var head4 = null;
      var tail = null;
      var tmp, fid;
      loop: while (true) {
        tmp = null;
        fid = null;
        switch (status2) {
          case CONTINUE:
            switch (step3.tag) {
              case MAP:
                if (head4) {
                  tail = new Aff2(CONS, head4, tail);
                }
                head4 = new Aff2(MAP, step3._1, EMPTY, EMPTY);
                step3 = step3._2;
                break;
              case APPLY:
                if (head4) {
                  tail = new Aff2(CONS, head4, tail);
                }
                head4 = new Aff2(APPLY, EMPTY, step3._2, EMPTY);
                step3 = step3._1;
                break;
              case ALT:
                if (head4) {
                  tail = new Aff2(CONS, head4, tail);
                }
                head4 = new Aff2(ALT, EMPTY, step3._2, EMPTY);
                step3 = step3._1;
                break;
              default:
                fid = fiberId++;
                status2 = RETURN;
                tmp = step3;
                step3 = new Aff2(FORKED, fid, new Aff2(CONS, head4, tail), EMPTY);
                tmp = Fiber(util, supervisor, tmp);
                tmp.onComplete({
                  rethrow: false,
                  handler: resolve(step3),
                })();
                fibers[fid] = tmp;
                if (supervisor) {
                  supervisor.register(tmp);
                }
            }
            break;
          case RETURN:
            if (head4 === null) {
              break loop;
            }
            if (head4._1 === EMPTY) {
              head4._1 = step3;
              status2 = CONTINUE;
              step3 = head4._2;
              head4._2 = EMPTY;
            } else {
              head4._2 = step3;
              step3 = head4;
              if (tail === null) {
                head4 = null;
              } else {
                head4 = tail._1;
                tail = tail._2;
              }
            }
        }
      }
      root = step3;
      for (fid = 0; fid < fiberId; fid++) {
        fibers[fid].run();
      }
    }
    function cancel(error3, cb2) {
      interrupt = util.left(error3);
      var innerKills;
      for (var kid in kills) {
        if (kills.hasOwnProperty(kid)) {
          innerKills = kills[kid];
          for (kid in innerKills) {
            if (innerKills.hasOwnProperty(kid)) {
              innerKills[kid]();
            }
          }
        }
      }
      kills = null;
      var newKills = kill2(error3, root, cb2);
      return function () {
        return new Aff2(ASYNC, function () {
          return function () {
            for (var kid2 in newKills) {
              if (newKills.hasOwnProperty(kid2)) {
                newKills[kid2]();
              }
            }
            return nonCanceler2;
          };
        });
      };
    }
    run3();
    return function (killError) {
      return new Aff2(ASYNC, function (killCb) {
        return function () {
          return cancel(killError, killCb);
        };
      });
    };
  }
  function sequential4(util, supervisor, par) {
    return new Aff2(ASYNC, function (cb) {
      return function () {
        return runPar(util, supervisor, par, cb);
      };
    });
  }
  Aff2.EMPTY = EMPTY;
  Aff2.Pure = AffCtr(PURE);
  Aff2.Throw = AffCtr(THROW);
  Aff2.Catch = AffCtr(CATCH);
  Aff2.Sync = AffCtr(SYNC);
  Aff2.Async = AffCtr(ASYNC);
  Aff2.Bind = AffCtr(BIND);
  Aff2.Bracket = AffCtr(BRACKET);
  Aff2.Fork = AffCtr(FORK);
  Aff2.Seq = AffCtr(SEQ);
  Aff2.ParMap = AffCtr(MAP);
  Aff2.ParApply = AffCtr(APPLY);
  Aff2.ParAlt = AffCtr(ALT);
  Aff2.Fiber = Fiber;
  Aff2.Supervisor = Supervisor;
  Aff2.Scheduler = Scheduler;
  Aff2.nonCanceler = nonCanceler2;
  return Aff2;
})();
var _pure = Aff.Pure;
var _throwError = Aff.Throw;
function _catchError(aff) {
  return function (k) {
    return Aff.Catch(aff, k);
  };
}
function _map(f) {
  return function (aff) {
    if (aff.tag === Aff.Pure.tag) {
      return Aff.Pure(f(aff._1));
    } else {
      return Aff.Bind(aff, function (value14) {
        return Aff.Pure(f(value14));
      });
    }
  };
}
function _bind(aff) {
  return function (k) {
    return Aff.Bind(aff, k);
  };
}
var _liftEffect = Aff.Sync;
function _parAffMap(f) {
  return function (aff) {
    return Aff.ParMap(f, aff);
  };
}
function _parAffApply(aff1) {
  return function (aff2) {
    return Aff.ParApply(aff1, aff2);
  };
}
var makeAff = Aff.Async;
function _makeFiber(util, aff) {
  return function () {
    return Aff.Fiber(util, null, aff);
  };
}
var _delay = (function () {
  function setDelay(n, k) {
    if (n === 0 && typeof setImmediate !== "undefined") {
      return setImmediate(k);
    } else {
      return setTimeout(k, n);
    }
  }
  function clearDelay(n, t) {
    if (n === 0 && typeof clearImmediate !== "undefined") {
      return clearImmediate(t);
    } else {
      return clearTimeout(t);
    }
  }
  return function (right, ms) {
    return Aff.Async(function (cb) {
      return function () {
        var timer = setDelay(ms, cb(right()));
        return function () {
          return Aff.Sync(function () {
            return right(clearDelay(ms, timer));
          });
        };
      };
    });
  };
})();
var _sequential = Aff.Seq;

// output/Effect.Exception/foreign.js
function error(msg) {
  return new Error(msg);
}
function message(e) {
  return e.message;
}

// output/Control.Monad.Error.Class/index.js
var throwError = function (dict) {
  return dict.throwError;
};
var liftMaybe = function (dictMonadThrow) {
  var throwError1 = throwError(dictMonadThrow);
  var pure23 = pure(dictMonadThrow.Monad0().Applicative0());
  return function (error3) {
    return maybe(throwError1(error3))(pure23);
  };
};
var liftEither = function (dictMonadThrow) {
  return either(throwError(dictMonadThrow))(pure(dictMonadThrow.Monad0().Applicative0()));
};
var catchError = function (dict) {
  return dict.catchError;
};
var $$try = function (dictMonadError) {
  var catchError1 = catchError(dictMonadError);
  var Monad0 = dictMonadError.MonadThrow0().Monad0();
  var map36 = map(Monad0.Bind1().Apply0().Functor0());
  var pure23 = pure(Monad0.Applicative0());
  return function (a) {
    return catchError1(map36(Right.create)(a))(function ($52) {
      return pure23(Left.create($52));
    });
  };
};

// output/Control.Monad.Trans.Class/index.js
var lift = function (dict) {
  return dict.lift;
};

// output/Effect.Class/index.js
var monadEffectEffect = {
  liftEffect: /* @__PURE__ */ identity(categoryFn),
  Monad0: function () {
    return monadEffect;
  },
};
var liftEffect = function (dict) {
  return dict.liftEffect;
};

// output/Control.Monad.Except.Trans/index.js
var map4 = /* @__PURE__ */ map(functorEither);
var ExceptT = function (x) {
  return x;
};
var runExceptT = function (v) {
  return v;
};
var monadTransExceptT = {
  lift: function (dictMonad) {
    var bind9 = bind(dictMonad.Bind1());
    var pure23 = pure(dictMonad.Applicative0());
    return function (m) {
      return bind9(m)(function (a) {
        return pure23(new Right(a));
      });
    };
  },
};
var lift3 = /* @__PURE__ */ lift(monadTransExceptT);
var mapExceptT = function (f) {
  return function (v) {
    return f(v);
  };
};
var functorExceptT = function (dictFunctor) {
  var map115 = map(dictFunctor);
  return {
    map: function (f) {
      return mapExceptT(map115(map4(f)));
    },
  };
};
var except = function (dictApplicative) {
  var $185 = pure(dictApplicative);
  return function ($186) {
    return ExceptT($185($186));
  };
};
var monadExceptT = function (dictMonad) {
  return {
    Applicative0: function () {
      return applicativeExceptT(dictMonad);
    },
    Bind1: function () {
      return bindExceptT(dictMonad);
    },
  };
};
var bindExceptT = function (dictMonad) {
  var bind9 = bind(dictMonad.Bind1());
  var pure23 = pure(dictMonad.Applicative0());
  return {
    bind: function (v) {
      return function (k) {
        return bind9(v)(
          either(function ($187) {
            return pure23(Left.create($187));
          })(function (a) {
            var v1 = k(a);
            return v1;
          })
        );
      };
    },
    Apply0: function () {
      return applyExceptT(dictMonad);
    },
  };
};
var applyExceptT = function (dictMonad) {
  var functorExceptT1 = functorExceptT(dictMonad.Bind1().Apply0().Functor0());
  return {
    apply: ap(monadExceptT(dictMonad)),
    Functor0: function () {
      return functorExceptT1;
    },
  };
};
var applicativeExceptT = function (dictMonad) {
  return {
    pure: (function () {
      var $188 = pure(dictMonad.Applicative0());
      return function ($189) {
        return ExceptT($188(Right.create($189)));
      };
    })(),
    Apply0: function () {
      return applyExceptT(dictMonad);
    },
  };
};
var monadEffectExceptT = function (dictMonadEffect) {
  var Monad0 = dictMonadEffect.Monad0();
  var monadExceptT1 = monadExceptT(Monad0);
  return {
    liftEffect: (function () {
      var $190 = lift3(Monad0);
      var $191 = liftEffect(dictMonadEffect);
      return function ($192) {
        return $190($191($192));
      };
    })(),
    Monad0: function () {
      return monadExceptT1;
    },
  };
};
var monadThrowExceptT = function (dictMonad) {
  var monadExceptT1 = monadExceptT(dictMonad);
  return {
    throwError: (function () {
      var $198 = pure(dictMonad.Applicative0());
      return function ($199) {
        return ExceptT($198(Left.create($199)));
      };
    })(),
    Monad0: function () {
      return monadExceptT1;
    },
  };
};
var monadErrorExceptT = function (dictMonad) {
  var bind9 = bind(dictMonad.Bind1());
  var pure23 = pure(dictMonad.Applicative0());
  var monadThrowExceptT1 = monadThrowExceptT(dictMonad);
  return {
    catchError: function (v) {
      return function (k) {
        return bind9(v)(
          either(function (a) {
            var v1 = k(a);
            return v1;
          })(function ($200) {
            return pure23(Right.create($200));
          })
        );
      };
    },
    MonadThrow0: function () {
      return monadThrowExceptT1;
    },
  };
};
var altExceptT = function (dictSemigroup) {
  var append5 = append(dictSemigroup);
  return function (dictMonad) {
    var Bind1 = dictMonad.Bind1();
    var bind9 = bind(Bind1);
    var pure23 = pure(dictMonad.Applicative0());
    var functorExceptT1 = functorExceptT(Bind1.Apply0().Functor0());
    return {
      alt: function (v) {
        return function (v1) {
          return bind9(v)(function (rm) {
            if (rm instanceof Right) {
              return pure23(new Right(rm.value0));
            }
            if (rm instanceof Left) {
              return bind9(v1)(function (rn) {
                if (rn instanceof Right) {
                  return pure23(new Right(rn.value0));
                }
                if (rn instanceof Left) {
                  return pure23(new Left(append5(rm.value0)(rn.value0)));
                }
                throw new Error(
                  "Failed pattern match at Control.Monad.Except.Trans (line 86, column 9 - line 88, column 49): " +
                    [rn.constructor.name]
                );
              });
            }
            throw new Error(
              "Failed pattern match at Control.Monad.Except.Trans (line 82, column 5 - line 88, column 49): " +
                [rm.constructor.name]
            );
          });
        };
      },
      Functor0: function () {
        return functorExceptT1;
      },
    };
  };
};

// output/Type.Equality/index.js
var refl = {
  proof: function (a) {
    return a;
  },
  Coercible0: function () {
    return void 0;
  },
};
var proof = function (dict) {
  return dict.proof;
};
var from2 = function (dictTypeEquals) {
  var v = proof(dictTypeEquals)(function (a) {
    return a;
  });
  return v;
};

// output/Control.Monad.Reader.Trans/index.js
var ReaderT = function (x) {
  return x;
};
var runReaderT = function (v) {
  return v;
};
var mapReaderT = function (f) {
  return function (v) {
    return function ($148) {
      return f(v($148));
    };
  };
};
var functorReaderT = function (dictFunctor) {
  return {
    map: (function () {
      var $149 = map(dictFunctor);
      return function ($150) {
        return mapReaderT($149($150));
      };
    })(),
  };
};
var applyReaderT = function (dictApply) {
  var apply3 = apply(dictApply);
  var functorReaderT1 = functorReaderT(dictApply.Functor0());
  return {
    apply: function (v) {
      return function (v1) {
        return function (r) {
          return apply3(v(r))(v1(r));
        };
      };
    },
    Functor0: function () {
      return functorReaderT1;
    },
  };
};
var applicativeReaderT = function (dictApplicative) {
  var applyReaderT1 = applyReaderT(dictApplicative.Apply0());
  return {
    pure: (function () {
      var $154 = pure(dictApplicative);
      return function ($155) {
        return ReaderT($$const($154($155)));
      };
    })(),
    Apply0: function () {
      return applyReaderT1;
    },
  };
};

// output/Control.Monad.Writer.Trans/index.js
var WriterT = function (x) {
  return x;
};
var runWriterT = function (v) {
  return v;
};
var mapWriterT = function (f) {
  return function (v) {
    return f(v);
  };
};
var functorWriterT = function (dictFunctor) {
  var map36 = map(dictFunctor);
  return {
    map: function (f) {
      return mapWriterT(
        map36(function (v) {
          return new Tuple(f(v.value0), v.value1);
        })
      );
    },
  };
};

// output/Data.Profunctor/index.js
var wrap2 = /* @__PURE__ */ wrap();
var unwrap2 = /* @__PURE__ */ unwrap();
var dimap = function (dict) {
  return dict.dimap;
};
var wrapIso = function (dictProfunctor) {
  var dimap1 = dimap(dictProfunctor);
  return function () {
    return function () {
      return dimap1(unwrap2)(wrap2);
    };
  };
};

// output/Data.Profunctor.Star/index.js
var Star = function (x) {
  return x;
};
var profunctorStar = function (dictFunctor) {
  var map36 = map(dictFunctor);
  return {
    dimap: function (f) {
      return function (g) {
        return function (v) {
          var $127 = map36(g);
          return function ($128) {
            return $127(v(f($128)));
          };
        };
      };
    },
  };
};

// output/Control.Parallel.Class/index.js
var sequential = function (dict) {
  return dict.sequential;
};
var parallel = function (dict) {
  return dict.parallel;
};

// output/Control.Parallel/index.js
var identity7 = /* @__PURE__ */ identity(categoryFn);
var parTraverse_ = function (dictParallel) {
  var sequential4 = sequential(dictParallel);
  var traverse_2 = traverse_(dictParallel.Applicative1());
  var parallel4 = parallel(dictParallel);
  return function (dictFoldable) {
    var traverse_1 = traverse_2(dictFoldable);
    return function (f) {
      var $48 = traverse_1(function ($50) {
        return parallel4(f($50));
      });
      return function ($49) {
        return sequential4($48($49));
      };
    };
  };
};
var parSequence_ = function (dictParallel) {
  var parTraverse_1 = parTraverse_(dictParallel);
  return function (dictFoldable) {
    return parTraverse_1(dictFoldable)(identity7);
  };
};

// output/Data.Time.Duration/index.js
var over2 = /* @__PURE__ */ over()();
var negate2 = /* @__PURE__ */ negate(ringNumber);
var identity8 = /* @__PURE__ */ identity(categoryFn);
var Seconds = function (x) {
  return x;
};
var Milliseconds = function (x) {
  return x;
};
var toDuration = function (dict) {
  return dict.toDuration;
};
var semigroupSeconds = {
  append: function (v) {
    return function (v1) {
      return v + v1;
    };
  },
};
var semigroupMilliseconds = {
  append: function (v) {
    return function (v1) {
      return v + v1;
    };
  },
};
var ordSeconds = ordNumber;
var monoidSeconds = {
  mempty: 0,
  Semigroup0: function () {
    return semigroupSeconds;
  },
};
var fromDuration = function (dict) {
  return dict.fromDuration;
};
var negateDuration = function (dictDuration) {
  var $57 = toDuration(dictDuration);
  var $58 = over2(Milliseconds)(negate2);
  var $59 = fromDuration(dictDuration);
  return function ($60) {
    return $57($58($59($60)));
  };
};
var durationSeconds = {
  fromDuration: /* @__PURE__ */ over2(Seconds)(function (v) {
    return v * 1e3;
  }),
  toDuration: /* @__PURE__ */ over2(Milliseconds)(function (v) {
    return v / 1e3;
  }),
};
var durationMilliseconds = {
  fromDuration: identity8,
  toDuration: identity8,
};

// output/Effect.Unsafe/foreign.js
var unsafePerformEffect = function (f) {
  return f();
};

// output/Partial.Unsafe/foreign.js
var _unsafePartial = function (f) {
  return f();
};

// output/Partial/foreign.js
var _crashWith = function (msg) {
  throw new Error(msg);
};

// output/Partial/index.js
var crashWith = function () {
  return _crashWith;
};

// output/Partial.Unsafe/index.js
var crashWith2 = /* @__PURE__ */ crashWith();
var unsafePartial = _unsafePartial;
var unsafeCrashWith = function (msg) {
  return unsafePartial(function () {
    return crashWith2(msg);
  });
};

// output/Effect.Aff/index.js
var $runtime_lazy2 = function (name16, moduleName, init2) {
  var state3 = 0;
  var val;
  return function (lineNumber) {
    if (state3 === 2) return val;
    if (state3 === 1)
      throw new ReferenceError(
        name16 + " was needed before it finished initializing (module " + moduleName + ", line " + lineNumber + ")",
        moduleName,
        lineNumber
      );
    state3 = 1;
    val = init2();
    state3 = 2;
    return val;
  };
};
var $$void2 = /* @__PURE__ */ $$void(functorEffect);
var Canceler = function (x) {
  return x;
};
var functorParAff = {
  map: _parAffMap,
};
var functorAff = {
  map: _map,
};
var ffiUtil = /* @__PURE__ */ (function () {
  var unsafeFromRight = function (v) {
    if (v instanceof Right) {
      return v.value0;
    }
    if (v instanceof Left) {
      return unsafeCrashWith("unsafeFromRight: Left");
    }
    throw new Error(
      "Failed pattern match at Effect.Aff (line 407, column 21 - line 409, column 54): " + [v.constructor.name]
    );
  };
  var unsafeFromLeft = function (v) {
    if (v instanceof Left) {
      return v.value0;
    }
    if (v instanceof Right) {
      return unsafeCrashWith("unsafeFromLeft: Right");
    }
    throw new Error(
      "Failed pattern match at Effect.Aff (line 402, column 20 - line 404, column 55): " + [v.constructor.name]
    );
  };
  var isLeft = function (v) {
    if (v instanceof Left) {
      return true;
    }
    if (v instanceof Right) {
      return false;
    }
    throw new Error(
      "Failed pattern match at Effect.Aff (line 397, column 12 - line 399, column 21): " + [v.constructor.name]
    );
  };
  return {
    isLeft,
    fromLeft: unsafeFromLeft,
    fromRight: unsafeFromRight,
    left: Left.create,
    right: Right.create,
  };
})();
var makeFiber = function (aff) {
  return _makeFiber(ffiUtil, aff);
};
var launchAff = function (aff) {
  return function __do2() {
    var fiber = makeFiber(aff)();
    fiber.run();
    return fiber;
  };
};
var launchAff_ = function ($73) {
  return $$void2(launchAff($73));
};
var delay = function (v) {
  return _delay(Right.create, v);
};
var applyParAff = {
  apply: _parAffApply,
  Functor0: function () {
    return functorParAff;
  },
};
var lift22 = /* @__PURE__ */ lift2(applyParAff);
var semigroupParAff = function (dictSemigroup) {
  return {
    append: lift22(append(dictSemigroup)),
  };
};
var monadAff = {
  Applicative0: function () {
    return applicativeAff;
  },
  Bind1: function () {
    return bindAff;
  },
};
var bindAff = {
  bind: _bind,
  Apply0: function () {
    return $lazy_applyAff(0);
  },
};
var applicativeAff = {
  pure: _pure,
  Apply0: function () {
    return $lazy_applyAff(0);
  },
};
var $lazy_applyAff = /* @__PURE__ */ $runtime_lazy2("applyAff", "Effect.Aff", function () {
  return {
    apply: ap(monadAff),
    Functor0: function () {
      return functorAff;
    },
  };
});
var pure2 = /* @__PURE__ */ pure(applicativeAff);
var bindFlipped2 = /* @__PURE__ */ bindFlipped(bindAff);
var monadEffectAff = {
  liftEffect: _liftEffect,
  Monad0: function () {
    return monadAff;
  },
};
var liftEffect2 = /* @__PURE__ */ liftEffect(monadEffectAff);
var effectCanceler = function ($74) {
  return Canceler($$const(liftEffect2($74)));
};
var monadThrowAff = {
  throwError: _throwError,
  Monad0: function () {
    return monadAff;
  },
};
var monadErrorAff = {
  catchError: _catchError,
  MonadThrow0: function () {
    return monadThrowAff;
  },
};
var $$try2 = /* @__PURE__ */ $$try(monadErrorAff);
var runAff = function (k) {
  return function (aff) {
    return launchAff(
      bindFlipped2(function ($77) {
        return liftEffect2(k($77));
      })($$try2(aff))
    );
  };
};
var runAff_ = function (k) {
  return function (aff) {
    return $$void2(runAff(k)(aff));
  };
};
var parallelAff = {
  parallel: unsafeCoerce2,
  sequential: _sequential,
  Monad0: function () {
    return monadAff;
  },
  Applicative1: function () {
    return $lazy_applicativeParAff(0);
  },
};
var $lazy_applicativeParAff = /* @__PURE__ */ $runtime_lazy2("applicativeParAff", "Effect.Aff", function () {
  return {
    pure: (function () {
      var $79 = parallel(parallelAff);
      return function ($80) {
        return $79(pure2($80));
      };
    })(),
    Apply0: function () {
      return applyParAff;
    },
  };
});
var applicativeParAff = /* @__PURE__ */ $lazy_applicativeParAff(131);
var pure3 = /* @__PURE__ */ pure(applicativeParAff);
var parSequence_2 = /* @__PURE__ */ parSequence_(parallelAff)(foldableArray);
var monoidParAff = function (dictMonoid) {
  var semigroupParAff1 = semigroupParAff(dictMonoid.Semigroup0());
  return {
    mempty: pure3(mempty(dictMonoid)),
    Semigroup0: function () {
      return semigroupParAff1;
    },
  };
};
var semigroupCanceler = {
  append: function (v) {
    return function (v1) {
      return function (err) {
        return parSequence_2([v(err), v1(err)]);
      };
    };
  },
};
var nonCanceler = /* @__PURE__ */ $$const(/* @__PURE__ */ pure2(unit));
var monoidCanceler = {
  mempty: nonCanceler,
  Semigroup0: function () {
    return semigroupCanceler;
  },
};

// output/Effect.Console/foreign.js
var log = function (s) {
  return function () {
    console.log(s);
  };
};

// output/Common.Common/index.js
var fromJust4 = /* @__PURE__ */ fromJust();
var getJust = function (a) {
  return fromJust4(a);
};
var contains = function (dictEq) {
  var eq6 = eq(dictEq);
  return function (xs) {
    return function (x) {
      return any(function (v) {
        return eq6(v)(x);
      })(xs);
    };
  };
};

// output/Common.Error/foreign.js
var BlockfrostRequestError = class extends Error {
  constructor(message2) {
    super(message2);
    this.name = "BlockfrostRequestError";
    Object.setPrototypeOf(this, BlockfrostRequestError.prototype);
  }
};
var MissingWalletError = class extends Error {
  constructor(message2) {
    super(message2);
    this.name = "MissingWalletError";
    Object.setPrototypeOf(this, MissingWalletError.prototype);
  }
};
var CSLError = class extends Error {
  constructor(message2) {
    super(message2);
    this.name = "CSLError";
    Object.setPrototypeOf(this, CSLError.prototype);
  }
};
var _throwBlockfrostRequestError = (message2) => () => {
  throw new BlockfrostRequestError(message2);
};
var _throwMissingWalletError = (message2) => () => {
  throw new MissingWalletError(message2);
};
var _throwCslError = (message2) => () => {
  throw new CSLError(message2);
};

// output/Common.Error/index.js
var liftEffect3 = /* @__PURE__ */ liftEffect(monadEffectAff);
var pure4 = /* @__PURE__ */ pure(applicativeAff);
var throwMissingWalletError = function ($3) {
  return liftEffect3(_throwMissingWalletError($3));
};
var throwCslError = function ($4) {
  return liftEffect3(_throwCslError($4));
};
var throwBlockfrostRequestError = function ($5) {
  return liftEffect3(_throwBlockfrostRequestError($5));
};
var fromMaybeAff = function (err) {
  return maybe(err)(pure4);
};

// output/Csl/foreign.js
function errorableToPurs(f, ...vars) {
  return (left) => (right) => {
    try {
      return right(f(...vars));
    } catch (err) {
      if (typeof err === "string") return left(err);
      else return left(err.message);
    }
  };
}
var address_free = (self) => () => self.free();
var address_fromBytes = (data) => errorableToPurs(CSL.Address.from_bytes, data);
var address_toJson = (self) => self.to_json();
var address_toJsValue = (self) => self.to_js_value();
var address_fromJson = (json3) => errorableToPurs(CSL.Address.from_json, json3);
var address_toHex = (self) => self.to_hex();
var address_fromHex = (hex_str) => errorableToPurs(CSL.Address.from_hex, hex_str);
var address_toBytes = (self) => self.to_bytes();
var address_toBech32 = (self) => (prefix) => self.to_bech32(prefix);
var address_fromBech32 = (bech_str) => errorableToPurs(CSL.Address.from_bech32, bech_str);
var address_networkId = (self) => self.network_id();
var auxiliaryData_free = (self) => () => self.free();
var auxiliaryData_toBytes = (self) => self.to_bytes();
var auxiliaryData_fromBytes = (bytes) => errorableToPurs(CSL.AuxiliaryData.from_bytes, bytes);
var auxiliaryData_toHex = (self) => self.to_hex();
var auxiliaryData_fromHex = (hex_str) => errorableToPurs(CSL.AuxiliaryData.from_hex, hex_str);
var auxiliaryData_toJson = (self) => self.to_json();
var auxiliaryData_toJsValue = (self) => self.to_js_value();
var auxiliaryData_fromJson = (json3) => errorableToPurs(CSL.AuxiliaryData.from_json, json3);
var auxiliaryData_new = () => CSL.AuxiliaryData.new();
var auxiliaryData_metadata = (self) => self.metadata();
var auxiliaryData_setMetadata = (self) => (metadata) => () => self.set_metadata(metadata);
var auxiliaryData_nativeScripts = (self) => () => self.native_scripts();
var auxiliaryData_setNativeScripts = (self) => (native_scripts) => () => self.set_native_scripts(native_scripts);
var auxiliaryData_plutusScripts = (self) => () => self.plutus_scripts();
var auxiliaryData_setPlutusScripts = (self) => (plutus_scripts) => () => self.set_plutus_scripts(plutus_scripts);
var baseAddress_free = (self) => () => self.free();
var baseAddress_new = (network) => (payment) => (stake) => CSL.BaseAddress.new(network, payment, stake);
var baseAddress_paymentCred = (self) => self.payment_cred();
var baseAddress_stakeCred = (self) => self.stake_cred();
var baseAddress_toAddress = (self) => self.to_address();
var baseAddress_fromAddress = (addr) => CSL.BaseAddress.from_address(addr);
var bigNum_free = (self) => () => self.free();
var bigNum_toBytes = (self) => self.to_bytes();
var bigNum_fromBytes = (bytes) => errorableToPurs(CSL.BigNum.from_bytes, bytes);
var bigNum_toHex = (self) => self.to_hex();
var bigNum_fromHex = (hex_str) => errorableToPurs(CSL.BigNum.from_hex, hex_str);
var bigNum_toJson = (self) => self.to_json();
var bigNum_toJsValue = (self) => self.to_js_value();
var bigNum_fromJson = (json3) => errorableToPurs(CSL.BigNum.from_json, json3);
var bigNum_fromStr = (string2) => errorableToPurs(CSL.BigNum.from_str, string2);
var bigNum_toStr = (self) => self.to_str();
var bigNum_zero = CSL.BigNum.zero();
var bigNum_one = CSL.BigNum.one();
var bigNum_isZero = (self) => self.is_zero();
var bigNum_divFloor = (self) => (other) => self.div_floor(other);
var bigNum_checkedMul = (self) => (other) => self.checked_mul(other);
var bigNum_checkedAdd = (self) => (other) => self.checked_add(other);
var bigNum_checkedSub = (self) => (other) => self.checked_sub(other);
var bigNum_clampedSub = (self) => (other) => self.clamped_sub(other);
var bigNum_compare = (self) => (rhs_value) => self.compare(rhs_value);
var bigNum_lessThan = (self) => (rhs_value) => self.less_than(rhs_value);
var bigNum_max = (a) => (b) => CSL.BigNum.max(a, b);
var linearFee_free = (self) => () => self.free();
var linearFee_constant = (self) => self.constant();
var linearFee_coefficient = (self) => self.coefficient();
var linearFee_new = (coefficient) => (constant) => CSL.LinearFee.new(coefficient, constant);
var rewardAddress_free = (self) => () => self.free();
var rewardAddress_new = (network) => (payment) => CSL.RewardAddress.new(network, payment);
var rewardAddress_paymentCred = (self) => self.payment_cred();
var rewardAddress_toAddress = (self) => self.to_address();
var rewardAddress_fromAddress = (addr) => CSL.RewardAddress.from_address(addr);
var stakeCredential_free = (self) => () => self.free();
var stakeCredential_fromKeyhash = (hash2) => CSL.StakeCredential.from_keyhash(hash2);
var stakeCredential_fromScripthash = (hash2) => CSL.StakeCredential.from_scripthash(hash2);
var stakeCredential_toKeyhash = (self) => self.to_keyhash();
var stakeCredential_toScripthash = (self) => self.to_scripthash();
var stakeCredential_kind = (self) => self.kind();
var stakeCredential_toBytes = (self) => self.to_bytes();
var stakeCredential_fromBytes = (bytes) => errorableToPurs(CSL.StakeCredential.from_bytes, bytes);
var stakeCredential_toHex = (self) => self.to_hex();
var stakeCredential_fromHex = (hex_str) => errorableToPurs(CSL.StakeCredential.from_hex, hex_str);
var stakeCredential_toJson = (self) => self.to_json();
var stakeCredential_toJsValue = (self) => self.to_js_value();
var stakeCredential_fromJson = (json3) => errorableToPurs(CSL.StakeCredential.from_json, json3);
var tx_free = (self) => () => self.free();
var tx_toBytes = (self) => self.to_bytes();
var tx_fromBytes = (bytes) => errorableToPurs(CSL.Transaction.from_bytes, bytes);
var tx_toHex = (self) => self.to_hex();
var tx_fromHex = (hex_str) => errorableToPurs(CSL.Transaction.from_hex, hex_str);
var tx_toJson = (self) => self.to_json();
var tx_toJsValue = (self) => self.to_js_value();
var tx_fromJson = (json3) => errorableToPurs(CSL.Transaction.from_json, json3);
var tx_body = (self) => self.body();
var tx_witnessSet = (self) => self.witness_set();
var tx_isValid = (self) => self.is_valid();
var tx_auxiliaryData = (self) => self.auxiliary_data();
var tx_setIsValid = (self) => (valid) => () => self.set_is_valid(valid);
var tx_new = (body) => (witness_set) => (auxiliary_data) => CSL.Transaction.new(body, witness_set, auxiliary_data);
var txBuilder_free = (self) => () => self.free();
var txBuilder_addInsFrom = (self) => (inputs) => (strategy) => () => self.add_inputs_from(inputs, strategy);
var txBuilder_setIns = (self) => (inputs) => () => self.set_inputs(inputs);
var txBuilder_setCollateral = (self) => (collateral) => () => self.set_collateral(collateral);
var txBuilder_setCollateralReturn = (self) => (collateral_return) => () =>
  self.set_collateral_return(collateral_return);
var txBuilder_setCollateralReturnAndTotal = (self) => (collateral_return) => () =>
  self.set_collateral_return_and_total(collateral_return);
var txBuilder_setTotalCollateral = (self) => (total_collateral) => () => self.set_total_collateral(total_collateral);
var txBuilder_setTotalCollateralAndReturn = (self) => (total_collateral) => (return_address) => () =>
  self.set_total_collateral_and_return(total_collateral, return_address);
var txBuilder_addReferenceIn = (self) => (reference_input) => () => self.add_reference_input(reference_input);
var txBuilder_addKeyIn = (self) => (hash2) => (input) => (amount) => () => self.add_key_input(hash2, input, amount);
var txBuilder_addScriptIn = (self) => (hash2) => (input) => (amount) => () =>
  self.add_script_input(hash2, input, amount);
var txBuilder_addNativeScriptIn = (self) => (script) => (input) => (amount) => () =>
  self.add_native_script_input(script, input, amount);
var txBuilder_addPlutusScriptIn = (self) => (witness) => (input) => (amount) => () =>
  self.add_plutus_script_input(witness, input, amount);
var txBuilder_addBootstrapIn = (self) => (hash2) => (input) => (amount) => () =>
  self.add_bootstrap_input(hash2, input, amount);
var txBuilder_addIn = (self) => (address2) => (input) => (amount) => () => self.add_input(address2, input, amount);
var txBuilder_countMissingInScripts = (self) => () => self.count_missing_input_scripts();
var txBuilder_addRequiredNativeInScripts = (self) => (scripts) => () => self.add_required_native_input_scripts(scripts);
var txBuilder_addRequiredPlutusInScripts = (self) => (scripts) => () => self.add_required_plutus_input_scripts(scripts);
var txBuilder_getNativeInScripts = (self) => () => self.get_native_input_scripts();
var txBuilder_getPlutusInScripts = (self) => () => self.get_plutus_input_scripts();
var txBuilder_feeForIn = (self) => (address2) => (input) => (amount) => () =>
  self.fee_for_input(address2, input, amount);
var txBuilder_addOut = (self) => (output) => () => self.add_output(output);
var txBuilder_feeForOut = (self) => (output) => () => self.fee_for_output(output);
var txBuilder_setFee = (self) => (fee) => () => self.set_fee(fee);
var txBuilder_setTtl = (self) => (ttl) => () => self.set_ttl(ttl);
var txBuilder_setTtlBignum = (self) => (ttl) => () => self.set_ttl_bignum(ttl);
var txBuilder_setValidityStartInterval = (self) => (validity_start_interval) => () =>
  self.set_validity_start_interval(validity_start_interval);
var txBuilder_setValidityStartIntervalBignum = (self) => (validity_start_interval) => () =>
  self.set_validity_start_interval_bignum(validity_start_interval);
var txBuilder_setCerts = (self) => (certs) => () => self.set_certs(certs);
var txBuilder_setWithdrawals = (self) => (withdrawals) => () => self.set_withdrawals(withdrawals);
var txBuilder_getAuxiliaryData = (self) => () => self.get_auxiliary_data();
var txBuilder_setAuxiliaryData = (self) => (auxiliary_data) => () => self.set_auxiliary_data(auxiliary_data);
var txBuilder_setMetadata = (self) => (metadata) => () => self.set_metadata(metadata);
var txBuilder_addMetadatum = (self) => (key) => (val) => () => self.add_metadatum(key, val);
var txBuilder_addJsonMetadatum = (self) => (key) => (val) => () => self.add_json_metadatum(key, val);
var txBuilder_addJsonMetadatumWithSchema = (self) => (key) => (val) => (schema) => () =>
  self.add_json_metadatum_with_schema(key, val, schema);
var txBuilder_setMint = (self) => (mint) => (mint_scripts) => () => self.set_mint(mint, mint_scripts);
var txBuilder_getMint = (self) => () => self.get_mint();
var txBuilder_getMintScripts = (self) => () => self.get_mint_scripts();
var txBuilder_setMintAsset = (self) => (policy_script) => (mint_assets) => () =>
  self.set_mint_asset(policy_script, mint_assets);
var txBuilder_addMintAsset = (self) => (policy_script) => (asset_name) => (amount) => () =>
  self.add_mint_asset(policy_script, asset_name, amount);
var txBuilder_addMintAssetAndOut =
  (self) => (policy_script) => (asset_name) => (amount) => (output_builder) => (output_coin) => () =>
    self.add_mint_asset_and_output(policy_script, asset_name, amount, output_builder, output_coin);
var txBuilder_addMintAssetAndOutMinRequiredCoin =
  (self) => (policy_script) => (asset_name) => (amount) => (output_builder) => () =>
    self.add_mint_asset_and_output_min_required_coin(policy_script, asset_name, amount, output_builder);
var txBuilder_new = (cfg) => () => CSL.TransactionBuilder.new(cfg);
var txBuilder_getReferenceIns = (self) => () => self.get_reference_inputs();
var txBuilder_getExplicitIn = (self) => () => self.get_explicit_input();
var txBuilder_getImplicitIn = (self) => () => self.get_implicit_input();
var txBuilder_getTotalIn = (self) => () => self.get_total_input();
var txBuilder_getTotalOut = (self) => () => self.get_total_output();
var txBuilder_getExplicitOut = (self) => () => self.get_explicit_output();
var txBuilder_getDeposit = (self) => () => self.get_deposit();
var txBuilder_getFeeIfSet = (self) => () => self.get_fee_if_set();
var txBuilder_addChangeIfNeeded = (self) => (address2) => () => self.add_change_if_needed(address2);
var txBuilder_calcScriptDataHash = (self) => (cost_models) => () => self.calc_script_data_hash(cost_models);
var txBuilder_setScriptDataHash = (self) => (hash2) => () => self.set_script_data_hash(hash2);
var txBuilder_removeScriptDataHash = (self) => () => self.remove_script_data_hash();
var txBuilder_addRequiredSigner = (self) => (key) => () => self.add_required_signer(key);
var txBuilder_fullSize = (self) => () => self.full_size();
var txBuilder_outSizes = (self) => () => self.output_sizes();
var txBuilder_build = (self) => () => self.build();
var txBuilder_buildTx = (self) => () => self.build_tx();
var txBuilder_buildTxUnsafe = (self) => () => self.build_tx_unsafe();
var txBuilder_minFee = (self) => () => self.min_fee();
var txBuilderConfigBuilder_free = (self) => () => self.free();
var txBuilderConfigBuilder_new = CSL.TransactionBuilderConfigBuilder.new();
var txBuilderConfigBuilder_feeAlgo = (self) => (fee_algo) => self.fee_algo(fee_algo);
var txBuilderConfigBuilder_coinsPerUtxoWord = (self) => (coins_per_utxo_word) =>
  self.coins_per_utxo_word(coins_per_utxo_word);
var txBuilderConfigBuilder_coinsPerUtxoByte = (self) => (coins_per_utxo_byte) =>
  self.coins_per_utxo_byte(coins_per_utxo_byte);
var txBuilderConfigBuilder_exUnitPrices = (self) => (ex_unit_prices) => self.ex_unit_prices(ex_unit_prices);
var txBuilderConfigBuilder_poolDeposit = (self) => (pool_deposit) => self.pool_deposit(pool_deposit);
var txBuilderConfigBuilder_keyDeposit = (self) => (key_deposit) => self.key_deposit(key_deposit);
var txBuilderConfigBuilder_maxValueSize = (self) => (max_value_size) => self.max_value_size(max_value_size);
var txBuilderConfigBuilder_maxTxSize = (self) => (max_tx_size) => self.max_tx_size(max_tx_size);
var txBuilderConfigBuilder_preferPureChange = (self) => (prefer_pure_change) =>
  self.prefer_pure_change(prefer_pure_change);
var txBuilderConfigBuilder_build = (self) => self.build();
var txHash_free = (self) => () => self.free();
var txHash_fromBytes = (bytes) => errorableToPurs(CSL.TransactionHash.from_bytes, bytes);
var txHash_toBytes = (self) => self.to_bytes();
var txHash_toBech32 = (self) => (prefix) => self.to_bech32(prefix);
var txHash_fromBech32 = (bech_str) => errorableToPurs(CSL.TransactionHash.from_bech32, bech_str);
var txHash_toHex = (self) => self.to_hex();
var txHash_fromHex = (hex) => errorableToPurs(CSL.TransactionHash.from_hex, hex);
var txIn_free = (self) => () => self.free();
var txIn_toBytes = (self) => self.to_bytes();
var txIn_fromBytes = (bytes) => errorableToPurs(CSL.TransactionInput.from_bytes, bytes);
var txIn_toHex = (self) => self.to_hex();
var txIn_fromHex = (hex_str) => errorableToPurs(CSL.TransactionInput.from_hex, hex_str);
var txIn_toJson = (self) => self.to_json();
var txIn_toJsValue = (self) => self.to_js_value();
var txIn_fromJson = (json3) => errorableToPurs(CSL.TransactionInput.from_json, json3);
var txIn_txId = (self) => self.transaction_id();
var txIn_index = (self) => self.index();
var txIn_new = (transaction_id) => (index5) => CSL.TransactionInput.new(transaction_id, index5);
var txMetadatum_free = (self) => () => self.free();
var txMetadatum_toBytes = (self) => self.to_bytes();
var txMetadatum_fromBytes = (bytes) => errorableToPurs(CSL.TransactionMetadatum.from_bytes, bytes);
var txMetadatum_toHex = (self) => self.to_hex();
var txMetadatum_fromHex = (hex_str) => errorableToPurs(CSL.TransactionMetadatum.from_hex, hex_str);
var txMetadatum_newMap = (map36) => CSL.TransactionMetadatum.new_map(map36);
var txMetadatum_newList = (list2) => CSL.TransactionMetadatum.new_list(list2);
var txMetadatum_newInt = (int) => CSL.TransactionMetadatum.new_int(int);
var txMetadatum_newBytes = (bytes) => CSL.TransactionMetadatum.new_bytes(bytes);
var txMetadatum_newText = (text5) => CSL.TransactionMetadatum.new_text(text5);
var txMetadatum_kind = (self) => self.kind();
var txMetadatum_asMap = (self) => self.as_map();
var txMetadatum_asList = (self) => self.as_list();
var txMetadatum_asInt = (self) => self.as_int();
var txMetadatum_asBytes = (self) => self.as_bytes();
var txMetadatum_asText = (self) => self.as_text();
var txUnspentOut_free = (self) => () => self.free();
var txUnspentOut_toBytes = (self) => self.to_bytes();
var txUnspentOut_fromBytes = (bytes) => errorableToPurs(CSL.TransactionUnspentOutput.from_bytes, bytes);
var txUnspentOut_toHex = (self) => self.to_hex();
var txUnspentOut_fromHex = (hex_str) => errorableToPurs(CSL.TransactionUnspentOutput.from_hex, hex_str);
var txUnspentOut_toJson = (self) => self.to_json();
var txUnspentOut_toJsValue = (self) => self.to_js_value();
var txUnspentOut_fromJson = (json3) => errorableToPurs(CSL.TransactionUnspentOutput.from_json, json3);
var txUnspentOut_new = (input) => (output) => CSL.TransactionUnspentOutput.new(input, output);
var txUnspentOut_in = (self) => self.input();
var txUnspentOut_out = (self) => self.output();
var txWitnessSet_free = (self) => () => self.free();
var txWitnessSet_toBytes = (self) => self.to_bytes();
var txWitnessSet_fromBytes = (bytes) => errorableToPurs(CSL.TransactionWitnessSet.from_bytes, bytes);
var txWitnessSet_toHex = (self) => self.to_hex();
var txWitnessSet_fromHex = (hex_str) => errorableToPurs(CSL.TransactionWitnessSet.from_hex, hex_str);
var txWitnessSet_toJson = (self) => self.to_json();
var txWitnessSet_toJsValue = (self) => self.to_js_value();
var txWitnessSet_fromJson = (json3) => errorableToPurs(CSL.TransactionWitnessSet.from_json, json3);
var txWitnessSet_setVkeys = (self) => (vkeys) => () => self.set_vkeys(vkeys);
var txWitnessSet_vkeys = (self) => () => self.vkeys();
var txWitnessSet_setNativeScripts = (self) => (native_scripts) => () => self.set_native_scripts(native_scripts);
var txWitnessSet_nativeScripts = (self) => () => self.native_scripts();
var txWitnessSet_setBootstraps = (self) => (bootstraps) => () => self.set_bootstraps(bootstraps);
var txWitnessSet_bootstraps = (self) => () => self.bootstraps();
var txWitnessSet_setPlutusScripts = (self) => (plutus_scripts) => () => self.set_plutus_scripts(plutus_scripts);
var txWitnessSet_plutusScripts = (self) => () => self.plutus_scripts();
var txWitnessSet_setPlutusData = (self) => (plutus_data) => () => self.set_plutus_data(plutus_data);
var txWitnessSet_plutusData = (self) => () => self.plutus_data();
var txWitnessSet_setRedeemers = (self) => (redeemers) => () => self.set_redeemers(redeemers);
var txWitnessSet_redeemers = (self) => () => self.redeemers();
var txWitnessSet_new = () => CSL.TransactionWitnessSet.new();
var value_free = (self) => () => self.free();
var value_toBytes = (self) => self.to_bytes();
var value_fromBytes = (bytes) => errorableToPurs(CSL.Value.from_bytes, bytes);
var value_toHex = (self) => self.to_hex();
var value_fromHex = (hex_str) => errorableToPurs(CSL.Value.from_hex, hex_str);
var value_toJson = (self) => self.to_json();
var value_toJsValue = (self) => self.to_js_value();
var value_fromJson = (json3) => errorableToPurs(CSL.Value.from_json, json3);
var value_new = (coin) => CSL.Value.new(coin);
var value_newFromAssets = (multiasset) => CSL.Value.new_from_assets(multiasset);
var value_newWithAssets = (coin) => (multiasset) => CSL.Value.new_with_assets(coin, multiasset);
var value_zero = CSL.Value.zero();
var value_isZero = (self) => self.is_zero();
var value_coin = (self) => self.coin();
var value_setCoin = (self) => (coin) => () => self.set_coin(coin);
var value_multiasset = (self) => self.multiasset();
var value_setMultiasset = (self) => (multiasset) => () => self.set_multiasset(multiasset);
var value_checkedAdd = (self) => (rhs) => self.checked_add(rhs);
var value_checkedSub = (self) => (rhs_value) => self.checked_sub(rhs_value);
var value_clampedSub = (self) => (rhs_value) => self.clamped_sub(rhs_value);
var value_compare = (self) => (rhs_value) => self.compare(rhs_value);
var vkeywitnesses_free = (self) => () => self.free();
var vkeywitnesses_new = () => CSL.Vkeywitnesses.new();
var vkeywitnesses_len = (self) => () => self.len();
var vkeywitnesses_get = (self) => (index5) => () => self.get(index5);
var vkeywitnesses_add = (self) => (elem2) => () => self.add(elem2);

// output/Data.Nullable/foreign.js
var nullImpl = null;
function nullable(a, r, f) {
  return a == null ? r : f(a);
}
function notNull(x) {
  return x;
}

// output/Data.Nullable/index.js
var toNullable = /* @__PURE__ */ maybe(nullImpl)(notNull);
var toMaybe = function (n) {
  return nullable(n, Nothing.value, Just.create);
};

// output/Csl/index.js
var map5 = /* @__PURE__ */ map(functorEffect);
var fromJust5 = /* @__PURE__ */ fromJust();
var vkeywitnesses = {
  free: vkeywitnesses_free,
  new: vkeywitnesses_new,
  len: vkeywitnesses_len,
  get: vkeywitnesses_get,
  add: vkeywitnesses_add,
};
var txBuilderConfigBuilder = {
  free: txBuilderConfigBuilder_free,
  new: txBuilderConfigBuilder_new,
  feeAlgo: txBuilderConfigBuilder_feeAlgo,
  coinsPerUtxoWord: txBuilderConfigBuilder_coinsPerUtxoWord,
  coinsPerUtxoByte: txBuilderConfigBuilder_coinsPerUtxoByte,
  exUnitPrices: txBuilderConfigBuilder_exUnitPrices,
  poolDeposit: txBuilderConfigBuilder_poolDeposit,
  keyDeposit: txBuilderConfigBuilder_keyDeposit,
  maxValueSize: txBuilderConfigBuilder_maxValueSize,
  maxTxSize: txBuilderConfigBuilder_maxTxSize,
  preferPureChange: txBuilderConfigBuilder_preferPureChange,
  build: txBuilderConfigBuilder_build,
};
var txBuilder = {
  free: txBuilder_free,
  addInsFrom: txBuilder_addInsFrom,
  setIns: txBuilder_setIns,
  setCollateral: txBuilder_setCollateral,
  setCollateralReturn: txBuilder_setCollateralReturn,
  setCollateralReturnAndTotal: txBuilder_setCollateralReturnAndTotal,
  setTotalCollateral: txBuilder_setTotalCollateral,
  setTotalCollateralAndReturn: txBuilder_setTotalCollateralAndReturn,
  addReferenceIn: txBuilder_addReferenceIn,
  addKeyIn: txBuilder_addKeyIn,
  addScriptIn: txBuilder_addScriptIn,
  addNativeScriptIn: txBuilder_addNativeScriptIn,
  addPlutusScriptIn: txBuilder_addPlutusScriptIn,
  addBootstrapIn: txBuilder_addBootstrapIn,
  addIn: txBuilder_addIn,
  countMissingInScripts: txBuilder_countMissingInScripts,
  addRequiredNativeInScripts: txBuilder_addRequiredNativeInScripts,
  addRequiredPlutusInScripts: txBuilder_addRequiredPlutusInScripts,
  getNativeInScripts: function (a1) {
    return map5(toMaybe)(txBuilder_getNativeInScripts(a1));
  },
  getPlutusInScripts: function (a1) {
    return map5(toMaybe)(txBuilder_getPlutusInScripts(a1));
  },
  feeForIn: txBuilder_feeForIn,
  addOut: txBuilder_addOut,
  feeForOut: txBuilder_feeForOut,
  setFee: txBuilder_setFee,
  setTtl: txBuilder_setTtl,
  setTtlBignum: txBuilder_setTtlBignum,
  setValidityStartInterval: txBuilder_setValidityStartInterval,
  setValidityStartIntervalBignum: txBuilder_setValidityStartIntervalBignum,
  setCerts: txBuilder_setCerts,
  setWithdrawals: txBuilder_setWithdrawals,
  getAuxiliaryData: function (a1) {
    return map5(toMaybe)(txBuilder_getAuxiliaryData(a1));
  },
  setAuxiliaryData: txBuilder_setAuxiliaryData,
  setMetadata: txBuilder_setMetadata,
  addMetadatum: txBuilder_addMetadatum,
  addJsonMetadatum: txBuilder_addJsonMetadatum,
  addJsonMetadatumWithSchema: txBuilder_addJsonMetadatumWithSchema,
  setMint: txBuilder_setMint,
  getMint: function (a1) {
    return map5(toMaybe)(txBuilder_getMint(a1));
  },
  getMintScripts: function (a1) {
    return map5(toMaybe)(txBuilder_getMintScripts(a1));
  },
  setMintAsset: txBuilder_setMintAsset,
  addMintAsset: txBuilder_addMintAsset,
  addMintAssetAndOut: txBuilder_addMintAssetAndOut,
  addMintAssetAndOutMinRequiredCoin: txBuilder_addMintAssetAndOutMinRequiredCoin,
  new: txBuilder_new,
  getReferenceIns: txBuilder_getReferenceIns,
  getExplicitIn: txBuilder_getExplicitIn,
  getImplicitIn: txBuilder_getImplicitIn,
  getTotalIn: txBuilder_getTotalIn,
  getTotalOut: txBuilder_getTotalOut,
  getExplicitOut: txBuilder_getExplicitOut,
  getDeposit: txBuilder_getDeposit,
  getFeeIfSet: function (a1) {
    return map5(toMaybe)(txBuilder_getFeeIfSet(a1));
  },
  addChangeIfNeeded: txBuilder_addChangeIfNeeded,
  calcScriptDataHash: txBuilder_calcScriptDataHash,
  setScriptDataHash: txBuilder_setScriptDataHash,
  removeScriptDataHash: txBuilder_removeScriptDataHash,
  addRequiredSigner: txBuilder_addRequiredSigner,
  fullSize: txBuilder_fullSize,
  outSizes: txBuilder_outSizes,
  build: txBuilder_build,
  buildTx: txBuilder_buildTx,
  buildTxUnsafe: txBuilder_buildTxUnsafe,
  minFee: txBuilder_minFee,
};
var toHex = function (dict) {
  return dict.toHex;
};
var runForeignErrorable = function (f) {
  return f(Left.create)(Right.create);
};
var runForeignMaybe = function ($753) {
  return hush(runForeignErrorable($753));
};
var stakeCredential = {
  free: stakeCredential_free,
  fromKeyhash: stakeCredential_fromKeyhash,
  fromScripthash: stakeCredential_fromScripthash,
  toKeyhash: function (a1) {
    return toMaybe(stakeCredential_toKeyhash(a1));
  },
  toScripthash: function (a1) {
    return toMaybe(stakeCredential_toScripthash(a1));
  },
  kind: stakeCredential_kind,
  toBytes: stakeCredential_toBytes,
  fromBytes: function (a1) {
    return runForeignMaybe(stakeCredential_fromBytes(a1));
  },
  toHex: stakeCredential_toHex,
  fromHex: function (a1) {
    return runForeignMaybe(stakeCredential_fromHex(a1));
  },
  toJson: stakeCredential_toJson,
  toJsValue: stakeCredential_toJsValue,
  fromJson: function (a1) {
    return runForeignMaybe(stakeCredential_fromJson(a1));
  },
};
var tx = {
  free: tx_free,
  toBytes: tx_toBytes,
  fromBytes: function (a1) {
    return runForeignMaybe(tx_fromBytes(a1));
  },
  toHex: tx_toHex,
  fromHex: function (a1) {
    return runForeignMaybe(tx_fromHex(a1));
  },
  toJson: tx_toJson,
  toJsValue: tx_toJsValue,
  fromJson: function (a1) {
    return runForeignMaybe(tx_fromJson(a1));
  },
  body: tx_body,
  witnessSet: tx_witnessSet,
  isValid: tx_isValid,
  auxiliaryData: function (a1) {
    return toMaybe(tx_auxiliaryData(a1));
  },
  setIsValid: tx_setIsValid,
  new: tx_new,
};
var isHexTx = /* @__PURE__ */ (function () {
  return {
    toHex: tx.toHex,
    fromHex: tx.fromHex,
  };
})();
var txHash = {
  free: txHash_free,
  fromBytes: function (a1) {
    return runForeignMaybe(txHash_fromBytes(a1));
  },
  toBytes: txHash_toBytes,
  toBech32: txHash_toBech32,
  fromBech32: function (a1) {
    return runForeignMaybe(txHash_fromBech32(a1));
  },
  toHex: txHash_toHex,
  fromHex: function (a1) {
    return runForeignMaybe(txHash_fromHex(a1));
  },
};
var isHexTxHash = /* @__PURE__ */ (function () {
  return {
    toHex: txHash.toHex,
    fromHex: txHash.fromHex,
  };
})();
var txIn = {
  free: txIn_free,
  toBytes: txIn_toBytes,
  fromBytes: function (a1) {
    return runForeignMaybe(txIn_fromBytes(a1));
  },
  toHex: txIn_toHex,
  fromHex: function (a1) {
    return runForeignMaybe(txIn_fromHex(a1));
  },
  toJson: txIn_toJson,
  toJsValue: txIn_toJsValue,
  fromJson: function (a1) {
    return runForeignMaybe(txIn_fromJson(a1));
  },
  txId: txIn_txId,
  index: txIn_index,
  new: txIn_new,
};
var txMetadatum = {
  free: txMetadatum_free,
  toBytes: txMetadatum_toBytes,
  fromBytes: function (a1) {
    return runForeignMaybe(txMetadatum_fromBytes(a1));
  },
  toHex: txMetadatum_toHex,
  fromHex: function (a1) {
    return runForeignMaybe(txMetadatum_fromHex(a1));
  },
  newMap: txMetadatum_newMap,
  newList: txMetadatum_newList,
  newInt: txMetadatum_newInt,
  newBytes: txMetadatum_newBytes,
  newText: txMetadatum_newText,
  kind: txMetadatum_kind,
  asMap: txMetadatum_asMap,
  asList: txMetadatum_asList,
  asInt: txMetadatum_asInt,
  asBytes: txMetadatum_asBytes,
  asText: txMetadatum_asText,
};
var txUnspentOut = {
  free: txUnspentOut_free,
  toBytes: txUnspentOut_toBytes,
  fromBytes: function (a1) {
    return runForeignMaybe(txUnspentOut_fromBytes(a1));
  },
  toHex: txUnspentOut_toHex,
  fromHex: function (a1) {
    return runForeignMaybe(txUnspentOut_fromHex(a1));
  },
  toJson: txUnspentOut_toJson,
  toJsValue: txUnspentOut_toJsValue,
  fromJson: function (a1) {
    return runForeignMaybe(txUnspentOut_fromJson(a1));
  },
  new: txUnspentOut_new,
  in: txUnspentOut_in,
  out: txUnspentOut_out,
};
var isHexTxUnspentOut = /* @__PURE__ */ (function () {
  return {
    toHex: txUnspentOut.toHex,
    fromHex: txUnspentOut.fromHex,
  };
})();
var txWitnessSet = {
  free: txWitnessSet_free,
  toBytes: txWitnessSet_toBytes,
  fromBytes: function (a1) {
    return runForeignMaybe(txWitnessSet_fromBytes(a1));
  },
  toHex: txWitnessSet_toHex,
  fromHex: function (a1) {
    return runForeignMaybe(txWitnessSet_fromHex(a1));
  },
  toJson: txWitnessSet_toJson,
  toJsValue: txWitnessSet_toJsValue,
  fromJson: function (a1) {
    return runForeignMaybe(txWitnessSet_fromJson(a1));
  },
  setVkeys: txWitnessSet_setVkeys,
  vkeys: function (a1) {
    return map5(toMaybe)(txWitnessSet_vkeys(a1));
  },
  setNativeScripts: txWitnessSet_setNativeScripts,
  nativeScripts: function (a1) {
    return map5(toMaybe)(txWitnessSet_nativeScripts(a1));
  },
  setBootstraps: txWitnessSet_setBootstraps,
  bootstraps: function (a1) {
    return map5(toMaybe)(txWitnessSet_bootstraps(a1));
  },
  setPlutusScripts: txWitnessSet_setPlutusScripts,
  plutusScripts: function (a1) {
    return map5(toMaybe)(txWitnessSet_plutusScripts(a1));
  },
  setPlutusData: txWitnessSet_setPlutusData,
  plutusData: function (a1) {
    return map5(toMaybe)(txWitnessSet_plutusData(a1));
  },
  setRedeemers: txWitnessSet_setRedeemers,
  redeemers: function (a1) {
    return map5(toMaybe)(txWitnessSet_redeemers(a1));
  },
  new: txWitnessSet_new,
};
var isHexTxWitnessSet = /* @__PURE__ */ (function () {
  return {
    toHex: txWitnessSet.toHex,
    fromHex: txWitnessSet.fromHex,
  };
})();
var value = {
  free: value_free,
  toBytes: value_toBytes,
  fromBytes: function (a1) {
    return runForeignMaybe(value_fromBytes(a1));
  },
  toHex: value_toHex,
  fromHex: function (a1) {
    return runForeignMaybe(value_fromHex(a1));
  },
  toJson: value_toJson,
  toJsValue: value_toJsValue,
  fromJson: function (a1) {
    return runForeignMaybe(value_fromJson(a1));
  },
  new: value_new,
  newFromAssets: value_newFromAssets,
  newWithAssets: value_newWithAssets,
  zero: value_zero,
  isZero: value_isZero,
  coin: value_coin,
  setCoin: value_setCoin,
  multiasset: function (a1) {
    return toMaybe(value_multiasset(a1));
  },
  setMultiasset: value_setMultiasset,
  checkedAdd: value_checkedAdd,
  checkedSub: value_checkedSub,
  clampedSub: value_clampedSub,
  compare: function (a1) {
    return function (a2) {
      return toMaybe(value_compare(a1)(a2));
    };
  },
};
var isHexValue = /* @__PURE__ */ (function () {
  return {
    toHex: value.toHex,
    fromHex: value.fromHex,
  };
})();
var rewardAddress = {
  free: rewardAddress_free,
  new: rewardAddress_new,
  paymentCred: rewardAddress_paymentCred,
  toAddress: rewardAddress_toAddress,
  fromAddress: function (a1) {
    return toMaybe(rewardAddress_fromAddress(a1));
  },
};
var linearFee = {
  free: linearFee_free,
  constant: linearFee_constant,
  coefficient: linearFee_coefficient,
  new: linearFee_new,
};
var getJust2 = function (a) {
  return fromJust5(a);
};
var fromStr = function (dict) {
  return dict.fromStr;
};
var fromStr$prime = function (dictIsStr) {
  var $754 = fromStr(dictIsStr);
  return function ($755) {
    return getJust2($754($755));
  };
};
var fromHex = function (dict) {
  return dict.fromHex;
};
var bigNum = {
  free: bigNum_free,
  toBytes: bigNum_toBytes,
  fromBytes: function (a1) {
    return runForeignMaybe(bigNum_fromBytes(a1));
  },
  toHex: bigNum_toHex,
  fromHex: function (a1) {
    return runForeignMaybe(bigNum_fromHex(a1));
  },
  toJson: bigNum_toJson,
  toJsValue: bigNum_toJsValue,
  fromJson: function (a1) {
    return runForeignMaybe(bigNum_fromJson(a1));
  },
  fromStr: function (a1) {
    return runForeignMaybe(bigNum_fromStr(a1));
  },
  toStr: bigNum_toStr,
  zero: bigNum_zero,
  one: bigNum_one,
  isZero: bigNum_isZero,
  divFloor: bigNum_divFloor,
  checkedMul: bigNum_checkedMul,
  checkedAdd: bigNum_checkedAdd,
  checkedSub: bigNum_checkedSub,
  clampedSub: bigNum_clampedSub,
  compare: bigNum_compare,
  lessThan: bigNum_lessThan,
  max: bigNum_max,
};
var isStrBigNum = /* @__PURE__ */ (function () {
  return {
    toStr: bigNum.toStr,
    fromStr: bigNum.fromStr,
  };
})();
var baseAddress = {
  free: baseAddress_free,
  new: baseAddress_new,
  paymentCred: baseAddress_paymentCred,
  stakeCred: baseAddress_stakeCred,
  toAddress: baseAddress_toAddress,
  fromAddress: function (a1) {
    return toMaybe(baseAddress_fromAddress(a1));
  },
};
var auxiliaryData = {
  free: auxiliaryData_free,
  toBytes: auxiliaryData_toBytes,
  fromBytes: function (a1) {
    return runForeignMaybe(auxiliaryData_fromBytes(a1));
  },
  toHex: auxiliaryData_toHex,
  fromHex: function (a1) {
    return runForeignMaybe(auxiliaryData_fromHex(a1));
  },
  toJson: auxiliaryData_toJson,
  toJsValue: auxiliaryData_toJsValue,
  fromJson: function (a1) {
    return runForeignMaybe(auxiliaryData_fromJson(a1));
  },
  new: auxiliaryData_new,
  metadata: function (a1) {
    return toMaybe(auxiliaryData_metadata(a1));
  },
  setMetadata: auxiliaryData_setMetadata,
  nativeScripts: function (a1) {
    return map5(toMaybe)(auxiliaryData_nativeScripts(a1));
  },
  setNativeScripts: auxiliaryData_setNativeScripts,
  plutusScripts: function (a1) {
    return map5(toMaybe)(auxiliaryData_plutusScripts(a1));
  },
  setPlutusScripts: auxiliaryData_setPlutusScripts,
};
var address = {
  free: address_free,
  fromBytes: function (a1) {
    return runForeignMaybe(address_fromBytes(a1));
  },
  toJson: address_toJson,
  toJsValue: address_toJsValue,
  fromJson: function (a1) {
    return runForeignMaybe(address_fromJson(a1));
  },
  toHex: address_toHex,
  fromHex: function (a1) {
    return runForeignMaybe(address_fromHex(a1));
  },
  toBytes: address_toBytes,
  toBech32: function (self) {
    return function (prefix) {
      return address_toBech32(self)(toNullable(prefix));
    };
  },
  fromBech32: function (a1) {
    return runForeignMaybe(address_fromBech32(a1));
  },
  networkId: address_networkId,
};
var isHexAddress = /* @__PURE__ */ (function () {
  return {
    toHex: address.toHex,
    fromHex: address.fromHex,
  };
})();

// output/Data.String.CodePoints/foreign.js
var hasArrayFrom = typeof Array.from === "function";
var hasStringIterator =
  typeof Symbol !== "undefined" &&
  Symbol != null &&
  typeof Symbol.iterator !== "undefined" &&
  typeof String.prototype[Symbol.iterator] === "function";
var hasFromCodePoint = typeof String.prototype.fromCodePoint === "function";
var hasCodePointAt = typeof String.prototype.codePointAt === "function";
var _unsafeCodePointAt0 = function (fallback) {
  return hasCodePointAt
    ? function (str) {
        return str.codePointAt(0);
      }
    : fallback;
};
var _singleton = function (fallback) {
  return hasFromCodePoint ? String.fromCodePoint : fallback;
};
var _take = function (fallback) {
  return function (n) {
    if (hasStringIterator) {
      return function (str) {
        var accum = "";
        var iter = str[Symbol.iterator]();
        for (var i3 = 0; i3 < n; ++i3) {
          var o = iter.next();
          if (o.done) return accum;
          accum += o.value;
        }
        return accum;
      };
    }
    return fallback(n);
  };
};
var _toCodePointArray = function (fallback) {
  return function (unsafeCodePointAt02) {
    if (hasArrayFrom) {
      return function (str) {
        return Array.from(str, unsafeCodePointAt02);
      };
    }
    return fallback;
  };
};

// output/Data.Enum/foreign.js
function toCharCode(c) {
  return c.charCodeAt(0);
}
function fromCharCode(c) {
  return String.fromCharCode(c);
}

// output/Data.Enum/index.js
var bottom1 = /* @__PURE__ */ bottom(boundedChar);
var top1 = /* @__PURE__ */ top(boundedChar);
var toEnum = function (dict) {
  return dict.toEnum;
};
var fromEnum = function (dict) {
  return dict.fromEnum;
};
var toEnumWithDefaults = function (dictBoundedEnum) {
  var toEnum1 = toEnum(dictBoundedEnum);
  var fromEnum12 = fromEnum(dictBoundedEnum);
  var bottom22 = bottom(dictBoundedEnum.Bounded0());
  return function (low2) {
    return function (high2) {
      return function (x) {
        var v = toEnum1(x);
        if (v instanceof Just) {
          return v.value0;
        }
        if (v instanceof Nothing) {
          var $140 = x < fromEnum12(bottom22);
          if ($140) {
            return low2;
          }
          return high2;
        }
        throw new Error(
          "Failed pattern match at Data.Enum (line 158, column 33 - line 160, column 62): " + [v.constructor.name]
        );
      };
    };
  };
};
var defaultSucc = function (toEnum$prime) {
  return function (fromEnum$prime) {
    return function (a) {
      return toEnum$prime((fromEnum$prime(a) + 1) | 0);
    };
  };
};
var defaultPred = function (toEnum$prime) {
  return function (fromEnum$prime) {
    return function (a) {
      return toEnum$prime((fromEnum$prime(a) - 1) | 0);
    };
  };
};
var charToEnum = function (v) {
  if (v >= toCharCode(bottom1) && v <= toCharCode(top1)) {
    return new Just(fromCharCode(v));
  }
  return Nothing.value;
};
var enumChar = {
  succ: /* @__PURE__ */ defaultSucc(charToEnum)(toCharCode),
  pred: /* @__PURE__ */ defaultPred(charToEnum)(toCharCode),
  Ord0: function () {
    return ordChar;
  },
};
var boundedEnumChar = /* @__PURE__ */ (function () {
  return {
    cardinality: (toCharCode(top1) - toCharCode(bottom1)) | 0,
    toEnum: charToEnum,
    fromEnum: toCharCode,
    Bounded0: function () {
      return boundedChar;
    },
    Enum1: function () {
      return enumChar;
    },
  };
})();

// output/Data.Int/foreign.js
var fromNumberImpl = function (just) {
  return function (nothing) {
    return function (n) {
      return (n | 0) === n ? just(n) : nothing;
    };
  };
};
var toNumber = function (n) {
  return n;
};
var fromStringAsImpl = function (just) {
  return function (nothing) {
    return function (radix) {
      var digits;
      if (radix < 11) {
        digits = "[0-" + (radix - 1).toString() + "]";
      } else if (radix === 11) {
        digits = "[0-9a]";
      } else {
        digits = "[0-9a-" + String.fromCharCode(86 + radix) + "]";
      }
      var pattern2 = new RegExp("^[\\+\\-]?" + digits + "+$", "i");
      return function (s) {
        if (pattern2.test(s)) {
          var i3 = parseInt(s, radix);
          return (i3 | 0) === i3 ? just(i3) : nothing;
        } else {
          return nothing;
        }
      };
    };
  };
};
var toStringAs = function (radix) {
  return function (i3) {
    return i3.toString(radix);
  };
};

// output/Data.Number/foreign.js
var isFiniteImpl = isFinite;
var floor = Math.floor;

// output/Data.Int/index.js
var top2 = /* @__PURE__ */ top(boundedInt);
var bottom2 = /* @__PURE__ */ bottom(boundedInt);
var hexadecimal = 16;
var fromStringAs = /* @__PURE__ */ (function () {
  return fromStringAsImpl(Just.create)(Nothing.value);
})();
var fromNumber = /* @__PURE__ */ (function () {
  return fromNumberImpl(Just.create)(Nothing.value);
})();
var unsafeClamp = function (x) {
  if (!isFiniteImpl(x)) {
    return 0;
  }
  if (x >= toNumber(top2)) {
    return top2;
  }
  if (x <= toNumber(bottom2)) {
    return bottom2;
  }
  if (otherwise) {
    return fromMaybe(0)(fromNumber(x));
  }
  throw new Error("Failed pattern match at Data.Int (line 72, column 1 - line 72, column 29): " + [x.constructor.name]);
};
var floor2 = function ($39) {
  return unsafeClamp(floor($39));
};

// output/Data.String.CodeUnits/foreign.js
var singleton3 = function (c) {
  return c;
};
var length2 = function (s) {
  return s.length;
};
var drop = function (n) {
  return function (s) {
    return s.substring(n);
  };
};

// output/Data.String.Unsafe/foreign.js
var charAt = function (i3) {
  return function (s) {
    if (i3 >= 0 && i3 < s.length) return s.charAt(i3);
    throw new Error("Data.String.Unsafe.charAt: Invalid index.");
  };
};

// output/Data.String.Common/foreign.js
var joinWith = function (s) {
  return function (xs) {
    return xs.join(s);
  };
};

// output/Data.String.CodePoints/index.js
var $runtime_lazy3 = function (name16, moduleName, init2) {
  var state3 = 0;
  var val;
  return function (lineNumber) {
    if (state3 === 2) return val;
    if (state3 === 1)
      throw new ReferenceError(
        name16 + " was needed before it finished initializing (module " + moduleName + ", line " + lineNumber + ")",
        moduleName,
        lineNumber
      );
    state3 = 1;
    val = init2();
    state3 = 2;
    return val;
  };
};
var fromEnum2 = /* @__PURE__ */ fromEnum(boundedEnumChar);
var map6 = /* @__PURE__ */ map(functorMaybe);
var unfoldr2 = /* @__PURE__ */ unfoldr(unfoldableArray);
var div2 = /* @__PURE__ */ div(euclideanRingInt);
var mod2 = /* @__PURE__ */ mod(euclideanRingInt);
var compare2 = /* @__PURE__ */ compare(ordInt);
var unsurrogate = function (lead) {
  return function (trail) {
    return (((((((lead - 55296) | 0) * 1024) | 0) + ((trail - 56320) | 0)) | 0) + 65536) | 0;
  };
};
var isTrail = function (cu) {
  return 56320 <= cu && cu <= 57343;
};
var isLead = function (cu) {
  return 55296 <= cu && cu <= 56319;
};
var uncons = function (s) {
  var v = length2(s);
  if (v === 0) {
    return Nothing.value;
  }
  if (v === 1) {
    return new Just({
      head: fromEnum2(charAt(0)(s)),
      tail: "",
    });
  }
  var cu1 = fromEnum2(charAt(1)(s));
  var cu0 = fromEnum2(charAt(0)(s));
  var $42 = isLead(cu0) && isTrail(cu1);
  if ($42) {
    return new Just({
      head: unsurrogate(cu0)(cu1),
      tail: drop(2)(s),
    });
  }
  return new Just({
    head: cu0,
    tail: drop(1)(s),
  });
};
var unconsButWithTuple = function (s) {
  return map6(function (v) {
    return new Tuple(v.head, v.tail);
  })(uncons(s));
};
var toCodePointArrayFallback = function (s) {
  return unfoldr2(unconsButWithTuple)(s);
};
var unsafeCodePointAt0Fallback = function (s) {
  var cu0 = fromEnum2(charAt(0)(s));
  var $46 = isLead(cu0) && length2(s) > 1;
  if ($46) {
    var cu1 = fromEnum2(charAt(1)(s));
    var $47 = isTrail(cu1);
    if ($47) {
      return unsurrogate(cu0)(cu1);
    }
    return cu0;
  }
  return cu0;
};
var unsafeCodePointAt0 = /* @__PURE__ */ _unsafeCodePointAt0(unsafeCodePointAt0Fallback);
var toCodePointArray = /* @__PURE__ */ _toCodePointArray(toCodePointArrayFallback)(unsafeCodePointAt0);
var length3 = function ($73) {
  return length(toCodePointArray($73));
};
var fromCharCode2 = /* @__PURE__ */ (function () {
  var $74 = toEnumWithDefaults(boundedEnumChar)(bottom(boundedChar))(top(boundedChar));
  return function ($75) {
    return singleton3($74($75));
  };
})();
var singletonFallback = function (v) {
  if (v <= 65535) {
    return fromCharCode2(v);
  }
  var lead = (div2((v - 65536) | 0)(1024) + 55296) | 0;
  var trail = (mod2((v - 65536) | 0)(1024) + 56320) | 0;
  return fromCharCode2(lead) + fromCharCode2(trail);
};
var singleton4 = /* @__PURE__ */ _singleton(singletonFallback);
var takeFallback = function (n) {
  return function (v) {
    if (n < 1) {
      return "";
    }
    var v1 = uncons(v);
    if (v1 instanceof Just) {
      return singleton4(v1.value0.head) + takeFallback((n - 1) | 0)(v1.value0.tail);
    }
    return v;
  };
};
var take2 = /* @__PURE__ */ _take(takeFallback);
var splitAt2 = function (i3) {
  return function (s) {
    var before = take2(i3)(s);
    return {
      before,
      after: drop(length2(before))(s),
    };
  };
};
var eqCodePoint = {
  eq: function (x) {
    return function (y) {
      return x === y;
    };
  },
};
var ordCodePoint = {
  compare: function (x) {
    return function (y) {
      return compare2(x)(y);
    };
  },
  Eq0: function () {
    return eqCodePoint;
  },
};
var drop2 = function (n) {
  return function (s) {
    return drop(length2(take2(n)(s)))(s);
  };
};
var boundedCodePoint = {
  bottom: 0,
  top: 1114111,
  Ord0: function () {
    return ordCodePoint;
  },
};
var boundedEnumCodePoint = /* @__PURE__ */ (function () {
  return {
    cardinality: (1114111 + 1) | 0,
    fromEnum: function (v) {
      return v;
    },
    toEnum: function (n) {
      if (n >= 0 && n <= 1114111) {
        return new Just(n);
      }
      if (otherwise) {
        return Nothing.value;
      }
      throw new Error(
        "Failed pattern match at Data.String.CodePoints (line 63, column 1 - line 68, column 26): " +
          [n.constructor.name]
      );
    },
    Bounded0: function () {
      return boundedCodePoint;
    },
    Enum1: function () {
      return $lazy_enumCodePoint(0);
    },
  };
})();
var $lazy_enumCodePoint = /* @__PURE__ */ $runtime_lazy3("enumCodePoint", "Data.String.CodePoints", function () {
  return {
    succ: defaultSucc(toEnum(boundedEnumCodePoint))(fromEnum(boundedEnumCodePoint)),
    pred: defaultPred(toEnum(boundedEnumCodePoint))(fromEnum(boundedEnumCodePoint)),
    Ord0: function () {
      return ordCodePoint;
    },
  };
});

// output/Affjax/foreign.js
function _ajax(platformSpecificDriver, timeoutErrorMessageIdent, requestFailedMessageIdent, mkHeader, options2) {
  return function (errback, callback) {
    var xhr = platformSpecificDriver.newXHR();
    var fixedUrl = platformSpecificDriver.fixupUrl(options2.url, xhr);
    xhr.open(options2.method || "GET", fixedUrl, true, options2.username, options2.password);
    if (options2.headers) {
      try {
        for (var i3 = 0, header; (header = options2.headers[i3]) != null; i3++) {
          xhr.setRequestHeader(header.field, header.value);
        }
      } catch (e) {
        errback(e);
      }
    }
    var onerror = function (msgIdent) {
      return function () {
        errback(new Error(msgIdent));
      };
    };
    xhr.onerror = onerror(requestFailedMessageIdent);
    xhr.ontimeout = onerror(timeoutErrorMessageIdent);
    xhr.onload = function () {
      callback({
        status: xhr.status,
        statusText: xhr.statusText,
        headers: xhr
          .getAllResponseHeaders()
          .split("\r\n")
          .filter(function (header2) {
            return header2.length > 0;
          })
          .map(function (header2) {
            var i4 = header2.indexOf(":");
            return mkHeader(header2.substring(0, i4))(header2.substring(i4 + 2));
          }),
        body: xhr.response,
      });
    };
    xhr.responseType = options2.responseType;
    xhr.withCredentials = options2.withCredentials;
    xhr.timeout = options2.timeout;
    xhr.send(options2.content);
    return function (error3, cancelErrback, cancelCallback) {
      try {
        xhr.abort();
      } catch (e) {
        return cancelErrback(e);
      }
      return cancelCallback();
    };
  };
}

// output/Data.MediaType.Common/index.js
var applicationJSON = "application/json";
var applicationFormURLEncoded = "application/x-www-form-urlencoded";

// output/Affjax.RequestBody/index.js
var ArrayView = /* @__PURE__ */ (function () {
  function ArrayView2(value0) {
    this.value0 = value0;
  }
  ArrayView2.create = function (value0) {
    return new ArrayView2(value0);
  };
  return ArrayView2;
})();
var Blob = /* @__PURE__ */ (function () {
  function Blob3(value0) {
    this.value0 = value0;
  }
  Blob3.create = function (value0) {
    return new Blob3(value0);
  };
  return Blob3;
})();
var Document = /* @__PURE__ */ (function () {
  function Document3(value0) {
    this.value0 = value0;
  }
  Document3.create = function (value0) {
    return new Document3(value0);
  };
  return Document3;
})();
var $$String = /* @__PURE__ */ (function () {
  function $$String3(value0) {
    this.value0 = value0;
  }
  $$String3.create = function (value0) {
    return new $$String3(value0);
  };
  return $$String3;
})();
var FormData = /* @__PURE__ */ (function () {
  function FormData2(value0) {
    this.value0 = value0;
  }
  FormData2.create = function (value0) {
    return new FormData2(value0);
  };
  return FormData2;
})();
var FormURLEncoded = /* @__PURE__ */ (function () {
  function FormURLEncoded2(value0) {
    this.value0 = value0;
  }
  FormURLEncoded2.create = function (value0) {
    return new FormURLEncoded2(value0);
  };
  return FormURLEncoded2;
})();
var Json = /* @__PURE__ */ (function () {
  function Json3(value0) {
    this.value0 = value0;
  }
  Json3.create = function (value0) {
    return new Json3(value0);
  };
  return Json3;
})();
var toMediaType = function (v) {
  if (v instanceof FormURLEncoded) {
    return new Just(applicationFormURLEncoded);
  }
  if (v instanceof Json) {
    return new Just(applicationJSON);
  }
  return Nothing.value;
};

// output/Affjax.RequestHeader/index.js
var unwrap3 = /* @__PURE__ */ unwrap();
var Accept = /* @__PURE__ */ (function () {
  function Accept2(value0) {
    this.value0 = value0;
  }
  Accept2.create = function (value0) {
    return new Accept2(value0);
  };
  return Accept2;
})();
var ContentType = /* @__PURE__ */ (function () {
  function ContentType2(value0) {
    this.value0 = value0;
  }
  ContentType2.create = function (value0) {
    return new ContentType2(value0);
  };
  return ContentType2;
})();
var RequestHeader = /* @__PURE__ */ (function () {
  function RequestHeader2(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }
  RequestHeader2.create = function (value0) {
    return function (value1) {
      return new RequestHeader2(value0, value1);
    };
  };
  return RequestHeader2;
})();
var value2 = function (v) {
  if (v instanceof Accept) {
    return unwrap3(v.value0);
  }
  if (v instanceof ContentType) {
    return unwrap3(v.value0);
  }
  if (v instanceof RequestHeader) {
    return v.value1;
  }
  throw new Error(
    "Failed pattern match at Affjax.RequestHeader (line 26, column 1 - line 26, column 33): " + [v.constructor.name]
  );
};
var name2 = function (v) {
  if (v instanceof Accept) {
    return "Accept";
  }
  if (v instanceof ContentType) {
    return "Content-Type";
  }
  if (v instanceof RequestHeader) {
    return v.value0;
  }
  throw new Error(
    "Failed pattern match at Affjax.RequestHeader (line 21, column 1 - line 21, column 32): " + [v.constructor.name]
  );
};

// output/Affjax.ResponseFormat/index.js
var identity9 = /* @__PURE__ */ identity(categoryFn);
var $$ArrayBuffer = /* @__PURE__ */ (function () {
  function $$ArrayBuffer2(value0) {
    this.value0 = value0;
  }
  $$ArrayBuffer2.create = function (value0) {
    return new $$ArrayBuffer2(value0);
  };
  return $$ArrayBuffer2;
})();
var Blob2 = /* @__PURE__ */ (function () {
  function Blob3(value0) {
    this.value0 = value0;
  }
  Blob3.create = function (value0) {
    return new Blob3(value0);
  };
  return Blob3;
})();
var Document2 = /* @__PURE__ */ (function () {
  function Document3(value0) {
    this.value0 = value0;
  }
  Document3.create = function (value0) {
    return new Document3(value0);
  };
  return Document3;
})();
var Json2 = /* @__PURE__ */ (function () {
  function Json3(value0) {
    this.value0 = value0;
  }
  Json3.create = function (value0) {
    return new Json3(value0);
  };
  return Json3;
})();
var $$String2 = /* @__PURE__ */ (function () {
  function $$String3(value0) {
    this.value0 = value0;
  }
  $$String3.create = function (value0) {
    return new $$String3(value0);
  };
  return $$String3;
})();
var Ignore = /* @__PURE__ */ (function () {
  function Ignore2(value0) {
    this.value0 = value0;
  }
  Ignore2.create = function (value0) {
    return new Ignore2(value0);
  };
  return Ignore2;
})();
var toResponseType = function (v) {
  if (v instanceof $$ArrayBuffer) {
    return "arraybuffer";
  }
  if (v instanceof Blob2) {
    return "blob";
  }
  if (v instanceof Document2) {
    return "document";
  }
  if (v instanceof Json2) {
    return "text";
  }
  if (v instanceof $$String2) {
    return "text";
  }
  if (v instanceof Ignore) {
    return "";
  }
  throw new Error(
    "Failed pattern match at Affjax.ResponseFormat (line 44, column 3 - line 50, column 19): " + [v.constructor.name]
  );
};
var toMediaType2 = function (v) {
  if (v instanceof Json2) {
    return new Just(applicationJSON);
  }
  return Nothing.value;
};
var json = /* @__PURE__ */ (function () {
  return new Json2(identity9);
})();
var ignore = /* @__PURE__ */ (function () {
  return new Ignore(identity9);
})();

// output/Affjax.ResponseHeader/index.js
var ResponseHeader = /* @__PURE__ */ (function () {
  function ResponseHeader2(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }
  ResponseHeader2.create = function (value0) {
    return function (value1) {
      return new ResponseHeader2(value0, value1);
    };
  };
  return ResponseHeader2;
})();

// output/Control.Monad.Except/index.js
var unwrap4 = /* @__PURE__ */ unwrap();
var runExcept = function ($3) {
  return unwrap4(runExceptT($3));
};

// output/Data.Argonaut.Core/foreign.js
function id(x) {
  return x;
}
var jsonNull = null;
function stringify(j) {
  return JSON.stringify(j);
}
function _caseJson(isNull3, isBool, isNum, isStr, isArr, isObj, j) {
  if (j == null) return isNull3();
  else if (typeof j === "boolean") return isBool(j);
  else if (typeof j === "number") return isNum(j);
  else if (typeof j === "string") return isStr(j);
  else if (Object.prototype.toString.call(j) === "[object Array]") return isArr(j);
  else return isObj(j);
}

// output/Foreign.Object/foreign.js
function _copyST(m) {
  return function () {
    var r = {};
    for (var k in m) {
      if (hasOwnProperty.call(m, k)) {
        r[k] = m[k];
      }
    }
    return r;
  };
}
var empty2 = {};
function runST(f) {
  return f();
}
function _fmapObject(m0, f) {
  var m = {};
  for (var k in m0) {
    if (hasOwnProperty.call(m0, k)) {
      m[k] = f(m0[k]);
    }
  }
  return m;
}
function _lookup(no, yes, k, m) {
  return k in m ? yes(m[k]) : no;
}
function toArrayWithKey(f) {
  return function (m) {
    var r = [];
    for (var k in m) {
      if (hasOwnProperty.call(m, k)) {
        r.push(f(k)(m[k]));
      }
    }
    return r;
  };
}

// output/Data.Function.Uncurried/foreign.js
var runFn3 = function (fn) {
  return function (a) {
    return function (b) {
      return function (c) {
        return fn(a, b, c);
      };
    };
  };
};
var runFn4 = function (fn) {
  return function (a) {
    return function (b) {
      return function (c) {
        return function (d) {
          return fn(a, b, c, d);
        };
      };
    };
  };
};

// output/Data.FoldableWithIndex/index.js
var foldrWithIndex = function (dict) {
  return dict.foldrWithIndex;
};
var traverseWithIndex_ = function (dictApplicative) {
  var applySecond2 = applySecond(dictApplicative.Apply0());
  var pure23 = pure(dictApplicative);
  return function (dictFoldableWithIndex) {
    var foldrWithIndex1 = foldrWithIndex(dictFoldableWithIndex);
    return function (f) {
      return foldrWithIndex1(function (i3) {
        var $287 = f(i3);
        return function ($288) {
          return applySecond2($287($288));
        };
      })(pure23(unit));
    };
  };
};
var forWithIndex_ = function (dictApplicative) {
  var traverseWithIndex_1 = traverseWithIndex_(dictApplicative);
  return function (dictFoldableWithIndex) {
    return flip(traverseWithIndex_1(dictFoldableWithIndex));
  };
};
var foldlWithIndex = function (dict) {
  return dict.foldlWithIndex;
};
var foldMapWithIndex = function (dict) {
  return dict.foldMapWithIndex;
};

// output/Foreign.Object.ST/foreign.js
var newImpl = function () {
  return {};
};
function poke2(k) {
  return function (v) {
    return function (m) {
      return function () {
        m[k] = v;
        return m;
      };
    };
  };
}
var deleteImpl = function (k) {
  return function (m) {
    return function () {
      delete m[k];
      return m;
    };
  };
};

// output/Foreign.Object/index.js
var $$void3 = /* @__PURE__ */ $$void(functorST);
var mapFlipped2 = /* @__PURE__ */ mapFlipped(functorMaybe);
var toUnfoldable2 = function (dictUnfoldable) {
  var $86 = toUnfoldable(dictUnfoldable);
  var $87 = toArrayWithKey(Tuple.create);
  return function ($88) {
    return $86($87($88));
  };
};
var thawST = _copyST;
var mutate = function (f) {
  return function (m) {
    return runST(function __do2() {
      var s = thawST(m)();
      f(s)();
      return s;
    });
  };
};
var lookup = /* @__PURE__ */ (function () {
  return runFn4(_lookup)(Nothing.value)(Just.create);
})();
var insert = function (k) {
  return function (v) {
    return mutate(poke2(k)(v));
  };
};
var functorObject = {
  map: function (f) {
    return function (m) {
      return _fmapObject(m, f);
    };
  },
};
var fromFoldable2 = function (dictFoldable) {
  var fromFoldable12 = fromFoldable(dictFoldable);
  return function (l) {
    return runST(function __do2() {
      var s = newImpl();
      foreach(fromFoldable12(l))(function (v) {
        return $$void3(poke2(v.value0)(v.value1)(s));
      })();
      return s;
    });
  };
};
var $$delete = function (k) {
  return mutate(deleteImpl(k));
};
var pop = function (k) {
  return function (m) {
    return mapFlipped2(lookup(k)(m))(function (a) {
      return new Tuple(a, $$delete(k)(m));
    });
  };
};
var alter = function (f) {
  return function (k) {
    return function (m) {
      var v = f(lookup(k)(m));
      if (v instanceof Nothing) {
        return $$delete(k)(m);
      }
      if (v instanceof Just) {
        return insert(k)(v.value0)(m);
      }
      throw new Error(
        "Failed pattern match at Foreign.Object (line 209, column 15 - line 211, column 25): " + [v.constructor.name]
      );
    };
  };
};

// output/Data.Argonaut.Core/index.js
var verbJsonType = function (def) {
  return function (f) {
    return function (g) {
      return g(def)(f);
    };
  };
};
var toJsonType = /* @__PURE__ */ (function () {
  return verbJsonType(Nothing.value)(Just.create);
})();
var jsonEmptyObject = /* @__PURE__ */ id(empty2);
var isJsonType = /* @__PURE__ */ verbJsonType(false)(/* @__PURE__ */ $$const(true));
var caseJsonString = function (d) {
  return function (f) {
    return function (j) {
      return _caseJson($$const(d), $$const(d), $$const(d), f, $$const(d), $$const(d), j);
    };
  };
};
var toString = /* @__PURE__ */ toJsonType(caseJsonString);
var caseJsonObject = function (d) {
  return function (f) {
    return function (j) {
      return _caseJson($$const(d), $$const(d), $$const(d), $$const(d), $$const(d), f, j);
    };
  };
};
var toObject = /* @__PURE__ */ toJsonType(caseJsonObject);
var caseJsonNumber = function (d) {
  return function (f) {
    return function (j) {
      return _caseJson($$const(d), $$const(d), f, $$const(d), $$const(d), $$const(d), j);
    };
  };
};
var toNumber2 = /* @__PURE__ */ toJsonType(caseJsonNumber);
var caseJsonNull = function (d) {
  return function (f) {
    return function (j) {
      return _caseJson(f, $$const(d), $$const(d), $$const(d), $$const(d), $$const(d), j);
    };
  };
};
var isNull = /* @__PURE__ */ isJsonType(caseJsonNull);
var caseJsonBoolean = function (d) {
  return function (f) {
    return function (j) {
      return _caseJson($$const(d), f, $$const(d), $$const(d), $$const(d), $$const(d), j);
    };
  };
};
var toBoolean = /* @__PURE__ */ toJsonType(caseJsonBoolean);
var caseJsonArray = function (d) {
  return function (f) {
    return function (j) {
      return _caseJson($$const(d), $$const(d), $$const(d), $$const(d), f, $$const(d), j);
    };
  };
};
var toArray = /* @__PURE__ */ toJsonType(caseJsonArray);

// output/Data.Argonaut.Parser/foreign.js
function _jsonParser(fail3, succ2, s) {
  try {
    return succ2(JSON.parse(s));
  } catch (e) {
    return fail3(e.message);
  }
}

// output/Data.Argonaut.Parser/index.js
var jsonParser = function (j) {
  return _jsonParser(Left.create, Right.create, j);
};

// output/JSURI/foreign.js
function encodeURIComponent_to_RFC3986(input) {
  return input.replace(/[!'()*]/g, function (c) {
    return "%" + c.charCodeAt(0).toString(16);
  });
}
function _encodeFormURLComponent(fail3, succeed, input) {
  try {
    return succeed(encodeURIComponent_to_RFC3986(encodeURIComponent(input)).replace(/%20/g, "+"));
  } catch (err) {
    return fail3(err);
  }
}

// output/JSURI/index.js
var encodeFormURLComponent = /* @__PURE__ */ (function () {
  return runFn3(_encodeFormURLComponent)($$const(Nothing.value))(Just.create);
})();

// output/Data.FormURLEncoded/index.js
var apply2 = /* @__PURE__ */ apply(applyMaybe);
var map7 = /* @__PURE__ */ map(functorMaybe);
var traverse3 = /* @__PURE__ */ traverse(traversableArray)(applicativeMaybe);
var toArray2 = function (v) {
  return v;
};
var encode = /* @__PURE__ */ (function () {
  var encodePart = function (v) {
    if (v.value1 instanceof Nothing) {
      return encodeFormURLComponent(v.value0);
    }
    if (v.value1 instanceof Just) {
      return apply2(
        map7(function (key) {
          return function (val) {
            return key + ("=" + val);
          };
        })(encodeFormURLComponent(v.value0))
      )(encodeFormURLComponent(v.value1.value0));
    }
    throw new Error(
      "Failed pattern match at Data.FormURLEncoded (line 37, column 16 - line 39, column 114): " + [v.constructor.name]
    );
  };
  var $37 = map7(joinWith("&"));
  var $38 = traverse3(encodePart);
  return function ($39) {
    return $37($38(toArray2($39)));
  };
})();

// output/Data.HTTP.Method/index.js
var OPTIONS = /* @__PURE__ */ (function () {
  function OPTIONS2() {}
  OPTIONS2.value = new OPTIONS2();
  return OPTIONS2;
})();
var GET = /* @__PURE__ */ (function () {
  function GET2() {}
  GET2.value = new GET2();
  return GET2;
})();
var HEAD = /* @__PURE__ */ (function () {
  function HEAD2() {}
  HEAD2.value = new HEAD2();
  return HEAD2;
})();
var POST = /* @__PURE__ */ (function () {
  function POST2() {}
  POST2.value = new POST2();
  return POST2;
})();
var PUT = /* @__PURE__ */ (function () {
  function PUT2() {}
  PUT2.value = new PUT2();
  return PUT2;
})();
var DELETE = /* @__PURE__ */ (function () {
  function DELETE2() {}
  DELETE2.value = new DELETE2();
  return DELETE2;
})();
var TRACE = /* @__PURE__ */ (function () {
  function TRACE2() {}
  TRACE2.value = new TRACE2();
  return TRACE2;
})();
var CONNECT = /* @__PURE__ */ (function () {
  function CONNECT2() {}
  CONNECT2.value = new CONNECT2();
  return CONNECT2;
})();
var PROPFIND = /* @__PURE__ */ (function () {
  function PROPFIND2() {}
  PROPFIND2.value = new PROPFIND2();
  return PROPFIND2;
})();
var PROPPATCH = /* @__PURE__ */ (function () {
  function PROPPATCH2() {}
  PROPPATCH2.value = new PROPPATCH2();
  return PROPPATCH2;
})();
var MKCOL = /* @__PURE__ */ (function () {
  function MKCOL2() {}
  MKCOL2.value = new MKCOL2();
  return MKCOL2;
})();
var COPY = /* @__PURE__ */ (function () {
  function COPY2() {}
  COPY2.value = new COPY2();
  return COPY2;
})();
var MOVE = /* @__PURE__ */ (function () {
  function MOVE2() {}
  MOVE2.value = new MOVE2();
  return MOVE2;
})();
var LOCK = /* @__PURE__ */ (function () {
  function LOCK2() {}
  LOCK2.value = new LOCK2();
  return LOCK2;
})();
var UNLOCK = /* @__PURE__ */ (function () {
  function UNLOCK2() {}
  UNLOCK2.value = new UNLOCK2();
  return UNLOCK2;
})();
var PATCH = /* @__PURE__ */ (function () {
  function PATCH2() {}
  PATCH2.value = new PATCH2();
  return PATCH2;
})();
var unCustomMethod = function (v) {
  return v;
};
var showMethod = {
  show: function (v) {
    if (v instanceof OPTIONS) {
      return "OPTIONS";
    }
    if (v instanceof GET) {
      return "GET";
    }
    if (v instanceof HEAD) {
      return "HEAD";
    }
    if (v instanceof POST) {
      return "POST";
    }
    if (v instanceof PUT) {
      return "PUT";
    }
    if (v instanceof DELETE) {
      return "DELETE";
    }
    if (v instanceof TRACE) {
      return "TRACE";
    }
    if (v instanceof CONNECT) {
      return "CONNECT";
    }
    if (v instanceof PROPFIND) {
      return "PROPFIND";
    }
    if (v instanceof PROPPATCH) {
      return "PROPPATCH";
    }
    if (v instanceof MKCOL) {
      return "MKCOL";
    }
    if (v instanceof COPY) {
      return "COPY";
    }
    if (v instanceof MOVE) {
      return "MOVE";
    }
    if (v instanceof LOCK) {
      return "LOCK";
    }
    if (v instanceof UNLOCK) {
      return "UNLOCK";
    }
    if (v instanceof PATCH) {
      return "PATCH";
    }
    throw new Error(
      "Failed pattern match at Data.HTTP.Method (line 43, column 1 - line 59, column 23): " + [v.constructor.name]
    );
  },
};
var print = /* @__PURE__ */ either(/* @__PURE__ */ show(showMethod))(unCustomMethod);

// output/Data.NonEmpty/index.js
var NonEmpty = /* @__PURE__ */ (function () {
  function NonEmpty2(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }
  NonEmpty2.create = function (value0) {
    return function (value1) {
      return new NonEmpty2(value0, value1);
    };
  };
  return NonEmpty2;
})();
var singleton6 = function (dictPlus) {
  var empty6 = empty(dictPlus);
  return function (a) {
    return new NonEmpty(a, empty6);
  };
};

// output/Data.List.Types/index.js
var Nil = /* @__PURE__ */ (function () {
  function Nil3() {}
  Nil3.value = new Nil3();
  return Nil3;
})();
var Cons = /* @__PURE__ */ (function () {
  function Cons3(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }
  Cons3.create = function (value0) {
    return function (value1) {
      return new Cons3(value0, value1);
    };
  };
  return Cons3;
})();
var NonEmptyList = function (x) {
  return x;
};
var toList = function (v) {
  return new Cons(v.value0, v.value1);
};
var listMap = function (f) {
  var chunkedRevMap = function ($copy_chunksAcc) {
    return function ($copy_v) {
      var $tco_var_chunksAcc = $copy_chunksAcc;
      var $tco_done = false;
      var $tco_result;
      function $tco_loop(chunksAcc, v) {
        if (v instanceof Cons && v.value1 instanceof Cons && v.value1.value1 instanceof Cons) {
          $tco_var_chunksAcc = new Cons(v, chunksAcc);
          $copy_v = v.value1.value1.value1;
          return;
        }
        var unrolledMap = function (v1) {
          if (v1 instanceof Cons && v1.value1 instanceof Cons && v1.value1.value1 instanceof Nil) {
            return new Cons(f(v1.value0), new Cons(f(v1.value1.value0), Nil.value));
          }
          if (v1 instanceof Cons && v1.value1 instanceof Nil) {
            return new Cons(f(v1.value0), Nil.value);
          }
          return Nil.value;
        };
        var reverseUnrolledMap = function ($copy_v1) {
          return function ($copy_acc) {
            var $tco_var_v1 = $copy_v1;
            var $tco_done1 = false;
            var $tco_result2;
            function $tco_loop2(v1, acc) {
              if (
                v1 instanceof Cons &&
                v1.value0 instanceof Cons &&
                v1.value0.value1 instanceof Cons &&
                v1.value0.value1.value1 instanceof Cons
              ) {
                $tco_var_v1 = v1.value1;
                $copy_acc = new Cons(
                  f(v1.value0.value0),
                  new Cons(f(v1.value0.value1.value0), new Cons(f(v1.value0.value1.value1.value0), acc))
                );
                return;
              }
              $tco_done1 = true;
              return acc;
            }
            while (!$tco_done1) {
              $tco_result2 = $tco_loop2($tco_var_v1, $copy_acc);
            }
            return $tco_result2;
          };
        };
        $tco_done = true;
        return reverseUnrolledMap(chunksAcc)(unrolledMap(v));
      }
      while (!$tco_done) {
        $tco_result = $tco_loop($tco_var_chunksAcc, $copy_v);
      }
      return $tco_result;
    };
  };
  return chunkedRevMap(Nil.value);
};
var functorList = {
  map: listMap,
};
var map8 = /* @__PURE__ */ map(functorList);
var foldableList = {
  foldr: function (f) {
    return function (b) {
      var rev3 = (function () {
        var go2 = function ($copy_acc) {
          return function ($copy_v) {
            var $tco_var_acc = $copy_acc;
            var $tco_done = false;
            var $tco_result;
            function $tco_loop(acc, v) {
              if (v instanceof Nil) {
                $tco_done = true;
                return acc;
              }
              if (v instanceof Cons) {
                $tco_var_acc = new Cons(v.value0, acc);
                $copy_v = v.value1;
                return;
              }
              throw new Error(
                "Failed pattern match at Data.List.Types (line 107, column 7 - line 107, column 23): " +
                  [acc.constructor.name, v.constructor.name]
              );
            }
            while (!$tco_done) {
              $tco_result = $tco_loop($tco_var_acc, $copy_v);
            }
            return $tco_result;
          };
        };
        return go2(Nil.value);
      })();
      var $281 = foldl(foldableList)(flip(f))(b);
      return function ($282) {
        return $281(rev3($282));
      };
    };
  },
  foldl: function (f) {
    var go2 = function ($copy_b) {
      return function ($copy_v) {
        var $tco_var_b = $copy_b;
        var $tco_done1 = false;
        var $tco_result;
        function $tco_loop(b, v) {
          if (v instanceof Nil) {
            $tco_done1 = true;
            return b;
          }
          if (v instanceof Cons) {
            $tco_var_b = f(b)(v.value0);
            $copy_v = v.value1;
            return;
          }
          throw new Error(
            "Failed pattern match at Data.List.Types (line 111, column 12 - line 113, column 30): " +
              [v.constructor.name]
          );
        }
        while (!$tco_done1) {
          $tco_result = $tco_loop($tco_var_b, $copy_v);
        }
        return $tco_result;
      };
    };
    return go2;
  },
  foldMap: function (dictMonoid) {
    var append23 = append(dictMonoid.Semigroup0());
    var mempty5 = mempty(dictMonoid);
    return function (f) {
      return foldl(foldableList)(function (acc) {
        var $283 = append23(acc);
        return function ($284) {
          return $283(f($284));
        };
      })(mempty5);
    };
  },
};
var foldr2 = /* @__PURE__ */ foldr(foldableList);
var semigroupList = {
  append: function (xs) {
    return function (ys) {
      return foldr2(Cons.create)(ys)(xs);
    };
  },
};
var append1 = /* @__PURE__ */ append(semigroupList);
var semigroupNonEmptyList = {
  append: function (v) {
    return function (as$prime) {
      return new NonEmpty(v.value0, append1(v.value1)(toList(as$prime)));
    };
  },
};
var applyList = {
  apply: function (v) {
    return function (v1) {
      if (v instanceof Nil) {
        return Nil.value;
      }
      if (v instanceof Cons) {
        return append1(map8(v.value0)(v1))(apply(applyList)(v.value1)(v1));
      }
      throw new Error(
        "Failed pattern match at Data.List.Types (line 157, column 1 - line 159, column 48): " +
          [v.constructor.name, v1.constructor.name]
      );
    };
  },
  Functor0: function () {
    return functorList;
  },
};
var applicativeList = {
  pure: function (a) {
    return new Cons(a, Nil.value);
  },
  Apply0: function () {
    return applyList;
  },
};
var altList = {
  alt: append1,
  Functor0: function () {
    return functorList;
  },
};
var plusList = /* @__PURE__ */ (function () {
  return {
    empty: Nil.value,
    Alt0: function () {
      return altList;
    },
  };
})();

// output/Data.List.NonEmpty/index.js
var singleton7 = /* @__PURE__ */ (function () {
  var $199 = singleton6(plusList);
  return function ($200) {
    return NonEmptyList($199($200));
  };
})();
var head2 = function (v) {
  return v.value0;
};

// output/Effect.Aff.Compat/index.js
var fromEffectFnAff = function (v) {
  return makeAff(function (k) {
    return function __do2() {
      var v1 = v(
        function ($9) {
          return k(Left.create($9))();
        },
        function ($10) {
          return k(Right.create($10))();
        }
      );
      return function (e) {
        return makeAff(function (k2) {
          return function __do3() {
            v1(
              e,
              function ($11) {
                return k2(Left.create($11))();
              },
              function ($12) {
                return k2(Right.create($12))();
              }
            );
            return nonCanceler;
          };
        });
      };
    };
  });
};

// output/Foreign/foreign.js
function tagOf(value14) {
  return Object.prototype.toString.call(value14).slice(8, -1);
}

// output/Foreign/index.js
var show2 = /* @__PURE__ */ show(showString);
var show1 = /* @__PURE__ */ show(showInt);
var ForeignError = /* @__PURE__ */ (function () {
  function ForeignError2(value0) {
    this.value0 = value0;
  }
  ForeignError2.create = function (value0) {
    return new ForeignError2(value0);
  };
  return ForeignError2;
})();
var TypeMismatch = /* @__PURE__ */ (function () {
  function TypeMismatch3(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }
  TypeMismatch3.create = function (value0) {
    return function (value1) {
      return new TypeMismatch3(value0, value1);
    };
  };
  return TypeMismatch3;
})();
var ErrorAtIndex = /* @__PURE__ */ (function () {
  function ErrorAtIndex2(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }
  ErrorAtIndex2.create = function (value0) {
    return function (value1) {
      return new ErrorAtIndex2(value0, value1);
    };
  };
  return ErrorAtIndex2;
})();
var ErrorAtProperty = /* @__PURE__ */ (function () {
  function ErrorAtProperty2(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }
  ErrorAtProperty2.create = function (value0) {
    return function (value1) {
      return new ErrorAtProperty2(value0, value1);
    };
  };
  return ErrorAtProperty2;
})();
var unsafeToForeign = unsafeCoerce2;
var unsafeFromForeign = unsafeCoerce2;
var renderForeignError = function (v) {
  if (v instanceof ForeignError) {
    return v.value0;
  }
  if (v instanceof ErrorAtIndex) {
    return "Error at array index " + (show1(v.value0) + (": " + renderForeignError(v.value1)));
  }
  if (v instanceof ErrorAtProperty) {
    return "Error at property " + (show2(v.value0) + (": " + renderForeignError(v.value1)));
  }
  if (v instanceof TypeMismatch) {
    return "Type mismatch: expected " + (v.value0 + (", found " + v.value1));
  }
  throw new Error("Failed pattern match at Foreign (line 78, column 1 - line 78, column 45): " + [v.constructor.name]);
};
var fail = function (dictMonad) {
  var $153 = throwError(monadThrowExceptT(dictMonad));
  return function ($154) {
    return $153(singleton7($154));
  };
};
var unsafeReadTagged = function (dictMonad) {
  var pure111 = pure(applicativeExceptT(dictMonad));
  var fail1 = fail(dictMonad);
  return function (tag) {
    return function (value14) {
      if (tagOf(value14) === tag) {
        return pure111(unsafeFromForeign(value14));
      }
      if (otherwise) {
        return fail1(new TypeMismatch(tag, tagOf(value14)));
      }
      throw new Error(
        "Failed pattern match at Foreign (line 123, column 1 - line 123, column 104): " +
          [tag.constructor.name, value14.constructor.name]
      );
    };
  };
};
var readString = function (dictMonad) {
  return unsafeReadTagged(dictMonad)("String");
};

// output/Affjax/index.js
var pure5 = /* @__PURE__ */ pure(/* @__PURE__ */ applicativeExceptT(monadIdentity));
var fail2 = /* @__PURE__ */ fail(monadIdentity);
var unsafeReadTagged2 = /* @__PURE__ */ unsafeReadTagged(monadIdentity);
var alt2 = /* @__PURE__ */ alt(/* @__PURE__ */ altExceptT(semigroupNonEmptyList)(monadIdentity));
var composeKleisliFlipped2 = /* @__PURE__ */ composeKleisliFlipped(/* @__PURE__ */ bindExceptT(monadIdentity));
var map9 = /* @__PURE__ */ map(functorMaybe);
var any3 = /* @__PURE__ */ any2(foldableArray)(heytingAlgebraBoolean);
var eq3 = /* @__PURE__ */ eq(eqString);
var bindFlipped3 = /* @__PURE__ */ bindFlipped(bindMaybe);
var map1 = /* @__PURE__ */ map(functorArray);
var mapFlipped3 = /* @__PURE__ */ mapFlipped(functorAff);
var $$try3 = /* @__PURE__ */ $$try(monadErrorAff);
var pure1 = /* @__PURE__ */ pure(applicativeAff);
var RequestContentError = /* @__PURE__ */ (function () {
  function RequestContentError2(value0) {
    this.value0 = value0;
  }
  RequestContentError2.create = function (value0) {
    return new RequestContentError2(value0);
  };
  return RequestContentError2;
})();
var ResponseBodyError = /* @__PURE__ */ (function () {
  function ResponseBodyError2(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }
  ResponseBodyError2.create = function (value0) {
    return function (value1) {
      return new ResponseBodyError2(value0, value1);
    };
  };
  return ResponseBodyError2;
})();
var TimeoutError = /* @__PURE__ */ (function () {
  function TimeoutError2() {}
  TimeoutError2.value = new TimeoutError2();
  return TimeoutError2;
})();
var RequestFailedError = /* @__PURE__ */ (function () {
  function RequestFailedError2() {}
  RequestFailedError2.value = new RequestFailedError2();
  return RequestFailedError2;
})();
var XHROtherError = /* @__PURE__ */ (function () {
  function XHROtherError2(value0) {
    this.value0 = value0;
  }
  XHROtherError2.create = function (value0) {
    return new XHROtherError2(value0);
  };
  return XHROtherError2;
})();
var request = function (driver2) {
  return function (req) {
    var parseJSON = function (v2) {
      if (v2 === "") {
        return pure5(jsonEmptyObject);
      }
      return either(function ($74) {
        return fail2(ForeignError.create($74));
      })(pure5)(jsonParser(v2));
    };
    var fromResponse = (function () {
      if (req.responseFormat instanceof $$ArrayBuffer) {
        return unsafeReadTagged2("ArrayBuffer");
      }
      if (req.responseFormat instanceof Blob2) {
        return unsafeReadTagged2("Blob");
      }
      if (req.responseFormat instanceof Document2) {
        return function (x) {
          return alt2(unsafeReadTagged2("Document")(x))(
            alt2(unsafeReadTagged2("XMLDocument")(x))(unsafeReadTagged2("HTMLDocument")(x))
          );
        };
      }
      if (req.responseFormat instanceof Json2) {
        return composeKleisliFlipped2(function ($75) {
          return req.responseFormat.value0(parseJSON($75));
        })(unsafeReadTagged2("String"));
      }
      if (req.responseFormat instanceof $$String2) {
        return unsafeReadTagged2("String");
      }
      if (req.responseFormat instanceof Ignore) {
        return $$const(req.responseFormat.value0(pure5(unit)));
      }
      throw new Error(
        "Failed pattern match at Affjax (line 274, column 18 - line 283, column 57): " +
          [req.responseFormat.constructor.name]
      );
    })();
    var extractContent = function (v2) {
      if (v2 instanceof ArrayView) {
        return new Right(v2.value0(unsafeToForeign));
      }
      if (v2 instanceof Blob) {
        return new Right(unsafeToForeign(v2.value0));
      }
      if (v2 instanceof Document) {
        return new Right(unsafeToForeign(v2.value0));
      }
      if (v2 instanceof $$String) {
        return new Right(unsafeToForeign(v2.value0));
      }
      if (v2 instanceof FormData) {
        return new Right(unsafeToForeign(v2.value0));
      }
      if (v2 instanceof FormURLEncoded) {
        return note("Body contains values that cannot be encoded as application/x-www-form-urlencoded")(
          map9(unsafeToForeign)(encode(v2.value0))
        );
      }
      if (v2 instanceof Json) {
        return new Right(unsafeToForeign(stringify(v2.value0)));
      }
      throw new Error(
        "Failed pattern match at Affjax (line 235, column 20 - line 250, column 69): " + [v2.constructor.name]
      );
    };
    var addHeader = function (mh) {
      return function (hs) {
        if (mh instanceof Just && !any3(on(eq3)(name2)(mh.value0))(hs)) {
          return snoc(hs)(mh.value0);
        }
        return hs;
      };
    };
    var headers = function (reqContent) {
      return addHeader(map9(ContentType.create)(bindFlipped3(toMediaType)(reqContent)))(
        addHeader(map9(Accept.create)(toMediaType2(req.responseFormat)))(req.headers)
      );
    };
    var ajaxRequest = function (v2) {
      return {
        method: print(req.method),
        url: req.url,
        headers: map1(function (h) {
          return {
            field: name2(h),
            value: value2(h),
          };
        })(headers(req.content)),
        content: v2,
        responseType: toResponseType(req.responseFormat),
        username: toNullable(req.username),
        password: toNullable(req.password),
        withCredentials: req.withCredentials,
        timeout: fromMaybe(0)(
          map9(function (v1) {
            return v1;
          })(req.timeout)
        ),
      };
    };
    var send = function (content3) {
      return mapFlipped3(
        $$try3(
          fromEffectFnAff(
            _ajax(
              driver2,
              "AffjaxTimeoutErrorMessageIdent",
              "AffjaxRequestFailedMessageIdent",
              ResponseHeader.create,
              ajaxRequest(content3)
            )
          )
        )
      )(function (v2) {
        if (v2 instanceof Right) {
          var v1 = runExcept(fromResponse(v2.value0.body));
          if (v1 instanceof Left) {
            return new Left(new ResponseBodyError(head2(v1.value0), v2.value0));
          }
          if (v1 instanceof Right) {
            return new Right({
              body: v1.value0,
              headers: v2.value0.headers,
              status: v2.value0.status,
              statusText: v2.value0.statusText,
            });
          }
          throw new Error(
            "Failed pattern match at Affjax (line 209, column 9 - line 211, column 52): " + [v1.constructor.name]
          );
        }
        if (v2 instanceof Left) {
          return new Left(
            (function () {
              var message2 = message(v2.value0);
              var $61 = message2 === "AffjaxTimeoutErrorMessageIdent";
              if ($61) {
                return TimeoutError.value;
              }
              var $62 = message2 === "AffjaxRequestFailedMessageIdent";
              if ($62) {
                return RequestFailedError.value;
              }
              return new XHROtherError(v2.value0);
            })()
          );
        }
        throw new Error(
          "Failed pattern match at Affjax (line 207, column 144 - line 219, column 28): " + [v2.constructor.name]
        );
      });
    };
    if (req.content instanceof Nothing) {
      return send(toNullable(Nothing.value));
    }
    if (req.content instanceof Just) {
      var v = extractContent(req.content.value0);
      if (v instanceof Right) {
        return send(toNullable(new Just(v.value0)));
      }
      if (v instanceof Left) {
        return pure1(new Left(new RequestContentError(v.value0)));
      }
      throw new Error(
        "Failed pattern match at Affjax (line 199, column 7 - line 203, column 48): " + [v.constructor.name]
      );
    }
    throw new Error(
      "Failed pattern match at Affjax (line 195, column 3 - line 203, column 48): " + [req.content.constructor.name]
    );
  };
};
var printError = function (v) {
  if (v instanceof RequestContentError) {
    return "There was a problem with the request content: " + v.value0;
  }
  if (v instanceof ResponseBodyError) {
    return "There was a problem with the response body: " + renderForeignError(v.value0);
  }
  if (v instanceof TimeoutError) {
    return "There was a problem making the request: timeout";
  }
  if (v instanceof RequestFailedError) {
    return "There was a problem making the request: request failed";
  }
  if (v instanceof XHROtherError) {
    return "There was a problem making the request: " + message(v.value0);
  }
  throw new Error(
    "Failed pattern match at Affjax (line 113, column 14 - line 123, column 66): " + [v.constructor.name]
  );
};
var defaultRequest = /* @__PURE__ */ (function () {
  return {
    method: new Left(GET.value),
    url: "/",
    headers: [],
    content: Nothing.value,
    username: Nothing.value,
    password: Nothing.value,
    withCredentials: false,
    responseFormat: ignore,
    timeout: Nothing.value,
  };
})();

// output/Data.BigInt/foreign.js
function fromBaseImpl(just) {
  return function (nothing) {
    return function (b) {
      return function (s) {
        try {
          var x = bigInt(s, b);
          return just(x);
        } catch (err) {
          return nothing;
        }
      };
    };
  };
}
function fromInt(n) {
  return bigInt(n);
}
function toBase(base) {
  return function (x) {
    return x.toString(base);
  };
}
function biAdd(x) {
  return function (y) {
    return x.add(y);
  };
}
function biMul(x) {
  return function (y) {
    return x.multiply(y);
  };
}

// output/Data.BigInt/index.js
var toString2 = /* @__PURE__ */ toBase(10);
var semiringBigInt = {
  add: biAdd,
  zero: /* @__PURE__ */ fromInt(0),
  mul: biMul,
  one: /* @__PURE__ */ fromInt(1),
};
var fromBase = /* @__PURE__ */ (function () {
  return fromBaseImpl(Just.create)(Nothing.value);
})();
var fromString2 = /* @__PURE__ */ fromBase(10);

// output/Control.Monad.Writer/index.js
var unwrap5 = /* @__PURE__ */ unwrap();
var writer = /* @__PURE__ */ (function () {
  var $3 = pure(applicativeIdentity);
  return function ($4) {
    return WriterT($3($4));
  };
})();
var runWriter = function ($5) {
  return unwrap5(runWriterT($5));
};
var mapWriter = function (f) {
  return mapWriterT(function ($6) {
    return Identity(f(unwrap5($6)));
  });
};
var execWriter = function (m) {
  return snd(runWriter(m));
};

// output/Data.Codec/index.js
var un2 = /* @__PURE__ */ un();
var GCodec = /* @__PURE__ */ (function () {
  function GCodec2(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }
  GCodec2.create = function (value0) {
    return function (value1) {
      return new GCodec2(value0, value1);
    };
  };
  return GCodec2;
})();
var profunctorGCodec = function (dictFunctor) {
  var map36 = map(dictFunctor);
  return function (dictFunctor1) {
    var dimap2 = dimap(profunctorStar(dictFunctor1));
    return {
      dimap: function (f) {
        return function (g) {
          return function (v) {
            return new GCodec(map36(g)(v.value0), dimap2(f)(g)(v.value1));
          };
        };
      },
    };
  };
};
var mapCodec = function (dictBind) {
  var bindFlipped11 = bindFlipped(dictBind);
  return function (f) {
    return function (g) {
      return function (v) {
        var enc = function (a) {
          var v1 = runWriter(un2(Star)(v.value1)(g(a)));
          return writer(new Tuple(a, v1.value1));
        };
        var dec = function (x) {
          return bindFlipped11(f)(runReaderT(v.value0)(x));
        };
        return new GCodec(dec, enc);
      };
    };
  };
};
var encoder = function (v) {
  return v.value1;
};
var encode2 = function (c) {
  var $179 = un2(Star)(encoder(c));
  return function ($180) {
    return execWriter($179($180));
  };
};
var decoder = function (v) {
  return v.value0;
};
var decode = function ($181) {
  return runReaderT(decoder($181));
};
var composeCodec = function (dictBind) {
  var bindFlipped11 = bindFlipped(dictBind);
  return function (v) {
    return function (v1) {
      return new GCodec(
        function (x) {
          return bindFlipped11(runReaderT(v.value0))(runReaderT(v1.value0)(x));
        },
        function (c) {
          var v2 = runWriter(un2(Star)(v.value1)(c));
          return writer(new Tuple(v2.value0, execWriter(un2(Star)(v1.value1)(v2.value1))));
        }
      );
    };
  };
};
var composeCodecFlipped = function (dictBind) {
  return flip(composeCodec(dictBind));
};
var bihoistGCodec = function (f) {
  return function (g) {
    return function (v) {
      return new GCodec(f(v.value0), function ($182) {
        return g(v.value1($182));
      });
    };
  };
};
var basicCodec = function (f) {
  return function (g) {
    return new GCodec(f, function (x) {
      return writer(new Tuple(x, g(x)));
    });
  };
};

// output/Data.Codec.Argonaut/index.js
var show12 = /* @__PURE__ */ show(showInt);
var fromJust6 = /* @__PURE__ */ fromJust();
var bind2 = /* @__PURE__ */ bind(bindEither);
var lmap2 = /* @__PURE__ */ lmap(bifunctorEither);
var pure6 = /* @__PURE__ */ pure(applicativeEither);
var pure12 = /* @__PURE__ */ pure(applicativeList);
var bindFlipped4 = /* @__PURE__ */ bindFlipped(bindEither);
var composeKleisliFlipped3 = /* @__PURE__ */ composeKleisliFlipped(bindEither);
var rmap2 = /* @__PURE__ */ rmap(bifunctorTuple);
var fromFoldable4 = /* @__PURE__ */ fromFoldable2(foldableList);
var composeKleisliFlipped1 = /* @__PURE__ */ composeKleisliFlipped(bindMaybe);
var map10 = /* @__PURE__ */ map(functorArray);
var traverse4 = /* @__PURE__ */ traverse(traversableArray)(applicativeEither);
var TypeMismatch2 = /* @__PURE__ */ (function () {
  function TypeMismatch3(value0) {
    this.value0 = value0;
  }
  TypeMismatch3.create = function (value0) {
    return new TypeMismatch3(value0);
  };
  return TypeMismatch3;
})();
var UnexpectedValue = /* @__PURE__ */ (function () {
  function UnexpectedValue2(value0) {
    this.value0 = value0;
  }
  UnexpectedValue2.create = function (value0) {
    return new UnexpectedValue2(value0);
  };
  return UnexpectedValue2;
})();
var AtIndex = /* @__PURE__ */ (function () {
  function AtIndex2(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }
  AtIndex2.create = function (value0) {
    return function (value1) {
      return new AtIndex2(value0, value1);
    };
  };
  return AtIndex2;
})();
var AtKey = /* @__PURE__ */ (function () {
  function AtKey2(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }
  AtKey2.create = function (value0) {
    return function (value1) {
      return new AtKey2(value0, value1);
    };
  };
  return AtKey2;
})();
var Named = /* @__PURE__ */ (function () {
  function Named2(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }
  Named2.create = function (value0) {
    return function (value1) {
      return new Named2(value0, value1);
    };
  };
  return Named2;
})();
var MissingValue = /* @__PURE__ */ (function () {
  function MissingValue2() {}
  MissingValue2.value = new MissingValue2();
  return MissingValue2;
})();
var recordProp = function (dictIsSymbol) {
  var reflectSymbol2 = reflectSymbol(dictIsSymbol);
  return function () {
    return function (p) {
      return function (codecA) {
        return function (codecR) {
          var unsafeSet2 = function (key2) {
            return function (a) {
              var $202 = insert(key2)(a);
              return function ($203) {
                return $202($203);
              };
            };
          };
          var unsafeGet2 = function (s) {
            var $205 = lookup(s);
            return function ($206) {
              return fromJust6($205($206));
            };
          };
          var enc$prime = function (key2) {
            return function (val) {
              return writer(
                new Tuple(val, new Cons(new Tuple(key2, encode2(codecA)(unsafeGet2(key2)(val))), encode2(codecR)(val)))
              );
            };
          };
          var dec$prime = function (key2) {
            return function (obj) {
              return bind2(decode(codecR)(obj))(function (r) {
                return bind2(
                  lmap2(AtKey.create(key2))(
                    (function () {
                      var v = lookup(key2)(obj);
                      if (v instanceof Just) {
                        return decode(codecA)(v.value0);
                      }
                      if (v instanceof Nothing) {
                        return new Left(MissingValue.value);
                      }
                      throw new Error(
                        "Failed pattern match at Data.Codec.Argonaut (line 270, column 31 - line 272, column 36): " +
                          [v.constructor.name]
                      );
                    })()
                  )
                )(function (a) {
                  return pure6(unsafeSet2(key2)(a)(r));
                });
              });
            };
          };
          var key = reflectSymbol2(p);
          return new GCodec(dec$prime(key), enc$prime(key));
        };
      };
    };
  };
};
var record = /* @__PURE__ */ (function () {
  return new GCodec(pure(applicativeReaderT(applicativeEither))({}), function (val) {
    return writer(new Tuple(val, Nil.value));
  });
})();
var prop = function (key) {
  return function (codec) {
    var enc = function (val) {
      return writer(new Tuple(val, pure12(new Tuple(key, encode2(codec)(val)))));
    };
    var dec = function (obj) {
      return lmap2(AtKey.create(key))(
        (function () {
          var v = lookup(key)(obj);
          if (v instanceof Just) {
            return decode(codec)(v.value0);
          }
          if (v instanceof Nothing) {
            return new Left(MissingValue.value);
          }
          throw new Error(
            "Failed pattern match at Data.Codec.Argonaut (line 224, column 25 - line 226, column 34): " +
              [v.constructor.name]
          );
        })()
      );
    };
    return new GCodec(dec, enc);
  };
};
var prismaticCodec = function (name16) {
  return function (f) {
    return function (g) {
      return function (orig) {
        return basicCodec(function (json$prime) {
          return bindFlipped4(
            (function () {
              var $207 = note(new Named(name16, new UnexpectedValue(json$prime)));
              return function ($208) {
                return $207(f($208));
              };
            })()
          )(decode(orig)(json$prime));
        })(
          (function () {
            var $209 = encode2(orig);
            return function ($210) {
              return $209(g($210));
            };
          })()
        );
      };
    };
  };
};
var printJsonDecodeError = function (err) {
  var go2 = function (v) {
    if (v instanceof TypeMismatch2) {
      return "  Expected value of type '" + (v.value0 + "'.");
    }
    if (v instanceof UnexpectedValue) {
      return "  Unexpected value " + (stringify(v.value0) + ".");
    }
    if (v instanceof AtIndex) {
      return "  At array index " + (show12(v.value0) + (":\n" + go2(v.value1)));
    }
    if (v instanceof AtKey) {
      return "  At object key " + (v.value0 + (":\n" + go2(v.value1)));
    }
    if (v instanceof Named) {
      return "  Under '" + (v.value0 + ("':\n" + go2(v.value1)));
    }
    if (v instanceof MissingValue) {
      return "  No value was found.";
    }
    throw new Error(
      "Failed pattern match at Data.Codec.Argonaut (line 87, column 10 - line 93, column 45): " + [v.constructor.name]
    );
  };
  return "An error occurred while decoding a JSON value:\n" + go2(err);
};
var jsonPrimCodec = function (ty) {
  return function (f) {
    return basicCodec(
      (function () {
        var $211 = maybe(new Left(new TypeMismatch2(ty)))(pure6);
        return function ($212) {
          return $211(f($212));
        };
      })()
    );
  };
};
var number = /* @__PURE__ */ jsonPrimCodec("Number")(toNumber2)(id);
var string = /* @__PURE__ */ jsonPrimCodec("String")(toString)(id);
var json2 = /* @__PURE__ */ basicCodec(pure6)(/* @__PURE__ */ identity(categoryFn));
var jobject = /* @__PURE__ */ jsonPrimCodec("Object")(toObject)(id);
var object = function (name16) {
  return bihoistGCodec(function (r) {
    return composeKleisliFlipped3(
      (function () {
        var $213 = lmap2(Named.create(name16));
        var $214 = runReaderT(r);
        return function ($215) {
          return $213($214($215));
        };
      })()
    )(decode(jobject));
  })(
    mapWriter(
      rmap2(function ($216) {
        return id(fromFoldable4($216));
      })
    )
  );
};
var jarray = /* @__PURE__ */ jsonPrimCodec("Array")(toArray)(id);
var $$int = /* @__PURE__ */ jsonPrimCodec("Int")(/* @__PURE__ */ composeKleisliFlipped1(fromNumber)(toNumber2))(
  function ($217) {
    return id(toNumber($217));
  }
);
var $$boolean = /* @__PURE__ */ jsonPrimCodec("Boolean")(toBoolean)(id);
var array = function (codec) {
  var enc = function (xs) {
    return writer(new Tuple(xs, id(map10(encode2(codec))(xs))));
  };
  var dec = function (j) {
    return bindFlipped4(
      (function () {
        var $224 = traverse4(function (v) {
          return lmap2(AtIndex.create(v.value0))(decode(codec)(v.value1));
        });
        var $225 = mapWithIndex(Tuple.create);
        return function ($226) {
          return $224($225($226));
        };
      })()
    )(decode(jarray)(j));
  };
  return new GCodec(dec, enc);
};

// output/Data.Map.Internal/index.js
var Leaf = /* @__PURE__ */ (function () {
  function Leaf2() {}
  Leaf2.value = new Leaf2();
  return Leaf2;
})();
var Two = /* @__PURE__ */ (function () {
  function Two2(value0, value1, value22, value32) {
    this.value0 = value0;
    this.value1 = value1;
    this.value2 = value22;
    this.value3 = value32;
  }
  Two2.create = function (value0) {
    return function (value1) {
      return function (value22) {
        return function (value32) {
          return new Two2(value0, value1, value22, value32);
        };
      };
    };
  };
  return Two2;
})();
var Three = /* @__PURE__ */ (function () {
  function Three2(value0, value1, value22, value32, value42, value52, value62) {
    this.value0 = value0;
    this.value1 = value1;
    this.value2 = value22;
    this.value3 = value32;
    this.value4 = value42;
    this.value5 = value52;
    this.value6 = value62;
  }
  Three2.create = function (value0) {
    return function (value1) {
      return function (value22) {
        return function (value32) {
          return function (value42) {
            return function (value52) {
              return function (value62) {
                return new Three2(value0, value1, value22, value32, value42, value52, value62);
              };
            };
          };
        };
      };
    };
  };
  return Three2;
})();
var TwoLeft = /* @__PURE__ */ (function () {
  function TwoLeft2(value0, value1, value22) {
    this.value0 = value0;
    this.value1 = value1;
    this.value2 = value22;
  }
  TwoLeft2.create = function (value0) {
    return function (value1) {
      return function (value22) {
        return new TwoLeft2(value0, value1, value22);
      };
    };
  };
  return TwoLeft2;
})();
var TwoRight = /* @__PURE__ */ (function () {
  function TwoRight2(value0, value1, value22) {
    this.value0 = value0;
    this.value1 = value1;
    this.value2 = value22;
  }
  TwoRight2.create = function (value0) {
    return function (value1) {
      return function (value22) {
        return new TwoRight2(value0, value1, value22);
      };
    };
  };
  return TwoRight2;
})();
var ThreeLeft = /* @__PURE__ */ (function () {
  function ThreeLeft2(value0, value1, value22, value32, value42, value52) {
    this.value0 = value0;
    this.value1 = value1;
    this.value2 = value22;
    this.value3 = value32;
    this.value4 = value42;
    this.value5 = value52;
  }
  ThreeLeft2.create = function (value0) {
    return function (value1) {
      return function (value22) {
        return function (value32) {
          return function (value42) {
            return function (value52) {
              return new ThreeLeft2(value0, value1, value22, value32, value42, value52);
            };
          };
        };
      };
    };
  };
  return ThreeLeft2;
})();
var ThreeMiddle = /* @__PURE__ */ (function () {
  function ThreeMiddle2(value0, value1, value22, value32, value42, value52) {
    this.value0 = value0;
    this.value1 = value1;
    this.value2 = value22;
    this.value3 = value32;
    this.value4 = value42;
    this.value5 = value52;
  }
  ThreeMiddle2.create = function (value0) {
    return function (value1) {
      return function (value22) {
        return function (value32) {
          return function (value42) {
            return function (value52) {
              return new ThreeMiddle2(value0, value1, value22, value32, value42, value52);
            };
          };
        };
      };
    };
  };
  return ThreeMiddle2;
})();
var ThreeRight = /* @__PURE__ */ (function () {
  function ThreeRight2(value0, value1, value22, value32, value42, value52) {
    this.value0 = value0;
    this.value1 = value1;
    this.value2 = value22;
    this.value3 = value32;
    this.value4 = value42;
    this.value5 = value52;
  }
  ThreeRight2.create = function (value0) {
    return function (value1) {
      return function (value22) {
        return function (value32) {
          return function (value42) {
            return function (value52) {
              return new ThreeRight2(value0, value1, value22, value32, value42, value52);
            };
          };
        };
      };
    };
  };
  return ThreeRight2;
})();
var KickUp = /* @__PURE__ */ (function () {
  function KickUp2(value0, value1, value22, value32) {
    this.value0 = value0;
    this.value1 = value1;
    this.value2 = value22;
    this.value3 = value32;
  }
  KickUp2.create = function (value0) {
    return function (value1) {
      return function (value22) {
        return function (value32) {
          return new KickUp2(value0, value1, value22, value32);
        };
      };
    };
  };
  return KickUp2;
})();
var singleton8 = function (k) {
  return function (v) {
    return new Two(Leaf.value, k, v, Leaf.value);
  };
};
var toUnfoldable3 = function (dictUnfoldable) {
  var unfoldr3 = unfoldr(dictUnfoldable);
  return function (m) {
    var go2 = function ($copy_v) {
      var $tco_done = false;
      var $tco_result;
      function $tco_loop(v) {
        if (v instanceof Nil) {
          $tco_done = true;
          return Nothing.value;
        }
        if (v instanceof Cons) {
          if (v.value0 instanceof Leaf) {
            $copy_v = v.value1;
            return;
          }
          if (v.value0 instanceof Two && v.value0.value0 instanceof Leaf && v.value0.value3 instanceof Leaf) {
            $tco_done = true;
            return new Just(new Tuple(new Tuple(v.value0.value1, v.value0.value2), v.value1));
          }
          if (v.value0 instanceof Two && v.value0.value0 instanceof Leaf) {
            $tco_done = true;
            return new Just(
              new Tuple(new Tuple(v.value0.value1, v.value0.value2), new Cons(v.value0.value3, v.value1))
            );
          }
          if (v.value0 instanceof Two) {
            $copy_v = new Cons(
              v.value0.value0,
              new Cons(singleton8(v.value0.value1)(v.value0.value2), new Cons(v.value0.value3, v.value1))
            );
            return;
          }
          if (v.value0 instanceof Three) {
            $copy_v = new Cons(
              v.value0.value0,
              new Cons(
                singleton8(v.value0.value1)(v.value0.value2),
                new Cons(
                  v.value0.value3,
                  new Cons(singleton8(v.value0.value4)(v.value0.value5), new Cons(v.value0.value6, v.value1))
                )
              )
            );
            return;
          }
          throw new Error(
            "Failed pattern match at Data.Map.Internal (line 624, column 18 - line 633, column 71): " +
              [v.value0.constructor.name]
          );
        }
        throw new Error(
          "Failed pattern match at Data.Map.Internal (line 623, column 3 - line 623, column 19): " +
            [v.constructor.name]
        );
      }
      while (!$tco_done) {
        $tco_result = $tco_loop($copy_v);
      }
      return $tco_result;
    };
    return unfoldr3(go2)(new Cons(m, Nil.value));
  };
};
var lookup2 = function (dictOrd) {
  var compare3 = compare(dictOrd);
  return function (k) {
    var go2 = function ($copy_v) {
      var $tco_done = false;
      var $tco_result;
      function $tco_loop(v) {
        if (v instanceof Leaf) {
          $tco_done = true;
          return Nothing.value;
        }
        if (v instanceof Two) {
          var v2 = compare3(k)(v.value1);
          if (v2 instanceof EQ) {
            $tco_done = true;
            return new Just(v.value2);
          }
          if (v2 instanceof LT) {
            $copy_v = v.value0;
            return;
          }
          $copy_v = v.value3;
          return;
        }
        if (v instanceof Three) {
          var v3 = compare3(k)(v.value1);
          if (v3 instanceof EQ) {
            $tco_done = true;
            return new Just(v.value2);
          }
          var v4 = compare3(k)(v.value4);
          if (v4 instanceof EQ) {
            $tco_done = true;
            return new Just(v.value5);
          }
          if (v3 instanceof LT) {
            $copy_v = v.value0;
            return;
          }
          if (v4 instanceof GT) {
            $copy_v = v.value6;
            return;
          }
          $copy_v = v.value3;
          return;
        }
        throw new Error(
          "Failed pattern match at Data.Map.Internal (line 241, column 5 - line 241, column 22): " +
            [v.constructor.name]
        );
      }
      while (!$tco_done) {
        $tco_result = $tco_loop($copy_v);
      }
      return $tco_result;
    };
    return go2;
  };
};
var functorMap = {
  map: function (v) {
    return function (v1) {
      if (v1 instanceof Leaf) {
        return Leaf.value;
      }
      if (v1 instanceof Two) {
        return new Two(map(functorMap)(v)(v1.value0), v1.value1, v(v1.value2), map(functorMap)(v)(v1.value3));
      }
      if (v1 instanceof Three) {
        return new Three(
          map(functorMap)(v)(v1.value0),
          v1.value1,
          v(v1.value2),
          map(functorMap)(v)(v1.value3),
          v1.value4,
          v(v1.value5),
          map(functorMap)(v)(v1.value6)
        );
      }
      throw new Error(
        "Failed pattern match at Data.Map.Internal (line 116, column 1 - line 119, column 110): " +
          [v.constructor.name, v1.constructor.name]
      );
    };
  },
};
var fromZipper = function ($copy_dictOrd) {
  return function ($copy_v) {
    return function ($copy_tree) {
      var $tco_var_dictOrd = $copy_dictOrd;
      var $tco_var_v = $copy_v;
      var $tco_done = false;
      var $tco_result;
      function $tco_loop(dictOrd, v, tree) {
        if (v instanceof Nil) {
          $tco_done = true;
          return tree;
        }
        if (v instanceof Cons) {
          if (v.value0 instanceof TwoLeft) {
            $tco_var_dictOrd = dictOrd;
            $tco_var_v = v.value1;
            $copy_tree = new Two(tree, v.value0.value0, v.value0.value1, v.value0.value2);
            return;
          }
          if (v.value0 instanceof TwoRight) {
            $tco_var_dictOrd = dictOrd;
            $tco_var_v = v.value1;
            $copy_tree = new Two(v.value0.value0, v.value0.value1, v.value0.value2, tree);
            return;
          }
          if (v.value0 instanceof ThreeLeft) {
            $tco_var_dictOrd = dictOrd;
            $tco_var_v = v.value1;
            $copy_tree = new Three(
              tree,
              v.value0.value0,
              v.value0.value1,
              v.value0.value2,
              v.value0.value3,
              v.value0.value4,
              v.value0.value5
            );
            return;
          }
          if (v.value0 instanceof ThreeMiddle) {
            $tco_var_dictOrd = dictOrd;
            $tco_var_v = v.value1;
            $copy_tree = new Three(
              v.value0.value0,
              v.value0.value1,
              v.value0.value2,
              tree,
              v.value0.value3,
              v.value0.value4,
              v.value0.value5
            );
            return;
          }
          if (v.value0 instanceof ThreeRight) {
            $tco_var_dictOrd = dictOrd;
            $tco_var_v = v.value1;
            $copy_tree = new Three(
              v.value0.value0,
              v.value0.value1,
              v.value0.value2,
              v.value0.value3,
              v.value0.value4,
              v.value0.value5,
              tree
            );
            return;
          }
          throw new Error(
            "Failed pattern match at Data.Map.Internal (line 462, column 3 - line 467, column 88): " +
              [v.value0.constructor.name]
          );
        }
        throw new Error(
          "Failed pattern match at Data.Map.Internal (line 459, column 1 - line 459, column 80): " +
            [v.constructor.name, tree.constructor.name]
        );
      }
      while (!$tco_done) {
        $tco_result = $tco_loop($tco_var_dictOrd, $tco_var_v, $copy_tree);
      }
      return $tco_result;
    };
  };
};
var insert2 = function (dictOrd) {
  var fromZipper1 = fromZipper(dictOrd);
  var compare3 = compare(dictOrd);
  return function (k) {
    return function (v) {
      var up = function ($copy_v1) {
        return function ($copy_v2) {
          var $tco_var_v1 = $copy_v1;
          var $tco_done = false;
          var $tco_result;
          function $tco_loop(v1, v2) {
            if (v1 instanceof Nil) {
              $tco_done = true;
              return new Two(v2.value0, v2.value1, v2.value2, v2.value3);
            }
            if (v1 instanceof Cons) {
              if (v1.value0 instanceof TwoLeft) {
                $tco_done = true;
                return fromZipper1(v1.value1)(
                  new Three(
                    v2.value0,
                    v2.value1,
                    v2.value2,
                    v2.value3,
                    v1.value0.value0,
                    v1.value0.value1,
                    v1.value0.value2
                  )
                );
              }
              if (v1.value0 instanceof TwoRight) {
                $tco_done = true;
                return fromZipper1(v1.value1)(
                  new Three(
                    v1.value0.value0,
                    v1.value0.value1,
                    v1.value0.value2,
                    v2.value0,
                    v2.value1,
                    v2.value2,
                    v2.value3
                  )
                );
              }
              if (v1.value0 instanceof ThreeLeft) {
                $tco_var_v1 = v1.value1;
                $copy_v2 = new KickUp(
                  new Two(v2.value0, v2.value1, v2.value2, v2.value3),
                  v1.value0.value0,
                  v1.value0.value1,
                  new Two(v1.value0.value2, v1.value0.value3, v1.value0.value4, v1.value0.value5)
                );
                return;
              }
              if (v1.value0 instanceof ThreeMiddle) {
                $tco_var_v1 = v1.value1;
                $copy_v2 = new KickUp(
                  new Two(v1.value0.value0, v1.value0.value1, v1.value0.value2, v2.value0),
                  v2.value1,
                  v2.value2,
                  new Two(v2.value3, v1.value0.value3, v1.value0.value4, v1.value0.value5)
                );
                return;
              }
              if (v1.value0 instanceof ThreeRight) {
                $tco_var_v1 = v1.value1;
                $copy_v2 = new KickUp(
                  new Two(v1.value0.value0, v1.value0.value1, v1.value0.value2, v1.value0.value3),
                  v1.value0.value4,
                  v1.value0.value5,
                  new Two(v2.value0, v2.value1, v2.value2, v2.value3)
                );
                return;
              }
              throw new Error(
                "Failed pattern match at Data.Map.Internal (line 498, column 5 - line 503, column 108): " +
                  [v1.value0.constructor.name, v2.constructor.name]
              );
            }
            throw new Error(
              "Failed pattern match at Data.Map.Internal (line 495, column 3 - line 495, column 56): " +
                [v1.constructor.name, v2.constructor.name]
            );
          }
          while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_v1, $copy_v2);
          }
          return $tco_result;
        };
      };
      var down = function ($copy_ctx) {
        return function ($copy_v1) {
          var $tco_var_ctx = $copy_ctx;
          var $tco_done1 = false;
          var $tco_result;
          function $tco_loop(ctx, v1) {
            if (v1 instanceof Leaf) {
              $tco_done1 = true;
              return up(ctx)(new KickUp(Leaf.value, k, v, Leaf.value));
            }
            if (v1 instanceof Two) {
              var v2 = compare3(k)(v1.value1);
              if (v2 instanceof EQ) {
                $tco_done1 = true;
                return fromZipper1(ctx)(new Two(v1.value0, k, v, v1.value3));
              }
              if (v2 instanceof LT) {
                $tco_var_ctx = new Cons(new TwoLeft(v1.value1, v1.value2, v1.value3), ctx);
                $copy_v1 = v1.value0;
                return;
              }
              $tco_var_ctx = new Cons(new TwoRight(v1.value0, v1.value1, v1.value2), ctx);
              $copy_v1 = v1.value3;
              return;
            }
            if (v1 instanceof Three) {
              var v3 = compare3(k)(v1.value1);
              if (v3 instanceof EQ) {
                $tco_done1 = true;
                return fromZipper1(ctx)(new Three(v1.value0, k, v, v1.value3, v1.value4, v1.value5, v1.value6));
              }
              var v4 = compare3(k)(v1.value4);
              if (v4 instanceof EQ) {
                $tco_done1 = true;
                return fromZipper1(ctx)(new Three(v1.value0, v1.value1, v1.value2, v1.value3, k, v, v1.value6));
              }
              if (v3 instanceof LT) {
                $tco_var_ctx = new Cons(
                  new ThreeLeft(v1.value1, v1.value2, v1.value3, v1.value4, v1.value5, v1.value6),
                  ctx
                );
                $copy_v1 = v1.value0;
                return;
              }
              if (v3 instanceof GT && v4 instanceof LT) {
                $tco_var_ctx = new Cons(
                  new ThreeMiddle(v1.value0, v1.value1, v1.value2, v1.value4, v1.value5, v1.value6),
                  ctx
                );
                $copy_v1 = v1.value3;
                return;
              }
              $tco_var_ctx = new Cons(
                new ThreeRight(v1.value0, v1.value1, v1.value2, v1.value3, v1.value4, v1.value5),
                ctx
              );
              $copy_v1 = v1.value6;
              return;
            }
            throw new Error(
              "Failed pattern match at Data.Map.Internal (line 478, column 3 - line 478, column 55): " +
                [ctx.constructor.name, v1.constructor.name]
            );
          }
          while (!$tco_done1) {
            $tco_result = $tco_loop($tco_var_ctx, $copy_v1);
          }
          return $tco_result;
        };
      };
      return down(Nil.value);
    };
  };
};
var pop2 = function (dictOrd) {
  var fromZipper1 = fromZipper(dictOrd);
  var compare3 = compare(dictOrd);
  return function (k) {
    var up = function ($copy_ctxs) {
      return function ($copy_tree) {
        var $tco_var_ctxs = $copy_ctxs;
        var $tco_done = false;
        var $tco_result;
        function $tco_loop(ctxs, tree) {
          if (ctxs instanceof Nil) {
            $tco_done = true;
            return tree;
          }
          if (ctxs instanceof Cons) {
            if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Leaf && tree instanceof Leaf) {
              $tco_done = true;
              return fromZipper1(ctxs.value1)(new Two(Leaf.value, ctxs.value0.value0, ctxs.value0.value1, Leaf.value));
            }
            if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Leaf && tree instanceof Leaf) {
              $tco_done = true;
              return fromZipper1(ctxs.value1)(new Two(Leaf.value, ctxs.value0.value1, ctxs.value0.value2, Leaf.value));
            }
            if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Two) {
              $tco_var_ctxs = ctxs.value1;
              $copy_tree = new Three(
                tree,
                ctxs.value0.value0,
                ctxs.value0.value1,
                ctxs.value0.value2.value0,
                ctxs.value0.value2.value1,
                ctxs.value0.value2.value2,
                ctxs.value0.value2.value3
              );
              return;
            }
            if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Two) {
              $tco_var_ctxs = ctxs.value1;
              $copy_tree = new Three(
                ctxs.value0.value0.value0,
                ctxs.value0.value0.value1,
                ctxs.value0.value0.value2,
                ctxs.value0.value0.value3,
                ctxs.value0.value1,
                ctxs.value0.value2,
                tree
              );
              return;
            }
            if (ctxs.value0 instanceof TwoLeft && ctxs.value0.value2 instanceof Three) {
              $tco_done = true;
              return fromZipper1(ctxs.value1)(
                new Two(
                  new Two(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0),
                  ctxs.value0.value2.value1,
                  ctxs.value0.value2.value2,
                  new Two(
                    ctxs.value0.value2.value3,
                    ctxs.value0.value2.value4,
                    ctxs.value0.value2.value5,
                    ctxs.value0.value2.value6
                  )
                )
              );
            }
            if (ctxs.value0 instanceof TwoRight && ctxs.value0.value0 instanceof Three) {
              $tco_done = true;
              return fromZipper1(ctxs.value1)(
                new Two(
                  new Two(
                    ctxs.value0.value0.value0,
                    ctxs.value0.value0.value1,
                    ctxs.value0.value0.value2,
                    ctxs.value0.value0.value3
                  ),
                  ctxs.value0.value0.value4,
                  ctxs.value0.value0.value5,
                  new Two(ctxs.value0.value0.value6, ctxs.value0.value1, ctxs.value0.value2, tree)
                )
              );
            }
            if (
              ctxs.value0 instanceof ThreeLeft &&
              ctxs.value0.value2 instanceof Leaf &&
              ctxs.value0.value5 instanceof Leaf &&
              tree instanceof Leaf
            ) {
              $tco_done = true;
              return fromZipper1(ctxs.value1)(
                new Three(
                  Leaf.value,
                  ctxs.value0.value0,
                  ctxs.value0.value1,
                  Leaf.value,
                  ctxs.value0.value3,
                  ctxs.value0.value4,
                  Leaf.value
                )
              );
            }
            if (
              ctxs.value0 instanceof ThreeMiddle &&
              ctxs.value0.value0 instanceof Leaf &&
              ctxs.value0.value5 instanceof Leaf &&
              tree instanceof Leaf
            ) {
              $tco_done = true;
              return fromZipper1(ctxs.value1)(
                new Three(
                  Leaf.value,
                  ctxs.value0.value1,
                  ctxs.value0.value2,
                  Leaf.value,
                  ctxs.value0.value3,
                  ctxs.value0.value4,
                  Leaf.value
                )
              );
            }
            if (
              ctxs.value0 instanceof ThreeRight &&
              ctxs.value0.value0 instanceof Leaf &&
              ctxs.value0.value3 instanceof Leaf &&
              tree instanceof Leaf
            ) {
              $tco_done = true;
              return fromZipper1(ctxs.value1)(
                new Three(
                  Leaf.value,
                  ctxs.value0.value1,
                  ctxs.value0.value2,
                  Leaf.value,
                  ctxs.value0.value4,
                  ctxs.value0.value5,
                  Leaf.value
                )
              );
            }
            if (ctxs.value0 instanceof ThreeLeft && ctxs.value0.value2 instanceof Two) {
              $tco_done = true;
              return fromZipper1(ctxs.value1)(
                new Two(
                  new Three(
                    tree,
                    ctxs.value0.value0,
                    ctxs.value0.value1,
                    ctxs.value0.value2.value0,
                    ctxs.value0.value2.value1,
                    ctxs.value0.value2.value2,
                    ctxs.value0.value2.value3
                  ),
                  ctxs.value0.value3,
                  ctxs.value0.value4,
                  ctxs.value0.value5
                )
              );
            }
            if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value0 instanceof Two) {
              $tco_done = true;
              return fromZipper1(ctxs.value1)(
                new Two(
                  new Three(
                    ctxs.value0.value0.value0,
                    ctxs.value0.value0.value1,
                    ctxs.value0.value0.value2,
                    ctxs.value0.value0.value3,
                    ctxs.value0.value1,
                    ctxs.value0.value2,
                    tree
                  ),
                  ctxs.value0.value3,
                  ctxs.value0.value4,
                  ctxs.value0.value5
                )
              );
            }
            if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value5 instanceof Two) {
              $tco_done = true;
              return fromZipper1(ctxs.value1)(
                new Two(
                  ctxs.value0.value0,
                  ctxs.value0.value1,
                  ctxs.value0.value2,
                  new Three(
                    tree,
                    ctxs.value0.value3,
                    ctxs.value0.value4,
                    ctxs.value0.value5.value0,
                    ctxs.value0.value5.value1,
                    ctxs.value0.value5.value2,
                    ctxs.value0.value5.value3
                  )
                )
              );
            }
            if (ctxs.value0 instanceof ThreeRight && ctxs.value0.value3 instanceof Two) {
              $tco_done = true;
              return fromZipper1(ctxs.value1)(
                new Two(
                  ctxs.value0.value0,
                  ctxs.value0.value1,
                  ctxs.value0.value2,
                  new Three(
                    ctxs.value0.value3.value0,
                    ctxs.value0.value3.value1,
                    ctxs.value0.value3.value2,
                    ctxs.value0.value3.value3,
                    ctxs.value0.value4,
                    ctxs.value0.value5,
                    tree
                  )
                )
              );
            }
            if (ctxs.value0 instanceof ThreeLeft && ctxs.value0.value2 instanceof Three) {
              $tco_done = true;
              return fromZipper1(ctxs.value1)(
                new Three(
                  new Two(tree, ctxs.value0.value0, ctxs.value0.value1, ctxs.value0.value2.value0),
                  ctxs.value0.value2.value1,
                  ctxs.value0.value2.value2,
                  new Two(
                    ctxs.value0.value2.value3,
                    ctxs.value0.value2.value4,
                    ctxs.value0.value2.value5,
                    ctxs.value0.value2.value6
                  ),
                  ctxs.value0.value3,
                  ctxs.value0.value4,
                  ctxs.value0.value5
                )
              );
            }
            if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value0 instanceof Three) {
              $tco_done = true;
              return fromZipper1(ctxs.value1)(
                new Three(
                  new Two(
                    ctxs.value0.value0.value0,
                    ctxs.value0.value0.value1,
                    ctxs.value0.value0.value2,
                    ctxs.value0.value0.value3
                  ),
                  ctxs.value0.value0.value4,
                  ctxs.value0.value0.value5,
                  new Two(ctxs.value0.value0.value6, ctxs.value0.value1, ctxs.value0.value2, tree),
                  ctxs.value0.value3,
                  ctxs.value0.value4,
                  ctxs.value0.value5
                )
              );
            }
            if (ctxs.value0 instanceof ThreeMiddle && ctxs.value0.value5 instanceof Three) {
              $tco_done = true;
              return fromZipper1(ctxs.value1)(
                new Three(
                  ctxs.value0.value0,
                  ctxs.value0.value1,
                  ctxs.value0.value2,
                  new Two(tree, ctxs.value0.value3, ctxs.value0.value4, ctxs.value0.value5.value0),
                  ctxs.value0.value5.value1,
                  ctxs.value0.value5.value2,
                  new Two(
                    ctxs.value0.value5.value3,
                    ctxs.value0.value5.value4,
                    ctxs.value0.value5.value5,
                    ctxs.value0.value5.value6
                  )
                )
              );
            }
            if (ctxs.value0 instanceof ThreeRight && ctxs.value0.value3 instanceof Three) {
              $tco_done = true;
              return fromZipper1(ctxs.value1)(
                new Three(
                  ctxs.value0.value0,
                  ctxs.value0.value1,
                  ctxs.value0.value2,
                  new Two(
                    ctxs.value0.value3.value0,
                    ctxs.value0.value3.value1,
                    ctxs.value0.value3.value2,
                    ctxs.value0.value3.value3
                  ),
                  ctxs.value0.value3.value4,
                  ctxs.value0.value3.value5,
                  new Two(ctxs.value0.value3.value6, ctxs.value0.value4, ctxs.value0.value5, tree)
                )
              );
            }
            $tco_done = true;
            return unsafeCrashWith("The impossible happened in partial function `up`.");
          }
          throw new Error(
            "Failed pattern match at Data.Map.Internal (line 552, column 5 - line 573, column 86): " +
              [ctxs.constructor.name]
          );
        }
        while (!$tco_done) {
          $tco_result = $tco_loop($tco_var_ctxs, $copy_tree);
        }
        return $tco_result;
      };
    };
    var removeMaxNode = function ($copy_ctx) {
      return function ($copy_m) {
        var $tco_var_ctx = $copy_ctx;
        var $tco_done1 = false;
        var $tco_result;
        function $tco_loop(ctx, m) {
          if (m instanceof Two && m.value0 instanceof Leaf && m.value3 instanceof Leaf) {
            $tco_done1 = true;
            return up(ctx)(Leaf.value);
          }
          if (m instanceof Two) {
            $tco_var_ctx = new Cons(new TwoRight(m.value0, m.value1, m.value2), ctx);
            $copy_m = m.value3;
            return;
          }
          if (m instanceof Three && m.value0 instanceof Leaf && m.value3 instanceof Leaf && m.value6 instanceof Leaf) {
            $tco_done1 = true;
            return up(new Cons(new TwoRight(Leaf.value, m.value1, m.value2), ctx))(Leaf.value);
          }
          if (m instanceof Three) {
            $tco_var_ctx = new Cons(new ThreeRight(m.value0, m.value1, m.value2, m.value3, m.value4, m.value5), ctx);
            $copy_m = m.value6;
            return;
          }
          $tco_done1 = true;
          return unsafeCrashWith("The impossible happened in partial function `removeMaxNode`.");
        }
        while (!$tco_done1) {
          $tco_result = $tco_loop($tco_var_ctx, $copy_m);
        }
        return $tco_result;
      };
    };
    var maxNode = function ($copy_m) {
      var $tco_done2 = false;
      var $tco_result;
      function $tco_loop(m) {
        if (m instanceof Two && m.value3 instanceof Leaf) {
          $tco_done2 = true;
          return {
            key: m.value1,
            value: m.value2,
          };
        }
        if (m instanceof Two) {
          $copy_m = m.value3;
          return;
        }
        if (m instanceof Three && m.value6 instanceof Leaf) {
          $tco_done2 = true;
          return {
            key: m.value4,
            value: m.value5,
          };
        }
        if (m instanceof Three) {
          $copy_m = m.value6;
          return;
        }
        $tco_done2 = true;
        return unsafeCrashWith("The impossible happened in partial function `maxNode`.");
      }
      while (!$tco_done2) {
        $tco_result = $tco_loop($copy_m);
      }
      return $tco_result;
    };
    var down = function ($copy_ctx) {
      return function ($copy_m) {
        var $tco_var_ctx = $copy_ctx;
        var $tco_done3 = false;
        var $tco_result;
        function $tco_loop(ctx, m) {
          if (m instanceof Leaf) {
            $tco_done3 = true;
            return Nothing.value;
          }
          if (m instanceof Two) {
            var v = compare3(k)(m.value1);
            if (m.value3 instanceof Leaf && v instanceof EQ) {
              $tco_done3 = true;
              return new Just(new Tuple(m.value2, up(ctx)(Leaf.value)));
            }
            if (v instanceof EQ) {
              var max6 = maxNode(m.value0);
              $tco_done3 = true;
              return new Just(
                new Tuple(m.value2, removeMaxNode(new Cons(new TwoLeft(max6.key, max6.value, m.value3), ctx))(m.value0))
              );
            }
            if (v instanceof LT) {
              $tco_var_ctx = new Cons(new TwoLeft(m.value1, m.value2, m.value3), ctx);
              $copy_m = m.value0;
              return;
            }
            $tco_var_ctx = new Cons(new TwoRight(m.value0, m.value1, m.value2), ctx);
            $copy_m = m.value3;
            return;
          }
          if (m instanceof Three) {
            var leaves = (function () {
              if (m.value0 instanceof Leaf && m.value3 instanceof Leaf && m.value6 instanceof Leaf) {
                return true;
              }
              return false;
            })();
            var v = compare3(k)(m.value4);
            var v3 = compare3(k)(m.value1);
            if (leaves && v3 instanceof EQ) {
              $tco_done3 = true;
              return new Just(
                new Tuple(m.value2, fromZipper1(ctx)(new Two(Leaf.value, m.value4, m.value5, Leaf.value)))
              );
            }
            if (leaves && v instanceof EQ) {
              $tco_done3 = true;
              return new Just(
                new Tuple(m.value5, fromZipper1(ctx)(new Two(Leaf.value, m.value1, m.value2, Leaf.value)))
              );
            }
            if (v3 instanceof EQ) {
              var max6 = maxNode(m.value0);
              $tco_done3 = true;
              return new Just(
                new Tuple(
                  m.value2,
                  removeMaxNode(
                    new Cons(new ThreeLeft(max6.key, max6.value, m.value3, m.value4, m.value5, m.value6), ctx)
                  )(m.value0)
                )
              );
            }
            if (v instanceof EQ) {
              var max6 = maxNode(m.value3);
              $tco_done3 = true;
              return new Just(
                new Tuple(
                  m.value5,
                  removeMaxNode(
                    new Cons(new ThreeMiddle(m.value0, m.value1, m.value2, max6.key, max6.value, m.value6), ctx)
                  )(m.value3)
                )
              );
            }
            if (v3 instanceof LT) {
              $tco_var_ctx = new Cons(new ThreeLeft(m.value1, m.value2, m.value3, m.value4, m.value5, m.value6), ctx);
              $copy_m = m.value0;
              return;
            }
            if (v3 instanceof GT && v instanceof LT) {
              $tco_var_ctx = new Cons(new ThreeMiddle(m.value0, m.value1, m.value2, m.value4, m.value5, m.value6), ctx);
              $copy_m = m.value3;
              return;
            }
            $tco_var_ctx = new Cons(new ThreeRight(m.value0, m.value1, m.value2, m.value3, m.value4, m.value5), ctx);
            $copy_m = m.value6;
            return;
          }
          throw new Error(
            "Failed pattern match at Data.Map.Internal (line 525, column 16 - line 548, column 80): " +
              [m.constructor.name]
          );
        }
        while (!$tco_done3) {
          $tco_result = $tco_loop($tco_var_ctx, $copy_m);
        }
        return $tco_result;
      };
    };
    return down(Nil.value);
  };
};
var foldableMap = {
  foldr: function (f) {
    return function (z) {
      return function (m) {
        if (m instanceof Leaf) {
          return z;
        }
        if (m instanceof Two) {
          return foldr(foldableMap)(f)(f(m.value2)(foldr(foldableMap)(f)(z)(m.value3)))(m.value0);
        }
        if (m instanceof Three) {
          return foldr(foldableMap)(f)(
            f(m.value2)(foldr(foldableMap)(f)(f(m.value5)(foldr(foldableMap)(f)(z)(m.value6)))(m.value3))
          )(m.value0);
        }
        throw new Error(
          "Failed pattern match at Data.Map.Internal (line 133, column 17 - line 136, column 85): " +
            [m.constructor.name]
        );
      };
    };
  },
  foldl: function (f) {
    return function (z) {
      return function (m) {
        if (m instanceof Leaf) {
          return z;
        }
        if (m instanceof Two) {
          return foldl(foldableMap)(f)(f(foldl(foldableMap)(f)(z)(m.value0))(m.value2))(m.value3);
        }
        if (m instanceof Three) {
          return foldl(foldableMap)(f)(
            f(foldl(foldableMap)(f)(f(foldl(foldableMap)(f)(z)(m.value0))(m.value2))(m.value3))(m.value5)
          )(m.value6);
        }
        throw new Error(
          "Failed pattern match at Data.Map.Internal (line 137, column 17 - line 140, column 85): " +
            [m.constructor.name]
        );
      };
    };
  },
  foldMap: function (dictMonoid) {
    var mempty5 = mempty(dictMonoid);
    var append23 = append(dictMonoid.Semigroup0());
    return function (f) {
      return function (m) {
        if (m instanceof Leaf) {
          return mempty5;
        }
        if (m instanceof Two) {
          return append23(foldMap(foldableMap)(dictMonoid)(f)(m.value0))(
            append23(f(m.value2))(foldMap(foldableMap)(dictMonoid)(f)(m.value3))
          );
        }
        if (m instanceof Three) {
          return append23(foldMap(foldableMap)(dictMonoid)(f)(m.value0))(
            append23(f(m.value2))(
              append23(foldMap(foldableMap)(dictMonoid)(f)(m.value3))(
                append23(f(m.value5))(foldMap(foldableMap)(dictMonoid)(f)(m.value6))
              )
            )
          );
        }
        throw new Error(
          "Failed pattern match at Data.Map.Internal (line 141, column 17 - line 144, column 93): " +
            [m.constructor.name]
        );
      };
    };
  },
};
var foldableWithIndexMap = {
  foldrWithIndex: function (f) {
    return function (z) {
      return function (m) {
        if (m instanceof Leaf) {
          return z;
        }
        if (m instanceof Two) {
          return foldrWithIndex(foldableWithIndexMap)(f)(
            f(m.value1)(m.value2)(foldrWithIndex(foldableWithIndexMap)(f)(z)(m.value3))
          )(m.value0);
        }
        if (m instanceof Three) {
          return foldrWithIndex(foldableWithIndexMap)(f)(
            f(m.value1)(m.value2)(
              foldrWithIndex(foldableWithIndexMap)(f)(
                f(m.value4)(m.value5)(foldrWithIndex(foldableWithIndexMap)(f)(z)(m.value6))
              )(m.value3)
            )
          )(m.value0);
        }
        throw new Error(
          "Failed pattern match at Data.Map.Internal (line 147, column 26 - line 150, column 120): " +
            [m.constructor.name]
        );
      };
    };
  },
  foldlWithIndex: function (f) {
    return function (z) {
      return function (m) {
        if (m instanceof Leaf) {
          return z;
        }
        if (m instanceof Two) {
          return foldlWithIndex(foldableWithIndexMap)(f)(
            f(m.value1)(foldlWithIndex(foldableWithIndexMap)(f)(z)(m.value0))(m.value2)
          )(m.value3);
        }
        if (m instanceof Three) {
          return foldlWithIndex(foldableWithIndexMap)(f)(
            f(m.value4)(
              foldlWithIndex(foldableWithIndexMap)(f)(
                f(m.value1)(foldlWithIndex(foldableWithIndexMap)(f)(z)(m.value0))(m.value2)
              )(m.value3)
            )(m.value5)
          )(m.value6);
        }
        throw new Error(
          "Failed pattern match at Data.Map.Internal (line 151, column 26 - line 154, column 120): " +
            [m.constructor.name]
        );
      };
    };
  },
  foldMapWithIndex: function (dictMonoid) {
    var mempty5 = mempty(dictMonoid);
    var append23 = append(dictMonoid.Semigroup0());
    return function (f) {
      return function (m) {
        if (m instanceof Leaf) {
          return mempty5;
        }
        if (m instanceof Two) {
          return append23(foldMapWithIndex(foldableWithIndexMap)(dictMonoid)(f)(m.value0))(
            append23(f(m.value1)(m.value2))(foldMapWithIndex(foldableWithIndexMap)(dictMonoid)(f)(m.value3))
          );
        }
        if (m instanceof Three) {
          return append23(foldMapWithIndex(foldableWithIndexMap)(dictMonoid)(f)(m.value0))(
            append23(f(m.value1)(m.value2))(
              append23(foldMapWithIndex(foldableWithIndexMap)(dictMonoid)(f)(m.value3))(
                append23(f(m.value4)(m.value5))(foldMapWithIndex(foldableWithIndexMap)(dictMonoid)(f)(m.value6))
              )
            )
          );
        }
        throw new Error(
          "Failed pattern match at Data.Map.Internal (line 155, column 26 - line 158, column 128): " +
            [m.constructor.name]
        );
      };
    };
  },
  Foldable0: function () {
    return foldableMap;
  },
};
var foldrWithIndex2 = /* @__PURE__ */ foldrWithIndex(foldableWithIndexMap);
var foldlWithIndex2 = /* @__PURE__ */ foldlWithIndex(foldableWithIndexMap);
var keys2 = /* @__PURE__ */ (function () {
  return foldrWithIndex2(function (k) {
    return function () {
      return function (acc) {
        return new Cons(k, acc);
      };
    };
  })(Nil.value);
})();
var empty3 = /* @__PURE__ */ (function () {
  return Leaf.value;
})();
var fromFoldable5 = function (dictOrd) {
  var insert1 = insert2(dictOrd);
  return function (dictFoldable) {
    return foldl(dictFoldable)(function (m) {
      return function (v) {
        return insert1(v.value0)(v.value1)(m);
      };
    })(empty3);
  };
};
var $$delete2 = function (dictOrd) {
  var pop1 = pop2(dictOrd);
  return function (k) {
    return function (m) {
      return maybe(m)(snd)(pop1(k)(m));
    };
  };
};
var alter2 = function (dictOrd) {
  var lookup1 = lookup2(dictOrd);
  var delete1 = $$delete2(dictOrd);
  var insert1 = insert2(dictOrd);
  return function (f) {
    return function (k) {
      return function (m) {
        var v = f(lookup1(k)(m));
        if (v instanceof Nothing) {
          return delete1(k)(m);
        }
        if (v instanceof Just) {
          return insert1(k)(v.value0)(m);
        }
        throw new Error(
          "Failed pattern match at Data.Map.Internal (line 596, column 15 - line 598, column 25): " +
            [v.constructor.name]
        );
      };
    };
  };
};
var unionWith = function (dictOrd) {
  var alter1 = alter2(dictOrd);
  return function (f) {
    return function (m1) {
      return function (m2) {
        var go2 = function (k) {
          return function (m) {
            return function (v) {
              return alter1(
                (function () {
                  var $932 = maybe(v)(f(v));
                  return function ($933) {
                    return Just.create($932($933));
                  };
                })()
              )(k)(m);
            };
          };
        };
        return foldlWithIndex2(go2)(m2)(m1);
      };
    };
  };
};
var union2 = function (dictOrd) {
  return unionWith(dictOrd)($$const);
};

// output/Record.Unsafe.Union/foreign.js
function unsafeUnionFn(r1, r2) {
  var copy = {};
  for (var k1 in r2) {
    if ({}.hasOwnProperty.call(r2, k1)) {
      copy[k1] = r2[k1];
    }
  }
  for (var k2 in r1) {
    if ({}.hasOwnProperty.call(r1, k2)) {
      copy[k2] = r1[k2];
    }
  }
  return copy;
}

// output/Record/index.js
var get = function (dictIsSymbol) {
  var reflectSymbol2 = reflectSymbol(dictIsSymbol);
  return function () {
    return function (l) {
      return function (r) {
        return unsafeGet(reflectSymbol2(l))(r);
      };
    };
  };
};
var disjointUnion = function () {
  return function () {
    return function (l) {
      return function (r) {
        return unsafeUnionFn(l, r);
      };
    };
  };
};

// output/Data.Codec.Argonaut.Record/index.js
var from3 = /* @__PURE__ */ from2(refl);
var rowListCodecNil = {
  rowListCodec: function () {
    return function () {
      return record;
    };
  },
};
var rowListCodec = function (dict) {
  return dict.rowListCodec;
};
var rowListCodecCons = function (dictRowListCodec) {
  var rowListCodec1 = rowListCodec(dictRowListCodec);
  return function () {
    return function () {
      return function (dictIsSymbol) {
        var get3 = get(dictIsSymbol)();
        var recordProp2 = recordProp(dictIsSymbol)();
        return function () {
          return {
            rowListCodec: function () {
              return function (codecs) {
                var tail = rowListCodec1($$Proxy.value)(codecs);
                var codec = from3(get3($$Proxy.value)(codecs));
                return recordProp2($$Proxy.value)(codec)(tail);
              };
            },
          };
        };
      };
    };
  };
};
var record2 = function () {
  return function (dictRowListCodec) {
    return rowListCodec(dictRowListCodec)($$Proxy.value);
  };
};
var record1 = /* @__PURE__ */ record2();
var object2 = function () {
  return function (dictRowListCodec) {
    var record22 = record1(dictRowListCodec);
    return function (name16) {
      return function (rec) {
        return object(name16)(record22(rec));
      };
    };
  };
};

// output/Control.Promise/foreign.js
function promise(f) {
  return function () {
    return new Promise(function (success, error3) {
      var succF = function (s) {
        return function () {
          return success(s);
        };
      };
      var failF = function (s) {
        return function () {
          return error3(s);
        };
      };
      try {
        f(succF)(failF)();
      } catch (e) {
        error3(e);
      }
    });
  };
}
function thenImpl(promise2) {
  return function (errCB) {
    return function (succCB) {
      return function () {
        promise2.then(succCB, errCB);
      };
    };
  };
}

// output/Control.Promise/index.js
var voidRight2 = /* @__PURE__ */ voidRight(functorEffect);
var mempty2 = /* @__PURE__ */ mempty(monoidCanceler);
var identity10 = /* @__PURE__ */ identity(categoryFn);
var alt3 = /* @__PURE__ */ alt(/* @__PURE__ */ altExceptT(semigroupNonEmptyList)(monadIdentity));
var unsafeReadTagged3 = /* @__PURE__ */ unsafeReadTagged(monadIdentity);
var map11 = /* @__PURE__ */ map(/* @__PURE__ */ functorExceptT(functorIdentity));
var readString2 = /* @__PURE__ */ readString(monadIdentity);
var bind3 = /* @__PURE__ */ bind(bindAff);
var liftEffect4 = /* @__PURE__ */ liftEffect(monadEffectAff);
var toAff$prime = function (customCoerce) {
  return function (p) {
    return makeAff(function (cb) {
      return voidRight2(mempty2)(
        thenImpl(p)(function ($14) {
          return cb(Left.create(customCoerce($14)))();
        })(function ($15) {
          return cb(Right.create($15))();
        })
      );
    });
  };
};
var fromAff = function (aff) {
  return promise(function (succ2) {
    return function (err) {
      return runAff_(either(err)(succ2))(aff);
    };
  });
};
var coerce3 = function (fn) {
  return either(function () {
    return error("Promise failed, couldn't extract JS Error or String");
  })(identity10)(runExcept(alt3(unsafeReadTagged3("Error")(fn))(map11(error)(readString2(fn)))));
};
var toAff = /* @__PURE__ */ toAff$prime(coerce3);
var toAffE = function (f) {
  return bind3(liftEffect4(f))(toAff);
};

// output/Data.Codec.Argonaut.Compat/index.js
var pure7 = /* @__PURE__ */ pure(applicativeEither);
var bimap2 = /* @__PURE__ */ bimap(bifunctorEither);
var fromFoldable6 = /* @__PURE__ */ fromFoldable2(foldableArray);
var mapCodec2 = /* @__PURE__ */ mapCodec(bindEither);
var lmap3 = /* @__PURE__ */ lmap(bifunctorEither);
var map13 = /* @__PURE__ */ map(functorEither);
var traverse5 = /* @__PURE__ */ traverse(traversableArray)(applicativeEither);
var toUnfoldable4 = /* @__PURE__ */ toUnfoldable2(unfoldableArray);
var map14 = /* @__PURE__ */ map(functorObject);
var maybe2 = function (codec) {
  var enc = function (v) {
    if (v instanceof Nothing) {
      return jsonNull;
    }
    if (v instanceof Just) {
      return encode2(codec)(v.value0);
    }
    throw new Error(
      "Failed pattern match at Data.Codec.Argonaut.Compat (line 36, column 9 - line 38, column 28): " +
        [v.constructor.name]
    );
  };
  var dec = function (j) {
    if (isNull(j)) {
      return pure7(Nothing.value);
    }
    if (otherwise) {
      return bimap2(Named.create("Maybe"))(Just.create)(decode(codec)(j));
    }
    throw new Error(
      "Failed pattern match at Data.Codec.Argonaut.Compat (line 31, column 3 - line 31, column 50): " +
        [j.constructor.name]
    );
  };
  return basicCodec(dec)(enc);
};
var foreignObject = function (codec) {
  var decodeItem = function (v) {
    return bimap2(AtKey.create(v.value0))(Tuple.create(v.value0))(decode(codec)(v.value1));
  };
  return mapCodec2(
    (function () {
      var $19 = lmap3(Named.create("StrMap"));
      var $20 = map13(fromFoldable6);
      var $21 = traverse5(decodeItem);
      return function ($22) {
        return $19($20($21(toUnfoldable4($22))));
      };
    })()
  )(map14(encode2(codec)))(jobject);
};

// output/Data.String.Read/index.js
var read3 = function (dict) {
  return dict.read;
};

// output/Json.Codec.Common/index.js
var map15 = /* @__PURE__ */ map(functorEffect);
var map16 = /* @__PURE__ */ map(functorAff);
var hasJsonBoolean = {
  jsonCodec: /* @__PURE__ */ $$const($$boolean),
};
var stringLike = function (dictRead) {
  var read9 = read3(dictRead);
  return function (dictShow) {
    var show8 = show(dictShow);
    return function (typeName) {
      return prismaticCodec(typeName)(read9)(show8)(string);
    };
  };
};
var jsonCodec = function (dict) {
  return dict.jsonCodec;
};
var toJs = function (dictHasJson) {
  return encode2(jsonCodec(dictHasJson)($$Proxy.value));
};
var liftEffect5 = function (dictHasJson) {
  var toJs1 = toJs(dictHasJson);
  return function (eff) {
    return map15(toJs1)(eff);
  };
};
var hasJsonArray = function (dictHasJson) {
  return {
    jsonCodec: $$const(array(jsonCodec(dictHasJson)($$Proxy.value))),
  };
};
var hasJsonMaybe = function (dictHasJson) {
  return {
    jsonCodec: $$const(maybe2(jsonCodec(dictHasJson)($$Proxy.value))),
  };
};
var fromJs = function (dictHasJson) {
  var $82 = fromRight($$undefined);
  var $83 = decode(jsonCodec(dictHasJson)($$Proxy.value));
  return function ($84) {
    return $82($83($84));
  };
};
var liftAff1 = function (dictHasJson) {
  var fromJs1 = fromJs(dictHasJson);
  return function (dictHasJson1) {
    var toJs1 = toJs(dictHasJson1);
    return function (f) {
      return function (a) {
        return fromAff(map16(toJs1)(f(fromJs1(a))));
      };
    };
  };
};
var bigInt2 = /* @__PURE__ */ prismaticCodec("BigInt")(fromString2)(toString2)(string);

// output/Cardano.Types/index.js
var assetNameIsSymbol = {
  reflectSymbol: function () {
    return "assetName";
  },
};
var policyIdIsSymbol = {
  reflectSymbol: function () {
    return "policyId";
  },
};
var wrapIso2 = /* @__PURE__ */ wrapIso(
  /* @__PURE__ */ profunctorGCodec(/* @__PURE__ */ functorReaderT(functorEither))(
    /* @__PURE__ */ functorWriterT(functorIdentity)
  )
)();
var add2 = /* @__PURE__ */ add(semiringBigInt);
var bindFlipped5 = /* @__PURE__ */ bindFlipped(bindArray);
var map17 = /* @__PURE__ */ map(functorArray);
var toUnfoldable5 = /* @__PURE__ */ toUnfoldable3(unfoldableArray);
var TokenName = function (x) {
  return x;
};
var StakingAddress = function (x) {
  return x;
};
var ShelleyAddress = function (x) {
  return x;
};
var CurrencySymbol = function (x) {
  return x;
};
var AssetClass = function (x) {
  return x;
};
var Address2 = function (x) {
  return x;
};
var ordTokenName = ordString;
var unionWith2 = /* @__PURE__ */ unionWith(ordTokenName);
var ordCurrencySymbol = ordString;
var unionWith1 = /* @__PURE__ */ unionWith(ordCurrencySymbol);
var ordAssetClass = /* @__PURE__ */ ordRecord()(
  /* @__PURE__ */ ordRecordCons(/* @__PURE__ */ ordRecordCons(ordRecordNil)()(policyIdIsSymbol)(ordCurrencySymbol))()(
    assetNameIsSymbol
  )(ordTokenName)
);
var hasJsonTokenName = {
  jsonCodec: /* @__PURE__ */ $$const(/* @__PURE__ */ wrapIso2(TokenName)(string)),
};
var hasJsonStakingAddress = {
  jsonCodec: /* @__PURE__ */ $$const(/* @__PURE__ */ wrapIso2(StakingAddress)(string)),
};
var hasJsonShelleyAddress = {
  jsonCodec: /* @__PURE__ */ $$const(/* @__PURE__ */ wrapIso2(ShelleyAddress)(string)),
};
var hasJsonCurrencySymbol = {
  jsonCodec: /* @__PURE__ */ $$const(/* @__PURE__ */ wrapIso2(CurrencySymbol)(string)),
};
var hasJsonAddress = {
  jsonCodec: /* @__PURE__ */ $$const(/* @__PURE__ */ wrapIso2(Address2)(string)),
};
var eqCurrencySymbol = eqString;
var semigroupValue = {
  append: function (v) {
    return function (v1) {
      return unionWith1(unionWith2(add2))(v)(v1);
    };
  },
};
var monoidValue = {
  mempty: empty3,
  Semigroup0: function () {
    return semigroupValue;
  },
};
var flatten = function (v) {
  var prepend = function (a) {
    return function (v1) {
      return new Tuple(a, new Tuple(v1.value0, v1.value1));
    };
  };
  return bindFlipped5(function (v1) {
    return map17(prepend(v1.value0))(toUnfoldable5(v1.value1));
  })(toUnfoldable5(v));
};
var assetClassValue = function (cs) {
  return function (tn) {
    return function (n) {
      return singleton8(cs)(singleton8(tn)(n));
    };
  };
};
var assetClassCodec = /* @__PURE__ */ (function () {
  return wrapIso2(AssetClass)(
    object("AssetClass")(
      record2()(
        rowListCodecCons(rowListCodecCons(rowListCodecNil)()()(policyIdIsSymbol)(refl))()()(assetNameIsSymbol)(refl)
      )({
        policyId: jsonCodec(hasJsonCurrencySymbol)($$Proxy.value),
        assetName: jsonCodec(hasJsonTokenName)($$Proxy.value),
      })
    )
  );
})();
var hasJsonAssetClass = {
  jsonCodec: /* @__PURE__ */ $$const(assetClassCodec),
};
var assetClass = function (cs) {
  return function (tn) {
    return {
      policyId: cs,
      assetName: tn,
    };
  };
};
var adaToken = "";
var adaSymbol = "";
var lovelaceValue = /* @__PURE__ */ assetClassValue(adaSymbol)(adaToken);

// output/Data.Char/index.js
var toCharCode2 = /* @__PURE__ */ fromEnum(boundedEnumChar);

// output/Data.CodePoint.Unicode/index.js
var fromEnum3 = /* @__PURE__ */ fromEnum(boundedEnumCodePoint);
var isDecDigit = function (c) {
  var diff3 = (fromEnum3(c) - toCharCode2("0")) | 0;
  return diff3 <= 9 && diff3 >= 0;
};
var isHexDigit = function (c) {
  return (
    isDecDigit(c) ||
    (function () {
      var diff3 = (fromEnum3(c) - toCharCode2("A")) | 0;
      return diff3 <= 5 && diff3 >= 0;
    })() ||
    (function () {
      var diff3 = (fromEnum3(c) - toCharCode2("a")) | 0;
      return diff3 <= 5 && diff3 >= 0;
    })()
  );
};

// output/Data.DateTime.Instant/foreign.js
function toDateTimeImpl(ctor) {
  return function (instant) {
    var dt = new Date(instant);
    return ctor(dt.getUTCFullYear())(dt.getUTCMonth() + 1)(dt.getUTCDate())(dt.getUTCHours())(dt.getUTCMinutes())(
      dt.getUTCSeconds()
    )(dt.getUTCMilliseconds());
  };
}

// output/Data.Date/foreign.js
var createDate = function (y, m, d) {
  var date2 = new Date(Date.UTC(y, m, d));
  if (y >= 0 && y < 100) {
    date2.setUTCFullYear(y);
  }
  return date2;
};
function canonicalDateImpl(ctor, y, m, d) {
  var date2 = createDate(y, m - 1, d);
  return ctor(date2.getUTCFullYear())(date2.getUTCMonth() + 1)(date2.getUTCDate());
}

// output/Data.Date.Component/index.js
var $runtime_lazy4 = function (name16, moduleName, init2) {
  var state3 = 0;
  var val;
  return function (lineNumber) {
    if (state3 === 2) return val;
    if (state3 === 1)
      throw new ReferenceError(
        name16 + " was needed before it finished initializing (module " + moduleName + ", line " + lineNumber + ")",
        moduleName,
        lineNumber
      );
    state3 = 1;
    val = init2();
    state3 = 2;
    return val;
  };
};
var January = /* @__PURE__ */ (function () {
  function January2() {}
  January2.value = new January2();
  return January2;
})();
var February = /* @__PURE__ */ (function () {
  function February2() {}
  February2.value = new February2();
  return February2;
})();
var March = /* @__PURE__ */ (function () {
  function March2() {}
  March2.value = new March2();
  return March2;
})();
var April = /* @__PURE__ */ (function () {
  function April2() {}
  April2.value = new April2();
  return April2;
})();
var May = /* @__PURE__ */ (function () {
  function May2() {}
  May2.value = new May2();
  return May2;
})();
var June = /* @__PURE__ */ (function () {
  function June2() {}
  June2.value = new June2();
  return June2;
})();
var July = /* @__PURE__ */ (function () {
  function July2() {}
  July2.value = new July2();
  return July2;
})();
var August = /* @__PURE__ */ (function () {
  function August2() {}
  August2.value = new August2();
  return August2;
})();
var September = /* @__PURE__ */ (function () {
  function September2() {}
  September2.value = new September2();
  return September2;
})();
var October = /* @__PURE__ */ (function () {
  function October2() {}
  October2.value = new October2();
  return October2;
})();
var November = /* @__PURE__ */ (function () {
  function November2() {}
  November2.value = new November2();
  return November2;
})();
var December = /* @__PURE__ */ (function () {
  function December2() {}
  December2.value = new December2();
  return December2;
})();
var eqMonth = {
  eq: function (x) {
    return function (y) {
      if (x instanceof January && y instanceof January) {
        return true;
      }
      if (x instanceof February && y instanceof February) {
        return true;
      }
      if (x instanceof March && y instanceof March) {
        return true;
      }
      if (x instanceof April && y instanceof April) {
        return true;
      }
      if (x instanceof May && y instanceof May) {
        return true;
      }
      if (x instanceof June && y instanceof June) {
        return true;
      }
      if (x instanceof July && y instanceof July) {
        return true;
      }
      if (x instanceof August && y instanceof August) {
        return true;
      }
      if (x instanceof September && y instanceof September) {
        return true;
      }
      if (x instanceof October && y instanceof October) {
        return true;
      }
      if (x instanceof November && y instanceof November) {
        return true;
      }
      if (x instanceof December && y instanceof December) {
        return true;
      }
      return false;
    };
  },
};
var ordMonth = {
  compare: function (x) {
    return function (y) {
      if (x instanceof January && y instanceof January) {
        return EQ.value;
      }
      if (x instanceof January) {
        return LT.value;
      }
      if (y instanceof January) {
        return GT.value;
      }
      if (x instanceof February && y instanceof February) {
        return EQ.value;
      }
      if (x instanceof February) {
        return LT.value;
      }
      if (y instanceof February) {
        return GT.value;
      }
      if (x instanceof March && y instanceof March) {
        return EQ.value;
      }
      if (x instanceof March) {
        return LT.value;
      }
      if (y instanceof March) {
        return GT.value;
      }
      if (x instanceof April && y instanceof April) {
        return EQ.value;
      }
      if (x instanceof April) {
        return LT.value;
      }
      if (y instanceof April) {
        return GT.value;
      }
      if (x instanceof May && y instanceof May) {
        return EQ.value;
      }
      if (x instanceof May) {
        return LT.value;
      }
      if (y instanceof May) {
        return GT.value;
      }
      if (x instanceof June && y instanceof June) {
        return EQ.value;
      }
      if (x instanceof June) {
        return LT.value;
      }
      if (y instanceof June) {
        return GT.value;
      }
      if (x instanceof July && y instanceof July) {
        return EQ.value;
      }
      if (x instanceof July) {
        return LT.value;
      }
      if (y instanceof July) {
        return GT.value;
      }
      if (x instanceof August && y instanceof August) {
        return EQ.value;
      }
      if (x instanceof August) {
        return LT.value;
      }
      if (y instanceof August) {
        return GT.value;
      }
      if (x instanceof September && y instanceof September) {
        return EQ.value;
      }
      if (x instanceof September) {
        return LT.value;
      }
      if (y instanceof September) {
        return GT.value;
      }
      if (x instanceof October && y instanceof October) {
        return EQ.value;
      }
      if (x instanceof October) {
        return LT.value;
      }
      if (y instanceof October) {
        return GT.value;
      }
      if (x instanceof November && y instanceof November) {
        return EQ.value;
      }
      if (x instanceof November) {
        return LT.value;
      }
      if (y instanceof November) {
        return GT.value;
      }
      if (x instanceof December && y instanceof December) {
        return EQ.value;
      }
      throw new Error(
        "Failed pattern match at Data.Date.Component (line 0, column 0 - line 0, column 0): " +
          [x.constructor.name, y.constructor.name]
      );
    };
  },
  Eq0: function () {
    return eqMonth;
  },
};
var boundedMonth = /* @__PURE__ */ (function () {
  return {
    bottom: January.value,
    top: December.value,
    Ord0: function () {
      return ordMonth;
    },
  };
})();
var boundedEnumMonth = {
  cardinality: 12,
  toEnum: function (v) {
    if (v === 1) {
      return new Just(January.value);
    }
    if (v === 2) {
      return new Just(February.value);
    }
    if (v === 3) {
      return new Just(March.value);
    }
    if (v === 4) {
      return new Just(April.value);
    }
    if (v === 5) {
      return new Just(May.value);
    }
    if (v === 6) {
      return new Just(June.value);
    }
    if (v === 7) {
      return new Just(July.value);
    }
    if (v === 8) {
      return new Just(August.value);
    }
    if (v === 9) {
      return new Just(September.value);
    }
    if (v === 10) {
      return new Just(October.value);
    }
    if (v === 11) {
      return new Just(November.value);
    }
    if (v === 12) {
      return new Just(December.value);
    }
    return Nothing.value;
  },
  fromEnum: function (v) {
    if (v instanceof January) {
      return 1;
    }
    if (v instanceof February) {
      return 2;
    }
    if (v instanceof March) {
      return 3;
    }
    if (v instanceof April) {
      return 4;
    }
    if (v instanceof May) {
      return 5;
    }
    if (v instanceof June) {
      return 6;
    }
    if (v instanceof July) {
      return 7;
    }
    if (v instanceof August) {
      return 8;
    }
    if (v instanceof September) {
      return 9;
    }
    if (v instanceof October) {
      return 10;
    }
    if (v instanceof November) {
      return 11;
    }
    if (v instanceof December) {
      return 12;
    }
    throw new Error(
      "Failed pattern match at Data.Date.Component (line 87, column 14 - line 99, column 19): " + [v.constructor.name]
    );
  },
  Bounded0: function () {
    return boundedMonth;
  },
  Enum1: function () {
    return $lazy_enumMonth(0);
  },
};
var $lazy_enumMonth = /* @__PURE__ */ $runtime_lazy4("enumMonth", "Data.Date.Component", function () {
  return {
    succ: (function () {
      var $67 = toEnum(boundedEnumMonth);
      var $68 = fromEnum(boundedEnumMonth);
      return function ($69) {
        return $67(
          (function (v) {
            return (v + 1) | 0;
          })($68($69))
        );
      };
    })(),
    pred: (function () {
      var $70 = toEnum(boundedEnumMonth);
      var $71 = fromEnum(boundedEnumMonth);
      return function ($72) {
        return $70(
          (function (v) {
            return (v - 1) | 0;
          })($71($72))
        );
      };
    })(),
    Ord0: function () {
      return ordMonth;
    },
  };
});

// output/Data.Date/index.js
var fromEnum4 = /* @__PURE__ */ fromEnum(boundedEnumMonth);
var fromJust7 = /* @__PURE__ */ fromJust();
var toEnum2 = /* @__PURE__ */ toEnum(boundedEnumMonth);
var $$Date = /* @__PURE__ */ (function () {
  function $$Date2(value0, value1, value22) {
    this.value0 = value0;
    this.value1 = value1;
    this.value2 = value22;
  }
  $$Date2.create = function (value0) {
    return function (value1) {
      return function (value22) {
        return new $$Date2(value0, value1, value22);
      };
    };
  };
  return $$Date2;
})();
var canonicalDate = function (y) {
  return function (m) {
    return function (d) {
      var mkDate = function (y$prime) {
        return function (m$prime) {
          return function (d$prime) {
            return new $$Date(y$prime, fromJust7(toEnum2(m$prime)), d$prime);
          };
        };
      };
      return canonicalDateImpl(mkDate, y, fromEnum4(m), d);
    };
  };
};

// output/Data.Time.Component/index.js
var $runtime_lazy5 = function (name16, moduleName, init2) {
  var state3 = 0;
  var val;
  return function (lineNumber) {
    if (state3 === 2) return val;
    if (state3 === 1)
      throw new ReferenceError(
        name16 + " was needed before it finished initializing (module " + moduleName + ", line " + lineNumber + ")",
        moduleName,
        lineNumber
      );
    state3 = 1;
    val = init2();
    state3 = 2;
    return val;
  };
};
var ordSecond = ordInt;
var ordMinute = ordInt;
var ordMillisecond = ordInt;
var ordHour = ordInt;
var boundedSecond = {
  bottom: 0,
  top: 59,
  Ord0: function () {
    return ordSecond;
  },
};
var boundedMinute = {
  bottom: 0,
  top: 59,
  Ord0: function () {
    return ordMinute;
  },
};
var boundedMillisecond = {
  bottom: 0,
  top: 999,
  Ord0: function () {
    return ordMillisecond;
  },
};
var boundedHour = {
  bottom: 0,
  top: 23,
  Ord0: function () {
    return ordHour;
  },
};
var boundedEnumSecond = {
  cardinality: 60,
  toEnum: function (n) {
    if (n >= 0 && n <= 59) {
      return new Just(n);
    }
    if (otherwise) {
      return Nothing.value;
    }
    throw new Error(
      "Failed pattern match at Data.Time.Component (line 90, column 1 - line 95, column 26): " + [n.constructor.name]
    );
  },
  fromEnum: function (v) {
    return v;
  },
  Bounded0: function () {
    return boundedSecond;
  },
  Enum1: function () {
    return $lazy_enumSecond(0);
  },
};
var $lazy_enumSecond = /* @__PURE__ */ $runtime_lazy5("enumSecond", "Data.Time.Component", function () {
  return {
    succ: (function () {
      var $36 = toEnum(boundedEnumSecond);
      var $37 = fromEnum(boundedEnumSecond);
      return function ($38) {
        return $36(
          (function (v) {
            return (v + 1) | 0;
          })($37($38))
        );
      };
    })(),
    pred: (function () {
      var $39 = toEnum(boundedEnumSecond);
      var $40 = fromEnum(boundedEnumSecond);
      return function ($41) {
        return $39(
          (function (v) {
            return (v - 1) | 0;
          })($40($41))
        );
      };
    })(),
    Ord0: function () {
      return ordSecond;
    },
  };
});
var boundedEnumMinute = {
  cardinality: 60,
  toEnum: function (n) {
    if (n >= 0 && n <= 59) {
      return new Just(n);
    }
    if (otherwise) {
      return Nothing.value;
    }
    throw new Error(
      "Failed pattern match at Data.Time.Component (line 61, column 1 - line 66, column 26): " + [n.constructor.name]
    );
  },
  fromEnum: function (v) {
    return v;
  },
  Bounded0: function () {
    return boundedMinute;
  },
  Enum1: function () {
    return $lazy_enumMinute(0);
  },
};
var $lazy_enumMinute = /* @__PURE__ */ $runtime_lazy5("enumMinute", "Data.Time.Component", function () {
  return {
    succ: (function () {
      var $42 = toEnum(boundedEnumMinute);
      var $43 = fromEnum(boundedEnumMinute);
      return function ($44) {
        return $42(
          (function (v) {
            return (v + 1) | 0;
          })($43($44))
        );
      };
    })(),
    pred: (function () {
      var $45 = toEnum(boundedEnumMinute);
      var $46 = fromEnum(boundedEnumMinute);
      return function ($47) {
        return $45(
          (function (v) {
            return (v - 1) | 0;
          })($46($47))
        );
      };
    })(),
    Ord0: function () {
      return ordMinute;
    },
  };
});
var boundedEnumMillisecond = {
  cardinality: 1e3,
  toEnum: function (n) {
    if (n >= 0 && n <= 999) {
      return new Just(n);
    }
    if (otherwise) {
      return Nothing.value;
    }
    throw new Error(
      "Failed pattern match at Data.Time.Component (line 120, column 1 - line 125, column 31): " + [n.constructor.name]
    );
  },
  fromEnum: function (v) {
    return v;
  },
  Bounded0: function () {
    return boundedMillisecond;
  },
  Enum1: function () {
    return $lazy_enumMillisecond(0);
  },
};
var $lazy_enumMillisecond = /* @__PURE__ */ $runtime_lazy5("enumMillisecond", "Data.Time.Component", function () {
  return {
    succ: (function () {
      var $48 = toEnum(boundedEnumMillisecond);
      var $49 = fromEnum(boundedEnumMillisecond);
      return function ($50) {
        return $48(
          (function (v) {
            return (v + 1) | 0;
          })($49($50))
        );
      };
    })(),
    pred: (function () {
      var $51 = toEnum(boundedEnumMillisecond);
      var $52 = fromEnum(boundedEnumMillisecond);
      return function ($53) {
        return $51(
          (function (v) {
            return (v - 1) | 0;
          })($52($53))
        );
      };
    })(),
    Ord0: function () {
      return ordMillisecond;
    },
  };
});
var boundedEnumHour = {
  cardinality: 24,
  toEnum: function (n) {
    if (n >= 0 && n <= 23) {
      return new Just(n);
    }
    if (otherwise) {
      return Nothing.value;
    }
    throw new Error(
      "Failed pattern match at Data.Time.Component (line 32, column 1 - line 37, column 24): " + [n.constructor.name]
    );
  },
  fromEnum: function (v) {
    return v;
  },
  Bounded0: function () {
    return boundedHour;
  },
  Enum1: function () {
    return $lazy_enumHour(0);
  },
};
var $lazy_enumHour = /* @__PURE__ */ $runtime_lazy5("enumHour", "Data.Time.Component", function () {
  return {
    succ: (function () {
      var $54 = toEnum(boundedEnumHour);
      var $55 = fromEnum(boundedEnumHour);
      return function ($56) {
        return $54(
          (function (v) {
            return (v + 1) | 0;
          })($55($56))
        );
      };
    })(),
    pred: (function () {
      var $57 = toEnum(boundedEnumHour);
      var $58 = fromEnum(boundedEnumHour);
      return function ($59) {
        return $57(
          (function (v) {
            return (v - 1) | 0;
          })($58($59))
        );
      };
    })(),
    Ord0: function () {
      return ordHour;
    },
  };
});

// output/Data.Time/index.js
var fromEnum5 = /* @__PURE__ */ fromEnum(boundedEnumHour);
var fromEnum1 = /* @__PURE__ */ fromEnum(boundedEnumMinute);
var fromEnum22 = /* @__PURE__ */ fromEnum(boundedEnumSecond);
var fromEnum32 = /* @__PURE__ */ fromEnum(boundedEnumMillisecond);
var append12 = /* @__PURE__ */ append(semigroupMilliseconds);
var negateDuration2 = /* @__PURE__ */ negateDuration(durationMilliseconds);
var Time = /* @__PURE__ */ (function () {
  function Time2(value0, value1, value22, value32) {
    this.value0 = value0;
    this.value1 = value1;
    this.value2 = value22;
    this.value3 = value32;
  }
  Time2.create = function (value0) {
    return function (value1) {
      return function (value22) {
        return function (value32) {
          return new Time2(value0, value1, value22, value32);
        };
      };
    };
  };
  return Time2;
})();
var second = function (v) {
  return v.value2;
};
var minute = function (v) {
  return v.value1;
};
var millisecond = function (v) {
  return v.value3;
};
var hour = function (v) {
  return v.value0;
};
var timeToMillis = function (t) {
  return (
    36e5 * toNumber(fromEnum5(hour(t))) +
    6e4 * toNumber(fromEnum1(minute(t))) +
    1e3 * toNumber(fromEnum22(second(t))) +
    toNumber(fromEnum32(millisecond(t)))
  );
};
var diff = function (dictDuration) {
  var toDuration3 = toDuration(dictDuration);
  return function (t1) {
    return function (t2) {
      return toDuration3(append12(timeToMillis(t1))(negateDuration2(timeToMillis(t2))));
    };
  };
};

// output/Data.DateTime/index.js
var DateTime = /* @__PURE__ */ (function () {
  function DateTime2(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }
  DateTime2.create = function (value0) {
    return function (value1) {
      return new DateTime2(value0, value1);
    };
  };
  return DateTime2;
})();
var time2 = function (v) {
  return v.value1;
};

// output/Data.DateTime.Instant/index.js
var fromJust8 = /* @__PURE__ */ fromJust();
var toEnum3 = /* @__PURE__ */ toEnum(boundedEnumMonth);
var unInstant = function (v) {
  return v;
};
var toDateTime = /* @__PURE__ */ (function () {
  var mkDateTime = function (y) {
    return function (mo) {
      return function (d) {
        return function (h) {
          return function (mi) {
            return function (s) {
              return function (ms) {
                return new DateTime(canonicalDate(y)(fromJust8(toEnum3(mo)))(d), new Time(h, mi, s, ms));
              };
            };
          };
        };
      };
    };
  };
  return toDateTimeImpl(mkDateTime);
})();

// output/Data.String.Utils/foreign.js
function startsWithImpl(searchString, s) {
  return s.startsWith(searchString);
}

// output/Data.String.Utils/index.js
var startsWith = function (searchString) {
  return function (s) {
    return startsWithImpl(searchString, s);
  };
};

// output/Effect.Now/foreign.js
function now() {
  return Date.now();
}

// output/Effect.Now/index.js
var map18 = /* @__PURE__ */ map(functorEffect);
var nowTime = /* @__PURE__ */ map18(function ($2) {
  return time2(toDateTime($2));
})(now);

// output/Affjax.StatusCode/index.js
var show3 = /* @__PURE__ */ show(showInt);
var showStatusCode = {
  show: function (v) {
    return "(StatusCode " + (show3(v) + ")");
  },
};
var eqStatusCode = {
  eq: function (x) {
    return function (y) {
      return x === y;
    };
  },
};

// output/Affjax.Web/foreign.js
var driver = {
  newXHR: function () {
    return new XMLHttpRequest();
  },
  fixupUrl: function (url) {
    return url || "/";
  },
};

// output/Affjax.Web/index.js
var request2 = /* @__PURE__ */ request(driver);

// output/Effect.AVar/foreign.js
var AVar = (function () {
  function MutableQueue() {
    this.head = null;
    this.last = null;
    this.size = 0;
  }
  function MutableCell(queue, value14) {
    this.queue = queue;
    this.value = value14;
    this.next = null;
    this.prev = null;
  }
  function AVar2(value14) {
    this.draining = false;
    this.error = null;
    this.value = value14;
    this.takes = new MutableQueue();
    this.reads = new MutableQueue();
    this.puts = new MutableQueue();
  }
  var EMPTY = {};
  function runEff(eff) {
    try {
      eff();
    } catch (error3) {
      setTimeout(function () {
        throw error3;
      }, 0);
    }
  }
  function putLast(queue, value14) {
    var cell = new MutableCell(queue, value14);
    switch (queue.size) {
      case 0:
        queue.head = cell;
        break;
      case 1:
        cell.prev = queue.head;
        queue.head.next = cell;
        queue.last = cell;
        break;
      default:
        cell.prev = queue.last;
        queue.last.next = cell;
        queue.last = cell;
    }
    queue.size++;
    return cell;
  }
  function takeLast(queue) {
    var cell;
    switch (queue.size) {
      case 0:
        return null;
      case 1:
        cell = queue.head;
        queue.head = null;
        break;
      case 2:
        cell = queue.last;
        queue.head.next = null;
        queue.last = null;
        break;
      default:
        cell = queue.last;
        queue.last = cell.prev;
        queue.last.next = null;
    }
    cell.prev = null;
    cell.queue = null;
    queue.size--;
    return cell.value;
  }
  function takeHead(queue) {
    var cell;
    switch (queue.size) {
      case 0:
        return null;
      case 1:
        cell = queue.head;
        queue.head = null;
        break;
      case 2:
        cell = queue.head;
        queue.last.prev = null;
        queue.head = queue.last;
        queue.last = null;
        break;
      default:
        cell = queue.head;
        queue.head = cell.next;
        queue.head.prev = null;
    }
    cell.next = null;
    cell.queue = null;
    queue.size--;
    return cell.value;
  }
  function deleteCell2(cell) {
    if (cell.queue === null) {
      return;
    }
    if (cell.queue.last === cell) {
      takeLast(cell.queue);
      return;
    }
    if (cell.queue.head === cell) {
      takeHead(cell.queue);
      return;
    }
    if (cell.prev) {
      cell.prev.next = cell.next;
    }
    if (cell.next) {
      cell.next.prev = cell.prev;
    }
    cell.queue.size--;
    cell.queue = null;
    cell.value = null;
    cell.next = null;
    cell.prev = null;
  }
  function drainVar(util, avar) {
    if (avar.draining) {
      return;
    }
    var ps = avar.puts;
    var ts = avar.takes;
    var rs = avar.reads;
    var p, r, t, value14, rsize;
    avar.draining = true;
    while (1) {
      p = null;
      r = null;
      t = null;
      value14 = avar.value;
      rsize = rs.size;
      if (avar.error !== null) {
        value14 = util.left(avar.error);
        while ((p = takeHead(ps))) {
          runEff(p.cb(value14));
        }
        while ((r = takeHead(rs))) {
          runEff(r(value14));
        }
        while ((t = takeHead(ts))) {
          runEff(t(value14));
        }
        break;
      }
      if (value14 === EMPTY && (p = takeHead(ps))) {
        avar.value = value14 = p.value;
      }
      if (value14 !== EMPTY) {
        t = takeHead(ts);
        while (rsize-- && (r = takeHead(rs))) {
          runEff(r(util.right(value14)));
        }
        if (t !== null) {
          avar.value = EMPTY;
          runEff(t(util.right(value14)));
        }
      }
      if (p !== null) {
        runEff(p.cb(util.right(void 0)));
      }
      if ((avar.value === EMPTY && ps.size === 0) || (avar.value !== EMPTY && ts.size === 0)) {
        break;
      }
    }
    avar.draining = false;
  }
  AVar2.EMPTY = EMPTY;
  AVar2.putLast = putLast;
  AVar2.takeLast = takeLast;
  AVar2.takeHead = takeHead;
  AVar2.deleteCell = deleteCell2;
  AVar2.drainVar = drainVar;
  return AVar2;
})();
function empty4() {
  return new AVar(AVar.EMPTY);
}
function _newVar(value14) {
  return function () {
    return new AVar(value14);
  };
}
function _putVar(util, value14, avar, cb) {
  return function () {
    var cell = AVar.putLast(avar.puts, { cb, value: value14 });
    AVar.drainVar(util, avar);
    return function () {
      AVar.deleteCell(cell);
    };
  };
}
function _takeVar(util, avar, cb) {
  return function () {
    var cell = AVar.putLast(avar.takes, cb);
    AVar.drainVar(util, avar);
    return function () {
      AVar.deleteCell(cell);
    };
  };
}
function _readVar(util, avar, cb) {
  return function () {
    var cell = AVar.putLast(avar.reads, cb);
    AVar.drainVar(util, avar);
    return function () {
      AVar.deleteCell(cell);
    };
  };
}
function _tryPutVar(util, value14, avar) {
  return function () {
    if (avar.value === AVar.EMPTY && avar.error === null) {
      avar.value = value14;
      AVar.drainVar(util, avar);
      return true;
    } else {
      return false;
    }
  };
}
function _tryReadVar(util, avar) {
  return function () {
    if (avar.value === AVar.EMPTY) {
      return util.nothing;
    } else {
      return util.just(avar.value);
    }
  };
}

// output/Effect.AVar/index.js
var Killed = /* @__PURE__ */ (function () {
  function Killed2(value0) {
    this.value0 = value0;
  }
  Killed2.create = function (value0) {
    return new Killed2(value0);
  };
  return Killed2;
})();
var Filled = /* @__PURE__ */ (function () {
  function Filled2(value0) {
    this.value0 = value0;
  }
  Filled2.create = function (value0) {
    return new Filled2(value0);
  };
  return Filled2;
})();
var Empty = /* @__PURE__ */ (function () {
  function Empty3() {}
  Empty3.value = new Empty3();
  return Empty3;
})();
var $$new2 = _newVar;
var ffiUtil2 = /* @__PURE__ */ (function () {
  return {
    left: Left.create,
    right: Right.create,
    nothing: Nothing.value,
    just: Just.create,
    killed: Killed.create,
    filled: Filled.create,
    empty: Empty.value,
  };
})();
var put2 = function (value14) {
  return function (avar) {
    return function (cb) {
      return _putVar(ffiUtil2, value14, avar, cb);
    };
  };
};
var read4 = function (avar) {
  return function (cb) {
    return _readVar(ffiUtil2, avar, cb);
  };
};
var take4 = function (avar) {
  return function (cb) {
    return _takeVar(ffiUtil2, avar, cb);
  };
};
var tryPut = function (value14) {
  return function (avar) {
    return _tryPutVar(ffiUtil2, value14, avar);
  };
};
var tryRead = function (avar) {
  return _tryReadVar(ffiUtil2, avar);
};

// output/Effect.Aff.AVar/index.js
var liftEffect6 = /* @__PURE__ */ liftEffect(monadEffectAff);
var tryRead2 = function ($5) {
  return liftEffect6(tryRead($5));
};
var take5 = function (avar) {
  return makeAff(function (k) {
    return function __do2() {
      var c = take4(avar)(k)();
      return effectCanceler(c);
    };
  });
};
var read5 = function (avar) {
  return makeAff(function (k) {
    return function __do2() {
      var c = read4(avar)(k)();
      return effectCanceler(c);
    };
  });
};
var put3 = function (value14) {
  return function (avar) {
    return makeAff(function (k) {
      return function __do2() {
        var c = put2(value14)(avar)(k)();
        return effectCanceler(c);
      };
    });
  };
};
var $$new3 = function ($9) {
  return liftEffect6($$new2($9));
};
var empty5 = /* @__PURE__ */ liftEffect6(empty4);

// output/Concurrent.Queue/index.js
var bind4 = /* @__PURE__ */ bind(bindAff);
var discard2 = /* @__PURE__ */ discard(discardUnit)(bindAff);
var pure8 = /* @__PURE__ */ pure(applicativeAff);
var QItem = /* @__PURE__ */ (function () {
  function QItem2(value0, value1) {
    this.value0 = value0;
    this.value1 = value1;
  }
  QItem2.create = function (value0) {
    return function (value1) {
      return new QItem2(value0, value1);
    };
  };
  return QItem2;
})();
var write3 = function (v) {
  return function (a) {
    return bind4(empty5)(function (newHole) {
      return bind4(take5(v.writeEnd))(function (oldHole) {
        return discard2(put3(new QItem(a, newHole))(oldHole))(function () {
          return put3(newHole)(v.writeEnd);
        });
      });
    });
  };
};
var tryRead3 = function (v) {
  return bind4(take5(v.readEnd))(function (readEnd) {
    return bind4(tryRead2(readEnd))(function (v1) {
      if (v1 instanceof Just) {
        return discard2(put3(v1.value0.value1)(v.readEnd))(function () {
          return pure8(new Just(v1.value0.value0));
        });
      }
      if (v1 instanceof Nothing) {
        return discard2(put3(readEnd)(v.readEnd))(function () {
          return pure8(Nothing.value);
        });
      }
      throw new Error(
        "Failed pattern match at Concurrent.Queue (line 59, column 28 - line 65, column 19): " + [v1.constructor.name]
      );
    });
  });
};
var read6 = function (v) {
  return bind4(take5(v.readEnd))(function (readEnd) {
    return bind4(read5(readEnd))(function (v1) {
      return discard2(put3(v1.value1)(v.readEnd))(function () {
        return pure8(v1.value0);
      });
    });
  });
};
var $$new4 = /* @__PURE__ */ bind4(empty5)(function (hole) {
  return bind4($$new3(hole))(function (readEnd) {
    return bind4($$new3(hole))(function (writeEnd) {
      return pure8({
        readEnd,
        writeEnd,
      });
    });
  });
});

// output/Data.Interpolate/index.js
var interpString = {
  interp: function (a) {
    return a;
  },
};
var interp = function (dict) {
  return dict.interp;
};
var interpStringFunction = function (dictInterp) {
  var interp1 = interp(dictInterp);
  return {
    interp: function (a) {
      return function (b) {
        return interp1(a + b);
      };
    },
  };
};
var i = function (dictInterp) {
  return interp(dictInterp)("");
};

// output/Equine.Const/index.js
var map19 = /* @__PURE__ */ map(functorArray);
var skinPolicies = /* @__PURE__ */ map19(CurrencySymbol)(["22d843e9e8b39d105c645944cc848abee2673d64e60f1f022791ec85"]);
var jockeyPolicies = /* @__PURE__ */ map19(CurrencySymbol)([
  "23a000a839f04bb71b59b4ae74bf8cf5ed21ee0be520690e665ae4b4",
]);
var horsePolicies = /* @__PURE__ */ map19(CurrencySymbol)(["30ed3d95db1d6bb2c12fc5228a2986eab4553f192a12a4607780e15b"]);
var handlePolicy = "f0ff48bbb7bbe9d59a40f1ce90e9e9d0ff5002ec48f232b49ca0fb9a";
var blockfrostUrl = function (method2) {
  return "https://cardano-mainnet.blockfrost.io/api/v0/" + method2;
};
var blockfrostProjectId = "mainnet3nz4o1FVWl5TSIzpe9jm287nQZAsCZbt";

// output/Equine.Blockfrost.Api/index.js
var unwrap6 = /* @__PURE__ */ unwrap();
var bind5 = /* @__PURE__ */ bind(bindAff);
var map20 = /* @__PURE__ */ map(functorAff);
var sequence2 = /* @__PURE__ */ sequence(traversableArray)(applicativeAff);
var replicate3 = /* @__PURE__ */ replicate2(unfoldableArray);
var liftEffect7 = /* @__PURE__ */ liftEffect(monadEffectAff);
var eq4 = /* @__PURE__ */ eq(eqStatusCode);
var show4 = /* @__PURE__ */ show(showStatusCode);
var discard3 = /* @__PURE__ */ discard(discardUnit);
var discard1 = /* @__PURE__ */ discard3(bindAff);
var sequential2 = /* @__PURE__ */ sequential(parallelAff);
var foldMap2 = /* @__PURE__ */ foldMap(foldableArray)(/* @__PURE__ */ monoidParAff(monoidUnit));
var parallel2 = /* @__PURE__ */ parallel(parallelAff);
var map110 = /* @__PURE__ */ map(functorArray);
var append13 = /* @__PURE__ */ append(semigroupSeconds);
var negateDuration3 = /* @__PURE__ */ negateDuration(durationSeconds);
var diff2 = /* @__PURE__ */ diff(durationSeconds);
var when2 = /* @__PURE__ */ when(applicativeAff);
var greaterThan2 = /* @__PURE__ */ greaterThan(ordSeconds);
var mempty3 = /* @__PURE__ */ mempty(monoidSeconds);
var fromDuration2 = /* @__PURE__ */ fromDuration(durationSeconds);
var $$void4 = /* @__PURE__ */ $$void(functorAff);
var map22 = /* @__PURE__ */ map(functorEffect);
var bind22 = /* @__PURE__ */ bind(/* @__PURE__ */ bindExceptT(monadAff));
var show13 = /* @__PURE__ */ show(showInt);
var map32 = /* @__PURE__ */ map(/* @__PURE__ */ functorExceptT(functorAff));
var append22 = /* @__PURE__ */ append(semigroupArray);
var pure13 = /* @__PURE__ */ pure(/* @__PURE__ */ applicativeExceptT(monadAff));
var interpStringFunction2 = /* @__PURE__ */ interpStringFunction(/* @__PURE__ */ interpStringFunction(interpString));
var i2 = /* @__PURE__ */ i(interpStringFunction2);
var i1 = /* @__PURE__ */ i(/* @__PURE__ */ interpStringFunction(interpStringFunction2));
var toAssetId = function (v) {
  return unwrap6(v.policyId) + unwrap6(v.assetName);
};
var handler = function (queue) {
  return bind5(read6(queue))(function (elem2) {
    return bind5(map20(catMaybes)(sequence2(replicate3(9)(tryRead3(queue)))))(function (elems$prime) {
      var runReq = function (v) {
        return bind5(request2(v.value0))(function (eResponse) {
          if (eResponse instanceof Left) {
            return liftEffect7(v.value1(new Left(eResponse.value0)));
          }
          if (eResponse instanceof Right) {
            if (eq4(eResponse.value0.status)(200)) {
              return liftEffect7(v.value1(new Right(eResponse.value0.body)));
            }
            if (otherwise) {
              return liftEffect7(
                v.value1(
                  new Left(
                    new RequestContentError(
                      "ERROR: Status: " + (show4(eResponse.value0.status) + (", body:\n" + eResponse.value0.statusText))
                    )
                  )
                )
              );
            }
          }
          throw new Error(
            "Failed pattern match at Equine.Blockfrost.Api (line 120, column 7 - line 129, column 39): " +
              [eResponse.constructor.name]
          );
        });
      };
      var elems = cons(elem2)(elems$prime);
      return bind5(liftEffect7(nowTime))(function (start2) {
        return discard1(sequential2(foldMap2(parallel2)(map110(runReq)(elems))))(function () {
          return bind5(liftEffect7(nowTime))(function (finish) {
            var expectedDelay = 0.1 * toNumber(length(elems));
            var realDelay = append13(expectedDelay)(negateDuration3(diff2(finish)(start2)));
            return discard1(when2(greaterThan2(realDelay)(mempty3))(delay(fromDuration2(realDelay))))(function () {
              return handler(queue);
            });
          });
        });
      });
    });
  });
};
var requestQueuePointer = /* @__PURE__ */ unsafePerformEffect(function __do() {
  var queueHolder = empty4();
  launchAff_(
    bind5($$new4)(function (v) {
      return discard1($$void4(liftEffect7(tryPut(v)(queueHolder))))(function () {
        return handler(v);
      });
    })
  )();
  return queueHolder;
});
var request$prime = function (req) {
  return makeAff(function (resolve) {
    return function __do2() {
      var requestQueue = map22(getJust)(tryRead(requestQueuePointer))();
      launchAff_(
        write3(requestQueue)(
          new Tuple(req, function ($61) {
            return resolve(Right.create($61));
          })
        )
      )();
      return nonCanceler;
    };
  });
};
var getBlockfrostSingle = function (method2) {
  return request$prime({
    method: defaultRequest.method,
    url: blockfrostUrl(method2),
    headers: [new RequestHeader("project_id", blockfrostProjectId)],
    content: defaultRequest.content,
    username: defaultRequest.username,
    password: defaultRequest.password,
    withCredentials: defaultRequest.withCredentials,
    responseFormat: json,
    timeout: defaultRequest.timeout,
  });
};
var getBlockfrostPaginated = function (method2) {
  var getNextPage = function (i22) {
    return bind22(getBlockfrostSingle(method2 + ("?page=" + show13(i22))))(function (res) {
      var arr = fromMaybe([])(toArray(res));
      var $60 = !$$null2(arr);
      if ($60) {
        return map32(function (v) {
          return append22(arr)(v);
        })(getNextPage((i22 + 1) | 0));
      }
      return pure13(arr);
    });
  };
  return getNextPage(1);
};
var realBlockfrostApi = {
  accountInfo: function (addr) {
    return getBlockfrostSingle(i2("accounts/")(unwrap6(addr)));
  },
  accountAssets: function (addr) {
    return getBlockfrostPaginated(i1("accounts/")(unwrap6(addr))("/addresses/assets"));
  },
  accountAddresses: function (addr) {
    return getBlockfrostPaginated(i1("accounts/")(unwrap6(addr))("/addresses"));
  },
  tip: /* @__PURE__ */ getBlockfrostSingle("blocks/latest"),
  assetAddressList: function (asset) {
    return getBlockfrostPaginated(i1("assets/")(toAssetId(asset))("/addresses"));
  },
  assetInfo: function (asset) {
    return getBlockfrostSingle(i2("assets/")(toAssetId(asset)));
  },
};

// output/Equine.Blockfrost.Types/index.js
var bindFlipped6 = /* @__PURE__ */ bindFlipped(/* @__PURE__ */ bindExceptT(monadAff));
var traverse6 = /* @__PURE__ */ traverse(traversableArray)(/* @__PURE__ */ applicativeExceptT(monadAff));
var except2 = /* @__PURE__ */ except(applicativeAff);
var liftEither2 = /* @__PURE__ */ liftEither(/* @__PURE__ */ monadThrowExceptT(monadAff));
var map21 = /* @__PURE__ */ map(functorAff);
var sequential3 = /* @__PURE__ */ sequential(parallelAff);
var parallel3 = /* @__PURE__ */ parallel(parallelAff);
var sequence3 = /* @__PURE__ */ sequence(traversableArray);
var bindFlipped1 = /* @__PURE__ */ bindFlipped(bindAff);
var pure9 = /* @__PURE__ */ pure(applicativeEither);
var pure14 = /* @__PURE__ */ pure(applicativeAff);
var lmap4 = /* @__PURE__ */ lmap(bifunctorEither);
var parseMap = function (extract2) {
  return function (a) {
    return bindFlipped6(
      traverse6(function ($37) {
        return except2(extract2($37));
      })
    )(a);
  };
};
var parse = function (extract2) {
  return function (a) {
    return bindFlipped6(function ($38) {
      return liftEither2(extract2($38));
    })(a);
  };
};
var parallelReqTuple = /* @__PURE__ */ (function () {
  var sequenceTuple = function (dictApplicative) {
    var Apply0 = dictApplicative.Apply0();
    var apply3 = apply(Apply0);
    var map115 = map(Apply0.Functor0());
    return function (v) {
      return apply3(map115(Tuple.create)(v.value0))(v.value1);
    };
  };
  var $39 = map21(sequenceTuple(applicativeEither));
  var $40 = sequenceTuple(applicativeParAff);
  var $41 = bimap(bifunctorTuple)(function ($43) {
    return parallel3(runExceptT($43));
  })(function ($44) {
    return parallel3(runExceptT($44));
  });
  return function ($42) {
    return ExceptT($39(sequential3($40($41($42)))));
  };
})();
var parallelReqArray = /* @__PURE__ */ (function () {
  var $45 = map21(sequence3(applicativeEither));
  var $46 = sequence3(applicativeParAff);
  var $47 = map(functorArray)(function ($49) {
    return parallel3(runExceptT($49));
  });
  return function ($48) {
    return ExceptT($45(sequential3($46($47($48)))));
  };
})();
var jsonField = function (field) {
  var err = new RequestContentError("No field " + field);
  return caseJsonObject(new Left(err))(
    (function () {
      var $54 = note(err);
      var $55 = lookup(field);
      return function ($56) {
        return $54($55($56));
      };
    })()
  );
};
var headMay = function ($57) {
  return pure9(head($57));
};
var fromReq = function (a) {
  var fromErr = either(function ($60) {
    return throwBlockfrostRequestError(printError($60));
  })(pure14);
  return bindFlipped1(fromErr)(runExceptT(a));
};
var decode2 = function (codec) {
  var toError = function ($61) {
    return RequestContentError.create(printJsonDecodeError($61));
  };
  var $62 = lmap4(toError);
  var $63 = decode(codec);
  return function ($64) {
    return $62($63($64));
  };
};

// output/Web.HTML/foreign.js
var windowImpl = function () {
  return window;
};

// output/Web.HTML.Window/foreign.js
function sessionStorage(window2) {
  return function () {
    return window2.sessionStorage;
  };
}

// output/Web.Storage.Storage/foreign.js
function _getItem(key) {
  return function (storage) {
    return function () {
      return storage.getItem(key);
    };
  };
}
function setItem(key) {
  return function (value14) {
    return function (storage) {
      return function () {
        storage.setItem(key, value14);
      };
    };
  };
}

// output/Web.Storage.Storage/index.js
var map23 = /* @__PURE__ */ map(functorEffect);
var getItem = function (s) {
  var $5 = map23(toMaybe);
  var $6 = _getItem(s);
  return function ($7) {
    return $5($6($7));
  };
};

// output/Equine.Blockfrost.Query/index.js
var record3 = /* @__PURE__ */ record2();
var rowListCodecCons2 = /* @__PURE__ */ rowListCodecCons(rowListCodecNil)()();
var bindExceptT2 = /* @__PURE__ */ bindExceptT(monadAff);
var bind6 = /* @__PURE__ */ bind(bindExceptT2);
var liftEffect8 = /* @__PURE__ */ liftEffect(/* @__PURE__ */ monadEffectExceptT(monadEffectAff));
var map24 = /* @__PURE__ */ map(functorEffect);
var un3 = /* @__PURE__ */ un();
var toDuration2 = /* @__PURE__ */ toDuration(durationSeconds);
var applicativeExceptT2 = /* @__PURE__ */ applicativeExceptT(monadAff);
var pure10 = /* @__PURE__ */ pure(applicativeExceptT2);
var discard4 = /* @__PURE__ */ discard(discardUnit)(bindExceptT2);
var lift4 = /* @__PURE__ */ lift(monadTransExceptT)(monadAff);
var fromDuration3 = /* @__PURE__ */ fromDuration(durationSeconds);
var fromHex2 = /* @__PURE__ */ fromHex(isHexAddress);
var bind1 = /* @__PURE__ */ bind(bindEither);
var pure15 = /* @__PURE__ */ pure(applicativeEither);
var bind23 = /* @__PURE__ */ bind(bindEffect);
var bind32 = /* @__PURE__ */ bind(bindMaybe);
var functorExceptT2 = /* @__PURE__ */ functorExceptT(functorAff);
var map111 = /* @__PURE__ */ map(functorExceptT2);
var map25 = /* @__PURE__ */ map(functorMaybe);
var composeKleisliFlipped4 = /* @__PURE__ */ composeKleisliFlipped(bindEither);
var traverse7 = /* @__PURE__ */ traverse(traversableMaybe)(applicativeEither);
var forWithIndex_2 = /* @__PURE__ */ forWithIndex_(applicativeExceptT2)(foldableWithIndexMap);
var fromFoldable7 = /* @__PURE__ */ fromFoldable5(ordAssetClass)(foldableArray);
var map33 = /* @__PURE__ */ map(functorArray);
var insert3 = /* @__PURE__ */ insert2(ordAssetClass);
var foldM3 = /* @__PURE__ */ foldM(foldableArray)(/* @__PURE__ */ monadExceptT(monadAff));
var $$null4 = /* @__PURE__ */ $$null(foldableArray);
var union3 = /* @__PURE__ */ union2(ordAssetClass);
var monadThrowExceptT2 = /* @__PURE__ */ monadThrowExceptT(monadAff);
var liftMaybe2 = /* @__PURE__ */ liftMaybe(monadThrowExceptT2);
var traverse12 = /* @__PURE__ */ traverse(traversableArray)(applicativeMaybe);
var lookup3 = /* @__PURE__ */ lookup2(ordAssetClass);
var foldMap3 = /* @__PURE__ */ foldMap(foldableArray)(monoidString);
var fromEnum6 = /* @__PURE__ */ fromEnum(boundedEnumCodePoint);
var liftEither3 = /* @__PURE__ */ liftEither(monadThrowExceptT2);
var lmap5 = /* @__PURE__ */ lmap(bifunctorEither);
var composeKleisliFlipped12 = /* @__PURE__ */ composeKleisliFlipped(bindExceptT2);
var mapFlipped4 = /* @__PURE__ */ mapFlipped(functorExceptT2);
var bindFlipped7 = /* @__PURE__ */ bindFlipped(bindExceptT2);
var throwError2 = /* @__PURE__ */ throwError(monadThrowExceptT2);
var unwrap7 = /* @__PURE__ */ unwrap();
var StakeBech32 = /* @__PURE__ */ (function () {
  function StakeBech322(value0) {
    this.value0 = value0;
  }
  StakeBech322.create = function (value0) {
    return new StakeBech322(value0);
  };
  return StakeBech322;
})();
var AddrBech32 = /* @__PURE__ */ (function () {
  function AddrBech322(value0) {
    this.value0 = value0;
  }
  AddrBech322.create = function (value0) {
    return new AddrBech322(value0);
  };
  return AddrBech322;
})();
var AdaHandle = /* @__PURE__ */ (function () {
  function AdaHandle2(value0) {
    this.value0 = value0;
  }
  AdaHandle2.create = function (value0) {
    return new AdaHandle2(value0);
  };
  return AdaHandle2;
})();
var Byron = /* @__PURE__ */ (function () {
  function Byron2(value0) {
    this.value0 = value0;
  }
  Byron2.create = function (value0) {
    return new Byron2(value0);
  };
  return Byron2;
})();
var AddrHex = /* @__PURE__ */ (function () {
  function AddrHex2(value0) {
    this.value0 = value0;
  }
  AddrHex2.create = function (value0) {
    return new AddrHex2(value0);
  };
  return AddrHex2;
})();
var toAssets = function (res) {
  return {
    asset_policy: take2(56)(res.unit),
    asset_name: drop2(56)(res.unit),
    quantity: res.quantity,
  };
};
var tipCodec = /* @__PURE__ */ object("Tip")(
  /* @__PURE__ */ record3(
    /* @__PURE__ */ rowListCodecCons(
      /* @__PURE__ */ rowListCodecCons(
        /* @__PURE__ */ rowListCodecCons(
          /* @__PURE__ */ rowListCodecCons(
            /* @__PURE__ */ rowListCodecCons(
              /* @__PURE__ */ rowListCodecCons(
                /* @__PURE__ */ rowListCodecCons(
                  /* @__PURE__ */ rowListCodecCons(
                    /* @__PURE__ */ rowListCodecCons(
                      /* @__PURE__ */ rowListCodecCons(
                        /* @__PURE__ */ rowListCodecCons(
                          /* @__PURE__ */ rowListCodecCons(
                            /* @__PURE__ */ rowListCodecCons(
                              /* @__PURE__ */ rowListCodecCons(
                                /* @__PURE__ */ rowListCodecCons(
                                  /* @__PURE__ */ rowListCodecCons(
                                    /* @__PURE__ */ rowListCodecCons2({
                                      reflectSymbol: function () {
                                        return "tx_count";
                                      },
                                    })(refl)
                                  )()()({
                                    reflectSymbol: function () {
                                      return "time";
                                    },
                                  })(refl)
                                )()()({
                                  reflectSymbol: function () {
                                    return "slot_leader";
                                  },
                                })(refl)
                              )()()({
                                reflectSymbol: function () {
                                  return "slot";
                                },
                              })(refl)
                            )()()({
                              reflectSymbol: function () {
                                return "size";
                              },
                            })(refl)
                          )()()({
                            reflectSymbol: function () {
                              return "previous_block";
                            },
                          })(refl)
                        )()()({
                          reflectSymbol: function () {
                            return "output";
                          },
                        })(refl)
                      )()()({
                        reflectSymbol: function () {
                          return "op_cert_counter";
                        },
                      })(refl)
                    )()()({
                      reflectSymbol: function () {
                        return "op_cert";
                      },
                    })(refl)
                  )()()({
                    reflectSymbol: function () {
                      return "next_block";
                    },
                  })(refl)
                )()()({
                  reflectSymbol: function () {
                    return "height";
                  },
                })(refl)
              )()()({
                reflectSymbol: function () {
                  return "hash";
                },
              })(refl)
            )()()({
              reflectSymbol: function () {
                return "fees";
              },
            })(refl)
          )()()({
            reflectSymbol: function () {
              return "epoch_slot";
            },
          })(refl)
        )()()({
          reflectSymbol: function () {
            return "epoch";
          },
        })(refl)
      )()()({
        reflectSymbol: function () {
          return "confirmations";
        },
      })(refl)
    )()()({
      reflectSymbol: function () {
        return "block_vrf";
      },
    })(refl)
  )({
    time: $$int,
    height: /* @__PURE__ */ maybe2($$int),
    hash: string,
    slot: /* @__PURE__ */ maybe2($$int),
    epoch: /* @__PURE__ */ maybe2($$int),
    epoch_slot: /* @__PURE__ */ maybe2($$int),
    slot_leader: string,
    size: $$int,
    tx_count: $$int,
    output: /* @__PURE__ */ maybe2(string),
    fees: /* @__PURE__ */ maybe2(string),
    block_vrf: /* @__PURE__ */ maybe2(string),
    op_cert: /* @__PURE__ */ maybe2(string),
    op_cert_counter: /* @__PURE__ */ maybe2(string),
    previous_block: /* @__PURE__ */ maybe2(string),
    next_block: /* @__PURE__ */ maybe2(string),
    confirmations: $$int,
  })
);
var tip = function (api) {
  return parse(decode2(tipCodec))(api.tip);
};
var tipSlot = function (api) {
  return bind6(tip(api))(function (tipRecord) {
    if (tipRecord.slot instanceof Just) {
      return bind6(
        liftEffect8(
          map24(
            (function () {
              var $211 = un3(Seconds);
              return function ($212) {
                return floor2($211(toDuration2(unInstant($212))));
              };
            })()
          )(now)
        )
      )(function (nowInt) {
        return pure10((tipRecord.slot.value0 + ((nowInt - tipRecord.time) | 0)) | 0);
      });
    }
    if (tipRecord.slot instanceof Nothing) {
      return discard4(lift4(delay(fromDuration3(0.5))))(function () {
        return tipSlot(api);
      });
    }
    throw new Error(
      "Failed pattern match at Equine.Blockfrost.Query (line 108, column 3 - line 115, column 18): " +
        [tipRecord.slot.constructor.name]
    );
  });
};
var shelleyAddrToCslAddr = function (v) {
  var $188 = startsWith("addr1")(v);
  if ($188) {
    return address.fromBech32(v);
  }
  return fromHex2(v);
};
var toStakeAddr = function (addr) {
  return bind1(note("Failed to convert to CSL address")(shelleyAddrToCslAddr(addr)))(function (cslAddr) {
    return bind1(note("Address missing stake credential")(baseAddress.fromAddress(cslAddr)))(function (baseAddr) {
      var rewardAddr = rewardAddress["new"](toNumber(address.networkId(cslAddr)))(baseAddress.stakeCred(baseAddr));
      return pure15(address.toBech32(rewardAddress.toAddress(rewardAddr))(Nothing.value));
    });
  });
};
var readMetadataCache = function (storage) {
  return function (asset) {
    return liftEffect8(function __do2() {
      var mStr = getItem(toAssetId(asset))(storage)();
      return bind32(mStr)(function ($213) {
        return hush(jsonParser($213));
      });
    });
  };
};
var isHex = /* @__PURE__ */ (function () {
  var $214 = all(foldableArray)(heytingAlgebraBoolean)(isHexDigit);
  return function ($215) {
    return $214(toCodePointArray($215));
  };
})();
var getStorage = /* @__PURE__ */ liftEffect8(/* @__PURE__ */ bind23(windowImpl)(sessionStorage));
var getFirstAddress = function (api) {
  return function (address2) {
    return map111(map25(ShelleyAddress))(
      parse(composeKleisliFlipped4(traverse7(composeKleisliFlipped4(decode2(string))(jsonField("address"))))(headMay))(
        api.accountAddresses(address2)
      )
    );
  };
};
var classifyAddress = function (v) {
  if (startsWith("stake1")(v)) {
    return new Just(new StakeBech32(v));
  }
  if (startsWith("addr1")(v)) {
    return new Just(new AddrBech32(v));
  }
  if (startsWith("$")(v)) {
    return new Just(new AdaHandle(v));
  }
  if (!isHex(v)) {
    return new Just(new Byron(v));
  }
  if (length3(v) === 114) {
    return new Just(new AddrHex(v));
  }
  if (otherwise) {
    return Nothing.value;
  }
  throw new Error(
    "Failed pattern match at Equine.Blockfrost.Query (line 211, column 1 - line 211, column 49): " +
      [v.constructor.name]
  );
};
var cacheMetadata = function (metaMap) {
  return bind6(getStorage)(function (storage) {
    return forWithIndex_2(metaMap)(function (asset) {
      return function (metaJson) {
        return liftEffect8(setItem(toAssetId(asset))(stringify(metaJson))(storage));
      };
    });
  });
};
var assetResCodec = /* @__PURE__ */ object("AssetRes")(
  /* @__PURE__ */ record3(
    /* @__PURE__ */ rowListCodecCons(
      /* @__PURE__ */ rowListCodecCons2({
        reflectSymbol: function () {
          return "unit";
        },
      })(refl)
    )()()({
      reflectSymbol: function () {
        return "quantity";
      },
    })(refl)
  )({
    unit: string,
    quantity: string,
  })
);
var assetInfoMetaQuery = function (api) {
  return function (assets) {
    var toTuple = function (f) {
      return function (asset) {
        return map111(function (v) {
          return new Tuple(asset, v);
        })(f(asset));
      };
    };
    return map111(fromFoldable7)(
      parallelReqArray(
        map33(
          toTuple(
            (function () {
              var $216 = parse(jsonField("onchain_metadata"));
              return function ($217) {
                return $216(api.assetInfo($217));
              };
            })()
          )
        )(assets)
      )
    );
  };
};
var assetInfoMeta = function (api) {
  return function (codec) {
    return function (assets) {
      return bind6(getStorage)(function (storage) {
        var f = function (v) {
          return function (asset) {
            return bind6(readMetadataCache(storage)(asset))(function (mVal) {
              if (mVal instanceof Just) {
                return pure10(new Tuple(insert3(asset)(mVal.value0)(v.value0), v.value1));
              }
              if (mVal instanceof Nothing) {
                return pure10(new Tuple(v.value0, cons(asset)(v.value1)));
              }
              throw new Error(
                "Failed pattern match at Equine.Blockfrost.Query (line 301, column 7 - line 303, column 61): " +
                  [mVal.constructor.name]
              );
            });
          };
        };
        return bind6(foldM3(f)(new Tuple(empty3, []))(assets))(function (v) {
          return bind6(
            (function () {
              var $197 = $$null4(v.value1);
              if ($197) {
                return pure10(v.value0);
              }
              return bind6(assetInfoMetaQuery(api)(v.value1))(function (assetMappingRes) {
                return discard4(cacheMetadata(assetMappingRes))(function () {
                  return pure10(union3(assetMappingRes)(v.value0));
                });
              });
            })()
          )(function (assetMapping) {
            return liftMaybe2(new RequestContentError("Failed to decode entry"))(
              traverse12(function (asset) {
                return bind32(lookup3(asset)(assetMapping))(
                  (function () {
                    var $218 = decode(codec);
                    return function ($219) {
                      return hush($218($219));
                    };
                  })()
                );
              })(assets)
            );
          });
        });
      });
    };
  };
};
var asciiToHex = function (str) {
  var bound256 = function (x) {
    var $200 = x >= 0 && x <= 255;
    if ($200) {
      return new Just(x);
    }
    return Nothing.value;
  };
  var addPrefix = function ($$byte) {
    var $201 = length3($$byte) < 2;
    if ($201) {
      return "0" + $$byte;
    }
    return $$byte;
  };
  return map25(
    foldMap3(
      (function () {
        var $220 = toStringAs(hexadecimal);
        return function ($221) {
          return addPrefix($220($221));
        };
      })()
    )
  )(
    traverse12(function ($222) {
      return bound256(fromEnum6($222));
    })(toCodePointArray(str))
  );
};
var getAddressFromAdaHandle = function (api) {
  return function (str) {
    return map111(map25(ShelleyAddress))(
      bind6(liftMaybe2(new RequestContentError("Failed to hex encode token name"))(asciiToHex(str)))(function (hexStr) {
        return parse(
          composeKleisliFlipped4(traverse7(composeKleisliFlipped4(decode2(string))(jsonField("address"))))(headMay)
        )(api.assetAddressList(assetClass(handlePolicy)(hexStr)));
      })
    );
  };
};
var resolveAddr = function (api) {
  return function (addr) {
    var toStakeAddrReq = (function () {
      var $223 = lmap5(RequestContentError.create);
      return function ($224) {
        return liftEither3($223(toStakeAddr(ShelleyAddress($224))));
      };
    })();
    var getFirstAddressErr = composeKleisliFlipped12(liftMaybe2(new RequestContentError("Couldn't get first address")))(
      getFirstAddress(api)
    );
    return bind6(liftMaybe2(new RequestContentError("Malformed address"))(classifyAddress(addr)))(function (typedAddr) {
      if (typedAddr instanceof StakeBech32) {
        return getFirstAddressErr(typedAddr.value0);
      }
      if (typedAddr instanceof AddrBech32) {
        return mapFlipped4(bind6(toStakeAddrReq(typedAddr.value0))(getFirstAddress(api)))(fromMaybe(typedAddr.value0));
      }
      if (typedAddr instanceof AdaHandle) {
        return bindFlipped7(liftMaybe2(new RequestContentError("Handle does not exist")))(
          getAddressFromAdaHandle(api)(drop2(1)(typedAddr.value0))
        );
      }
      if (typedAddr instanceof Byron) {
        return throwError2(new RequestContentError("Unsupported address type: " + typedAddr.value0));
      }
      if (typedAddr instanceof AddrHex) {
        return bind6(toStakeAddrReq(typedAddr.value0))(getFirstAddressErr);
      }
      throw new Error(
        "Failed pattern match at Equine.Blockfrost.Query (line 233, column 3 - line 241, column 61): " +
          [typedAddr.constructor.name]
      );
    });
  };
};
var adaAsset = function (n) {
  return {
    asset_policy: unwrap7(adaSymbol),
    asset_name: unwrap7(adaToken),
    quantity: n,
  };
};
var accountNativeAssets = function (api) {
  return function (address2) {
    return map111(map33(toAssets))(parseMap(decode2(assetResCodec))(api.accountAssets(address2)));
  };
};
var accountAda = function (api) {
  return function (address2) {
    return parse(composeKleisliFlipped4(decode2(string))(jsonField("controlled_amount")))(api.accountInfo(address2));
  };
};
var accountAssets = function (api) {
  return function (addr) {
    var addAdaAsset = function (v) {
      return cons(adaAsset(v.value1))(v.value0);
    };
    return map111(addAdaAsset)(parallelReqTuple(new Tuple(accountNativeAssets(api)(addr), accountAda(api)(addr))));
  };
};

// output/Data.Set/index.js
var foldMap4 = /* @__PURE__ */ foldMap(foldableList);
var foldl2 = /* @__PURE__ */ foldl(foldableList);
var foldr3 = /* @__PURE__ */ foldr(foldableList);
var $$Set = function (x) {
  return x;
};
var toList2 = function (v) {
  return keys2(v);
};
var fromMap = $$Set;
var foldableSet = {
  foldMap: function (dictMonoid) {
    var foldMap12 = foldMap4(dictMonoid);
    return function (f) {
      var $129 = foldMap12(f);
      return function ($130) {
        return $129(toList2($130));
      };
    };
  },
  foldl: function (f) {
    return function (x) {
      var $131 = foldl2(f)(x);
      return function ($132) {
        return $131(toList2($132));
      };
    };
  },
  foldr: function (f) {
    return function (x) {
      var $133 = foldr3(f)(x);
      return function ($134) {
        return $133(toList2($134));
      };
    };
  },
};

// output/Data.Map/index.js
var keys3 = /* @__PURE__ */ (function () {
  var $38 = $$void(functorMap);
  return function ($39) {
    return fromMap($38($39));
  };
})();

// output/Data.Codec.Argonaut.Migration/index.js
var pure11 = /* @__PURE__ */ pure(applicativeEither);
var identity11 = /* @__PURE__ */ identity(categoryFn);
var map112 = /* @__PURE__ */ map(functorFn);
var renameField = function (oldName) {
  return function (newName) {
    var rename = function (obj) {
      return maybe(obj)(uncurry(insert(newName)))(pop(oldName)(obj));
    };
    var dec = function (j) {
      return caseJsonObject(j)(function ($16) {
        return id(rename($16));
      })(j);
    };
    return basicCodec(function ($17) {
      return pure11(dec($17));
    })(identity11);
  };
};
var alterField = function (field) {
  return function (f) {
    var setDefault2 = alter(f)(field);
    var dec = function (j) {
      return caseJsonObject(j)(function ($20) {
        return id(setDefault2($20));
      })(j);
    };
    return basicCodec(function ($21) {
      return pure11(dec($21));
    })(identity11);
  };
};
var addDefaultOrUpdateField = function (field) {
  var $24 = alterField(field);
  var $25 = map112(Just.create);
  return function ($26) {
    return $24($25($26));
  };
};
var addDefaultField = function (field) {
  var $27 = addDefaultOrUpdateField(field);
  return function ($28) {
    return $27(fromMaybe($28));
  };
};

// output/Data.Variant/index.js
var onMatch = function () {
  return function () {
    return function () {
      return function (r) {
        return function (k) {
          return function (v) {
            if (unsafeHas(v.type)(r)) {
              return unsafeGet(v.type)(r)(v.value);
            }
            return k(v);
          };
        };
      };
    };
  };
};
var onMatch1 = /* @__PURE__ */ onMatch()()();
var on2 = function () {
  return function (dictIsSymbol) {
    var reflectSymbol2 = reflectSymbol(dictIsSymbol);
    return function (p) {
      return function (f) {
        return function (g) {
          return function (r) {
            if (r.type === reflectSymbol2(p)) {
              return f(r.value);
            }
            return g(r);
          };
        };
      };
    };
  };
};
var inj = function () {
  return function (dictIsSymbol) {
    var reflectSymbol2 = reflectSymbol(dictIsSymbol);
    return function (p) {
      return function (value14) {
        return {
          type: reflectSymbol2(p),
          value: value14,
        };
      };
    };
  };
};
var case_ = function (r) {
  return unsafeCrashWith("Data.Variant: pattern match failure [" + (r.type + "]"));
};
var match = function () {
  return function () {
    return function () {
      return function (r) {
        return onMatch1(r)(case_);
      };
    };
  };
};

// output/Data.Codec.Argonaut.Variant/index.js
var on3 = /* @__PURE__ */ on2();
var voidLeft2 = /* @__PURE__ */ voidLeft(/* @__PURE__ */ functorWriterT(functorIdentity));
var un4 = /* @__PURE__ */ un();
var bind12 = /* @__PURE__ */ bind(bindEither);
var pure16 = /* @__PURE__ */ pure(applicativeEither);
var inj2 = /* @__PURE__ */ inj();
var map26 = /* @__PURE__ */ map(functorEither);
var from4 = /* @__PURE__ */ from2(refl);
var variantCodec = function (dict) {
  return dict.variantCodec;
};
var variantMatch = function () {
  return function (dictVariantCodec) {
    return variantCodec(dictVariantCodec)($$Proxy.value);
  };
};
var variantCase = function (dictIsSymbol) {
  var on1 = on3(dictIsSymbol);
  var reflectSymbol2 = reflectSymbol(dictIsSymbol);
  var inj12 = inj2(dictIsSymbol);
  return function () {
    return function (proxy) {
      return function (eacodec) {
        return function (v) {
          var enc$prime = function (v1) {
            return on1(proxy)(function (v$prime) {
              return writer(
                new Tuple(
                  v1,
                  encode2(jobject)(
                    runST(function __do2() {
                      var obj = newImpl();
                      poke2("tag")(encode2(string)(reflectSymbol2(proxy)))(obj)();
                      if (eacodec instanceof Left) {
                        return obj;
                      }
                      if (eacodec instanceof Right) {
                        return poke2("value")(encode2(eacodec.value0)(v$prime))(obj)();
                      }
                      throw new Error(
                        "Failed pattern match at Data.Codec.Argonaut.Variant (line 121, column 11 - line 123, column 66): " +
                          [eacodec.constructor.name]
                      );
                    })
                  )
                )
              );
            })(function (v$prime) {
              return voidLeft2(un4(Star)(v.value1)(v$prime))(v1);
            })(v1);
          };
          var dec$prime = function (j) {
            return bind12(decode(jobject)(j))(function (obj) {
              return bind12(decode(prop("tag")(string))(obj))(function (tag) {
                var $38 = tag === reflectSymbol2(proxy);
                if ($38) {
                  if (eacodec instanceof Left) {
                    return pure16(inj12(proxy)(eacodec.value0));
                  }
                  if (eacodec instanceof Right) {
                    return bind12(decode(prop("value")(json2))(obj))(function (value14) {
                      return map26(inj12(proxy))(decode(eacodec.value0)(value14));
                    });
                  }
                  throw new Error(
                    "Failed pattern match at Data.Codec.Argonaut.Variant (line 107, column 12 - line 111, column 43): " +
                      [eacodec.constructor.name]
                  );
                }
                return map26(unsafeCoerce2)(runReaderT(v.value0)(j));
              });
            });
          };
          return new GCodec(dec$prime, enc$prime);
        };
      };
    };
  };
};
var variantCodecCons = function (dictVariantCodec) {
  var variantCodec1 = variantCodec(dictVariantCodec);
  return function () {
    return function () {
      return function (dictIsSymbol) {
        var get3 = get(dictIsSymbol)();
        var variantCase1 = variantCase(dictIsSymbol)();
        return function () {
          return {
            variantCodec: function () {
              return function (codecs) {
                var tail = variantCodec1($$Proxy.value)(codecs);
                var codec = from4(get3($$Proxy.value)(codecs));
                return variantCase1($$Proxy.value)(codec)(tail);
              };
            },
          };
        };
      };
    };
  };
};
var variant = /* @__PURE__ */ (function () {
  return new GCodec(function ($44) {
    return Left.create(UnexpectedValue.create($44));
  }, case_);
})();
var variantCodecNil = {
  variantCodec: function () {
    return function () {
      return variant;
    };
  },
};

// output/Data.Show.Generic/foreign.js
var intercalate2 = function (separator) {
  return function (xs) {
    return xs.join(separator);
  };
};

// output/Data.Show.Generic/index.js
var append3 = /* @__PURE__ */ append(semigroupArray);
var genericShowArgsNoArguments = {
  genericShowArgs: function () {
    return [];
  },
};
var genericShowArgs = function (dict) {
  return dict.genericShowArgs;
};
var genericShowConstructor = function (dictGenericShowArgs) {
  var genericShowArgs1 = genericShowArgs(dictGenericShowArgs);
  return function (dictIsSymbol) {
    var reflectSymbol2 = reflectSymbol(dictIsSymbol);
    return {
      "genericShow'": function (v) {
        var ctor = reflectSymbol2($$Proxy.value);
        var v1 = genericShowArgs1(v);
        if (v1.length === 0) {
          return ctor;
        }
        return "(" + (intercalate2(" ")(append3([ctor])(v1)) + ")");
      },
    };
  };
};
var genericShow$prime = function (dict) {
  return dict["genericShow'"];
};
var genericShowSum = function (dictGenericShow) {
  var genericShow$prime1 = genericShow$prime(dictGenericShow);
  return function (dictGenericShow1) {
    var genericShow$prime2 = genericShow$prime(dictGenericShow1);
    return {
      "genericShow'": function (v) {
        if (v instanceof Inl) {
          return genericShow$prime1(v.value0);
        }
        if (v instanceof Inr) {
          return genericShow$prime2(v.value0);
        }
        throw new Error(
          "Failed pattern match at Data.Show.Generic (line 26, column 1 - line 28, column 40): " + [v.constructor.name]
        );
      },
    };
  };
};
var genericShow = function (dictGeneric) {
  var from5 = from(dictGeneric);
  return function (dictGenericShow) {
    var genericShow$prime1 = genericShow$prime(dictGenericShow);
    return function (x) {
      return genericShow$prime1(from5(x));
    };
  };
};

// output/Cardano.Wallet/foreign.js
var _getWalletApi = (walletName) => () => window.cardano[walletName].enable();
var _isEnabled = (walletName) => () => window.cardano[walletName].isEnabled();
var _getBalance = (api) => () => api.getBalance();
var _getChangeAddress = (api) => () => api.getChangeAddress();
var _getRewardAddresses = (api) => () => api.getRewardAddresses();
var _getUsedAddresses = (api) => (page) => () => api.getUsedAddresses(page);
var _signTx = (api) => (tx2) => (partial) => () => api.signTx(tx2, partial);
var _getUtxos = (api) => (paginate) => () => api.getUtxos(paginate);
var _submitTx = (api) => (tx2) => () => api.submitTx(tx2.to_hex());
var isWalletAvailable = (walletName) => () =>
  typeof window.cardano != "undefined" &&
  typeof window.cardano[walletName] != "undefined" &&
  typeof window.cardano[walletName].enable == "function";

// output/Cardano.Wallet/index.js
var map27 = /* @__PURE__ */ map(functorAff);
var yoroi = "yoroi";
var typhon = "typhoncip30";
var submitTx = function (api) {
  return function (tx2) {
    return toAffE(_submitTx(api)(tx2));
  };
};
var signTx = function (api) {
  return function (tx2) {
    return function (isPartialSign) {
      return toAffE(_signTx(api)(tx2)(isPartialSign));
    };
  };
};
var nami = "nami";
var lace = "lace";
var isEnabled = function ($5) {
  return toAffE(_isEnabled($5));
};
var getUtxos = function (api) {
  return function (mPaginate) {
    return map27(
      (function () {
        var $6 = fromMaybe([]);
        return function ($7) {
          return $6(toMaybe($7));
        };
      })()
    )(toAffE(_getUtxos(api)(toNullable(mPaginate))));
  };
};
var getUsedAddresses = function (api) {
  return function (paginate) {
    return toAffE(_getUsedAddresses(api)(paginate));
  };
};
var getRewardAddresses = function (api) {
  return toAffE(_getRewardAddresses(api));
};
var getChangeAddress = function (api) {
  return toAffE(_getChangeAddress(api));
};
var getBalance = function (api) {
  return toAffE(_getBalance(api));
};
var gero = "gerowallet";
var flint = "flint";
var exodus = "exodus";
var eternl = "eternl";
var enable = function (v) {
  return toAffE(_getWalletApi(v));
};

// output/Equine.Wallet/index.js
var map28 = /* @__PURE__ */ map(functorMaybe);
var fromFoldable8 = /* @__PURE__ */ fromFoldable5(ordTokenName)(foldableArray);
var toUnfoldable7 = /* @__PURE__ */ toUnfoldable2(unfoldableArray);
var mempty4 = /* @__PURE__ */ mempty(monoidValue);
var fromFoldable1 = /* @__PURE__ */ fromFoldable5(ordCurrencySymbol)(foldableArray);
var map113 = /* @__PURE__ */ map(functorArray);
var lmap6 = /* @__PURE__ */ lmap(bifunctorTuple);
var map29 = /* @__PURE__ */ map(functorObject);
var bindFlipped8 = /* @__PURE__ */ bindFlipped(bindMaybe);
var composeKleisliFlipped5 = /* @__PURE__ */ composeKleisliFlipped(bindMaybe);
var append4 = /* @__PURE__ */ append(semigroupValue);
var pure17 = /* @__PURE__ */ pure(applicativeMaybe);
var genericShowConstructor2 = /* @__PURE__ */ genericShowConstructor(genericShowArgsNoArguments);
var bind7 = /* @__PURE__ */ bind(bindEffect);
var pure18 = /* @__PURE__ */ pure(applicativeEffect);
var discard5 = /* @__PURE__ */ discard(discardUnit);
var for_2 = /* @__PURE__ */ for_(applicativeEffect)(foldableArray);
var traverse8 = /* @__PURE__ */ traverse(traversableArray)(applicativeMaybe);
var fromHex3 = /* @__PURE__ */ fromHex(isHexTxUnspentOut);
var fromHex1 = /* @__PURE__ */ fromHex(isHexTxHash);
var bind13 = /* @__PURE__ */ bind(bindAff);
var fromHex22 = /* @__PURE__ */ fromHex(isHexTxWitnessSet);
var liftEffect9 = /* @__PURE__ */ liftEffect(monadEffectAff);
var discard22 = /* @__PURE__ */ discard5(bindAff);
var unless2 = /* @__PURE__ */ unless(applicativeAff);
var pure22 = /* @__PURE__ */ pure(applicativeAff);
var map34 = /* @__PURE__ */ map(functorAff);
var fromHex32 = /* @__PURE__ */ fromHex(isHexValue);
var bindFlipped12 = /* @__PURE__ */ bindFlipped(bindAff);
var toHex2 = /* @__PURE__ */ toHex(isHexTx);
var Eternl = /* @__PURE__ */ (function () {
  function Eternl2() {}
  Eternl2.value = new Eternl2();
  return Eternl2;
})();
var Exodus = /* @__PURE__ */ (function () {
  function Exodus2() {}
  Exodus2.value = new Exodus2();
  return Exodus2;
})();
var Flint = /* @__PURE__ */ (function () {
  function Flint2() {}
  Flint2.value = new Flint2();
  return Flint2;
})();
var Gero = /* @__PURE__ */ (function () {
  function Gero2() {}
  Gero2.value = new Gero2();
  return Gero2;
})();
var Lace = /* @__PURE__ */ (function () {
  function Lace2() {}
  Lace2.value = new Lace2();
  return Lace2;
})();
var Nami = /* @__PURE__ */ (function () {
  function Nami2() {}
  Nami2.value = new Nami2();
  return Nami2;
})();
var Typhon = /* @__PURE__ */ (function () {
  function Typhon2() {}
  Typhon2.value = new Typhon2();
  return Typhon2;
})();
var Yoroi = /* @__PURE__ */ (function () {
  function Yoroi2() {}
  Yoroi2.value = new Yoroi2();
  return Yoroi2;
})();
var valueFromJson = /* @__PURE__ */ (function () {
  var toToken = function (x) {
    return map28(Tuple.create(fst(x)))(caseJsonString(Nothing.value)(fromString2)(snd(x)));
  };
  var toTokens = caseJsonObject(empty3)(function (toks) {
    return fromFoldable8(mapMaybe(toToken)(toUnfoldable7(toks)));
  });
  var toSymbols = caseJsonObject(mempty4)(function (syms) {
    return fromFoldable1(map113(lmap6(CurrencySymbol))(toUnfoldable7(map29(toTokens)(syms))));
  });
  var getMultis = function (obj) {
    return maybe(mempty4)(toSymbols)(lookup("multiasset")(obj));
  };
  return caseJsonObject(mempty4)(function (obj) {
    var multis = getMultis(obj);
    var adas = fromMaybe(mempty4)(
      bindFlipped8(
        composeKleisliFlipped5(
          (function () {
            var $117 = map28(lovelaceValue);
            return function ($118) {
              return $117(fromString2($118));
            };
          })()
        )(toString)
      )(lookup("coin")(obj))
    );
    return append4(adas)(multis);
  });
})();
var readWalletApp = {
  read: function (str) {
    if (str === "Eternl") {
      return pure17(Eternl.value);
    }
    if (str === "Exodus") {
      return pure17(Exodus.value);
    }
    if (str === "Flint") {
      return pure17(Flint.value);
    }
    if (str === "Gero") {
      return pure17(Gero.value);
    }
    if (str === "Lace") {
      return pure17(Lace.value);
    }
    if (str === "Nami") {
      return pure17(Nami.value);
    }
    if (str === "Typhon") {
      return pure17(Typhon.value);
    }
    if (str === "Yoroi") {
      return pure17(Yoroi.value);
    }
    return Nothing.value;
  },
};
var genericWalletApp = {
  to: function (x) {
    if (x instanceof Inl) {
      return Eternl.value;
    }
    if (x instanceof Inr && x.value0 instanceof Inl) {
      return Exodus.value;
    }
    if (x instanceof Inr && x.value0 instanceof Inr && x.value0.value0 instanceof Inl) {
      return Flint.value;
    }
    if (
      x instanceof Inr &&
      x.value0 instanceof Inr &&
      x.value0.value0 instanceof Inr &&
      x.value0.value0.value0 instanceof Inl
    ) {
      return Gero.value;
    }
    if (
      x instanceof Inr &&
      x.value0 instanceof Inr &&
      x.value0.value0 instanceof Inr &&
      x.value0.value0.value0 instanceof Inr &&
      x.value0.value0.value0.value0 instanceof Inl
    ) {
      return Lace.value;
    }
    if (
      x instanceof Inr &&
      x.value0 instanceof Inr &&
      x.value0.value0 instanceof Inr &&
      x.value0.value0.value0 instanceof Inr &&
      x.value0.value0.value0.value0 instanceof Inr &&
      x.value0.value0.value0.value0.value0 instanceof Inl
    ) {
      return Nami.value;
    }
    if (
      x instanceof Inr &&
      x.value0 instanceof Inr &&
      x.value0.value0 instanceof Inr &&
      x.value0.value0.value0 instanceof Inr &&
      x.value0.value0.value0.value0 instanceof Inr &&
      x.value0.value0.value0.value0.value0 instanceof Inr &&
      x.value0.value0.value0.value0.value0.value0 instanceof Inl
    ) {
      return Typhon.value;
    }
    if (
      x instanceof Inr &&
      x.value0 instanceof Inr &&
      x.value0.value0 instanceof Inr &&
      x.value0.value0.value0 instanceof Inr &&
      x.value0.value0.value0.value0 instanceof Inr &&
      x.value0.value0.value0.value0.value0 instanceof Inr &&
      x.value0.value0.value0.value0.value0.value0 instanceof Inr
    ) {
      return Yoroi.value;
    }
    throw new Error(
      "Failed pattern match at Equine.Wallet (line 72, column 1 - line 72, column 56): " + [x.constructor.name]
    );
  },
  from: function (x) {
    if (x instanceof Eternl) {
      return new Inl(NoArguments.value);
    }
    if (x instanceof Exodus) {
      return new Inr(new Inl(NoArguments.value));
    }
    if (x instanceof Flint) {
      return new Inr(new Inr(new Inl(NoArguments.value)));
    }
    if (x instanceof Gero) {
      return new Inr(new Inr(new Inr(new Inl(NoArguments.value))));
    }
    if (x instanceof Lace) {
      return new Inr(new Inr(new Inr(new Inr(new Inl(NoArguments.value)))));
    }
    if (x instanceof Nami) {
      return new Inr(new Inr(new Inr(new Inr(new Inr(new Inl(NoArguments.value))))));
    }
    if (x instanceof Typhon) {
      return new Inr(new Inr(new Inr(new Inr(new Inr(new Inr(new Inl(NoArguments.value)))))));
    }
    if (x instanceof Yoroi) {
      return new Inr(new Inr(new Inr(new Inr(new Inr(new Inr(new Inr(NoArguments.value)))))));
    }
    throw new Error(
      "Failed pattern match at Equine.Wallet (line 72, column 1 - line 72, column 56): " + [x.constructor.name]
    );
  },
};
var showWalletApp = {
  show: /* @__PURE__ */ genericShow(genericWalletApp)(
    /* @__PURE__ */ genericShowSum(
      /* @__PURE__ */ genericShowConstructor2({
        reflectSymbol: function () {
          return "Eternl";
        },
      })
    )(
      /* @__PURE__ */ genericShowSum(
        /* @__PURE__ */ genericShowConstructor2({
          reflectSymbol: function () {
            return "Exodus";
          },
        })
      )(
        /* @__PURE__ */ genericShowSum(
          /* @__PURE__ */ genericShowConstructor2({
            reflectSymbol: function () {
              return "Flint";
            },
          })
        )(
          /* @__PURE__ */ genericShowSum(
            /* @__PURE__ */ genericShowConstructor2({
              reflectSymbol: function () {
                return "Gero";
              },
            })
          )(
            /* @__PURE__ */ genericShowSum(
              /* @__PURE__ */ genericShowConstructor2({
                reflectSymbol: function () {
                  return "Lace";
                },
              })
            )(
              /* @__PURE__ */ genericShowSum(
                /* @__PURE__ */ genericShowConstructor2({
                  reflectSymbol: function () {
                    return "Nami";
                  },
                })
              )(
                /* @__PURE__ */ genericShowSum(
                  /* @__PURE__ */ genericShowConstructor2({
                    reflectSymbol: function () {
                      return "Typhon";
                    },
                  })
                )(
                  /* @__PURE__ */ genericShowConstructor2({
                    reflectSymbol: function () {
                      return "Yoroi";
                    },
                  })
                )
              )
            )
          )
        )
      )
    )
  ),
};
var show5 = /* @__PURE__ */ show(showWalletApp);
var hasJsonWalletApp = {
  jsonCodec: /* @__PURE__ */ $$const(/* @__PURE__ */ stringLike(readWalletApp)(showWalletApp)("WalletApp")),
};
var fromWalletApp = function (v) {
  if (v instanceof Eternl) {
    return eternl;
  }
  if (v instanceof Exodus) {
    return exodus;
  }
  if (v instanceof Flint) {
    return flint;
  }
  if (v instanceof Gero) {
    return gero;
  }
  if (v instanceof Lace) {
    return lace;
  }
  if (v instanceof Nami) {
    return nami;
  }
  if (v instanceof Typhon) {
    return typhon;
  }
  if (v instanceof Yoroi) {
    return yoroi;
  }
  throw new Error(
    "Failed pattern match at Equine.Wallet (line 96, column 17 - line 104, column 25): " + [v.constructor.name]
  );
};
var isEnabled2 = function ($119) {
  return isEnabled(fromWalletApp($119));
};
var isWalletAvailable2 = function ($120) {
  return isWalletAvailable(fromWalletApp($120));
};
var allWalletApps = /* @__PURE__ */ (function () {
  return [Eternl.value, Exodus.value, Flint.value, Gero.value, Lace.value, Nami.value, Typhon.value, Yoroi.value];
})();
var addVKeyWitnesses = function (tx2) {
  return function (wSet) {
    var oldWitnesses = tx.witnessSet(tx2);
    return function __do2() {
      var oldVKeys = bind7(txWitnessSet.vkeys(oldWitnesses))(maybe(vkeywitnesses["new"])(pure18))();
      var vKeysToAdd = bind7(txWitnessSet.vkeys(wSet))(maybe(vkeywitnesses["new"])(pure18))();
      var auxData = maybe(auxiliaryData["new"])(pure18)(tx.auxiliaryData(tx2))();
      var newVKeyLen = vkeywitnesses.len(vKeysToAdd)();
      for_2(range(0)((newVKeyLen - 1) | 0))(function (i3) {
        return function __do3() {
          var vKey = vkeywitnesses.get(vKeysToAdd)(i3)();
          return vkeywitnesses.add(oldVKeys)(vKey)();
        };
      })();
      txWitnessSet.setVkeys(oldWitnesses)(oldVKeys)();
      return tx["new"](tx.body(tx2))(oldWitnesses)(auxData);
    };
  };
};
var getWalletApi = function (wallet) {
  var toUtxos = function (utxosArrStr) {
    return fromMaybeAff(throwCslError("Wallet returned malformed utxos"))(traverse8(fromHex3)(utxosArrStr));
  };
  var toTxHash = function (txHash2) {
    return fromMaybeAff(throwCslError("Wallet returned malformed tx hash"))(fromHex1(txHash2));
  };
  var addTxWitnesses = function (tx2) {
    return function (wSetStr) {
      return bind13(fromMaybeAff(throwCslError("Wallet returned malformed tx hash"))(fromHex22(wSetStr)))(function (v) {
        return liftEffect9(addVKeyWitnesses(tx2)(v));
      });
    };
  };
  return bind13(liftEffect9(isWalletAvailable2(wallet)))(function (avail) {
    return discard22(unless2(avail)(throwMissingWalletError("Could not find wallet: " + show5(wallet))))(function () {
      return bind13(enable(fromWalletApp(wallet)))(function (api) {
        return pure22({
          getUsedAddresses: function (paginate) {
            return map34(map113(ShelleyAddress))(getUsedAddresses(api)(paginate));
          },
          getRewardAddresses: map34(map113(StakingAddress))(getRewardAddresses(api)),
          getBalance: map34(
            (function () {
              var $121 = maybe(mempty4)(function ($123) {
                return valueFromJson(value.toJsValue($123));
              });
              return function ($122) {
                return $121(fromHex32($122));
              };
            })()
          )(getBalance(api)),
          signTx: function (tx2) {
            return function (isPartial) {
              return bindFlipped12(addTxWitnesses(tx2))(signTx(api)(toHex2(tx2))(isPartial));
            };
          },
          submitTx: function (tx2) {
            return bindFlipped12(toTxHash)(submitTx(api)(toHex2(tx2)));
          },
          getUtxos: bindFlipped12(toUtxos)(getUtxos(api)(Nothing.value)),
          getChangeAddress: map34(ShelleyAddress)(getChangeAddress(api)),
        });
      });
    });
  });
};

// output/Equine.Types/index.js
var record4 = /* @__PURE__ */ record2();
var rowListCodecCons3 = /* @__PURE__ */ rowListCodecCons(rowListCodecNil)()();
var priceIsSymbol = {
  reflectSymbol: function () {
    return "price";
  },
};
var guildPriceIsSymbol = {
  reflectSymbol: function () {
    return "guildPrice";
  },
};
var rowListCodecCons1 = /* @__PURE__ */ rowListCodecCons(/* @__PURE__ */ rowListCodecCons3(priceIsSymbol)(refl))()()(
  guildPriceIsSymbol
)(refl);
var coversOfferedPerMonthIsSymbol = {
  reflectSymbol: function () {
    return "coversOfferedPerMonth";
  },
};
var availableCoversIsSymbol = {
  reflectSymbol: function () {
    return "availableCovers";
  },
};
var show6 = /* @__PURE__ */ show(showWalletApp);
var map30 = /* @__PURE__ */ map(functorMaybe);
var read7 = /* @__PURE__ */ read3(readWalletApp);
var pure19 = /* @__PURE__ */ pure(applicativeMaybe);
var offerIsSymbol = {
  reflectSymbol: function () {
    return "offer";
  },
};
var idIsSymbol = {
  reflectSymbol: function () {
    return "id";
  },
};
var headIsSymbol = {
  reflectSymbol: function () {
    return "head";
  },
};
var frontRightLegIsSymbol = {
  reflectSymbol: function () {
    return "frontRightLeg";
  },
};
var frontLeftLegIsSymbol = {
  reflectSymbol: function () {
    return "frontLeftLeg";
  },
};
var bodyIsSymbol = {
  reflectSymbol: function () {
    return "body";
  },
};
var backRightLegIsSymbol = {
  reflectSymbol: function () {
    return "backRightLeg";
  },
};
var backLeftLegIsSymbol = {
  reflectSymbol: function () {
    return "backLeftLeg";
  },
};
var staminaIsSymbol = {
  reflectSymbol: function () {
    return "stamina";
  },
};
var speedIsSymbol = {
  reflectSymbol: function () {
    return "speed";
  },
};
var enduranceIsSymbol = {
  reflectSymbol: function () {
    return "endurance";
  },
};
var agilityIsSymbol = {
  reflectSymbol: function () {
    return "agility";
  },
};
var accelerationIsSymbol = {
  reflectSymbol: function () {
    return "acceleration";
  },
};
var totalRacesIsSymbol = {
  reflectSymbol: function () {
    return "totalRaces";
  },
};
var thirdPlacesIsSymbol = {
  reflectSymbol: function () {
    return "thirdPlaces";
  },
};
var secondPlacesIsSymbol = {
  reflectSymbol: function () {
    return "secondPlaces";
  },
};
var firstPlacesIsSymbol = {
  reflectSymbol: function () {
    return "firstPlaces";
  },
};
var buyNowIsSymbol = {
  reflectSymbol: function () {
    return "buyNow";
  },
};
var endDateIsSymbol = {
  reflectSymbol: function () {
    return "endDate";
  },
};
var initialPriceIsSymbol = {
  reflectSymbol: function () {
    return "initialPrice";
  },
};
var reserveIsSymbol = {
  reflectSymbol: function () {
    return "reserve";
  },
};
var startDateIsSymbol = {
  reflectSymbol: function () {
    return "startDate";
  },
};
var assetIsSymbol = {
  reflectSymbol: function () {
    return "asset";
  },
};
var colourIsSymbol = {
  reflectSymbol: function () {
    return "colour";
  },
};
var displayNameIsSymbol = {
  reflectSymbol: function () {
    return "displayName";
  },
};
var imageIsSymbol = {
  reflectSymbol: function () {
    return "image";
  },
};
var modelIsSymbol = {
  reflectSymbol: function () {
    return "model";
  },
};
var offersIsSymbol = {
  reflectSymbol: function () {
    return "offers";
  },
};
var rarityIsSymbol = {
  reflectSymbol: function () {
    return "rarity";
  },
};
var saleIsSymbol = {
  reflectSymbol: function () {
    return "sale";
  },
};
var typeIsSymbol = {
  reflectSymbol: function () {
    return "type";
  },
};
var mediaTypeIsSymbol = {
  reflectSymbol: function () {
    return "mediaType";
  },
};
var nameIsSymbol = {
  reflectSymbol: function () {
    return "name";
  },
};
var srcIsSymbol = {
  reflectSymbol: function () {
    return "src";
  },
};
var genericShowConstructor3 = /* @__PURE__ */ genericShowConstructor(genericShowArgsNoArguments);
var courageIsSymbol = {
  reflectSymbol: function () {
    return "courage";
  },
};
var determinationIsSymbol = {
  reflectSymbol: function () {
    return "determination";
  },
};
var personalityIsSymbol = {
  reflectSymbol: function () {
    return "personality";
  },
};
var themeSongIsSymbol = {
  reflectSymbol: function () {
    return "themeSong";
  },
};
var wisdomIsSymbol = {
  reflectSymbol: function () {
    return "wisdom";
  },
};
var staticIsSymbol = {
  reflectSymbol: function () {
    return "static";
  },
};
var volatileIsSymbol = {
  reflectSymbol: function () {
    return "volatile";
  },
};
var ageIsSymbol = {
  reflectSymbol: function () {
    return "age";
  },
};
var breedingStatsIsSymbol = {
  reflectSymbol: function () {
    return "breedingStats";
  },
};
var canSwitchToBreedingIsSymbol = {
  reflectSymbol: function () {
    return "canSwitchToBreeding";
  },
};
var careerIsSymbol = {
  reflectSymbol: function () {
    return "career";
  },
};
var careerStatsIsSymbol = {
  reflectSymbol: function () {
    return "careerStats";
  },
};
var classIsSymbol = {
  reflectSymbol: function () {
    return "class";
  },
};
var classAllTimeHighIsSymbol = {
  reflectSymbol: function () {
    return "classAllTimeHigh";
  },
};
var dailyRacesIsSymbol = {
  reflectSymbol: function () {
    return "dailyRaces";
  },
};
var earningsIsSymbol = {
  reflectSymbol: function () {
    return "earnings";
  },
};
var genderDisplayIsSymbol = {
  reflectSymbol: function () {
    return "genderDisplay";
  },
};
var isActiveIsSymbol = {
  reflectSymbol: function () {
    return "isActive";
  },
};
var isBreedingIsSymbol = {
  reflectSymbol: function () {
    return "isBreeding";
  },
};
var isFoalIsSymbol = {
  reflectSymbol: function () {
    return "isFoal";
  },
};
var isPregnantIsSymbol = {
  reflectSymbol: function () {
    return "isPregnant";
  },
};
var monthlyCoversIsSymbol = {
  reflectSymbol: function () {
    return "monthlyCovers";
  },
};
var progenyIsSymbol = {
  reflectSymbol: function () {
    return "progeny";
  },
};
var racingHistoryIsSymbol = {
  reflectSymbol: function () {
    return "racingHistory";
  },
};
var rpIsSymbol = {
  reflectSymbol: function () {
    return "rp";
  },
};
var rpAllTimeHighIsSymbol = {
  reflectSymbol: function () {
    return "rpAllTimeHigh";
  },
};
var rpSeasonHighIsSymbol = {
  reflectSymbol: function () {
    return "rpSeasonHigh";
  },
};
var statsIsSymbol = {
  reflectSymbol: function () {
    return "stats";
  },
};
var studdingIsSymbol = {
  reflectSymbol: function () {
    return "studding";
  },
};
var totalStatsIsSymbol = {
  reflectSymbol: function () {
    return "totalStats";
  },
};
var trainingIsSymbol = {
  reflectSymbol: function () {
    return "training";
  },
};
var dateIsSymbol = {
  reflectSymbol: function () {
    return "date";
  },
};
var distanceIsSymbol = {
  reflectSymbol: function () {
    return "distance";
  },
};
var placeIsSymbol = {
  reflectSymbol: function () {
    return "place";
  },
};
var timeIsSymbol = {
  reflectSymbol: function () {
    return "time";
  },
};
var genderIsSymbol = {
  reflectSymbol: function () {
    return "gender";
  },
};
var isPioneerIsSymbol = {
  reflectSymbol: function () {
    return "isPioneer";
  },
};
var markingsIsSymbol = {
  reflectSymbol: function () {
    return "markings";
  },
};
var pedigreeIsSymbol = {
  reflectSymbol: function () {
    return "pedigree";
  },
};
var $tildeDNAIsSymbol = {
  reflectSymbol: function () {
    return "~DNA";
  },
};
var $tildeLinksIsSymbol = {
  reflectSymbol: function () {
    return "~Links";
  },
};
var $tildeMetaIsSymbol = {
  reflectSymbol: function () {
    return "~Meta";
  },
};
var composeCodecFlipped2 = /* @__PURE__ */ composeCodecFlipped(bindEither);
var seriesIsSymbol = {
  reflectSymbol: function () {
    return "series";
  },
};
var filesIsSymbol = {
  reflectSymbol: function () {
    return "files";
  },
};
var ColorIsSymbol = {
  reflectSymbol: function () {
    return "Color";
  },
};
var jsonCodec2 = /* @__PURE__ */ jsonCodec(hasJsonAssetClass);
var copyrightIsSymbol = {
  reflectSymbol: function () {
    return "copyright";
  },
};
var profunctorGCodec2 = /* @__PURE__ */ profunctorGCodec(/* @__PURE__ */ functorReaderT(functorEither))(
  /* @__PURE__ */ functorWriterT(functorIdentity)
);
var wrapIso3 = /* @__PURE__ */ wrapIso(profunctorGCodec2)();
var inj3 = /* @__PURE__ */ inj();
var saleAdIsSymbol = {
  reflectSymbol: function () {
    return "saleAd";
  },
};
var inj1 = /* @__PURE__ */ inj3(saleAdIsSymbol);
var auctionIsSymbol = {
  reflectSymbol: function () {
    return "auction";
  },
};
var inj22 = /* @__PURE__ */ inj3(auctionIsSymbol);
var object3 = /* @__PURE__ */ object2();
var AppId = /* @__PURE__ */ (function () {
  function AppId2(value0) {
    this.value0 = value0;
  }
  AppId2.create = function (value0) {
    return new AppId2(value0);
  };
  return AppId2;
})();
var AddrId = /* @__PURE__ */ (function () {
  function AddrId2(value0) {
    this.value0 = value0;
  }
  AddrId2.create = function (value0) {
    return new AddrId2(value0);
  };
  return AddrId2;
})();
var HorseSkin = /* @__PURE__ */ (function () {
  function HorseSkin2() {}
  HorseSkin2.value = new HorseSkin2();
  return HorseSkin2;
})();
var JockeySkin = /* @__PURE__ */ (function () {
  function JockeySkin2() {}
  JockeySkin2.value = new JockeySkin2();
  return JockeySkin2;
})();
var DeluxeSkin = /* @__PURE__ */ (function () {
  function DeluxeSkin2() {}
  DeluxeSkin2.value = new DeluxeSkin2();
  return DeluxeSkin2;
})();
var Tempered = /* @__PURE__ */ (function () {
  function Tempered2() {}
  Tempered2.value = new Tempered2();
  return Tempered2;
})();
var Determined = /* @__PURE__ */ (function () {
  function Determined2() {}
  Determined2.value = new Determined2();
  return Determined2;
})();
var Steadfast = /* @__PURE__ */ (function () {
  function Steadfast2() {}
  Steadfast2.value = new Steadfast2();
  return Steadfast2;
})();
var Wise = /* @__PURE__ */ (function () {
  function Wise2() {}
  Wise2.value = new Wise2();
  return Wise2;
})();
var Visionary = /* @__PURE__ */ (function () {
  function Visionary2() {}
  Visionary2.value = new Visionary2();
  return Visionary2;
})();
var Brave = /* @__PURE__ */ (function () {
  function Brave2() {}
  Brave2.value = new Brave2();
  return Brave2;
})();
var Colt = /* @__PURE__ */ (function () {
  function Colt2() {}
  Colt2.value = new Colt2();
  return Colt2;
})();
var Stallion = /* @__PURE__ */ (function () {
  function Stallion2() {}
  Stallion2.value = new Stallion2();
  return Stallion2;
})();
var Filly = /* @__PURE__ */ (function () {
  function Filly2() {}
  Filly2.value = new Filly2();
  return Filly2;
})();
var Mare = /* @__PURE__ */ (function () {
  function Mare2() {}
  Mare2.value = new Mare2();
  return Mare2;
})();
var Male = /* @__PURE__ */ (function () {
  function Male2() {}
  Male2.value = new Male2();
  return Male2;
})();
var Female = /* @__PURE__ */ (function () {
  function Female2() {}
  Female2.value = new Female2();
  return Female2;
})();
var AuthentifyInfo = function (x) {
  return x;
};
var SaleAd = /* @__PURE__ */ (function () {
  function SaleAd2(value0) {
    this.value0 = value0;
  }
  SaleAd2.create = function (value0) {
    return new SaleAd2(value0);
  };
  return SaleAd2;
})();
var Auction = /* @__PURE__ */ (function () {
  function Auction2(value0) {
    this.value0 = value0;
  }
  Auction2.create = function (value0) {
    return new Auction2(value0);
  };
  return Auction2;
})();
var Horse = function (x) {
  return x;
};
var Jockey = function (x) {
  return x;
};
var Skin = function (x) {
  return x;
};
var studdingAdCodec = /* @__PURE__ */ object("StuddingAd")(
  /* @__PURE__ */ record4(
    /* @__PURE__ */ rowListCodecCons(
      /* @__PURE__ */ rowListCodecCons(rowListCodecCons1)()()(coversOfferedPerMonthIsSymbol)(refl)
    )()()(availableCoversIsSymbol)(refl)
  )({
    price: number,
    guildPrice: number,
    availableCovers: number,
    coversOfferedPerMonth: number,
  })
);
var showWalletId = {
  show: function (wid) {
    if (wid instanceof AppId) {
      return show6(wid.value0);
    }
    if (wid instanceof AddrId) {
      return wid.value0;
    }
    throw new Error(
      "Failed pattern match at Equine.Types (line 137, column 5 - line 139, column 43): " + [wid.constructor.name]
    );
  },
};
var showSkinType = {
  show: function (a) {
    if (a instanceof HorseSkin) {
      return "Horse Skin";
    }
    if (a instanceof JockeySkin) {
      return "Jockey Skin";
    }
    if (a instanceof DeluxeSkin) {
      return "Deluxe Skin";
    }
    throw new Error(
      "Failed pattern match at Equine.Types (line 345, column 12 - line 348, column 32): " + [a.constructor.name]
    );
  },
};
var saleAdCodec = /* @__PURE__ */ object("SaleAd")(
  /* @__PURE__ */ record4(rowListCodecCons1)({
    price: number,
    guildPrice: number,
  })
);
var readWalletId = {
  read: function (str) {
    if (startsWith("stake1")(str)) {
      return new Just(new AddrId(str));
    }
    if (otherwise) {
      return map30(AppId.create)(read7(str));
    }
    throw new Error(
      "Failed pattern match at Equine.Types (line 141, column 1 - line 144, column 37): " + [str.constructor.name]
    );
  },
};
var hasJsonWalletId = {
  jsonCodec: /* @__PURE__ */ $$const(/* @__PURE__ */ stringLike(readWalletId)(showWalletId)("WalletId")),
};
var readSkinType = {
  read: function (str) {
    if (str === "Horse Skin") {
      return pure19(HorseSkin.value);
    }
    if (str === "Jockey Skin") {
      return pure19(JockeySkin.value);
    }
    if (str === "Deluxe Skin") {
      return pure19(DeluxeSkin.value);
    }
    return Nothing.value;
  },
};
var skinTypeCodec = /* @__PURE__ */ stringLike(readSkinType)(showSkinType)("SkinType");
var readPersonality = {
  read: function (str) {
    if (str === "Tempered") {
      return pure19(Tempered.value);
    }
    if (str === "Determined") {
      return pure19(Determined.value);
    }
    if (str === "Steadfast") {
      return pure19(Steadfast.value);
    }
    if (str === "Wise") {
      return pure19(Wise.value);
    }
    if (str === "Visionary") {
      return pure19(Visionary.value);
    }
    if (str === "Brave") {
      return pure19(Brave.value);
    }
    return Nothing.value;
  },
};
var readGenderDisplay = {
  read: function (str) {
    if (str === "Colt") {
      return pure19(Colt.value);
    }
    if (str === "Stallion") {
      return pure19(Stallion.value);
    }
    if (str === "Filly") {
      return pure19(Filly.value);
    }
    if (str === "Mare") {
      return pure19(Mare.value);
    }
    return Nothing.value;
  },
};
var readGender = {
  read: function (str) {
    if (str === "Male") {
      return new Just(Male.value);
    }
    if (str === "Female") {
      return new Just(Female.value);
    }
    return Nothing.value;
  },
};
var marketOfferCodec = /* @__PURE__ */ object("MarketOffer")(
  /* @__PURE__ */ record4(
    /* @__PURE__ */ rowListCodecCons(/* @__PURE__ */ rowListCodecCons3(offerIsSymbol)(refl))()()(idIsSymbol)(refl)
  )({
    offer: number,
    id: number,
  })
);
var horseMarkingsMetaCodec = /* @__PURE__ */ object("HorseMarkingsMeta")(
  /* @__PURE__ */ record4(
    /* @__PURE__ */ rowListCodecCons(
      /* @__PURE__ */ rowListCodecCons(
        /* @__PURE__ */ rowListCodecCons(
          /* @__PURE__ */ rowListCodecCons(
            /* @__PURE__ */ rowListCodecCons(
              /* @__PURE__ */ rowListCodecCons3({
                reflectSymbol: function () {
                  return "Head";
                },
              })(refl)
            )()()({
              reflectSymbol: function () {
                return "Front Right Leg";
              },
            })(refl)
          )()()({
            reflectSymbol: function () {
              return "Front Left Leg";
            },
          })(refl)
        )()()({
          reflectSymbol: function () {
            return "Body";
          },
        })(refl)
      )()()({
        reflectSymbol: function () {
          return "Back Right Leg";
        },
      })(refl)
    )()()({
      reflectSymbol: function () {
        return "Back Left Leg";
      },
    })(refl)
  )({
    "Back Left Leg": string,
    "Front Right Leg": string,
    Head: string,
    "Back Right Leg": string,
    "Front Left Leg": string,
    Body: string,
  })
);
var horseMarkingsCodec = /* @__PURE__ */ object("HorseMarkings")(
  /* @__PURE__ */ record4(
    /* @__PURE__ */ rowListCodecCons(
      /* @__PURE__ */ rowListCodecCons(
        /* @__PURE__ */ rowListCodecCons(
          /* @__PURE__ */ rowListCodecCons(
            /* @__PURE__ */ rowListCodecCons(/* @__PURE__ */ rowListCodecCons3(headIsSymbol)(refl))()()(
              frontRightLegIsSymbol
            )(refl)
          )()()(frontLeftLegIsSymbol)(refl)
        )()()(bodyIsSymbol)(refl)
      )()()(backRightLegIsSymbol)(refl)
    )()()(backLeftLegIsSymbol)(refl)
  )({
    body: string,
    head: string,
    frontLeftLeg: string,
    frontRightLeg: string,
    backLeftLeg: string,
    backRightLeg: string,
  })
);
var horseDisplayStatsCodec = /* @__PURE__ */ object("HorseDisplayStats")(
  /* @__PURE__ */ record4(
    /* @__PURE__ */ rowListCodecCons(
      /* @__PURE__ */ rowListCodecCons(
        /* @__PURE__ */ rowListCodecCons(
          /* @__PURE__ */ rowListCodecCons(/* @__PURE__ */ rowListCodecCons3(staminaIsSymbol)(refl))()()(speedIsSymbol)(
            refl
          )
        )()()(enduranceIsSymbol)(refl)
      )()()(agilityIsSymbol)(refl)
    )()()(accelerationIsSymbol)(refl)
  )({
    acceleration: number,
    agility: number,
    endurance: number,
    speed: number,
    stamina: number,
  })
);
var horseCareerStatsCodec = /* @__PURE__ */ object("HorseCareerStats")(
  /* @__PURE__ */ record4(
    /* @__PURE__ */ rowListCodecCons(
      /* @__PURE__ */ rowListCodecCons(
        /* @__PURE__ */ rowListCodecCons(/* @__PURE__ */ rowListCodecCons3(totalRacesIsSymbol)(refl))()()(
          thirdPlacesIsSymbol
        )(refl)
      )()()(secondPlacesIsSymbol)(refl)
    )()()(firstPlacesIsSymbol)(refl)
  )({
    totalRaces: $$int,
    firstPlaces: $$int,
    secondPlaces: $$int,
    thirdPlaces: $$int,
  })
);
var genericPersonality = {
  to: function (x) {
    if (x instanceof Inl) {
      return Tempered.value;
    }
    if (x instanceof Inr && x.value0 instanceof Inl) {
      return Determined.value;
    }
    if (x instanceof Inr && x.value0 instanceof Inr && x.value0.value0 instanceof Inl) {
      return Steadfast.value;
    }
    if (
      x instanceof Inr &&
      x.value0 instanceof Inr &&
      x.value0.value0 instanceof Inr &&
      x.value0.value0.value0 instanceof Inl
    ) {
      return Wise.value;
    }
    if (
      x instanceof Inr &&
      x.value0 instanceof Inr &&
      x.value0.value0 instanceof Inr &&
      x.value0.value0.value0 instanceof Inr &&
      x.value0.value0.value0.value0 instanceof Inl
    ) {
      return Visionary.value;
    }
    if (
      x instanceof Inr &&
      x.value0 instanceof Inr &&
      x.value0.value0 instanceof Inr &&
      x.value0.value0.value0 instanceof Inr &&
      x.value0.value0.value0.value0 instanceof Inr
    ) {
      return Brave.value;
    }
    throw new Error(
      "Failed pattern match at Equine.Types (line 307, column 1 - line 307, column 60): " + [x.constructor.name]
    );
  },
  from: function (x) {
    if (x instanceof Tempered) {
      return new Inl(NoArguments.value);
    }
    if (x instanceof Determined) {
      return new Inr(new Inl(NoArguments.value));
    }
    if (x instanceof Steadfast) {
      return new Inr(new Inr(new Inl(NoArguments.value)));
    }
    if (x instanceof Wise) {
      return new Inr(new Inr(new Inr(new Inl(NoArguments.value))));
    }
    if (x instanceof Visionary) {
      return new Inr(new Inr(new Inr(new Inr(new Inl(NoArguments.value)))));
    }
    if (x instanceof Brave) {
      return new Inr(new Inr(new Inr(new Inr(new Inr(NoArguments.value)))));
    }
    throw new Error(
      "Failed pattern match at Equine.Types (line 307, column 1 - line 307, column 60): " + [x.constructor.name]
    );
  },
};
var showPersonality = {
  show: /* @__PURE__ */ genericShow(genericPersonality)(
    /* @__PURE__ */ genericShowSum(
      /* @__PURE__ */ genericShowConstructor3({
        reflectSymbol: function () {
          return "Tempered";
        },
      })
    )(
      /* @__PURE__ */ genericShowSum(
        /* @__PURE__ */ genericShowConstructor3({
          reflectSymbol: function () {
            return "Determined";
          },
        })
      )(
        /* @__PURE__ */ genericShowSum(
          /* @__PURE__ */ genericShowConstructor3({
            reflectSymbol: function () {
              return "Steadfast";
            },
          })
        )(
          /* @__PURE__ */ genericShowSum(
            /* @__PURE__ */ genericShowConstructor3({
              reflectSymbol: function () {
                return "Wise";
              },
            })
          )(
            /* @__PURE__ */ genericShowSum(
              /* @__PURE__ */ genericShowConstructor3({
                reflectSymbol: function () {
                  return "Visionary";
                },
              })
            )(
              /* @__PURE__ */ genericShowConstructor3({
                reflectSymbol: function () {
                  return "Brave";
                },
              })
            )
          )
        )
      )
    )
  ),
};
var personalityCodec = /* @__PURE__ */ stringLike(readPersonality)(showPersonality)("Personality");
var genericGenderDisplay = {
  to: function (x) {
    if (x instanceof Inl) {
      return Colt.value;
    }
    if (x instanceof Inr && x.value0 instanceof Inl) {
      return Stallion.value;
    }
    if (x instanceof Inr && x.value0 instanceof Inr && x.value0.value0 instanceof Inl) {
      return Filly.value;
    }
    if (x instanceof Inr && x.value0 instanceof Inr && x.value0.value0 instanceof Inr) {
      return Mare.value;
    }
    throw new Error(
      "Failed pattern match at Equine.Types (line 80, column 1 - line 80, column 64): " + [x.constructor.name]
    );
  },
  from: function (x) {
    if (x instanceof Colt) {
      return new Inl(NoArguments.value);
    }
    if (x instanceof Stallion) {
      return new Inr(new Inl(NoArguments.value));
    }
    if (x instanceof Filly) {
      return new Inr(new Inr(new Inl(NoArguments.value)));
    }
    if (x instanceof Mare) {
      return new Inr(new Inr(new Inr(NoArguments.value)));
    }
    throw new Error(
      "Failed pattern match at Equine.Types (line 80, column 1 - line 80, column 64): " + [x.constructor.name]
    );
  },
};
var showGenderDisplay = {
  show: /* @__PURE__ */ genericShow(genericGenderDisplay)(
    /* @__PURE__ */ genericShowSum(
      /* @__PURE__ */ genericShowConstructor3({
        reflectSymbol: function () {
          return "Colt";
        },
      })
    )(
      /* @__PURE__ */ genericShowSum(
        /* @__PURE__ */ genericShowConstructor3({
          reflectSymbol: function () {
            return "Stallion";
          },
        })
      )(
        /* @__PURE__ */ genericShowSum(
          /* @__PURE__ */ genericShowConstructor3({
            reflectSymbol: function () {
              return "Filly";
            },
          })
        )(
          /* @__PURE__ */ genericShowConstructor3({
            reflectSymbol: function () {
              return "Mare";
            },
          })
        )
      )
    )
  ),
};
var genericGender = {
  to: function (x) {
    if (x instanceof Inl) {
      return Male.value;
    }
    if (x instanceof Inr) {
      return Female.value;
    }
    throw new Error(
      "Failed pattern match at Equine.Types (line 67, column 1 - line 67, column 50): " + [x.constructor.name]
    );
  },
  from: function (x) {
    if (x instanceof Male) {
      return new Inl(NoArguments.value);
    }
    if (x instanceof Female) {
      return new Inr(NoArguments.value);
    }
    throw new Error(
      "Failed pattern match at Equine.Types (line 67, column 1 - line 67, column 50): " + [x.constructor.name]
    );
  },
};
var showGender = {
  show: /* @__PURE__ */ genericShow(genericGender)(
    /* @__PURE__ */ genericShowSum(
      /* @__PURE__ */ genericShowConstructor3({
        reflectSymbol: function () {
          return "Male";
        },
      })
    )(
      /* @__PURE__ */ genericShowConstructor3({
        reflectSymbol: function () {
          return "Female";
        },
      })
    )
  ),
};
var genderDisplayCodec = /* @__PURE__ */ stringLike(readGenderDisplay)(showGenderDisplay)("GenderDisplay");
var genderCodec = /* @__PURE__ */ stringLike(readGender)(showGender)("Gender");
var fileMetaCodec = /* @__PURE__ */ object("FileMeta")(
  /* @__PURE__ */ record4(
    /* @__PURE__ */ rowListCodecCons(
      /* @__PURE__ */ rowListCodecCons(/* @__PURE__ */ rowListCodecCons3(srcIsSymbol)(refl))()()(nameIsSymbol)(refl)
    )()()(mediaTypeIsSymbol)(refl)
  )({
    name: string,
    src: string,
    mediaType: string,
  })
);
var horseMetaCodec = /* @__PURE__ */ composeCodecFlipped2(/* @__PURE__ */ addDefaultField("Markings")(jsonNull))(
  /* @__PURE__ */ composeCodecFlipped2(/* @__PURE__ */ renameField("Collection")("series"))(
    /* @__PURE__ */ object("Horse")(
      /* @__PURE__ */ record4(
        /* @__PURE__ */ rowListCodecCons(
          /* @__PURE__ */ rowListCodecCons(
            /* @__PURE__ */ rowListCodecCons(
              /* @__PURE__ */ rowListCodecCons(
                /* @__PURE__ */ rowListCodecCons(
                  /* @__PURE__ */ rowListCodecCons(
                    /* @__PURE__ */ rowListCodecCons(
                      /* @__PURE__ */ rowListCodecCons(
                        /* @__PURE__ */ rowListCodecCons(
                          /* @__PURE__ */ rowListCodecCons(
                            /* @__PURE__ */ rowListCodecCons(
                              /* @__PURE__ */ rowListCodecCons(
                                /* @__PURE__ */ rowListCodecCons(
                                  /* @__PURE__ */ rowListCodecCons(
                                    /* @__PURE__ */ rowListCodecCons(
                                      /* @__PURE__ */ rowListCodecCons3($tildeLinksIsSymbol)(refl)
                                    )()()($tildeDNAIsSymbol)(refl)
                                  )()()(staminaIsSymbol)(refl)
                                )()()(speedIsSymbol)(refl)
                              )()()(seriesIsSymbol)(refl)
                            )()()(nameIsSymbol)(refl)
                          )()()(mediaTypeIsSymbol)(refl)
                        )()()(imageIsSymbol)(refl)
                      )()()(filesIsSymbol)(refl)
                    )()()(enduranceIsSymbol)(refl)
                  )()()(agilityIsSymbol)(refl)
                )()()(accelerationIsSymbol)(refl)
              )()()({
                reflectSymbol: function () {
                  return "Markings";
                },
              })(refl)
            )()()({
              reflectSymbol: function () {
                return "Gender";
              },
            })(refl)
          )()()(ColorIsSymbol)(refl)
        )()()({
          reflectSymbol: function () {
            return "Age";
          },
        })(refl)
      )({
        files: /* @__PURE__ */ array(fileMetaCodec),
        image: string,
        mediaType: string,
        name: string,
        Age: number,
        Color: string,
        Gender: genderDisplayCodec,
        series: string,
        Markings: /* @__PURE__ */ maybe2(horseMarkingsMetaCodec),
        acceleration: number,
        agility: number,
        endurance: number,
        speed: number,
        stamina: number,
        "~DNA": /* @__PURE__ */ array(string),
        "~Links": /* @__PURE__ */ foreignObject(string),
      })
    )
  )
);
var horseStatic = /* @__PURE__ */ (function () {
  return object("HorseStatic")(
    record4(
      rowListCodecCons(
        rowListCodecCons(
          rowListCodecCons(
            rowListCodecCons(
              rowListCodecCons(
                rowListCodecCons(
                  rowListCodecCons(
                    rowListCodecCons(
                      rowListCodecCons(
                        rowListCodecCons(
                          rowListCodecCons(rowListCodecCons3($tildeMetaIsSymbol)(refl))()()($tildeLinksIsSymbol)(refl)
                        )()()($tildeDNAIsSymbol)(refl)
                      )()()(pedigreeIsSymbol)(refl)
                    )()()(modelIsSymbol)(refl)
                  )()()(markingsIsSymbol)(refl)
                )()()(isPioneerIsSymbol)(refl)
              )()()(imageIsSymbol)(refl)
            )()()(genderIsSymbol)(refl)
          )()()(displayNameIsSymbol)(refl)
        )()()(colourIsSymbol)(refl)
      )()()(assetIsSymbol)(refl)
    )({
      image: fileMetaCodec,
      model: fileMetaCodec,
      asset: jsonCodec2($$Proxy.value),
      displayName: string,
      colour: string,
      gender: genderCodec,
      markings: horseMarkingsCodec,
      isPioneer: $$boolean,
      "~DNA": string,
      "~Meta": string,
      "~Links": foreignObject(string),
      pedigree: maybe2(array(jsonCodec2($$Proxy.value))),
    })
  );
})();
var jockeyMetaCodec = /* @__PURE__ */ composeCodecFlipped2(/* @__PURE__ */ renameField("Collection")("series"))(
  /* @__PURE__ */ object("JockeyMetadata_V0")(
    /* @__PURE__ */ record4(
      /* @__PURE__ */ rowListCodecCons(
        /* @__PURE__ */ rowListCodecCons(
          /* @__PURE__ */ rowListCodecCons(
            /* @__PURE__ */ rowListCodecCons(
              /* @__PURE__ */ rowListCodecCons(
                /* @__PURE__ */ rowListCodecCons(
                  /* @__PURE__ */ rowListCodecCons(
                    /* @__PURE__ */ rowListCodecCons(
                      /* @__PURE__ */ rowListCodecCons(
                        /* @__PURE__ */ rowListCodecCons3({
                          reflectSymbol: function () {
                            return "website";
                          },
                        })(refl)
                      )()()({
                        reflectSymbol: function () {
                          return "twitter";
                        },
                      })(refl)
                    )()()(seriesIsSymbol)(refl)
                  )()()(nameIsSymbol)(refl)
                )()()(mediaTypeIsSymbol)(refl)
              )()()(imageIsSymbol)(refl)
            )()()(filesIsSymbol)(refl)
          )()()({
            reflectSymbol: function () {
              return "discord";
            },
          })(refl)
        )()()(copyrightIsSymbol)(refl)
      )()()({
        reflectSymbol: function () {
          return "Personality";
        },
      })(refl)
    )({
      name: string,
      image: string,
      discord: string,
      twitter: string,
      Personality: string,
      copyright: string,
      files: /* @__PURE__ */ array(fileMetaCodec),
      series: string,
      mediaType: string,
      website: string,
    })
  )
);
var skinMetaCodec = /* @__PURE__ */ composeCodecFlipped2(/* @__PURE__ */ renameField("Collection")("series"))(
  /* @__PURE__ */ object("SkinMetadata_V0")(
    /* @__PURE__ */ record4(
      /* @__PURE__ */ rowListCodecCons(
        /* @__PURE__ */ rowListCodecCons(
          /* @__PURE__ */ rowListCodecCons(
            /* @__PURE__ */ rowListCodecCons(
              /* @__PURE__ */ rowListCodecCons(
                /* @__PURE__ */ rowListCodecCons(
                  /* @__PURE__ */ rowListCodecCons(
                    /* @__PURE__ */ rowListCodecCons(/* @__PURE__ */ rowListCodecCons3(seriesIsSymbol)(refl))()()(
                      nameIsSymbol
                    )(refl)
                  )()()(mediaTypeIsSymbol)(refl)
                )()()({
                  reflectSymbol: function () {
                    return "media";
                  },
                })(refl)
              )()()(imageIsSymbol)(refl)
            )()()(filesIsSymbol)(refl)
          )()()(copyrightIsSymbol)(refl)
        )()()({
          reflectSymbol: function () {
            return "Skin Type";
          },
        })(refl)
      )()()(ColorIsSymbol)(refl)
    )({
      image: string,
      name: string,
      Color: string,
      copyright: string,
      "Skin Type": string,
      mediaType: string,
      series: string,
      files: /* @__PURE__ */ array(fileMetaCodec),
      media: /* @__PURE__ */ foreignObject(string),
    })
  )
);
var dateEncoder = $$undefined;
var raceEntryCodec = /* @__PURE__ */ object("RaceEntry")(
  /* @__PURE__ */ record4(
    /* @__PURE__ */ rowListCodecCons(
      /* @__PURE__ */ rowListCodecCons(
        /* @__PURE__ */ rowListCodecCons(
          /* @__PURE__ */ rowListCodecCons(
            /* @__PURE__ */ rowListCodecCons(/* @__PURE__ */ rowListCodecCons3(timeIsSymbol)(refl))()()(placeIsSymbol)(
              refl
            )
          )()()(nameIsSymbol)(refl)
        )()()(distanceIsSymbol)(refl)
      )()()(dateIsSymbol)(refl)
    )()()(classIsSymbol)(refl)
  )({
    name: string,
    date: dateEncoder,
    class: number,
    distance: number,
    place: number,
    time: number,
  })
);
var authentifyInfoCodec = /* @__PURE__ */ (function () {
  return wrapIso3(AuthentifyInfo)(
    object("AuthentifyInfo")(
      record4(
        rowListCodecCons(
          rowListCodecCons3({
            reflectSymbol: function () {
              return "tx";
            },
          })(refl)
        )()()({
          reflectSymbol: function () {
            return "stakingAddr";
          },
        })(refl)
      )({
        tx: prismaticCodec("Tx")(fromHex(isHexTx))(toHex(isHexTx))(string),
        stakingAddr: jsonCodec(hasJsonStakingAddress)($$Proxy.value),
      })
    )
  );
})();
var hasJsonAuthentifyInfo = {
  jsonCodec: /* @__PURE__ */ $$const(authentifyInfoCodec),
};
var auctionCodec = /* @__PURE__ */ object("SaleAuction")(
  /* @__PURE__ */ record4(
    /* @__PURE__ */ rowListCodecCons(
      /* @__PURE__ */ rowListCodecCons(
        /* @__PURE__ */ rowListCodecCons(
          /* @__PURE__ */ rowListCodecCons(/* @__PURE__ */ rowListCodecCons3(startDateIsSymbol)(refl))()()(
            reserveIsSymbol
          )(refl)
        )()()(initialPriceIsSymbol)(refl)
      )()()(endDateIsSymbol)(refl)
    )()()(buyNowIsSymbol)(refl)
  )({
    reserve: number,
    initialPrice: number,
    buyNow: number,
    startDate: dateEncoder,
    endDate: dateEncoder,
  })
);
var saleCodec = /* @__PURE__ */ (function () {
  var toVariant = function (v) {
    if (v instanceof SaleAd) {
      return inj1($$Proxy.value)(v.value0);
    }
    if (v instanceof Auction) {
      return inj22($$Proxy.value)(v.value0);
    }
    throw new Error(
      "Failed pattern match at Equine.Types (line 208, column 15 - line 210, column 46): " + [v.constructor.name]
    );
  };
  var fromVariant = match()()()({
    saleAd: SaleAd.create,
    auction: Auction.create,
  });
  return dimap(profunctorGCodec2)(toVariant)(fromVariant)(
    variantMatch()(
      variantCodecCons(variantCodecCons(variantCodecNil)()()(saleAdIsSymbol)(refl))()()(auctionIsSymbol)(refl)
    )({
      saleAd: new Right(saleAdCodec),
      auction: new Right(auctionCodec),
    })
  );
})();
var horseVolatile = /* @__PURE__ */ (function () {
  return object("HorseVolatile")(
    record4(
      rowListCodecCons(
        rowListCodecCons(
          rowListCodecCons(
            rowListCodecCons(
              rowListCodecCons(
                rowListCodecCons(
                  rowListCodecCons(
                    rowListCodecCons(
                      rowListCodecCons(
                        rowListCodecCons(
                          rowListCodecCons(
                            rowListCodecCons(
                              rowListCodecCons(
                                rowListCodecCons(
                                  rowListCodecCons(
                                    rowListCodecCons(
                                      rowListCodecCons(
                                        rowListCodecCons(
                                          rowListCodecCons(
                                            rowListCodecCons(
                                              rowListCodecCons(
                                                rowListCodecCons(
                                                  rowListCodecCons(
                                                    rowListCodecCons(
                                                      rowListCodecCons(rowListCodecCons3(trainingIsSymbol)(refl))()()(
                                                        totalStatsIsSymbol
                                                      )(refl)
                                                    )()()(studdingIsSymbol)(refl)
                                                  )()()(statsIsSymbol)(refl)
                                                )()()(saleIsSymbol)(refl)
                                              )()()(rpSeasonHighIsSymbol)(refl)
                                            )()()(rpAllTimeHighIsSymbol)(refl)
                                          )()()(rpIsSymbol)(refl)
                                        )()()(racingHistoryIsSymbol)(refl)
                                      )()()(progenyIsSymbol)(refl)
                                    )()()(offersIsSymbol)(refl)
                                  )()()(monthlyCoversIsSymbol)(refl)
                                )()()(isPregnantIsSymbol)(refl)
                              )()()(isFoalIsSymbol)(refl)
                            )()()(isBreedingIsSymbol)(refl)
                          )()()(isActiveIsSymbol)(refl)
                        )()()(genderDisplayIsSymbol)(refl)
                      )()()(earningsIsSymbol)(refl)
                    )()()(dailyRacesIsSymbol)(refl)
                  )()()(classAllTimeHighIsSymbol)(refl)
                )()()(classIsSymbol)(refl)
              )()()(careerStatsIsSymbol)(refl)
            )()()(careerIsSymbol)(refl)
          )()()(canSwitchToBreedingIsSymbol)(refl)
        )()()(breedingStatsIsSymbol)(refl)
      )()()(ageIsSymbol)(refl)
    )({
      age: number,
      isActive: $$boolean,
      isFoal: $$boolean,
      canSwitchToBreeding: $$boolean,
      genderDisplay: genderDisplayCodec,
      progeny: array(jsonCodec2($$Proxy.value)),
      rp: number,
      rpSeasonHigh: number,
      rpAllTimeHigh: number,
      careerStats: horseCareerStatsCodec,
      career: string,
      class: number,
      classAllTimeHigh: number,
      earnings: number,
      stats: horseDisplayStatsCodec,
      totalStats: number,
      training: horseDisplayStatsCodec,
      isBreeding: $$boolean,
      sale: maybe2(saleCodec),
      offers: maybe2(array(marketOfferCodec)),
      studding: maybe2(studdingAdCodec),
      racingHistory: maybe2(array(raceEntryCodec)),
      breedingStats: maybe2(number),
      dailyRaces: maybe2(number),
      monthlyCovers: maybe2(number),
      isPregnant: maybe2($$boolean),
    })
  );
})();
var horseCodec = /* @__PURE__ */ wrapIso3(Horse)(
  /* @__PURE__ */ object3(
    /* @__PURE__ */ rowListCodecCons(/* @__PURE__ */ rowListCodecCons3(volatileIsSymbol)(refl))()()(staticIsSymbol)(
      refl
    )
  )("Horse")({
    static: horseStatic,
    volatile: horseVolatile,
  })
);
var hasJsonHorse = {
  jsonCodec: /* @__PURE__ */ $$const(horseCodec),
};
var jockeyCodec = /* @__PURE__ */ (function () {
  return wrapIso3(Jockey)(
    object3(
      rowListCodecCons(
        rowListCodecCons(
          rowListCodecCons(
            rowListCodecCons(
              rowListCodecCons(
                rowListCodecCons(
                  rowListCodecCons(
                    rowListCodecCons(
                      rowListCodecCons(
                        rowListCodecCons(
                          rowListCodecCons(rowListCodecCons3(wisdomIsSymbol)(refl))()()(themeSongIsSymbol)(refl)
                        )()()(saleIsSymbol)(refl)
                      )()()(rarityIsSymbol)(refl)
                    )()()(personalityIsSymbol)(refl)
                  )()()(offersIsSymbol)(refl)
                )()()(modelIsSymbol)(refl)
              )()()(imageIsSymbol)(refl)
            )()()(displayNameIsSymbol)(refl)
          )()()(determinationIsSymbol)(refl)
        )()()(courageIsSymbol)(refl)
      )()()(assetIsSymbol)(refl)
    )("Jockey")({
      displayName: string,
      asset: jsonCodec2($$Proxy.value),
      image: fileMetaCodec,
      model: fileMetaCodec,
      themeSong: fileMetaCodec,
      rarity: number,
      determination: $$int,
      courage: $$int,
      wisdom: $$int,
      personality: personalityCodec,
      sale: maybe2(saleCodec),
      offers: maybe2(array(marketOfferCodec)),
    })
  );
})();
var hasJsonJockey = {
  jsonCodec: /* @__PURE__ */ $$const(jockeyCodec),
};
var skinCodec = /* @__PURE__ */ (function () {
  return wrapIso3(Skin)(
    object3(
      rowListCodecCons(
        rowListCodecCons(
          rowListCodecCons(
            rowListCodecCons(
              rowListCodecCons(
                rowListCodecCons(
                  rowListCodecCons(rowListCodecCons(rowListCodecCons3(typeIsSymbol)(refl))()()(saleIsSymbol)(refl))()()(
                    rarityIsSymbol
                  )(refl)
                )()()(offersIsSymbol)(refl)
              )()()(modelIsSymbol)(refl)
            )()()(imageIsSymbol)(refl)
          )()()(displayNameIsSymbol)(refl)
        )()()(colourIsSymbol)(refl)
      )()()(assetIsSymbol)(refl)
    )("Skin")({
      displayName: string,
      type: skinTypeCodec,
      colour: string,
      asset: jsonCodec2($$Proxy.value),
      image: fileMetaCodec,
      model: fileMetaCodec,
      rarity: number,
      sale: maybe2(saleCodec),
      offers: maybe2(array(marketOfferCodec)),
    })
  );
})();
var hasJsonSkin = {
  jsonCodec: /* @__PURE__ */ $$const(skinCodec),
};

// output/Data.Char.Utils/foreign.js
function fromCodePointImpl(Just2, Nothing2, i3) {
  return i3 >= 0 && i3 <= 1114111 ? Just2(String.fromCodePoint(i3)) : Nothing2;
}

// output/Data.Char.Utils/index.js
var fromCodePoint = function (n) {
  return fromCodePointImpl(Just.create, Nothing.value, n);
};

// output/Equine.WalletInfo/index.js
var coinsIsSymbol = {
  reflectSymbol: function () {
    return "coins";
  },
};
var firstAddrIsSymbol = {
  reflectSymbol: function () {
    return "firstAddr";
  },
};
var foalsCountIsSymbol = {
  reflectSymbol: function () {
    return "foalsCount";
  },
};
var handleIsSymbol = {
  reflectSymbol: function () {
    return "handle";
  },
};
var horsesCountIsSymbol = {
  reflectSymbol: function () {
    return "horsesCount";
  },
};
var jockeysCountIsSymbol = {
  reflectSymbol: function () {
    return "jockeysCount";
  },
};
var skinsCountIsSymbol = {
  reflectSymbol: function () {
    return "skinsCount";
  },
};
var stakeIdIsSymbol = {
  reflectSymbol: function () {
    return "stakeId";
  },
};
var map31 = /* @__PURE__ */ map(functorArray);
var contains3 = /* @__PURE__ */ contains(eqCurrencySymbol);
var wrapIso4 = /* @__PURE__ */ wrapIso(
  /* @__PURE__ */ profunctorGCodec(/* @__PURE__ */ functorReaderT(functorEither))(
    /* @__PURE__ */ functorWriterT(functorIdentity)
  )
)();
var minimum2 = /* @__PURE__ */ minimum(ordString)(foldableArray);
var unwrap8 = /* @__PURE__ */ unwrap();
var eq5 = /* @__PURE__ */ eq(eqCurrencySymbol);
var sum2 = /* @__PURE__ */ sum(foldableArray)(semiringInt);
var alaF3 = /* @__PURE__ */ alaF()()()();
var foldMap5 = /* @__PURE__ */ foldMap(foldableArray)(/* @__PURE__ */ monoidAdditive(semiringBigInt));
var disjointUnion2 = /* @__PURE__ */ disjointUnion()();
var WalletInfo = function (x) {
  return x;
};
var ownAssetsBy = function (api) {
  return function (policyIds) {
    return function (as) {
      var toAssetClass = function (asset) {
        return assetClass(api.getSymbol(asset))(api.getToken(asset));
      };
      return map31(toAssetClass)(
        filter(
          (function () {
            var $159 = contains3(policyIds);
            return function ($160) {
              return $159(api.getSymbol($160));
            };
          })()
        )(as)
      );
    };
  };
};
var ownHorseAssets = function (api) {
  return function (as) {
    return ownAssetsBy(api)(horsePolicies)(as);
  };
};
var walletInfo = /* @__PURE__ */ wrapIso4(WalletInfo)(
  /* @__PURE__ */ object("WalletInfo")(
    /* @__PURE__ */ record2()(
      /* @__PURE__ */ rowListCodecCons(
        /* @__PURE__ */ rowListCodecCons(
          /* @__PURE__ */ rowListCodecCons(
            /* @__PURE__ */ rowListCodecCons(
              /* @__PURE__ */ rowListCodecCons(
                /* @__PURE__ */ rowListCodecCons(
                  /* @__PURE__ */ rowListCodecCons(
                    /* @__PURE__ */ rowListCodecCons(rowListCodecNil)()()(stakeIdIsSymbol)(refl)
                  )()()(skinsCountIsSymbol)(refl)
                )()()(jockeysCountIsSymbol)(refl)
              )()()(horsesCountIsSymbol)(refl)
            )()()(handleIsSymbol)(refl)
          )()()(foalsCountIsSymbol)(refl)
        )()()(firstAddrIsSymbol)(refl)
      )()()(coinsIsSymbol)(refl)
    )({
      coins: bigInt2,
      handle: string,
      stakeId: /* @__PURE__ */ wrapIso4(StakingAddress)(string),
      firstAddr: /* @__PURE__ */ maybe2(/* @__PURE__ */ wrapIso4(ShelleyAddress)(string)),
      horsesCount: $$int,
      jockeysCount: $$int,
      skinsCount: $$int,
      foalsCount: $$int,
    })
  )
);
var hasJsonWalletInfo = {
  jsonCodec: /* @__PURE__ */ $$const(walletInfo),
};
var getHandle = function (api) {
  return function (v) {
    return function (as) {
      return fromMaybe(v)(
        minimum2(
          map31(function ($161) {
            return unwrap8(api.getToken($161));
          })(
            filter(function ($162) {
              return (function (v1) {
                return eq5(v1)(handlePolicy);
              })(api.getSymbol($162));
            })(as)
          )
        )
      );
    };
  };
};
var getFoals = function (horses) {
  return length(
    filter(function (v) {
      return v["volatile"].age <= 4;
    })(horses)
  );
};
var getCounts = function (api) {
  return function (as) {
    var countBy = function (policyId) {
      return length(
        filter(function ($163) {
          return (function (v) {
            return eq5(v)(policyId);
          })(api.getSymbol($163));
        })(as)
      );
    };
    var horsesCount = sum2(map31(countBy)(horsePolicies));
    var jockeysCount = sum2(map31(countBy)(jockeyPolicies));
    var skinsCount = sum2(map31(countBy)(skinPolicies));
    return {
      jockeysCount,
      horsesCount,
      skinsCount,
    };
  };
};
var getCoins = function (api) {
  return alaF3(Additive)(foldMap5)(function (a) {
    var $156 = eq5(api.getSymbol(a))(adaSymbol);
    if ($156) {
      return api.getAmount(a);
    }
    return fromInt(0);
  });
};
var chunksOf = function (v) {
  return function (v1) {
    if (v1 === "") {
      return [];
    }
    var r = splitAt2(v)(v1);
    return cons(r.before)(chunksOf(v)(r.after));
  };
};
var hexToAscii = /* @__PURE__ */ (function () {
  var $164 = map(functorMaybe)(fold(foldableArray)(monoidString));
  var $165 = traverse(traversableArray)(applicativeMaybe)(
    composeKleisliFlipped(bindMaybe)(fromCodePoint)(fromStringAs(hexadecimal))
  );
  var $166 = chunksOf(2);
  return function ($167) {
    return $164($165($166($167)));
  };
})();
var toWalletInfo = function (api) {
  return function (horses) {
    return function (assets) {
      return function (stakeAddr) {
        return function (mShelleyAddr) {
          var handleHex = getHandle(api)(stakeAddr)(assets);
          return disjointUnion2({
            coins: getCoins(api)(assets),
            handle: "$" + fromMaybe(handleHex)(hexToAscii(handleHex)),
            stakeId: stakeAddr,
            firstAddr: mShelleyAddr,
            foalsCount: getFoals(horses),
          })(getCounts(api)(assets));
        };
      };
    };
  };
};

// output/Equine.Query/index.js
var contains4 = /* @__PURE__ */ contains(eqCurrencySymbol);
var map35 = /* @__PURE__ */ map(/* @__PURE__ */ functorExceptT(functorAff));
var bind8 = /* @__PURE__ */ bind(bindEither);
var fromHex4 = /* @__PURE__ */ fromHex(isHexAddress);
var pure20 = /* @__PURE__ */ pure(applicativeEither);
var bindExceptT3 = /* @__PURE__ */ bindExceptT(monadAff);
var bind14 = /* @__PURE__ */ bind(bindExceptT3);
var lift5 = /* @__PURE__ */ lift(monadTransExceptT)(monadAff);
var liftEither4 = /* @__PURE__ */ liftEither(/* @__PURE__ */ monadThrowExceptT(monadAff));
var lmap7 = /* @__PURE__ */ lmap(bifunctorEither);
var map114 = /* @__PURE__ */ map(functorArray);
var fromFoldable9 = /* @__PURE__ */ fromFoldable(foldableSet);
var map210 = /* @__PURE__ */ map(functorMaybe);
var lookup5 = /* @__PURE__ */ lookup2(ordCurrencySymbol);
var pure110 = /* @__PURE__ */ pure(/* @__PURE__ */ applicativeExceptT(monadAff));
var foldMap6 = /* @__PURE__ */ foldMap(foldableArray)(monoidArray);
var bindFlipped9 = /* @__PURE__ */ bindFlipped(bindExceptT3);
var catchError2 = /* @__PURE__ */ catchError(/* @__PURE__ */ monadErrorExceptT(monadAff));
var read8 = /* @__PURE__ */ read3(readSkinType);
var read1 = /* @__PURE__ */ read3(readPersonality);
var disjointUnion3 = /* @__PURE__ */ disjointUnion()();
var sum3 = /* @__PURE__ */ sum(foldableArray)(semiringNumber);
var fold3 = /* @__PURE__ */ fold(foldableArray)(monoidString);
var unwrap9 = /* @__PURE__ */ unwrap();
var stakingAssets = function (api) {
  return function (stakeAddr) {
    return function (css) {
      var go2 = function (asset) {
        if (contains4(css)(asset.asset_policy)) {
          return new Just(assetClass(asset.asset_policy)(asset.asset_name));
        }
        if (otherwise) {
          return Nothing.value;
        }
        throw new Error(
          "Failed pattern match at Equine.Query (line 141, column 3 - line 141, column 34): " + [asset.constructor.name]
        );
      };
      return map35(mapMaybe(go2))(accountAssets(api)(stakeAddr));
    };
  };
};
var personalityToTraits = function (v) {
  if (v instanceof Tempered) {
    return {
      determination: 0,
      courage: 1,
      wisdom: 1,
    };
  }
  if (v instanceof Determined) {
    return {
      determination: 2,
      courage: 0,
      wisdom: 0,
    };
  }
  if (v instanceof Steadfast) {
    return {
      determination: 1,
      courage: 1,
      wisdom: 0,
    };
  }
  if (v instanceof Wise) {
    return {
      determination: 0,
      courage: 0,
      wisdom: 2,
    };
  }
  if (v instanceof Visionary) {
    return {
      determination: 1,
      courage: 0,
      wisdom: 1,
    };
  }
  if (v instanceof Brave) {
    return {
      determination: 0,
      courage: 2,
      wisdom: 0,
    };
  }
  throw new Error(
    "Failed pattern match at Equine.Query (line 336, column 1 - line 336, column 45): " + [v.constructor.name]
  );
};
var ownStakeAddr = function (walletApi) {
  var stakeHexToBech = function (v) {
    return bind8(note("Invalid hex")(fromHex4(v)))(function (cslAddr) {
      return pure20(address.toBech32(cslAddr)(Nothing.value));
    });
  };
  return bind14(lift5(walletApi.getRewardAddresses))(function (rewardAddrs) {
    return liftEither4(
      bind8(note(new RequestContentError("Couldn't extract address"))(head(rewardAddrs)))(function (rewardAddr) {
        return lmap7(RequestContentError.create)(stakeHexToBech(rewardAddr));
      })
    );
  });
};
var ownChangeAddress = function (walletApi) {
  var shelleyHexToBech = function (v) {
    return bind8(note(new RequestContentError("Invalid hex"))(fromHex4(v)))(function (cslAddr) {
      return pure20(address.toBech32(cslAddr)(Nothing.value));
    });
  };
  return bind14(lift5(walletApi.getChangeAddress))(function (changeAddr) {
    return liftEither4(shelleyHexToBech(changeAddr));
  });
};
var ownAssets = function (walletApi) {
  return function (css) {
    return bind14(lift5(walletApi.getBalance))(function (v) {
      var getAssetClasses = function (cs) {
        return map114(assetClass(cs))(maybe([])(fromFoldable9)(map210(keys3)(lookup5(cs)(v))));
      };
      return pure110(foldMap6(getAssetClasses)(css));
    });
  };
};
var walletIdAssets = function (blockfrostApi) {
  return function (wId) {
    return function (css) {
      if (wId instanceof AppId) {
        return bindFlipped9(function (api) {
          return ownAssets(api)(css);
        })(lift5(getWalletApi(wId.value0)));
      }
      if (wId instanceof AddrId) {
        return stakingAssets(blockfrostApi)(wId.value0)(css);
      }
      throw new Error(
        "Failed pattern match at Equine.Query (line 120, column 40 - line 122, column 64): " + [wId.constructor.name]
      );
    };
  };
};
var getSingle = function () {
  return function (getter) {
    return function (asset) {
      return catchError2(map35(head)(getter([asset])))($$const(pure110(Nothing.value)));
    };
  };
};
var getItems = function (api) {
  return function (codec) {
    return function (assets) {
      return map35(zip(assets))(assetInfoMeta(api)(codec)(assets));
    };
  };
};
var fromSkinMeta = function (asset) {
  return function (meta) {
    return {
      displayName: meta.name,
      type: fromMaybe(HorseSkin.value)(read8(meta["Skin Type"])),
      colour: meta.Color,
      asset,
      image: {
        name: "thumbnail",
        src: meta.image,
        mediaType: meta.mediaType,
      },
      model: {
        name: "model",
        src: maybe("")(function (v) {
          return v.src;
        })(head(meta.files)),
        mediaType: maybe("")(function (v) {
          return v.mediaType;
        })(head(meta.files)),
      },
      rarity: 12.4,
      sale: Nothing.value,
      offers: Nothing.value,
    };
  };
};
var fromObjectMeta = function (from5) {
  return function (res) {
    return map35(
      map114(function (v) {
        return from5(v.value0)(v.value1);
      })
    )(res);
  };
};
var getSkins = function (api) {
  return function (assets) {
    return fromObjectMeta(fromSkinMeta)(getItems(api)(skinMetaCodec)(assets));
  };
};
var getSkin = /* @__PURE__ */ (function () {
  var $91 = getSingle(hasJsonSkin);
  return function ($92) {
    return $91(getSkins($92));
  };
})();
var walletIdSkins = function (api) {
  return function (addr) {
    return bindFlipped9(getSkins(api))(walletIdAssets(api)(addr)(skinPolicies));
  };
};
var fromJockeyMeta = function (asset) {
  return function (meta) {
    var personality = fromMaybe(Tempered.value)(read1(meta.Personality));
    return disjointUnion3({
      displayName: meta.name,
      asset,
      image: {
        name: "thumbnail",
        src: meta.image,
        mediaType: meta.mediaType,
      },
      model: {
        name: "model",
        src: maybe("")(function (v) {
          return v.src;
        })(index(meta.files)(0)),
        mediaType: maybe("")(function (v) {
          return v.mediaType;
        })(index(meta.files)(0)),
      },
      themeSong: {
        name: "song",
        src: maybe("")(function (v) {
          return v.src;
        })(index(meta.files)(1)),
        mediaType: maybe("")(function (v) {
          return v.mediaType;
        })(index(meta.files)(1)),
      },
      rarity: 12.4,
      personality,
      sale: Nothing.value,
      offers: Nothing.value,
    })(personalityToTraits(personality));
  };
};
var getJockeys = function (api) {
  return function (assets) {
    return fromObjectMeta(fromJockeyMeta)(getItems(api)(jockeyMetaCodec)(assets));
  };
};
var getJockey = /* @__PURE__ */ (function () {
  var $93 = getSingle(hasJsonJockey);
  return function ($94) {
    return $93(getJockeys($94));
  };
})();
var walletIdJockeys = function (api) {
  return function (addr) {
    return bindFlipped9(getJockeys(api))(walletIdAssets(api)(addr)(jockeyPolicies));
  };
};
var fromHorseMeta = function (asset) {
  return function (meta) {
    var $$volatile = {
      age: meta.Age,
      isActive: false,
      isFoal: meta.Age <= 4,
      canSwitchToBreeding: meta.Age >= 3,
      genderDisplay: meta.Gender,
      progeny: [],
      rp: 0,
      rpSeasonHigh: 0,
      rpAllTimeHigh: 0,
      careerStats: {
        totalRaces: 0,
        firstPlaces: 0,
        secondPlaces: 0,
        thirdPlaces: 0,
      },
      career: "0:0-0-0",
      class: 5,
      classAllTimeHigh: 5,
      earnings: 0,
      stats: {
        acceleration: meta.acceleration,
        agility: meta.agility,
        endurance: meta.endurance,
        speed: meta.speed,
        stamina: meta.stamina,
      },
      totalStats: sum3([meta.acceleration, meta.agility, meta.endurance, meta.speed, meta.stamina]),
      training: {
        acceleration: 0,
        agility: 0,
        endurance: 0,
        speed: 0,
        stamina: 0,
      },
      isBreeding: false,
      breedingStats: Nothing.value,
      dailyRaces: Nothing.value,
      monthlyCovers: Nothing.value,
      isPregnant: Nothing.value,
      offers: Nothing.value,
      studding: Nothing.value,
      racingHistory: Nothing.value,
      sale: Nothing.value,
    };
    var toMarkings = function (x) {
      return {
        body: x.Body,
        head: x.Head,
        frontLeftLeg: x["Front Left Leg"],
        frontRightLeg: x["Front Right Leg"],
        backLeftLeg: x["Back Left Leg"],
        backRightLeg: x["Back Right Leg"],
      };
    };
    var toGender = function (gender) {
      if (gender instanceof Colt) {
        return Male.value;
      }
      if (gender instanceof Stallion) {
        return Male.value;
      }
      return Female.value;
    };
    var noMarkings = {
      body: "None",
      head: "None",
      frontLeftLeg: "None",
      frontRightLeg: "None",
      backLeftLeg: "None",
      backRightLeg: "None",
    };
    var $$static = {
      image: {
        name: "thumbnail",
        src: meta.image,
        mediaType: meta.mediaType,
      },
      model: {
        name: "model",
        src: maybe("")(function (v) {
          return v.src;
        })(head(meta.files)),
        mediaType: maybe("")(function (v) {
          return v.mediaType;
        })(head(meta.files)),
      },
      asset,
      markings: maybe(noMarkings)(toMarkings)(meta.Markings),
      displayName: meta.name,
      colour: meta.Color,
      gender: toGender(meta.Gender),
      isPioneer: true,
      "~DNA": fold3(meta["~DNA"]),
      "~Meta": "https://api.equine.gg/horses/" + unwrap9(unwrap9(asset).assetName),
      "~Links": meta["~Links"],
      pedigree: Nothing.value,
    };
    return {
      static: $$static,
      volatile: $$volatile,
    };
  };
};
var getHorses = function (api) {
  return function (assets) {
    return fromObjectMeta(fromHorseMeta)(getItems(api)(horseMetaCodec)(assets));
  };
};
var getHorse = /* @__PURE__ */ (function () {
  var $95 = getSingle(hasJsonHorse);
  return function ($96) {
    return $95(getHorses($96));
  };
})();
var ownWalletInfo = function (walletApi) {
  return function (blockfrostApi) {
    var valueApi = {
      getSymbol: function (v) {
        return v.value0;
      },
      getToken: function (v) {
        return v.value1.value0;
      },
      getAmount: function (v) {
        return v.value1.value1;
      },
    };
    return bind14(lift5(walletApi.getBalance))(function (total) {
      return bind14(ownStakeAddr(walletApi))(function (stakeAddr) {
        var assets = flatten(total);
        var horseAssets = ownHorseAssets(valueApi)(assets);
        return bind14(getHorses(blockfrostApi)(horseAssets))(function (hs) {
          return bind14(getFirstAddress(blockfrostApi)(stakeAddr))(function (mShelleyAddr) {
            return pure110(toWalletInfo(valueApi)(hs)(assets)(stakeAddr)(mShelleyAddr));
          });
        });
      });
    });
  };
};
var walletIdHorses = function (api) {
  return function (addr) {
    return bindFlipped9(getHorses(api))(walletIdAssets(api)(addr)(horsePolicies));
  };
};
var walletInfoFromStake = function (api) {
  return function (addr) {
    var valueApi = {
      getSymbol: function ($97) {
        return CurrencySymbol(
          (function (v) {
            return v.asset_policy;
          })($97)
        );
      },
      getToken: function ($98) {
        return TokenName(
          (function (v) {
            return v.asset_name;
          })($98)
        );
      },
      getAmount: (function () {
        var $99 = fromMaybe(fromInt(0));
        return function ($100) {
          return $99(
            fromString2(
              (function (v) {
                return v.quantity;
              })($100)
            )
          );
        };
      })(),
    };
    return bind14(accountAssets(api)(addr))(function (assets) {
      return bind14(walletIdHorses(api)(new AddrId(addr)))(function (hs) {
        return bind14(getFirstAddress(api)(addr))(function (mShelleyAddr) {
          return pure110(toWalletInfo(valueApi)(hs)(assets)(addr)(mShelleyAddr));
        });
      });
    });
  };
};
var walletInfo2 = function (api) {
  return function (v) {
    if (v instanceof AppId) {
      return bindFlipped9(flip(ownWalletInfo)(api))(lift5(getWalletApi(v.value0)));
    }
    if (v instanceof AddrId) {
      return walletInfoFromStake(api)(v.value0);
    }
    throw new Error(
      "Failed pattern match at Equine.Query (line 149, column 18 - line 151, column 56): " + [v.constructor.name]
    );
  };
};

// output/Equine.AuthentifyWallet/index.js
var fromStr$prime2 = /* @__PURE__ */ fromStr$prime(isStrBigNum);
var show7 = /* @__PURE__ */ show(showInt);
var $$void5 = /* @__PURE__ */ $$void(functorEffect);
var bind15 = /* @__PURE__ */ bind(bindAff);
var unwrap10 = /* @__PURE__ */ unwrap();
var bindFlipped10 = /* @__PURE__ */ bindFlipped(bindMaybe);
var liftEffect10 = /* @__PURE__ */ liftEffect(monadEffectAff);
var pure21 = /* @__PURE__ */ pure(applicativeAff);
var warningMessage = "EQUINE AUTHENTICATION: Do NOT sign unless on equine.gg";
var coinsPerUTxOWord = 34482;
var mainnetTxBuilder = /* @__PURE__ */ (function () {
  var setPoolDeposit = function (x) {
    return txBuilderConfigBuilder.poolDeposit(x)(fromStr$prime2("500000000"));
  };
  var setMaxValueSize = function (x) {
    return txBuilderConfigBuilder.maxValueSize(x)(5e3);
  };
  var setMaxTxSize = function (x) {
    return txBuilderConfigBuilder.maxTxSize(x)(16384);
  };
  var setKeyDeposit = function (x) {
    return txBuilderConfigBuilder.keyDeposit(x)(fromStr$prime2("2000000"));
  };
  var setFee = function (x) {
    return txBuilderConfigBuilder.feeAlgo(x)(linearFee["new"](fromStr$prime2("44"))(fromStr$prime2("155381")));
  };
  var setCoins = function (x) {
    return txBuilderConfigBuilder.coinsPerUtxoWord(x)(fromStr$prime2(show7(coinsPerUTxOWord)));
  };
  var cfg = setMaxTxSize(
    setMaxValueSize(setKeyDeposit(setPoolDeposit(setCoins(setFee(txBuilderConfigBuilder["new"])))))
  );
  return txBuilder["new"](txBuilderConfigBuilder.build(cfg));
})();
var makeTx = function (slot) {
  return function (changeAddress) {
    return function (rewardAddressHash) {
      var value14 = value["new"](fromStr$prime2("2000000"));
      var txHash2 = getJust(txHash.fromHex("3822d51a1499929d4461183ad3c758bcebf201907e7b6316d1780381184a49be"));
      var txIn2 = txIn["new"](txHash2)(0);
      var addr = getJust(address.fromBech32("addr1vyqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqqkdl5mw"));
      return function __do2() {
        var builder = mainnetTxBuilder();
        txBuilder.addIn(builder)(addr)(txIn2)(value14)();
        txBuilder.setTtlBignum(builder)(fromStr$prime2(show7((slot + 120) | 0)))();
        txBuilder.addRequiredSigner(builder)(rewardAddressHash)();
        txBuilder.addMetadatum(builder)(fromStr$prime2("625"))(txMetadatum.newText(warningMessage))();
        $$void5(txBuilder.addChangeIfNeeded(builder)(changeAddress))();
        return txBuilder.buildTx(builder)();
      };
    };
  };
};
var authentifyWallet = function (wApi) {
  return function (bApi) {
    return bind15(fromReq(tipSlot(bApi)))(function (slot) {
      return bind15(fromReq(ownStakeAddr(wApi)))(function (ownStakingAddr) {
        return bind15(fromReq(ownChangeAddress(wApi)))(function (ownChangeAddr) {
          return bind15(
            fromMaybeAff(throwCslError("Can not convert address from shelley"))(shelleyAddrToCslAddr(ownChangeAddr))
          )(function (ownChangeCslAddr) {
            return bind15(
              fromMaybeAff(throwCslError("Can not convert address from staking"))(
                address.fromBech32(unwrap10(ownStakingAddr))
              )
            )(function (cslAddress) {
              return bind15(
                fromMaybeAff(throwCslError("Can not convert stake cred to key hash"))(
                  bindFlipped10(function ($14) {
                    return stakeCredential.toKeyhash(rewardAddress.paymentCred($14));
                  })(rewardAddress.fromAddress(cslAddress))
                )
              )(function (rAddressHash) {
                return bind15(liftEffect10(makeTx(slot)(ownChangeCslAddr)(rAddressHash)))(function (tx2) {
                  return bind15(wApi.signTx(tx2)(true))(function (signedTx) {
                    return pure21({
                      tx: signedTx,
                      stakingAddr: ownStakingAddr,
                    });
                  });
                });
              });
            });
          });
        });
      });
    });
  };
};

// output/Api/index.js
var resolveAddr2 = function (api) {
  var $2 = resolveAddr(api);
  return function ($3) {
    return fromReq($2($3));
  };
};
var ownStakeAddr2 = function (api) {
  return fromReq(ownStakeAddr(api));
};
var getWalletInfo = function (api) {
  var $4 = walletInfo2(api);
  return function ($5) {
    return fromReq($4($5));
  };
};
var getSkinsByWalletId = function (api) {
  var $6 = walletIdSkins(api);
  return function ($7) {
    return fromReq($6($7));
  };
};
var getSkin2 = function (api) {
  var $8 = getSkin(api);
  return function ($9) {
    return fromReq($8($9));
  };
};
var getJockeysByWalletId = function (api) {
  var $10 = walletIdJockeys(api);
  return function ($11) {
    return fromReq($10($11));
  };
};
var getJockey2 = function (api) {
  var $12 = getJockey(api);
  return function ($13) {
    return fromReq($12($13));
  };
};
var getHorsesByWalletId = function (api) {
  var $14 = walletIdHorses(api);
  return function ($15) {
    return fromReq($14($15));
  };
};
var getHorse2 = function (api) {
  var $16 = getHorse(api);
  return function ($17) {
    return fromReq($16($17));
  };
};
var availableWallets = /* @__PURE__ */ filterA(applicativeEffect)(isWalletAvailable2)(allWalletApps);
var authentifyWallet2 = function (walletApi) {
  return function (blockfrostApi) {
    return authentifyWallet(walletApi)(blockfrostApi);
  };
};

// output/Effect.Class.Console/index.js
var log3 = function (dictMonadEffect) {
  var $51 = liftEffect(dictMonadEffect);
  return function ($52) {
    return $51(log($52));
  };
};

// output/DebugMain/index.js
var debug2 = /* @__PURE__ */ log3(monadEffectEffect)("Put debug code here");

// output/Main/index.js
var liftAff12 = /* @__PURE__ */ liftAff1(hasJsonWalletApp);
var composeKleisliFlipped6 = /* @__PURE__ */ composeKleisliFlipped(bindAff);
var liftAff11 = /* @__PURE__ */ liftAff1(hasJsonWalletId);
var liftAff122 = /* @__PURE__ */ liftAff1(hasJsonAssetClass);
var resolveAddr3 = /* @__PURE__ */ liftAff1(hasJsonAddress)(hasJsonShelleyAddress)(
  /* @__PURE__ */ resolveAddr2(realBlockfrostApi)
);
var ownStakeAddr3 = /* @__PURE__ */ liftAff12(hasJsonStakingAddress)(
  /* @__PURE__ */ composeKleisliFlipped6(ownStakeAddr2)(getWalletApi)
);
var main = debug2;
var isEnabled3 = /* @__PURE__ */ liftAff12(hasJsonBoolean)(isEnabled2);
var getWalletInfo2 = /* @__PURE__ */ liftAff11(hasJsonWalletInfo)(/* @__PURE__ */ getWalletInfo(realBlockfrostApi));
var getSkinsByWalletId2 = /* @__PURE__ */ liftAff11(/* @__PURE__ */ hasJsonArray(hasJsonSkin))(
  /* @__PURE__ */ getSkinsByWalletId(realBlockfrostApi)
);
var getSkin3 = /* @__PURE__ */ liftAff122(/* @__PURE__ */ hasJsonMaybe(hasJsonSkin))(
  /* @__PURE__ */ getSkin2(realBlockfrostApi)
);
var getJockeysByWalletId2 = /* @__PURE__ */ liftAff11(/* @__PURE__ */ hasJsonArray(hasJsonJockey))(
  /* @__PURE__ */ getJockeysByWalletId(realBlockfrostApi)
);
var getJockey3 = /* @__PURE__ */ liftAff122(/* @__PURE__ */ hasJsonMaybe(hasJsonJockey))(
  /* @__PURE__ */ getJockey2(realBlockfrostApi)
);
var getHorsesByWalletId2 = /* @__PURE__ */ liftAff11(/* @__PURE__ */ hasJsonArray(hasJsonHorse))(
  /* @__PURE__ */ getHorsesByWalletId(realBlockfrostApi)
);
var getHorse3 = /* @__PURE__ */ liftAff122(/* @__PURE__ */ hasJsonMaybe(hasJsonHorse))(
  /* @__PURE__ */ getHorse2(realBlockfrostApi)
);
var availableWallets2 = /* @__PURE__ */ liftEffect5(/* @__PURE__ */ hasJsonArray(hasJsonWalletApp))(availableWallets);
var authentifyWallet3 = /* @__PURE__ */ liftAff12(hasJsonAuthentifyInfo)(
  /* @__PURE__ */ composeKleisliFlipped6(/* @__PURE__ */ flip(authentifyWallet2)(realBlockfrostApi))(getWalletApi)
);
export {
  authentifyWallet3 as authentifyWallet,
  availableWallets2 as availableWallets,
  getHorse3 as getHorse,
  getHorsesByWalletId2 as getHorsesByWalletId,
  getJockey3 as getJockey,
  getJockeysByWalletId2 as getJockeysByWalletId,
  getSkin3 as getSkin,
  getSkinsByWalletId2 as getSkinsByWalletId,
  getWalletInfo2 as getWalletInfo,
  isEnabled3 as isEnabled,
  main,
  ownStakeAddr3 as ownStakeAddr,
  resolveAddr3 as resolveAddr,
};
