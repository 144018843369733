import React, { PropsWithChildren, useCallback, useContext, useState } from "react";

type UiStateContextType = {
  walletModal: WalletModalStateType;
  mobileMenu: MobileMenuStateType;
};

type WalletModalStateType = {
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
};
type MobileMenuStateType = {
  isMenuOpen: boolean;
  openMenu: () => void;
  closeMenu: () => void;
};
const UiStateContext = React.createContext<UiStateContextType>({} as UiStateContextType);

const UiStateContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [isModalOpen, setModalOpen] = useState<UiStateContextType["walletModal"]["isModalOpen"]>(false);
  const [isMenuOpen, setMenuOpen] = useState<UiStateContextType["mobileMenu"]["isMenuOpen"]>(false);

  const openMenu = useCallback(() => {
    !isMenuOpen && setMenuOpen(true);
  }, [isMenuOpen, setModalOpen]);

  const closeMenu = useCallback(() => {
    isMenuOpen && setMenuOpen(false);
  }, [isMenuOpen, setMenuOpen]);

  const openModal = useCallback(() => {
    !isModalOpen && setModalOpen(true);
  }, [isModalOpen, setModalOpen]);

  const closeModal = useCallback(() => {
    isModalOpen && setModalOpen(false);
  }, [isModalOpen, setModalOpen]);

  const uiState: UiStateContextType = {
    walletModal: { isModalOpen, openModal, closeModal },
    mobileMenu: { isMenuOpen, openMenu, closeMenu },
  };

  return <UiStateContext.Provider value={uiState}>{children}</UiStateContext.Provider>;
};

export const useUiStateContext = () => useContext(UiStateContext) as UiStateContextType;
export default UiStateContextProvider;
