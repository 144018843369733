import React, { PropsWithChildren, useCallback, useEffect, useState } from "react";

export const BlockScreenWrapperContext = React.createContext<boolean>(false);

const AppBlockScreenProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const portrait = window.matchMedia("(orientation: portrait)");
  const { matches: isPortrait } = portrait;

  const [blockScreen, setBlockScreen] = useState(isPortrait);

  const onBlockScreenChange = useCallback(
    (e: MediaQueryListEvent) => {
      setBlockScreen(e.matches);
      if (window.innerHeight > window.innerWidth) {
        setBlockScreen(true);
      }
    },
    [setBlockScreen]
  );

  useEffect(() => {
    portrait.addEventListener("change", onBlockScreenChange);
    return () => {
      portrait.removeEventListener("change", onBlockScreenChange);
    };
  }, [portrait, onBlockScreenChange]);

  return <BlockScreenWrapperContext.Provider value={blockScreen}>{children}</BlockScreenWrapperContext.Provider>;
};

export default AppBlockScreenProvider;
