export default () => {
  return (
    <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M23 14H18.1952C16.431 13.999 15.0011 12.657 15 11C15 9.34304 16.431 8.00102 18.1952 8H23"
        stroke="#242121"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.0592 11.037H18.6955"
        stroke="#242121"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.03219 1H16.9678C20.2992 1 23 3.65897 23 6.93884V15.0612C23 18.341 20.2992 21 16.9678 21H7.03219C3.70076 21 1 18.341 1 15.0612V6.93884C1 3.65897 3.70076 1 7.03219 1Z"
        stroke="#242121"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
