import cn from "classnames";
import React, { FC } from "react";
import styles from "./HamburgerMenu.module.scss";

interface HamburgerMenuProps {
  onClick: () => void;
  isActive: boolean;
}

const HamburgerMenu: FC<HamburgerMenuProps> = ({ onClick, isActive }) => {
  return (
    <div onClick={onClick} className={cn(styles.element, isActive && styles["isActive"])}>
      <span className={cn(styles.line, styles.line1)}></span>
      <span className={cn(styles.line, styles.line2)}></span>
      <span className={cn(styles.line, styles.line3)}></span>
    </div>
  );
};

export default HamburgerMenu;
