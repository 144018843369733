enum LogLevels {
  silly,
  trace,
  debug,
  info,
  warn,
  error,
  fatal,
}

function getLogLevel(): LogLevels {
  if (process.env["NEXT_PUBLIC_LOG_LEVEL"] === undefined) return LogLevels.info;

  const level = Number.parseInt(process.env["NEXT_PUBLIC_LOG_LEVEL"]);
  if (Number.isNaN(level)) return LogLevels.info;

  return Math.max(Math.min(LogLevels.fatal, level), LogLevels.silly) as LogLevels;
}

const LogLevel = getLogLevel();

const log = {
  silly: LogLevel <= LogLevels.silly ? console.log : () => null,
  trace: LogLevel <= LogLevels.trace ? console.trace : () => null,
  debug: LogLevel <= LogLevels.debug ? console.debug : () => null,
  info: LogLevel <= LogLevels.info ? console.info : () => null,
  log: LogLevel <= LogLevels.info ? console.log : () => null,
  warn: LogLevel <= LogLevels.warn ? console.warn : () => null,
  error: LogLevel <= LogLevels.error ? console.error : () => null,
  fatal: LogLevel <= LogLevels.fatal ? console.error : () => null,
};
export default log;
