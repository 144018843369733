export default () => {
  return (
    <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="11"
        cy="9"
        r="7.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.24895 0.818359C2.679 2.62812 1 5.61807 1 9.00018C1 14.523 5.47715 19.0002 11 19.0002C13.2106 19.0002 15.2536 18.2829 16.9091 17.0684"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="10.75"
        y1="19.75"
        x2="10.75"
        y2="21.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <line
        x1="7.75"
        y1="21.25"
        x2="13.25"
        y2="21.25"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
