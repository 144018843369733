import classNames from "classnames";
import { ReactElement, useState } from "react";
import styles from "./Button.module.scss";
import { ButtonProps } from "./type";

const Button = (props: ButtonProps): ReactElement => {
  const { text, smallHeight, width, transparent, onClick, className, flexible, submit } = props;
  const [mouseMove, setMouseMove] = useState(false);
  return (
    <button
      data-testid="button"
      type={submit ? "submit" : "button"}
      className={`${classNames(
        styles.button,
        transparent && !mouseMove && styles[`button--transparent`],
        width === "small" && styles[`button--width--small`],
        width === "medium" && styles[`button--width--medium`],
        smallHeight && styles[`button--height--small`],
        !transparent && mouseMove && styles[`button--transparent`],
        flexible && styles[`button--flexible`]
      )} ${className}`}
      onClick={onClick}
      onMouseUp={() => setMouseMove(false)}
      onMouseEnter={() => setMouseMove(true)}
      onMouseLeave={() => setMouseMove(false)}
    >
      {text}
    </button>
  );
};

export default Button;
