export default () => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.8051 11.7801C12.065 11.4876 12.065 11.0121 11.8051 10.7196L7.60744 6L11.8051 1.2804C12.065 0.987905 12.065 0.51242 11.8051 0.219929C11.5453 -0.0733116 11.1188 -0.0733115 10.859 0.219929L6.19489 5.46977C5.93504 5.76226 5.93504 6.23774 6.19489 6.53023L10.859 11.7801C11.1188 12.0733 11.5453 12.0733 11.8051 11.7801Z"
        fill="#DFA04A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.80511 11.7801C6.06496 11.4876 6.06496 11.0121 5.80511 10.7196L1.60744 6L5.80511 1.2804C6.06496 0.987905 6.06496 0.51242 5.80511 0.219929C5.54525 -0.0733116 5.11882 -0.0733115 4.85897 0.219929L0.194891 5.46977C-0.0649647 5.76226 -0.0649647 6.23774 0.194891 6.53023L4.85897 11.7801C5.11882 12.0733 5.54525 12.0733 5.80511 11.7801Z"
        fill="#DFA04A"
      />
    </svg>
  );
};
