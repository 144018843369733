import { ApolloClient, InMemoryCache } from "@apollo/client";

const CmsUrl = process.env.NEXT_PUBLIC_CMS_URL;
if (CmsUrl === undefined) {
  throw new Error("Please define NEXT_PUBLIC_CMS_URL");
}
export const client = new ApolloClient({
  uri: `${CmsUrl}/graphql`,
  cache: new InMemoryCache(),
});
