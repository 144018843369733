import { gql, useQuery } from "@apollo/client";
import classNames from "classnames";
import Link from "next/link";
import { useState } from "react";
import DarkModeLogo from "../../assets/images/logo-darkmode.png";
import { headerAndFooterQuery } from "../../lib/queries/headerAndFooter";
import { useDarkModeContext } from "../../src/context/DarkModeContext";
import { useLanguageContext } from "../../src/context/LanguageContext";
import styles from "./Footer.module.scss";
import FooterList from "./FooterList/FooterList";

const Footer = () => {
  const [showDownloadActions, setShowDownloadActions] = useState(false);
  const { darkMode } = useDarkModeContext();
  const { lang } = useLanguageContext();

  //fetching common header-footer data
  const headerAndFooter = useQuery(
    gql`
      ${headerAndFooterQuery}
    `,
    { variables: { language: lang.code } }
  );

  //fetching qr codes

  // const { data, error, loading } = useQuery(
  //   gql`
  //     ${qrCodeQuery}
  //   `,
  //   {
  //     variables: {
  //       categoryName: `${lang.code}-qrcode`,
  //     },
  //   }
  // );
  // useEffect(() => {
  //   if (!error && !loading && data) {
  //     setShowDownloadActions(true);
  //   }
  // }, [error, loading, data]);
  // const dynamicQrdata = data?.posts.nodes[0].qrcode;

  const headerAndFooterData = headerAndFooter?.data?.pages?.nodes[3].headerAndFooter;
  return (
    <footer className={classNames(styles.element, "container")}>
      <div className={classNames(styles.footerContainer)}>
        <div className={classNames(styles.leftSide)}>
          {headerAndFooterData && headerAndFooterData.footerLogo && (
            <div className={classNames(styles.footLogo)}>
              <Link href="/">
                <a>
                  <img src={darkMode ? DarkModeLogo.src : headerAndFooterData?.footerLogo?.sourceUrl} alt="" />
                </a>
              </Link>
            </div>
          )}
          {headerAndFooterData && headerAndFooterData.footerDescription && (
            <p className={classNames(styles.description)}>{headerAndFooterData.footerDescription}</p>
          )}
          {/* Removing per request by client */}
          {/* <div className={classNames(styles.qrcodeWrapper)}>
            <h4>Download our Mobile game</h4>
            {showDownloadActions && (
              <div className={classNames(styles.qrBox)}>
                <Qrcode
                  downloadImageAndroid={
                    dynamicQrdata?.firstSectionQrModalAndroidDownload.sourceUrl
                  }
                  downloadImageIOS={
                    dynamicQrdata?.firstSectionQrModalIosDownload.sourceUrl
                  }
                  imageAndroid={
                    dynamicQrdata?.firstSectionQrAndroidImage.sourceUrl
                  }
                  imageIos={dynamicQrdata?.firstSectionQrIosImage.sourceUrl}
                  mobilDeviceIOS={dynamicQrdata?.firstSectionQrCodeIos}
                  mobileDeviceAndroid={dynamicQrdata?.firstSectionQrAndroid}
                  modalText={dynamicQrdata?.firstSectionQrModalText}
                  or={dynamicQrdata?.firstSectionQrOr}
                  style={undefined}
                  disabled={dynamicQrdata?.qrCodeDisable}
                />
              </div>
            )}
          </div> */}
        </div>
        <div className={classNames(styles.rightSide)}>
          <FooterList />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
