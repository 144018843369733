import { toast, ToastOptions } from "react-toastify";
import client from "src/api/axios";
type MessageBucketType = {
  pending?: string;
  success?: string;
  error?: string;
};

type NotificationsServiceType = Record<string, NotificationServiceFnType>;
type NotificationServiceFnType = <T>(promise: Promise<T>) => Promise<T>;

const notificationOptions: ToastOptions = {
  position: toast.POSITION.BOTTOM_RIGHT,
};

const messageBuilder = <T>(promise: Promise<T>, messages: MessageBucketType) =>
  toast.promise(promise, messages, notificationOptions);

const notificationMessages: Record<string, MessageBucketType> = {
  walletConnect: {
    pending: "Connecting the wallet",
    success: "Wallet connected successfully",
    error: "Wallet connection failed",
  },
  walletSelect: {
    pending: "Fetching the wallet",
  },
};

export const NotificationsService: NotificationsServiceType = {
  onWalletSelect: (promise) => messageBuilder(promise, notificationMessages.walletSelect),
  onWalletAuth: (promise) => messageBuilder(promise, notificationMessages.walletConnect),
};

export const getNofications = async () => {
  return await client.get(`/notifications/pages?page=1&limit=20`);
};
export const getChats = async () => {
  return await client.get(`/chats/my`);
};
