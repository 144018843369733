export default () => {
  return (
    <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.4375 1.5L4.56417 4.62638C4.60514 4.66741 4.66076 4.69047 4.71875 4.69047C4.77674 4.69047 4.83236 4.66741 4.87333 4.62638L8 1.5"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
